import { CourseFilterType } from './enums'
import { Media, VideoMedia } from './timelineMedia'

export enum InfoResultType {
  Series,
  Question,
  UserResult,
  CourseResult,
}

export interface CourseTab {
  media: Media | VideoMedia | null
  date: string
  filterType: CourseFilterType
  isCourseCompleted: boolean
  hasStartedEpisode: boolean
  id: string
  percent: number
  name: string
  unreadMessagesCount: number
  isCommentsFollowed: boolean
  viewOnly: boolean
}
