import React from 'react'
import styled from 'styled-components/macro'
import { SearchLoop } from 'assets/svg'
import { TextAvenirNextRegular } from 'modules/common/components/Typography'
import { useBrandingColors } from 'hooks/useBrandingColors'
import { useTranslation } from 'react-i18next'
import { ITheme } from 'styles/types'
import { getScaledValue } from 'utils/scale'

interface IProps {
  onClick: () => void
  hasGroup?: boolean
}

const SearchSection: React.FC<IProps> = ({ onClick, hasGroup = true }) => {
  const { icon, text } = useBrandingColors()
  const { t } = useTranslation()

  return (
    <SearchContainer onClick={onClick}>
      <Placeholder textColor={text}>
        {hasGroup ? t('ADD_MENTION.SELECT_PERSON_GROUP') : t('ADD_MENTION.SELECT_PERSON')}
      </Placeholder>
      <IconWrapper>
        <SearchLoop color={icon} />
      </IconWrapper>
    </SearchContainer>
  )
}

export default SearchSection

const SearchContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
`

const Placeholder = styled(TextAvenirNextRegular)<{ textColor: string }>`
  color: ${({ textColor }) => textColor};
  font-size: ${getScaledValue(18, 'px')};
  margin-bottom: 0 !important;
  line-height: 1.5;
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    font-size: 18px;
    line-height: 1.8;
  }
`

const IconWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 0px;
  line-height: 0;

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    top: 2px;
  }
`
