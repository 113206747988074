import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const CheckmarkSvg = () => {
  const { backgroundStart, iconOnBackground } = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="64.691"
      height="69.563"
      viewBox="0 0 64.691 69.563"
    >
      <defs>
        <filter
          id="Контур_1082"
          x="0"
          y="0"
          width="64.691"
          height="69.563"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Сгруппировать_4577"
        data-name="Сгруппировать 4577"
        transform="translate(-159.476 -2688.495)"
      >
        <g transform="matrix(1, 0, 0, 1, 159.48, 2688.49)" filter="url(#Контур_1082)">
          <path
            id="Контур_1082-2"
            data-name="Контур 1082"
            d="M0,25.167c.133-2.862.067-5.658.4-8.521A10.814,10.814,0,0,1,5.658,8.458C9.32,6.128,13.114,4,16.842,1.8c4.127-2.463,8.255-2.33,12.382.067,3.328,2,6.657,3.994,9.985,5.925,3.928,2.33,6.257,5.725,6.391,10.318.133,5.126.067,10.318-.2,15.444a9.991,9.991,0,0,1-4.593,7.855c-4.194,2.729-8.521,5.326-12.914,7.789a10.131,10.131,0,0,1-10.185-.067c-4.061-2.263-7.988-4.66-11.916-7.123A11.96,11.96,0,0,1,.133,31.624c-.067-2.13,0-4.26,0-6.457Z"
            transform="translate(9.52 6.49)"
            fill={backgroundStart}
            stroke="#fff"
            strokeWidth="1"
          />
        </g>
        <path
          id="Контур_1095"
          data-name="Контур 1095"
          d="M28.462,0C19.583,2.64,13.1,9.167,11.279,18.191A16.009,16.009,0,0,0,.72,8.831c-.24.48-.48.96-.72,1.488A19.846,19.846,0,0,1,10.655,26.158c.528-.048.96-.048,1.392-.1.432.048.912.048,1.392.1,1.248-10.7,6.576-18.623,16.079-23.95C29.182,1.488,28.8.72,28.462,0Z"
          transform="translate(177.686 2707.445)"
          fill={iconOnBackground}
        />
      </g>
    </svg>
  )
}
