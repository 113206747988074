import { PostResponse } from 'api/timeline/types/response'
import { TimelinePost } from 'store/types'
import { mapMedia } from './TimeLineMapper'

export const postMapper = (post: PostResponse): TimelinePost => ({
  id: post.id,
  postId: post.postId,
  dateActiveFrom: post.dateActiveFrom,
  categoryTitle: post.categoryTitle,
  categoryLogo: post.categoryLogo,
  categoryType: post.categoryType,
  postTitle: post.title,
  postText: post.text,
  media: mapMedia(post.visual),
  attachment: post.attachment,
  postItems: post.postItems,
  answer: post.answer,
  unreadCommentsCount: post.unreadMessageCount,
  hasNote: post.hasNote,
  isRead: post.isRead,
  journeyId: post.journeyId,
  isFollowed: post.isFollowed,
  isHighlighted: post.isHighlighted,
  sessionActivityId: post.sessionActivityId,
  journeyActivityId: post.journeyActivityId,
  sessionPostAppointment: post.sessionPostAppointment,
  sessionPostAppointmentOld: post.sessionPostAppointmentOld,
  sessionPostPlace: post.sessionPostPlace,
  sessionPostPlaceOld: post.sessionPostPlaceOld,
})
