import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

const scaleFactor = 1.2

export const MoodGood = () => {
  const brandingColors = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      style={{ transform: `scale(${scaleFactor})` }}
    >
      <g id="Сгруппировать_1122" data-name="Сгруппировать 1122" transform="translate(1392 254)">
        <path
          id="Контур_1127"
          data-name="Контур 1127"
          d="M132.4,12.2a19.557,19.557,0,0,0-4.3-6.35,19.558,19.558,0,0,0-6.35-4.3A20.416,20.416,0,0,0,114,0a20.863,20.863,0,0,0-7.8,1.55,19.558,19.558,0,0,0-6.35,4.3,20.971,20.971,0,0,0-4.3,6.35,20.4,20.4,0,0,0,0,15.6A20.286,20.286,0,0,0,106.2,38.45a20.4,20.4,0,0,0,15.6,0,19.558,19.558,0,0,0,6.35-4.3,20.971,20.971,0,0,0,4.3-6.35A20.863,20.863,0,0,0,134,20,19.974,19.974,0,0,0,132.4,12.2Zm-5.95,20.3A17.6,17.6,0,0,1,96.4,20a17.756,17.756,0,0,1,5.15-12.5A17.6,17.6,0,0,1,131.6,20,17.461,17.461,0,0,1,126.45,32.5Z"
          transform="translate(-1486 -254)"
          fill={brandingColors.iconOnBackground}
        />
        <path
          id="Контур_1128"
          data-name="Контур 1128"
          d="M124.473,51.394a18.848,18.848,0,0,1-7.85-1.7,1.212,1.212,0,0,0-1.55.5h0a1.17,1.17,0,0,0,.55,1.6,21.058,21.058,0,0,0,17.7,0,1.2,1.2,0,0,0,.55-1.6h0a1.2,1.2,0,0,0-1.55-.5A19.257,19.257,0,0,1,124.473,51.394Z"
          transform="translate(-1496.473 -278.794)"
          fill={brandingColors.iconOnBackground}
        />
        <circle
          id="Эллипс_70"
          data-name="Эллипс 70"
          cx="2.95"
          cy="2.95"
          r="2.95"
          transform="translate(-1381.05 -241.6)"
          fill={brandingColors.iconOnBackground}
        />
        <circle
          id="Эллипс_71"
          data-name="Эллипс 71"
          cx="2.95"
          cy="2.95"
          r="2.95"
          transform="translate(-1368.85 -241.6)"
          fill={brandingColors.iconOnBackground}
        />
      </g>
    </svg>
  )
}
