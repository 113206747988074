import React from 'react'
import styled from 'styled-components/macro'
import { getScaledValue } from 'utils/scale'
import { TextAvenirNextRegular, TextCalibriBold } from 'modules/common/components/Typography'
import { useBrandingColors } from 'hooks/useBrandingColors'
import { UserOutlined, VisibleSvg, AnonymousSvg, GroupOutlined } from 'assets/svg'
import Avatar from 'modules/common/components/Avatar'
import { User } from 'store/types'
import { useTranslation } from 'react-i18next'
import { ITheme } from 'styles/types'

interface IProps {
  viewer: User
  isAnonymous?: boolean
  isEveryone?: boolean
  isHandler?: boolean
}

const PrivacyViewer: React.FC<IProps> = ({
  viewer,
  isAnonymous = false,
  isEveryone = false,
  isHandler = false,
}) => {
  const brandingColors = useBrandingColors()
  const { t } = useTranslation()

  return (
    <ViewerItem>
      <Left>
        <IconWrapper>
          <Avatar
            logo={viewer.logo}
            defaultLogo={isEveryone ? GroupOutlined : UserOutlined}
            size={50}
          />
        </IconWrapper>
        <NameAndPosition>
          <Name textColor={brandingColors.textOnBackground}>{viewer.fullName}</Name>
          <Position textColor={brandingColors.textOnBackground}>
            {isHandler ? t('PRIVACY.HANDLER_OF_COMPLAINT') : viewer.position}
          </Position>
        </NameAndPosition>
      </Left>
      <Right>
        <StatusIcon>{isAnonymous ? <AnonymousSvg /> : <VisibleSvg />}</StatusIcon>
      </Right>
    </ViewerItem>
  )
}

export default PrivacyViewer

const ViewerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${getScaledValue(12, 'px')};
`
const Left = styled.div`
  display: flex;
  align-items: center;
`
const Right = styled.div``

const IconWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: ${getScaledValue(42, 'px')};
  height: ${getScaledValue(42, 'px')};
  margin-right: ${getScaledValue(6, 'px')};
  line-height: 0;
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    width: 50px;
    height: 50px;
  }
`

const NameAndPosition = styled.div`
  display: flex;
  flex-direction: column;
`

const Name = styled(TextCalibriBold)<{ textColor: string }>`
  color: ${({ textColor }) => textColor};
  font-size: ${getScaledValue(16, 'px')};
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    font-size: 16px;
  }
`
const Position = styled(TextAvenirNextRegular)<{ textColor: string }>`
  color: ${({ textColor }) => textColor};
  font-size: ${getScaledValue(17, 'px')};
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    font-size: 17px;
  }
`

const StatusIcon = styled.div`
  line-height: 0;

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    transform: scale(0.9);
  }
`
