import React from 'react'

export const SlidingMenuBackground = (props: { startColor: string; stopColor: string }) => (
  <svg width="100%" height="700" viewBox="0 0 375 519.861" preserveAspectRatio="none">
    <defs>
      <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="100%">
        <stop offset="0" stopColor={props.startColor} />
        <stop offset="1" stopColor={props.stopColor} />
      </linearGradient>
    </defs>
    <path d="M0,0 H375 V483.411 l-187.177,36.45 L0,483.411Z" fill="url(#gradient)" opacity={0.95} />
  </svg>
)
