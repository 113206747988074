import {
  LikeDislikeAnswerOption,
  MoodAnswerOptions,
  SliderKey,
  TimelineItemQuestionType,
  TimelineQuestion,
  YesNoAnswerOption,
} from 'store/types'
import { mapMedia } from './TimeLineMapper'
import { EpisodeItemType } from 'store/types/episode'
import { TimeLineQuestionResponse } from 'api/timeline/types/response'

const MULTIPLE_CHOICE_QUESTION = 0
const YES_OR_NO_QUESTION = 1
const LIKE_OR_DISLIKE_QUESTION = 2
const MOOD_QUESTION = 3
const SLIDER_BAR_QUESTION = 4
const TEXT = 7
const VISUAL = 8
const ATTACHMENT = 9
const OPEN = 10
const TIP = 11

const LIKE_ANSWER = 'Like'

const YES_ANSWER = 'Yes'

const MOOD_GOOD = 'Good'
const MOOD_BEST = 'Best'
const MOOD_COMMON = 'Common'
const MOOD_BAD = 'Bad'
const MOOD_WORSE = 'Worse'

const SLIDER_FIRST_VALUE = '0'
const SLIDER_SECOND_VALUE = '1'
const SLIDER_THIRD_VALUE = '2'
const SLIDER_FOURTH_VALUE = '3'
const SLIDER_FIFTH_VALUE = '4'

const mapLikeDislikeAnswerKey = (answerKey: string) =>
  answerKey === LIKE_ANSWER ? LikeDislikeAnswerOption.Like : LikeDislikeAnswerOption.Dislike

const mapYesNoAnswerKey = (answerKey: string) =>
  answerKey === YES_ANSWER ? YesNoAnswerOption.Yes : YesNoAnswerOption.No

const mapSliderKey = (answerKey: string) => {
  switch (answerKey) {
    case SLIDER_FIRST_VALUE:
      return SliderKey.First
    case SLIDER_SECOND_VALUE:
      return SliderKey.Second
    case SLIDER_THIRD_VALUE:
      return SliderKey.Third
    case SLIDER_FOURTH_VALUE:
      return SliderKey.Fourth
    case SLIDER_FIFTH_VALUE:
      return SliderKey.Fifth
    default:
      throw new Error('Please provide valid sliker key')
  }
}

const mapMoodAnswerKey = (answerKey: string) => {
  switch (answerKey) {
    case MOOD_WORSE:
      return MoodAnswerOptions.Worse
    case MOOD_BAD:
      return MoodAnswerOptions.Bad
    case MOOD_COMMON:
      return MoodAnswerOptions.Common
    case MOOD_GOOD:
      return MoodAnswerOptions.Good
    case MOOD_BEST:
      return MoodAnswerOptions.Best
    default:
      throw new Error('Please provide correct mood type')
  }
}

export const mapTimeLineQuestionType = (type: number) => {
  switch (type) {
    case MULTIPLE_CHOICE_QUESTION:
      return TimelineItemQuestionType.Multiple
    case YES_OR_NO_QUESTION:
      return TimelineItemQuestionType.YesNo
    case LIKE_OR_DISLIKE_QUESTION:
      return TimelineItemQuestionType.LikeDislike
    case MOOD_QUESTION:
      return TimelineItemQuestionType.Mood
    case SLIDER_BAR_QUESTION:
      return TimelineItemQuestionType.Slider
    case TEXT:
      return TimelineItemQuestionType.Text
    case VISUAL:
      return TimelineItemQuestionType.Visual
    case ATTACHMENT:
      return TimelineItemQuestionType.Attachment
    default:
      throw new Error('Provide valid question type')
  }
}

export const mapEpisodeItemType = (type: number) => {
  switch (type) {
    case MULTIPLE_CHOICE_QUESTION:
      return EpisodeItemType.Multiple
    case YES_OR_NO_QUESTION:
      return EpisodeItemType.YesNo
    case LIKE_OR_DISLIKE_QUESTION:
      return EpisodeItemType.LikeDislike
    case MOOD_QUESTION:
      return EpisodeItemType.Mood
    case SLIDER_BAR_QUESTION:
      return EpisodeItemType.Slider
    case TEXT:
      return EpisodeItemType.Text
    case VISUAL:
      return EpisodeItemType.Visual
    case ATTACHMENT:
      return EpisodeItemType.Attachment
    case OPEN:
      return EpisodeItemType.Open
    case TIP:
      return EpisodeItemType.Tip
    default:
      throw new Error('Provide valid question type')
  }
}

export const mapAnswerKey = (type: number, answerKey: string) => {
  switch (type) {
    case LIKE_OR_DISLIKE_QUESTION:
      return mapLikeDislikeAnswerKey(answerKey)
    case MOOD_QUESTION:
      return mapMoodAnswerKey(answerKey)
    case YES_OR_NO_QUESTION:
      return mapYesNoAnswerKey(answerKey)
    case MULTIPLE_CHOICE_QUESTION:
      return answerKey
    case SLIDER_BAR_QUESTION:
      return mapSliderKey(answerKey)
    case OPEN:
      return answerKey
    default:
      throw new Error('Provide valid quesiton type')
  }
}

export const questionMapper = (data: TimeLineQuestionResponse): TimelineQuestion => {
  return {
    id: data.id,
    questionId: data.questionId,
    date: data.dateActiveFrom,
    media: mapMedia(data.attachment),
    text: data.text ? data.text : '',
    variants: data.answerOptions.map((answer) => ({
      id: answer.id,
      value: answer.value !== null ? answer.value : '',
      key: mapAnswerKey(data.type, answer.key),
      isAnswerCorrect: false,
      isAnswerSaved: false,
    })),
    answer: data.answer
      ? {
          id: data.answer.id,
          value: data.answer.value,
          key: mapAnswerKey(data.type, data.answer.key),
          isAnswerCorrect: false,
          isAnswerSaved: false,
        }
      : null,
    itemType: mapTimeLineQuestionType(data.type),
    questionType: mapTimeLineQuestionType(data.type),
    properties: data.properties ? JSON.parse(data.properties) : {},
    unreadCommentsCount: data.unreadCommentsCount,
    isSeriesQuestion: data.isSeriesQuestion,
    concerning: data.concerning,
    isSaved: data.answer !== null,
    isSaveInProgress: data.isSaveInProgress,
    value: data.value,
    min: data.min,
    max: data.max,
    answered: !!data.answer,
  }
}
