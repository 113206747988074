import styled from 'styled-components/macro'
import { ITheme } from 'styles/types'

export const TextCalibriBold = styled.span`
  font-size: 23px;
  font-weight: 700;
  line-height: 1;
  color: ${({ theme }: { theme: ITheme }) => theme.text.white};
  font-family: 'Calibri';

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.md} {
    font-size: 21px;
  }

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    font-size: 18px;
  }
`

export const TextCalibriRegular = styled.span`
  font-size: 23px;
  font-family: 'CalibriRegular';
  color: ${({ theme }: { theme: ITheme }) => theme.text.white};

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.md} {
    font-size: 21px;
  }

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    font-size: 18px;
  }
`
