import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const PersonOutlinedSmall = () => {
  const { frame } = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="74"
      height="74"
      viewBox="0 0 74 70"
    >
      <defs>
        <filter id="Эллипс_1425" x="0" y="0" width="74" height="74" filterUnits="userSpaceOnUse">
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Сгруппировать_4591" data-name="Сгруппировать 4591" transform="translate(-236 -4408)">
        <g transform="matrix(1, 0, 0, 1, 236, 4408)" filter="url(#Эллипс_1425)">
          <g
            id="Эллипс_142-2"
            data-name="Эллипс 142"
            transform="translate(9 6)"
            fill="#fff"
            stroke={frame}
            strokeWidth="1"
          >
            <circle cx="28" cy="28" r="28" stroke="none" />
            <circle cx="28" cy="28" r="27.5" fill="none" />
          </g>
        </g>
        <g id="Сгруппировать_4590" data-name="Сгруппировать 4590" transform="translate(245 4414)">
          <g id="Сгруппировать_410" data-name="Сгруппировать 410">
            <g id="Эллипс_49" data-name="Эллипс 49" fill="#fff" stroke={frame} strokeWidth="0.2">
              <circle cx="28" cy="28" r="28" stroke="none" />
              <circle cx="28" cy="28" r="27.9" fill="none" />
            </g>
            <ellipse
              id="Эллипс_46"
              data-name="Эллипс 46"
              cx="7.893"
              cy="7.893"
              rx="7.893"
              ry="7.893"
              transform="translate(19.926 15.306)"
              fill="none"
              stroke={frame}
              strokeMiterlimit="10"
              strokeWidth="0.8"
            />
            <path
              id="Контур_546"
              data-name="Контур 546"
              d="M3,146.71a14.124,14.124,0,0,1,11.8-6.31h0a14.124,14.124,0,0,1,11.752,6.245"
              transform="translate(13.002 -106.446)"
              fill="none"
              stroke={frame}
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="0.8"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
