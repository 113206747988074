import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store'

const searchState = (state: RootState) => state.search

export const selectSearchCategories = createSelector(searchState, (state) => state.searchCategories)
export const selectSearchPostById = (id: string) =>
  createSelector(searchState, (state) => (state.posts ? state.posts[id] : null))
export const selectSearchCourseById = (id: string) =>
  createSelector(searchState, (state) => (state.courses ? state.courses[id] : null))

export const selectLoadingCategoryId = createSelector(
  searchState,
  (state) => state.loadingCategoryId
)

export const selectIsCategoryExpanded = (id: string) =>
  createSelector(searchState, (state) => state.expandedCategories.includes(id))

export const selectIsLoading = createSelector(searchState, (state) => state.isLoading)
