import { CourseTabItem } from 'api/user/types/response'
import { CourseTab } from 'store/types/info'
import { mapMedia } from './TimeLineMapper'

export const mapCourseInfo = (courses: CourseTabItem[]): CourseTab[] =>
  courses.map((course) => ({
    media: mapMedia(course.attachment),
    date: course.createdDate,
    filterType: course.filterType,
    id: course.id,
    isCourseCompleted: course.isCourseCompleted,
    hasStartedEpisode: course.hasStartedEpisode,
    name: course.name,
    percent: course.percent,
    unreadMessagesCount: course.unreadMessagesCount,
    isCommentsFollowed: course.isCommentsFollowed,
    viewOnly: course.viewOnly,
  }))
