import { RootState } from 'store'
import { createSelector } from '@reduxjs/toolkit'

const userState = (state: RootState) => state.user

export const selectCurrentUser = createSelector(userState, (state) => state.currentUser)

export const selectIsMasterUser = createSelector(
  userState,
  (state) => state.currentUser.credentialsInfo.isMasterUser
)

export const selectUsersList = createSelector(userState, (state) => state.users)

export const selectUsersSearchPage = createSelector(userState, (state) => state.searchPage)

export const selectUsersSearchTotal = createSelector(userState, (state) => state.total)

export const hasOneOrganization = createSelector(userState, (state) => state.hasOneOrganization)

export const selectUserLanguage = createSelector(
  userState,
  (state) => state.currentUser.credentialsInfo?.userLanguageId
)

export const selectUserIndividual = createSelector(userState, (state) => state.isUserIndividual)

export const isUserLoading = (state: RootState) => state.user.isLoading

export const selectIsUserListLoading = (state: RootState) => state.user.userListLoading
