import {
  createSlice,
  isFulfilled,
  isPending,
  isRejectedWithValue,
  PayloadAction,
} from '@reduxjs/toolkit'
import { ActiveInfoItem } from 'store/types'
import {
  getAboutMeUserInfo,
  changeUserLogo,
  getCourses,
  getMoreCourses,
  getMentions,
  getMoreMentions,
  searchMentions,
  getResults,
  searchResults,
  getMoreResults,
  getMyCoursesUserInfo,
  deleteUserAccount,
  getMyAccount,
  updateMyAccount,
} from './thunks'
import { AboutMeStateType, MyAccount } from './types'

const initialState: AboutMeStateType = {
  info: {
    complaintsCount: 0,
    complimentsCount: 0,
    tipsCount: 0,
    resultsCount: 0,
    courseCount: 0,
    commentsCount: 0,
    supportsCount: 0,
    id: '',
    logo: '',
    fullName: '',
  },
  myAccount: {} as MyAccount,
  organizationLanguages: [],
  results: null,
  courses: null,
  coursesTotal: 0,
  mentions: null,
  totalMentionsCount: 0,
  comments: null,
  extraTips: null,
  searchText: '',
  currentPage: 1,
  listEnded: false,
  totalPoints: 0,
  activeInfoItem: ActiveInfoItem.Results,
  isLoading: false,
  isTabContentLoading: false,
  error: null,
}

const aboutMeSlice = createSlice({
  name: 'about-me',
  initialState,
  reducers: {
    setMyAccountEmail: (state, action: PayloadAction<string | null>) => {
      state.myAccount.email = action.payload
    },
    setMyAccountPhoneNumber: (state, action: PayloadAction<string | null>) => {
      state.myAccount.phoneNumber = action.payload
    },
    setMyAccountName: (state, action: PayloadAction<string>) => {
      state.myAccount.name = action.payload
    },
    setMyAccountLanguage: (state, action: PayloadAction<number>) => {
      state.myAccount.languageId = action.payload
    },
    setMyAccountNoSmartphone: (state, action: PayloadAction<boolean>) => {
      state.myAccount.noSmartphone = action.payload
    },
    setMyAccountPhoneNumberAnonymous: (state, action: PayloadAction<boolean>) => {
      state.myAccount.phoneNumberAnonymous = action.payload
    },
    clearMyAccountState: (state) => {
      state.myAccount = {} as MyAccount
    },
    updateUserLogo: (state, action: PayloadAction<any>) => {
      state.info.logo = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAboutMeUserInfo.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAboutMeUserInfo.rejected, (state, action) => {
      if (action.payload) {
        state.error = {
          message: action.payload.message,
        }
      }

      state.isLoading = false
    })
    builder.addCase(getAboutMeUserInfo.fulfilled, (state, action) => {
      state.info = action.payload.userInfo
      state.organizationLanguages = action.payload.orgLanguages
      state.isLoading = false
    })
    builder.addCase(getMyCoursesUserInfo.fulfilled, (state, action) => {
      state.info = action.payload
      state.isLoading = false
    })

    builder.addCase(getCourses.fulfilled, (state, action) => {
      state.courses = action.payload.data
      state.coursesTotal = action.payload.total
    })

    builder.addCase(getMoreCourses.fulfilled, (state, action) => {
      if (state.courses) {
        state.courses = [...state.courses, ...action.payload.data]
        state.coursesTotal = action.payload.total
      }
    })

    builder.addCase(getMentions.fulfilled, (state, action) => {
      state.mentions = action.payload.data
      state.totalMentionsCount = action.payload.total
    })

    builder.addCase(getMoreMentions.fulfilled, (state, action) => {
      if (state.mentions) {
        state.mentions = [...state.mentions, ...action.payload.data]
      }
    })

    builder.addCase(searchMentions.fulfilled, (state, action) => {
      state.mentions = action.payload.data
    })

    builder.addCase(getResults.fulfilled, (state, action) => {
      state.results = action.payload.results.data
      state.totalPoints = action.payload.totalPoints
    })

    builder.addCase(getMoreResults.fulfilled, (state, action) => {
      if (state.results) {
        state.results = [...state.results, ...action.payload.results.data]
      }
    })

    builder.addCase(searchResults.fulfilled, (state, action) => {
      if (action.payload) {
        state.results = action.payload.data
      }
    })
    builder.addCase(getMyAccount.fulfilled, (state, action) => {
      if (action.payload) {
        state.myAccount = action.payload
      }
    })

    builder.addCase(updateMyAccount.rejected, (state, action) => {
      if (action.payload) {
        state.myAccount.error = action.payload.message
      }
    })

    builder.addMatcher(
      isFulfilled(
        getAboutMeUserInfo,
        getMyCoursesUserInfo,
        changeUserLogo,
        getCourses,
        getMentions,
        searchMentions,
        getResults,
        searchResults,
        deleteUserAccount,
        getMyAccount,
        updateMyAccount
      ),
      (state) => {
        state.isTabContentLoading = false
        state.error = null
      }
    )

    builder.addMatcher(
      isPending(
        getAboutMeUserInfo,
        getMyCoursesUserInfo,
        changeUserLogo,
        getCourses,
        getMentions,
        getResults,
        searchMentions,
        searchResults,
        deleteUserAccount,
        getMyAccount,
        updateMyAccount
      ),
      (state) => {
        state.isTabContentLoading = true
        state.error = null
      }
    )
    builder.addMatcher(
      isRejectedWithValue(
        getAboutMeUserInfo,
        getMyCoursesUserInfo,
        changeUserLogo,
        getCourses,
        getMentions,
        getResults,
        searchMentions,
        searchResults,
        deleteUserAccount,
        getMyAccount,
        updateMyAccount
      ),
      (state, action) => {
        state.error = {
          message: action.payload.message,
        }
        state.isTabContentLoading = false
      }
    )
  },
})

export const {
  updateUserLogo,
  setMyAccountEmail,
  setMyAccountPhoneNumber,
  setMyAccountName,
  setMyAccountLanguage,
  setMyAccountNoSmartphone,
  setMyAccountPhoneNumberAnonymous,
  clearMyAccountState,
} = aboutMeSlice.actions
export const aboutMeReducer = aboutMeSlice.reducer
