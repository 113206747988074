import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { checkPassword } from './thunks'

interface PasswordCheckStateType {
  isPasswordConfirmed: boolean
  isLoading: boolean
  error: {
    message: string
  } | null
}

const initialState: PasswordCheckStateType = {
  isPasswordConfirmed: false,
  isLoading: false,
  error: null,
}

const passwordCheck = createSlice({
  name: 'password-check',
  initialState,
  reducers: {
    resetPasswordConfirmed: (state) => {
      state.isPasswordConfirmed = false
      state.error = null
    },

    clearError: (state) => {
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkPassword.fulfilled, (state) => {
      state.isPasswordConfirmed = true
      state.isLoading = false
    })

    builder.addCase(checkPassword.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(checkPassword.rejected, (state, action) => {
      state.isLoading = false
      if (action.payload) {
        state.error = action.payload
      }
    })
  },
})

export const passwordCheckReducer = passwordCheck.reducer
export const { clearError, resetPasswordConfirmed } = passwordCheck.actions
export const selectError = (state: RootState) => state.passwordCheck.error
export const selectIsLoading = (state: RootState) => state.passwordCheck.isLoading
export const selectIsPasswordConfirmed = (state: RootState) =>
  state.passwordCheck.isPasswordConfirmed
