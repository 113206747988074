import {
  createSlice,
  isPending,
  isRejectedWithValue,
  isFulfilled,
  PayloadAction,
} from '@reduxjs/toolkit'
import { LanguageType } from 'api/auth/types/response'
import { Organization } from 'store/types'
import {
  forgotPassword,
  getUserOrganizations,
  checkIsRegistered,
  sendAccessCode,
  saveAccessCode,
  getCurrentStep,
  getOrganizationsList,
  getLanguages,
  savePassword,
  saveUserLogo,
} from './thunks'
import { AuthStateType } from './types'

const initialState: AuthStateType = {
  confirmedUsers: [],
  currentUserLoginDetails: {
    loginData: '',
    loginMethod: 0,
    registrationToken: '',
  },
  organizations: [],
  languages: [],
  defaultUserLanguage: {} as LanguageType,
  logo: null,
  currentStep: null,
  success: false,
  error: null,
  isLoading: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUserLoginDetails: (
      state,
      action: PayloadAction<{ loginData: string; loginMethod: 0 | 1 }>
    ) => {
      state.currentUserLoginDetails.loginData = action.payload.loginData
      state.currentUserLoginDetails.loginMethod = action.payload.loginMethod
    },

    setAvailableOranizations: (state, action: PayloadAction<Organization[]>) => {
      state.organizations = action.payload
    },

    addUserToConfirmed: (
      state,
      action: PayloadAction<{ loginData: string; registrationToken: string }>
    ) => {
      const itemIndex = state.confirmedUsers.findIndex(
        (user) => user.loginData === action.payload.loginData
      )

      if (itemIndex === -1) {
        state.confirmedUsers.push(action.payload)
      }

      if (itemIndex !== -1) {
        state.confirmedUsers[itemIndex] = action.payload
      }
    },

    setAuthStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload
    },

    resetAuthStep: (state) => {
      state.currentStep = initialState.currentStep
    },

    clearAsyncMessage: (state) => {
      state.error = null
      state.success = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserOrganizations.fulfilled, (state, action) => {
      state.organizations = action.payload
    })

    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.success = true
    })

    builder.addCase(saveAccessCode.fulfilled, (state, action) => {
      if (action.payload.registrationToken) {
        state.currentUserLoginDetails.registrationToken = action.payload.registrationToken
        state.currentStep = action.payload.registrationStep
      }
    })

    builder.addCase(getCurrentStep.fulfilled, (state, action) => {
      state.currentStep = action.payload.registrationStep
      state.defaultUserLanguage = action.payload.language
      state.logo = action.payload.logo
    })

    builder.addCase(getOrganizationsList.fulfilled, (state, action) => {
      state.organizations = action.payload
    })

    builder.addCase(getLanguages.fulfilled, (state, action) => {
      state.languages = action.payload
    })

    builder.addMatcher(isFulfilled(forgotPassword, sendAccessCode), (state) => {
      state.success = true
    })

    builder.addMatcher(
      isFulfilled(
        forgotPassword,
        getUserOrganizations,
        // checkIsRegistered,
        sendAccessCode,
        saveAccessCode,
        saveUserLogo,
        savePassword,
        // getCurrentStep,
        getOrganizationsList,
        getLanguages
      ),
      (state) => {
        state.isLoading = false
        state.error = null
      }
    )

    builder.addMatcher(
      isPending(
        forgotPassword,
        getUserOrganizations,
        // checkIsRegistered,
        sendAccessCode,
        saveAccessCode,
        savePassword,
        // getCurrentStep,
        getOrganizationsList,
        getLanguages,
        saveUserLogo
      ),
      (state) => {
        state.isLoading = true
        state.error = null
        state.success = false
      }
    )
    builder.addMatcher(
      isRejectedWithValue(
        forgotPassword,
        getUserOrganizations,
        checkIsRegistered,
        sendAccessCode,
        saveAccessCode,
        // getCurrentStep,
        savePassword,
        getOrganizationsList,
        getLanguages,
        saveUserLogo
      ),
      (state, action) => {
        state.error = {
          message: action.payload.message,
        }

        state.isLoading = false
        state.success = false
      }
    )
  },
})

export const {
  clearAsyncMessage,
  setCurrentUserLoginDetails,
  addUserToConfirmed,
  setAvailableOranizations,
  resetAuthStep,
  setAuthStep,
} = authSlice.actions
export const authReducer = authSlice.reducer
