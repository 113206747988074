import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store'

const mentionsState = (state: RootState) => state.mentions

export const selectSelectedUser = createSelector(mentionsState, (state) => state.selectedUser)

export const selectHandlerUser = createSelector(mentionsState, (state) => state.handlerUser)

export const selectMention = createSelector(mentionsState, (state) => state.mention)

export const selectMentionText = createSelector(mentionsState, (state) => state.mention.text)

export const selectMentionComment = createSelector(mentionsState, (state) => state.mention.comment)

export const selectMentionMedia = createSelector(mentionsState, (state) => state.mention.media)

export const selectIsMentionAnonymous = createSelector(
  mentionsState,
  (state) => state.mention.isAnonymous
)

export const selectNote = createSelector(mentionsState, (state) => state.note)

export const selectComments = createSelector(mentionsState, (state) => state.comment.data)

export const selectCommentsCount = createSelector(mentionsState, (state) => state.comment.total)

export const selectSupport = createSelector(mentionsState, (state) => state.support.data)

export const selectSupportCount = createSelector(mentionsState, (state) => state.support.total)

export const selectMentionPrivacySettings = createSelector(
  mentionsState,
  (state) => state.mentionPrivacySettings
)

export const selectComplaintPrivacySettings = createSelector(
  mentionsState,
  (state) => state.complaintPrivacySettings
)

export const isMentionsLoading = (state: RootState) => state.mentions.isLoading
