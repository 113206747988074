import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const SupportIcon = ({ isActive }: { isActive: boolean }) => {
  const { icon } = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="52.747"
      height="58.811"
      viewBox="0 0 52.747 58.811"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Контур_160"
            data-name="Контур 160"
            d="M44.567,13.379l13.187,7.889a13.679,13.679,0,0,1,6.593,11.774V48.819a13.536,13.536,0,0,1-6.593,11.774L44.567,68.481a12.648,12.648,0,0,1-13.187,0L18.193,60.593A13.679,13.679,0,0,1,11.6,48.819V33.042a13.536,13.536,0,0,1,6.593-11.774L31.38,13.379A12.649,12.649,0,0,1,44.567,13.379Z"
            transform="translate(-11.6 -11.525)"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Сгруппировать_4598" data-name="Сгруппировать 4598" transform="translate(-266.393 -38)">
        <g
          id="Сгруппировать_2711"
          data-name="Сгруппировать 2711"
          transform="translate(266.393 38)"
          opacity={isActive ? '1' : '0.503'}
        >
          <g
            id="Сгруппировать_53"
            data-name="Сгруппировать 53"
            transform="translate(0 0)"
            clipPath="url(#clip-path)"
          >
            <rect
              id="Прямоугольник_7"
              data-name="Прямоугольник 7"
              width="64.756"
              height="70.643"
              transform="translate(-6.005 -5.916)"
              fill="#fff"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
          </g>
          <path
            id="Контур_161"
            data-name="Контур 161"
            d="M44.567,13.379l13.187,7.889a13.679,13.679,0,0,1,6.593,11.774V48.819a13.536,13.536,0,0,1-6.593,11.774L44.567,68.481a12.648,12.648,0,0,1-13.187,0L18.193,60.593A13.679,13.679,0,0,1,11.6,48.819V33.042a13.536,13.536,0,0,1,6.593-11.774L31.38,13.379A12.649,12.649,0,0,1,44.567,13.379Z"
            transform="translate(-11.6 -11.525)"
            fill="#fff"
          />
        </g>
        <g
          id="Сгруппировать_3123"
          data-name="Сгруппировать 3123"
          transform="translate(275.857 51.317)"
        >
          <path
            id="Контур_7895"
            data-name="Контур 7895"
            d="M-1144.936,661.237c-9.382,0-16.988,6.909-16.988,15.432a14.721,14.721,0,0,0,5.013,10.944l-.618,6.376a1.02,1.02,0,0,0,1.5,1l6.494-3.461a18.639,18.639,0,0,0,4.6.574c9.382,0,16.988-6.909,16.988-15.432s-7.606-15.432-16.988-15.432m-.1,23.458a1.869,1.869,0,0,1-1.952-1.85,1.825,1.825,0,0,1,1.952-1.808,1.824,1.824,0,0,1,1.951,1.808,1.868,1.868,0,0,1-1.951,1.85m1.525-5.1h-3.07c0-2.846,3.171-3.476,3.171-5.245,0-1-.875-1.626-2.2-1.626a3.442,3.442,0,0,0-2.947,1.5l-2.46-1.442a6.477,6.477,0,0,1,5.753-2.725c3.048,0,5.163,1.362,5.163,3.761,0,3.252-3.415,3.618-3.415,5.773"
            transform="translate(1161.924 -661.237)"
            fill={isActive ? icon : '#fff'}
          />
        </g>
      </g>
    </svg>
  )
}
