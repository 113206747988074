import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const MoodCommonSmall = ({ isActive }: { isActive: boolean }) => {
  const { icon } = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="74.84"
      height="81.564"
      viewBox="0 0 74.84 81.564"
    >
      <defs>
        <filter
          id="Контур_1136"
          x="0"
          y="0"
          width="74.84"
          height="81.564"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Сгруппировать_4599"
        data-name="Сгруппировать 4599"
        transform="translate(-153.629 -740.318)"
      >
        <g transform="matrix(1, 0, 0, 1, 153.63, 740.32)" filter="url(#Контур_1136)">
          <path
            id="Контур_1136-2"
            data-name="Контур 1136"
            d="M46.5,13.488l13.96,8.351A14.481,14.481,0,0,1,67.44,34.3v16.7A14.33,14.33,0,0,1,60.46,63.47L46.5,71.821a13.39,13.39,0,0,1-13.96,0L18.58,63.47A14.481,14.481,0,0,1,11.6,51.006V34.3a14.33,14.33,0,0,1,6.98-12.464l13.96-8.351A13.39,13.39,0,0,1,46.5,13.488Z"
            transform="translate(-2.1 -4.89)"
            fill={isActive ? '#fff' : 'transparent'}
            stroke="#fff"
            opacity={isActive ? '1' : '0.5'}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
        <g id="Сгруппировать_985" data-name="Сгруппировать 985" transform="translate(171 758.082)">
          <path
            id="Контур_1131"
            data-name="Контур 1131"
            d="M232.057,12.375a20.578,20.578,0,0,0-10.8-10.8A20.715,20.715,0,0,0,213.39,0a21.169,21.169,0,0,0-7.913,1.572,19.841,19.841,0,0,0-6.442,4.362,21.271,21.271,0,0,0-4.362,6.441,20.689,20.689,0,0,0,0,15.823,20.579,20.579,0,0,0,10.8,10.8A20.7,20.7,0,0,0,221.3,39a19.841,19.841,0,0,0,6.442-4.362,21.27,21.27,0,0,0,4.362-6.441,21.158,21.158,0,0,0,1.572-7.912A20.256,20.256,0,0,0,232.057,12.375Zm-6.036,20.59a17.855,17.855,0,0,1-30.486-12.679,18.008,18.008,0,0,1,5.225-12.679,17.855,17.855,0,0,1,30.486,12.679A17.709,17.709,0,0,1,226.02,32.965Z"
            transform="translate(-193.1 0)"
            fill={isActive ? icon : '#fff'}
            opacity={isActive ? '1' : '0.5'}
          />
          <path
            id="Контур_1132"
            data-name="Контур 1132"
            d="M215.419,54.837h16.707a1.236,1.236,0,0,0,1.219-1.219h0a1.236,1.236,0,0,0-1.219-1.219H215.419a1.236,1.236,0,0,0-1.219,1.219h0A1.235,1.235,0,0,0,215.419,54.837Z"
            transform="translate(-203.508 -25.827)"
            fill={isActive ? icon : '#fff'}
            opacity={isActive ? '1' : '0.5'}
          />
          <ellipse
            id="Эллипс_74"
            data-name="Эллипс 74"
            cx="2.996"
            cy="2.996"
            rx="2.996"
            ry="2.996"
            transform="translate(11.107 12.575)"
            fill={isActive ? icon : '#fff'}
            opacity={isActive ? '1' : '0.5'}
          />
          <ellipse
            id="Эллипс_75"
            data-name="Эллипс 75"
            cx="2.996"
            cy="2.996"
            rx="2.996"
            ry="2.996"
            transform="translate(23.481 12.575)"
            fill={isActive ? icon : '#fff'}
            opacity={isActive ? '1' : '0.5'}
          />
        </g>
      </g>
    </svg>
  )
}
