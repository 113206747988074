import React from 'react'

export const PinSvg = ({ color = '#fff' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.26"
      height="21.411"
      viewBox="0 0 21.26 21.411"
    >
      <g
        id="Сгруппировать_3153"
        data-name="Сгруппировать 3153"
        transform="translate(968.6 -539.79)"
      >
        <path
          id="Контур_7917"
          data-name="Контур 7917"
          d="M-960.818,552.172a3,3,0,0,1-.316-.228q-1.643-1.636-3.281-3.278a.574.574,0,0,1,.017-.96,2.6,2.6,0,0,1,3.344-.178.236.236,0,0,0,.36-.016q2.952-2.546,5.915-5.08a.246.246,0,0,0,.07-.338,1.87,1.87,0,0,1,.393-2.082.544.544,0,0,1,.865-.016q2.95,2.945,5.895,5.9a.559.559,0,0,1-.031.906,1.968,1.968,0,0,1-2.264.285l-5.357,6.243a2.676,2.676,0,0,1,.639,1.46,2.547,2.547,0,0,1-.786,2.159.535.535,0,0,1-.826-.045q-1.555-1.552-3.108-3.108c-.069-.07-.127-.151-.21-.251-.1.1-.178.167-.252.239-1.654,1.618-3.284,3.26-4.971,4.843-.936.878-1.965,1.658-2.955,2.478a.7.7,0,0,1-.769-.03.545.545,0,0,1,0-.755c1.231-1.437,2.434-2.9,3.715-4.292,1.149-1.248,2.384-2.417,3.584-3.618a2.64,2.64,0,0,1,.333-.233"
          fill={color}
        />
      </g>
    </svg>
  )
}
