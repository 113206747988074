import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const MoodBadSmall = ({ isActive }: { isActive: boolean }) => {
  const { icon } = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="74.84"
      height="81.564"
      viewBox="0 0 74.84 81.564"
    >
      <defs>
        <filter
          id="Контур_1137"
          x="0"
          y="0"
          width="74.84"
          height="81.564"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Сгруппировать_4598"
        data-name="Сгруппировать 4598"
        transform="translate(-89.537 -741.318)"
      >
        <g transform="matrix(1, 0, 0, 1, 89.54, 741.32)" filter="url(#Контур_1137)">
          <path
            id="Контур_1137-2"
            data-name="Контур 1137"
            d="M46.5,13.488l13.96,8.351A14.481,14.481,0,0,1,67.44,34.3v16.7A14.33,14.33,0,0,1,60.46,63.47L46.5,71.821a13.39,13.39,0,0,1-13.96,0L18.58,63.47A14.481,14.481,0,0,1,11.6,51.006V34.3a14.33,14.33,0,0,1,6.98-12.464l13.96-8.351A13.39,13.39,0,0,1,46.5,13.488Z"
            transform="translate(-2.1 -4.89)"
            fill={isActive ? '#fff' : 'transparent'}
            stroke="#fff"
            opacity={isActive ? '1' : '0.5'}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
        <g
          id="Сгруппировать_986"
          data-name="Сгруппировать 986"
          transform="translate(106.516 759.082)"
        >
          <path
            id="Контур_1133"
            data-name="Контур 1133"
            d="M336.157,12.372a20.577,20.577,0,0,0-10.8-10.8A20.718,20.718,0,0,0,317.49,0a21.172,21.172,0,0,0-7.913,1.572,19.841,19.841,0,0,0-6.442,4.361,21.266,21.266,0,0,0-4.362,6.44,20.681,20.681,0,0,0,0,15.82,20.576,20.576,0,0,0,10.8,10.8,20.7,20.7,0,0,0,15.826,0,19.842,19.842,0,0,0,6.442-4.361,21.266,21.266,0,0,0,4.362-6.44,21.15,21.15,0,0,0,1.573-7.91A20.25,20.25,0,0,0,336.157,12.372ZM330.12,32.959a17.855,17.855,0,0,1-30.486-12.677,18,18,0,0,1,5.225-12.677,17.855,17.855,0,0,1,30.486,12.677A17.7,17.7,0,0,1,330.12,32.959Z"
            transform="translate(-297.2 0)"
            fill={isActive ? icon : '#fff'}
            opacity={isActive ? '1' : '0.5'}
          />
          <path
            id="Контур_1134"
            data-name="Контур 1134"
            d="M318.275,53.8h0a1.215,1.215,0,0,0,1.574.508,19.567,19.567,0,0,1,7.974-1.726,19.152,19.152,0,0,1,7.974,1.726,1.231,1.231,0,0,0,1.574-.508h0a1.187,1.187,0,0,0-.559-1.625,21.4,21.4,0,0,0-17.98,0A1.187,1.187,0,0,0,318.275,53.8Z"
            transform="translate(-307.533 -24.749)"
            fill={isActive ? icon : '#fff'}
            opacity={isActive ? '1' : '0.5'}
          />
          <ellipse
            id="Эллипс_76"
            data-name="Эллипс 76"
            cx="2.997"
            cy="2.996"
            rx="2.997"
            ry="2.996"
            transform="translate(11.106 12.572)"
            fill={isActive ? icon : '#fff'}
            opacity={isActive ? '1' : '0.5'}
          />
          <ellipse
            id="Эллипс_77"
            data-name="Эллипс 77"
            cx="2.997"
            cy="2.996"
            rx="2.997"
            ry="2.996"
            transform="translate(23.48 12.572)"
            fill={isActive ? icon : '#fff'}
            opacity={isActive ? '1' : '0.5'}
          />
        </g>
      </g>
    </svg>
  )
}
