import { saveNote } from 'store/slices/mentions/thunks'
import {
  createSlice,
  isFulfilled,
  isPending,
  isRejectedWithValue,
  PayloadAction,
} from '@reduxjs/toolkit'
import { CommentModel, MentionsState, NoteModel } from './types'
import { MentionInfo, NewPositiveMentionInfo, SearchUserAndGroup } from 'store/types'
import {
  getComments,
  getComplaintPrivacySettings,
  getMentionPrivacySettings,
  getNote,
  getExtraTips,
  addComment,
  createCompliment,
  createTip,
  createComplaint,
  getComplaintChatPrivacySettings,
  getMentionChatPrivacySettings,
} from './thunks'

const initialState: MentionsState = {
  selectedUser: null,
  handlerUser: null,
  mention: {
    recipientId: '',
    media: {
      source: '',
      type: 0,
    },
    text: '',
    handlerId: '',
    isAnonymous: null,
    comment: '',
  },
  note: {
    id: '',
    questionId: '',
    text: '',
  },
  comment: {
    total: 0,
    data: [],
  },
  support: {
    total: 0,
    data: [],
  },
  mentionPrivacySettings: {} as NewPositiveMentionInfo,
  complaintPrivacySettings: {} as MentionInfo,
  isLoading: false,
  error: null,
  success: false,
}

const mentionsSlice = createSlice({
  name: 'mentions',
  initialState,
  reducers: {
    setSelectedUser: (state, action: PayloadAction<SearchUserAndGroup>) => {
      state.selectedUser = action.payload
    },
    setHandlerUser: (state, action: PayloadAction<SearchUserAndGroup>) => {
      state.handlerUser = action.payload
    },

    setMentionText: (state, action: PayloadAction<string>) => {
      state.mention.text = action.payload
    },
    setMentionComment: (state, action: PayloadAction<string>) => {
      state.mention.comment = action.payload
    },

    setMentionMedia: (state, action: PayloadAction<{ source: string; type: number }>) => {
      state.mention.media.source = action.payload.source
      state.mention.media.type = action.payload.type
    },

    setIsMentionAnonymous: (state, action: PayloadAction<boolean>) => {
      state.mention.isAnonymous = action.payload
    },

    resetMention: (state) => {
      state.mention = initialState.mention
      state.selectedUser = null
      state.handlerUser = null
    },
    setNote: (state, action: PayloadAction<NoteModel>) => {
      state.note = action.payload
    },
    resetNote: (state) => {
      state.note = initialState.note
    },
    setComment: (state, action: PayloadAction<CommentModel>) => {
      state.comment = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMentionPrivacySettings.fulfilled, (state, action) => {
      state.mentionPrivacySettings = action.payload
    })
    builder.addCase(getComplaintPrivacySettings.fulfilled, (state, action) => {
      state.complaintPrivacySettings = action.payload
    })
    builder.addCase(getComplaintChatPrivacySettings.fulfilled, (state, action) => {
      state.complaintPrivacySettings = action.payload
    })
    builder.addCase(getMentionChatPrivacySettings.fulfilled, (state, action) => {
      state.mentionPrivacySettings = action.payload
    })

    builder.addCase(getComments.fulfilled, (state, action) => {
      state.comment.data = action.payload.data
      state.comment.total = action.payload.total
    })

    builder.addCase(getExtraTips.fulfilled, (state, action) => {
      state.support.data = action.payload.data
      state.support.total = action.payload.total
    })

    builder.addCase(saveNote.fulfilled, (state, action) => {
      state.note = action.payload
    })

    builder.addMatcher(
      isFulfilled(addComment, createCompliment, createTip, createComplaint),
      (state) => {
        state.mention = initialState.mention
        state.selectedUser = null
        state.handlerUser = null
        state.isLoading = false
        state.error = null
        state.success = true
      }
    )
    builder.addMatcher(
      isPending(addComment, createCompliment, createTip, createComplaint),
      (state) => {
        state.mention = initialState.mention
        state.selectedUser = null
        state.handlerUser = null
        state.isLoading = true
        state.error = null
        state.success = false
      }
    )
    builder.addMatcher(
      isRejectedWithValue(addComment, createCompliment, createTip, createComplaint),
      (state, action) => {
        state.mention = initialState.mention
        state.selectedUser = null
        state.handlerUser = null
        if (action.payload) {
          state.error = {
            message: action.payload.message,
          }
        }
        state.isLoading = false
        state.success = false
      }
    )

    builder.addMatcher(
      isFulfilled(
        getNote,
        getComments,
        getExtraTips,
        saveNote,
        getComplaintPrivacySettings,
        getComplaintChatPrivacySettings,
        getMentionChatPrivacySettings,
        getMentionPrivacySettings
      ),
      (state) => {
        state.isLoading = false
        state.error = null
        state.success = true
      }
    )

    builder.addMatcher(
      isPending(
        getNote,
        getComments,
        getExtraTips,
        saveNote,
        getComplaintPrivacySettings,
        getComplaintChatPrivacySettings,
        getMentionChatPrivacySettings,
        getMentionPrivacySettings
      ),
      (state) => {
        state.isLoading = true
        state.error = null
        state.success = false
      }
    )

    builder.addMatcher(
      isRejectedWithValue(
        getNote,
        getComments,
        getExtraTips,
        saveNote,
        getComplaintPrivacySettings,
        getComplaintChatPrivacySettings,
        getMentionChatPrivacySettings,
        getMentionPrivacySettings
      ),
      (state, action) => {
        state.error = {
          message: action.payload.message,
        }
        state.isLoading = false
        state.success = false
      }
    )
  },
})

export const mentionsReducer = mentionsSlice.reducer
export const {
  setSelectedUser,
  setHandlerUser,
  setIsMentionAnonymous,
  setMentionText,
  setMentionMedia,
  setMentionComment,
  resetMention,
  setNote,
  resetNote,
  setComment,
} = mentionsSlice.actions
