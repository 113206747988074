import React from 'react'

export const MultipleChoiseFrame = (props: { isAnswerCorrect: boolean; numberOfLines: number }) => {
  const itemHeight = props.numberOfLines * 50 + 10

  const FrameBig = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="103.1%"
        height={itemHeight}
        viewBox="0 0 437.311 113"
        preserveAspectRatio="none"
      >
        <path
          id="Path_10699"
          data-name="Path 10699"
          d="M110.3,88.961l.2-388.114c0-4.738-4.6-9.1-12.048-11.415l-30.855-9.8a41.4,41.4,0,0,0-24,0l-30.855,9.8C5.3-308.2.7-303.837.7-299.152L.5,88.908c0,4.738,4.6,9.1,12.048,11.415l30.953,9.8a41.4,41.4,0,0,0,24,0l30.953-9.8C105.8,98.007,110.3,93.646,110.3,88.961Z"
          transform="translate(323.778 112) rotate(-90)"
          fill="none"
          stroke={props.isAnswerCorrect ? '#28b573' : '#bd3c26'}
          strokeMiterlimit="10"
          strokeWidth="3"
        />
      </svg>
    )
  }
  const Frame = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="101.1%"
        height={itemHeight}
        viewBox="0 0 437.348 59.061"
        preserveAspectRatio="none"
      >
        <path
          id="Path_10699"
          data-name="Path 10699"
          d="M56.461,88.961l.1-388.114a13.312,13.312,0,0,0-6.14-11.415l-15.725-9.8a11.412,11.412,0,0,0-12.231,0l-15.725,9.8A13.448,13.448,0,0,0,.6-299.152L.5,88.908a13.312,13.312,0,0,0,6.14,11.415l15.775,9.8a11.412,11.412,0,0,0,12.231,0l15.775-9.8A13.315,13.315,0,0,0,56.461,88.961Z"
          transform="translate(323.796 58.061) rotate(-90)"
          fill="none"
          stroke={props.isAnswerCorrect ? '#28b573' : '#bd3c26'}
          strokeMiterlimit="10"
          strokeWidth="3"
        />
      </svg>
    )
  }

  return <>{props.numberOfLines > 1 ? FrameBig() : Frame()}</>
}
