import React from 'react'

export const HexArrowButtonDisabledFilled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="57.607"
      height="62.16"
      viewBox="0 0 57.607 62.16"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Контур_153"
            data-name="Контур 153"
            d="M36.354,12.917l9.9,5.923a10.272,10.272,0,0,1,4.951,8.841V39.528a10.164,10.164,0,0,1-4.951,8.841l-9.9,5.923a9.5,9.5,0,0,1-9.9,0l-9.9-5.923A10.272,10.272,0,0,1,11.6,39.528V27.682a10.164,10.164,0,0,1,4.951-8.841l9.9-5.923A9.5,9.5,0,0,1,36.354,12.917Z"
            transform="translate(-11.6 -11.525)"
            fill="#707070"
          />
        </clipPath>
        <filter
          id="Контур_154"
          x="0"
          y="0"
          width="57.607"
          height="62.16"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Сгруппировать_4571"
        data-name="Сгруппировать 4571"
        transform="translate(-214.39 -1708.105)"
      >
        <g
          id="Сгруппировать_274"
          data-name="Сгруппировать 274"
          transform="translate(223.39 1714.105)"
        >
          <g
            id="Сгруппировать_48"
            data-name="Сгруппировать 48"
            transform="translate(0 0)"
            clipPath="url(#clip-path)"
          >
            <rect
              id="Прямоугольник_5"
              data-name="Прямоугольник 5"
              width="48.625"
              height="53.045"
              transform="translate(-4.509 -4.443)"
              fill="none"
              stroke="#e80074"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
          </g>
          <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Контур_154)">
            <path
              id="Контур_154-2"
              data-name="Контур 154"
              d="M36.354,12.917l9.9,5.923a10.272,10.272,0,0,1,4.951,8.841V39.528a10.164,10.164,0,0,1-4.951,8.841l-9.9,5.923a9.5,9.5,0,0,1-9.9,0l-9.9-5.923A10.272,10.272,0,0,1,11.6,39.528V27.682a10.164,10.164,0,0,1,4.951-8.841l9.9-5.923A9.5,9.5,0,0,1,36.354,12.917Z"
              transform="translate(-2.6 -5.53)"
              fill="#707070"
              opacity="0.196"
            />
          </g>
        </g>
        <g id="baseline-arrow_upward-24px" transform="translate(254.997 1723.779) rotate(90)">
          <path id="Контур_251" data-name="Контур 251" d="M0,0H24V24H0Z" fill="none" />
          <path
            id="Контур_252"
            data-name="Контур 252"
            d="M4,12l1.41,1.41L11,7.83V20h2V7.83l5.58,5.59L20,12,12,4Z"
            transform="translate(0 0)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  )
}
