import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { UserApi } from 'api/user'

export const changePassword = createAsyncThunk<
  void,
  { currentPassword: string; newPassword: string },
  { rejectValue: { message: string } }
>('account/changePassword', async ({ currentPassword, newPassword }, { rejectWithValue }) => {
  try {
    await UserApi.changePasswordRequest(currentPassword, newPassword)
  } catch (err) {
    let error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})
