import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

const scaleFactor = 1.2

export const ProgressHexagon = (props: { progress: number }) => {
  const brandingColors = useBrandingColors()
  const fillRatio = `${props.progress * 100}%`

  return (
    <svg
      width="61"
      height="67.624"
      viewBox="0 0 61 67.624"
      preserveAspectRatio="none"
      style={{ transform: `scale(${scaleFactor})` }}
    >
      <defs>
        <clipPath id="clip">
          <path
            d="M49.1,13.634l15,8.973A15.56,15.56,0,0,1,71.6,36V53.947A15.4,15.4,0,0,1,64.1,67.34l-15,8.973a14.388,14.388,0,0,1-15,0l-15-8.973a15.56,15.56,0,0,1-7.5-13.393V36a15.4,15.4,0,0,1,7.5-13.393l15-8.973A14.388,14.388,0,0,1,49.1,13.634Z"
            transform="translate(-11 -11)"
          />
        </clipPath>
      </defs>
      <rect
        x="0"
        y="0"
        width={fillRatio}
        height="100%"
        fill={brandingColors.iconOnBackground}
        opacity="0.4"
        clipPath="url(#clip)"
      />
      <path
        d="M49.1,13.634l15,8.973A15.56,15.56,0,0,1,71.6,36V53.947A15.4,15.4,0,0,1,64.1,67.34l-15,8.973a14.388,14.388,0,0,1-15,0l-15-8.973a15.56,15.56,0,0,1-7.5-13.393V36a15.4,15.4,0,0,1,7.5-13.393l15-8.973A14.388,14.388,0,0,1,49.1,13.634Z"
        transform="translate(-11 -11)"
        fill="none"
        stroke={brandingColors.iconOnBackground}
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </svg>
  )
}
