import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getParticipantGroups } from './thunks'
import {
  ParticipantGroupsState,
  ParticipantGroupsByTypeState,
  ParticipantGroupsSearch,
} from './types'
import { GroupType } from 'store/types/enums'

export const ParticipantGroupsPageSize = 20

const initialState: ParticipantGroupsState = {
  groupsByType: <ParticipantGroupsByTypeState[]>[
    {
      groupType: GroupType.Department,
      groups: [],
      nextPage: 1,
      total: 0,
      totalWithChildren: 0,
      pageSize: ParticipantGroupsPageSize,
      isLoading: false,
      error: null,
    },
    {
      groupType: GroupType.Project,
      groups: [],
      nextPage: 1,
      total: 0,
      totalWithChildren: 0,
      pageSize: ParticipantGroupsPageSize,
      isLoading: false,
      error: null,
    },
    {
      groupType: GroupType.IndividualUsers,
      groups: [],
      nextPage: 1,
      total: 0,
      totalWithChildren: 0,
      pageSize: ParticipantGroupsPageSize,
      isLoading: false,
      error: null,
    },
  ],
}

const participantGroupsSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearGroups: (state, action: PayloadAction<GroupType>) => {
      const groupType = action.payload
      const stateIndex = state.groupsByType.findIndex((x) => x.groupType === groupType)
      const initialStateIndex = initialState.groupsByType.findIndex(
        (x) => x.groupType === groupType
      )
      state.groupsByType[stateIndex] = { ...initialState.groupsByType[initialStateIndex] }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getParticipantGroups.fulfilled, (state, action) => {
      const payload = action.payload as ParticipantGroupsSearch

      state.groupsByType
        .filter((x) => x.groupType === action.meta.arg.groupType)
        .forEach((x) => {
          if (payload.isSearch) {
            x.groups = payload.groups
          }

          if (!payload.isSearch) {
            x.groups = [...x.groups, ...payload.groups]
          }

          x.total = payload.total
          x.totalWithChildren = payload.totalWithChildren
          x.nextPage = payload.nextPage
          x.pageSize = payload.pageSize
          x.isLoading = false
        })
    })
    builder.addCase(getParticipantGroups.pending, (state, action) => {
      state.groupsByType
        .filter((x) => x.groupType == action.meta.arg.groupType)
        .forEach((x) => {
          x.isLoading = true
        })
    })
    builder.addCase(getParticipantGroups.rejected, (state, action) => {
      state.groupsByType
        .filter((x) => x.groupType == action.meta.arg.groupType)
        .forEach((x) => {
          x.isLoading = false
          if (action.payload) {
            x.error = {
              message: action.payload.message,
            }
          }
        })
    })
  },
})

export const participantGroupsReducer = participantGroupsSlice.reducer
export const { clearGroups } = participantGroupsSlice.actions
