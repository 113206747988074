import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const HexagonalProgressBar = ({
  progress,
  numberOfLines = 1,
}: {
  progress: number
  numberOfLines?: number
}) => {
  const { iconOnBackground } = useBrandingColors()
  const fillRatio = `${progress * 100}%`
  const itemHeight = numberOfLines * 50

  return (
    <svg width="100%" height={itemHeight} viewBox="0 0 311.2 44.229" preserveAspectRatio="none">
      <defs>
        <clipPath id="clip-multiple">
          <path
            d="M43.652-28.526l.077-277.2a9.354,9.354,0,0,0-4.735-8.153l-12.126-7a9.4,9.4,0,0,0-9.431,0l-12.126,7a9.447,9.447,0,0,0-4.735,8.153L.5-28.564a9.354,9.354,0,0,0,4.735,8.153l12.164,7a9.4,9.4,0,0,0,9.431,0l12.164-7A9.347,9.347,0,0,0,43.652-28.526Z"
            transform="translate(-12.143 -0.5) rotate(90)"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#clip-multiple)">
        <rect x="0" y="0" width="100%" height="100%" fill={iconOnBackground} opacity="0.2" />
        <rect x="0" y="0" width={fillRatio} height="100%" fill={iconOnBackground} opacity="0.3" />
      </g>
    </svg>
  )
}
