import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserApi } from 'api/user'
import { AxiosError } from 'axios'
import { base64ToBlob } from 'modules/common/helpers'
import { updateUserLogo } from 'store/slices/about-me/slice'
import { TimelineMention } from 'store/types'
import { AboutResultsDataType } from 'api/mappers/AboutResultsMapper'
import { CourseTab } from 'store/types/info'
import { AboutMeInfoType, Error, MyAccount } from './types'
import { RootState } from 'store'
import { updateCurrentUserLogo } from '../user/slice'
import { setMessage } from '../feedback/slice'
import history from 'utils/history'
import { AppRoutes } from 'router/routesConfig'
import { logout } from '../auth/thunks'
import { DELETE_USER_RESPONSE_CODES } from 'api/enums'
import i18n from 'i18next'

const courseQuery = (filterType: number, page: number) => ({
  pager: {
    current: page,
    pageSize: 10,
  },
  sorter: [
    {
      field: 'createdDate',
      order: 'desc',
    },
  ],
  filters:
    filterType === 0
      ? []
      : [
          {
            operator: 'eq',
            field: 'filterType',
            value: filterType.toString(),
            logic: 'and',
          },
        ],
})

const mentionQuery = (
  mentionType: number,
  received: boolean,
  text: string,
  page: number,
  handled?: boolean
) => {
  return {
    pager: {
      current: page,
      pageSize: 10,
    },
    sorter: [
      {
        field: 'lastUpdatedDate',
        order: 'desc',
      },
    ],
    filters: [
      {
        operator: 'eq',
        field: 'mentionType',
        value: mentionType.toString(),
        logic: 'and',
      },
      {
        operator: 'eq',
        field: 'direction',
        // eslint-disable-next-line no-nested-ternary
        value: handled ? '2' : received ? '1' : '0',
        logic: 'and',
      },
    ],
    search: {
      fields: ['text', 'searchName'],
      value: text,
    },
  }
}

const resultQuery = (page: number) => ({
  pager: {
    current: page,
    pageSize: 10,
  },
  sorter: [
    {
      field: 'date',
      order: 'desc',
    },
  ],
})

const resultSearchQuery = (text: string, page: number) => ({
  pager: {
    current: page,
    pageSize: 10,
  },
  sorter: [
    {
      field: 'date',
      order: 'desc',
    },
  ],
  search: {
    fields: ['text', 'title'],
    value: text,
  },
})

export const getMyAccount = createAsyncThunk<MyAccount, void, { rejectValue: Error }>(
  'about-me/getMyAccount',
  async (_, { rejectWithValue }) => {
    try {
      const { data: myAccount } = await UserApi.getMyAccountRequest()
      return {
        ...myAccount,
        error: null,
        originEmail: myAccount.email,
        originPhoneNumber: myAccount.phoneNumber,
      }
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const updateMyAccount = createAsyncThunk<void, MyAccount, { rejectValue: Error }>(
  'about-me/updateMyAccount',
  async (model, { rejectWithValue }) => {
    try {
      await UserApi.updateMyAccountRequest(model)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getAboutMeUserInfo = createAsyncThunk<AboutMeInfoType, string, { rejectValue: Error }>(
  'about-me/getAboutMeUserInfo',
  async (id, { rejectWithValue }) => {
    try {
      const { data: userInfo } = await UserApi.getAboutMeUserInfoRequest(id)
      const { data: orgLanguages } = await UserApi.getOrganizationLanguagesRequest()

      return {
        userInfo,
        orgLanguages,
      }
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)
export const getMyCoursesUserInfo = createAsyncThunk<any, void, { rejectValue: Error }>(
  'about-me/getMyCoursesUserInfo',
  async (_, { rejectWithValue }) => {
    try {
      const { data: userInfo } = await UserApi.getMyCoursesUserInfoRequest()

      return userInfo
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const changeUserLogo = createAsyncThunk<
  void,
  { userId: string; logo: string; fileName: string },
  { rejectValue: Error }
>('about-me/changeLanguage', async ({ userId, logo, fileName }, { rejectWithValue, dispatch }) => {
  try {
    await UserApi.changeUserLogoRequest({
      Id: userId,
      Logo: {
        file: base64ToBlob(logo),
        imageFilename: `${fileName}/${String(Date.now())}`,
      },
    })

    dispatch(updateUserLogo(logo))
    dispatch(updateCurrentUserLogo(logo))
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getCourses = createAsyncThunk<
  { data: CourseTab[]; total: number },
  number,
  { rejectValue: Error }
>('about-me/getCourses', async (filterType, { rejectWithValue }) => {
  try {
    return await UserApi.getCoursesRequest(courseQuery(filterType, 1))
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})
export const getMoreCourses = createAsyncThunk<
  { data: CourseTab[]; total: number },
  { filterType: number; page: number },
  { rejectValue: Error }
>('about-me/getMoreCourses', async ({ filterType, page }, { rejectWithValue }) => {
  try {
    return await UserApi.getCoursesRequest(courseQuery(filterType, page))
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getMentions = createAsyncThunk<
  { data: TimelineMention[]; total: number },
  { mentionType: number; received: boolean; handled?: boolean },
  { rejectValue: Error }
>('about-me/getMentions', async ({ mentionType, received, handled }, { rejectWithValue }) => {
  try {
    return await UserApi.getMentionsRequest(mentionQuery(mentionType, received, '', 1, handled))
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const searchMentions = createAsyncThunk<
  { data: TimelineMention[]; total: number },
  { searchValue: string; mentionType: number; received: boolean; handled?: boolean },
  { rejectValue: Error }
>(
  'about-me/searchMentions',
  async ({ searchValue, mentionType, received, handled }, { rejectWithValue }) => {
    try {
      return await UserApi.getMentionsRequest(
        mentionQuery(mentionType, received, searchValue, 1, handled)
      )
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getMoreMentions = createAsyncThunk<
  { data: TimelineMention[]; total: number },
  { searchValue: string; page: number; mentionType: number; received: boolean; handled?: boolean },
  { rejectValue: Error }
>(
  'about-me/getMoreMentions',
  async ({ searchValue, page, mentionType, received, handled }, { rejectWithValue }) => {
    try {
      return await UserApi.getMentionsRequest(
        mentionQuery(mentionType, received, searchValue, page, handled)
      )
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getResults = createAsyncThunk<
  { results: { data: AboutResultsDataType[]; total: number }; totalPoints: number },
  { isCourseResults?: boolean },
  { rejectValue: Error }
>('about-me/getResults', async ({ isCourseResults }, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState

    let results = {} as { data: AboutResultsDataType[]; total: number }

    if (isCourseResults) {
      results = await UserApi.getMyCoursesResultsRequest(resultQuery(1))
    }
    if (!isCourseResults) {
      results = await UserApi.getResultsRequest(resultQuery(1))
    }

    const totalPoints = await UserApi.getTotalPointsRequest(
      state.user.currentUser.credentialsInfo.id
    )

    return {
      results,
      totalPoints,
    }
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getMoreResults = createAsyncThunk<
  { results: { data: AboutResultsDataType[]; total: number }; totalPoints: number },
  { isCourseResults?: boolean; page: number },
  { rejectValue: Error }
>('about-me/getMoreResults', async ({ isCourseResults, page }, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState

    let results = {} as { data: AboutResultsDataType[]; total: number }

    if (isCourseResults) {
      results = await UserApi.getMyCoursesResultsRequest(resultQuery(page))
    }
    if (!isCourseResults) {
      results = await UserApi.getResultsRequest(resultQuery(page))
    }

    const totalPoints = await UserApi.getTotalPointsRequest(
      state.user.currentUser.credentialsInfo.id
    )

    return {
      results,
      totalPoints,
    }
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const searchResults = createAsyncThunk<
  { data: AboutResultsDataType[]; total: number },
  string,
  { rejectValue: Error }
>('about-me/searchResults', async (searchValue, { rejectWithValue }) => {
  try {
    return await UserApi.getResultsRequest(resultSearchQuery(searchValue, 1))
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const restartCourse = createAsyncThunk<void, string, { rejectValue: Error }>(
  'about-me/searchResults',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      await UserApi.restartCourseRequest(id)
      history.push(AppRoutes.TIMELINE)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        dispatch(setMessage(error.response?.data.Message))
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const startCourse = createAsyncThunk<void, string, { rejectValue: Error }>(
  'about-me/searchResults',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      await UserApi.startCourseRequest(id)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        dispatch(setMessage(error.response?.data.Message))
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const deleteUserAccount = createAsyncThunk<void, void, { rejectValue: Error }>(
  'about-me/deleteUserAccount',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await UserApi.deleteUserAccountRequest()

      if (data === DELETE_USER_RESPONSE_CODES.SUCCESS) {
        dispatch(logout())
      }

      if (data === DELETE_USER_RESPONSE_CODES.FAILED) {
        dispatch(setMessage(i18n.t('ERROR.TRY_AGAIN')))
      }

      if (data === DELETE_USER_RESPONSE_CODES.ONLY_ONE_IN_SERIES) {
        dispatch(setMessage(i18n.t('ABOUT_USER.ONLY_ONE_IN_SERIES')))
      }
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)
