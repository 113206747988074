import React from 'react'
import { HexanogYesNo } from './HexanogYesNo'
import { HexanogYesNoFrame } from './HexagoxYesNoFrame'
import { AnswerIndicatorCorrect } from './AnswerIndicatorCorrect'
import { AnswerIndicatorIncorrect } from './AnswerIndicatorIncorrect'
import styled from 'styled-components/macro'

export const YesNoAnsweredQuestion = (props: {
  isSelected: boolean
  id: string
  startColor: string
  endColor: string
  isAnswerCorrect: boolean
}) => {
  return (
    <YesNoAnsweredQuestionWrapper>
      <div>
        <HexanogYesNo
          id={props.id}
          isSelected={props.isSelected}
          startColor={props.startColor}
          endColor={props.endColor}
        ></HexanogYesNo>
      </div>
      <IndicatorWrapper>
        {props.isAnswerCorrect ? <AnswerIndicatorCorrect /> : <AnswerIndicatorIncorrect />}
      </IndicatorWrapper>
      <FrameWrapper>
        <HexanogYesNoFrame isAnswerCorrect={props.isAnswerCorrect}></HexanogYesNoFrame>
      </FrameWrapper>
    </YesNoAnsweredQuestionWrapper>
  )
}

const IndicatorWrapper = styled.div`
  position: absolute;
  left: 106px;
  top: 42px;
`
const FrameWrapper = styled.div`
  position: absolute;
  left: -12px;
  bottom: -22px;
  @media (max-width: 1440px) {
    bottom: -21px;
  }
`
const YesNoAnsweredQuestionWrapper = styled.div`
  display: block;
  position: relative;
`
