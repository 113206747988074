import React, { memo } from 'react'
import styled from 'styled-components/macro'
import logoDown from 'assets/images/hexagons-down.svg'
import logoUp from 'assets/images/hexagons-up.svg'
import { ITheme } from 'styles/types'

const HexagonBackground = ({ children }: { children: React.ReactNode }) => {
  return (
    <Container>
      {children}

      <HexagonsDown src={logoDown} alt="hex-down" />
      <HexagonsUp src={logoUp} alt="hex-up" />
    </Container>
  )
}

export default memo(HexagonBackground)

const Container = styled.div`
  height: 100vh;
  position: relative;
`

export const HexagonsUp = styled.img`
  position: absolute;
  top: -35px;
  left: 20px;
  max-width: 100%;
  display: block;
  height: auto;
  z-index: -1;
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.md} {
    width: 150px;
    left: 0;
  }
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    display: none;
  }

  @media (max-height: 600px) {
    display: none;
  }
`
export const HexagonsDown = styled.img`
  position: absolute;
  bottom: 10px;
  right: 10px;
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.md} {
    width: 150px;
  }
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    width: 120px;
  }

  @media (max-height: 600px) {
    display: none;
  }
`
