import { createAsyncThunk } from '@reduxjs/toolkit'
import { SearchApi } from 'api/search'
import { SearchCategoriesResponse } from 'api/search/types/response'
import { AxiosError } from 'axios'
import { Awaited } from 'utils/types'
import { setMessage } from '../feedback/slice'
import { Error } from '../mentions/types'
import { toggleExpandCategory } from './slice'
import { GetSearchItemsType, GetSearchResultThunkType, GetSearchResultType } from './types'

const PAGE_SIZE = 5

const postListQuery = (categoryId: string, page: number, text: string) => ({
  pager: {
    current: page,
    pageSize: PAGE_SIZE,
  },
  categoryId,
  value: text,
})

export const getSearchCategories = createAsyncThunk<
  SearchCategoriesResponse[],
  void,
  { rejectValue: Error }
>('search/getSearchCategories', async (_, { rejectWithValue, dispatch }) => {
  try {
    return await SearchApi.getSearchRequest()
  } catch (err) {
    let error = err as AxiosError
    if (error) {
      dispatch(setMessage(error.response?.data.Message))
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getSearchPosts = createAsyncThunk<
  Awaited<ReturnType<typeof SearchApi.getSearchPostsRequest>>,
  Omit<GetSearchItemsType, 'page'>,
  { rejectValue: Error }
>(
  'search/getSearchPosts',
  async ({ id, value }, { rejectWithValue, dispatch }: { rejectWithValue: any; dispatch: any }) => {
    try {
      const res = await SearchApi.getSearchPostsRequest(postListQuery(id, 1, value))
      dispatch(toggleExpandCategory(id))
      return res
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        dispatch(setMessage(error.response?.data.Message))
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getMoreSearchPosts = createAsyncThunk<
  Awaited<ReturnType<typeof SearchApi.getSearchPostsRequest>>,
  GetSearchItemsType,
  { rejectValue: Error }
>(
  'search/getMoreSearchPosts',
  async ({ id, page, value }, { rejectWithValue }: { rejectWithValue: any }) => {
    try {
      return await SearchApi.getSearchPostsRequest(postListQuery(id, page, value))
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)
export const getSearchCourses = createAsyncThunk<
  Awaited<ReturnType<typeof SearchApi.getSearchCoursesRequest>>,
  Omit<GetSearchItemsType, 'page'>,
  { rejectValue: Error }
>(
  'search/getSearchCourses',
  async ({ id, value }, { rejectWithValue, dispatch }: { rejectWithValue: any; dispatch: any }) => {
    try {
      const res = await SearchApi.getSearchCoursesRequest(postListQuery(id, 1, value))
      dispatch(toggleExpandCategory(id))

      return res
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        dispatch(setMessage(error.response?.data.Message))
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)
export const getMoreSearchCourses = createAsyncThunk<
  Awaited<ReturnType<typeof SearchApi.getSearchCoursesRequest>>,
  GetSearchItemsType,
  { rejectValue: Error }
>(
  'search/getMoreSearchCourses',
  async ({ id, page, value }, { rejectWithValue }: { rejectWithValue: any }) => {
    try {
      return await SearchApi.getSearchCoursesRequest(postListQuery(id, page, value))
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getSearchResult = createAsyncThunk<
  GetSearchResultThunkType,
  GetSearchResultType,
  { rejectValue: Error }
>(
  'search/getSearchResult',
  async (
    { categories, value },
    { rejectWithValue, dispatch }: { rejectWithValue: any; dispatch: any }
  ) => {
    try {
      let courses = {}
      let posts = {}
      let searchCategories: SearchCategoriesResponse[] = []

      for (const category of categories) {
        if (category.isCourse) {
          const res = await SearchApi.getSearchCoursesRequest(postListQuery(category.id, 1, value))
          if (res.questions.length) {
            courses[category.id] = res
            searchCategories.push(category)
          }
        } else {
          const res = await SearchApi.getSearchPostsRequest(postListQuery(category.id, 1, value))
          if (res.posts.length) {
            posts[category.id] = res
            searchCategories.push(category)
          }
        }
      }

      return {
        courses,
        posts,
        searchCategories,
      }
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        dispatch(setMessage(error.response?.data.Message))
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)
