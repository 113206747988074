import React from 'react'
import styled from 'styled-components/macro'
import Avatar from 'modules/common/components/Avatar/index'
import { GroupOutlined, UserOutlined } from 'assets/svg'
import { SearchSection } from 'modules/create-mention/components'
import { SearchUserAndGroup } from 'store/types'
import SearchButton from 'modules/common/components/SearchButton'

interface IProps {
  user: SearchUserAndGroup | null
  onClick: () => void
}

export const NOBODY_ID = 'NOBODY'

const UserInfo: React.FC<IProps> = ({ user, onClick }) => {
  return (
    <UserInfoWrapper>
      {user ? (
        <>
          {user.id !== NOBODY_ID && (
            <Avatar
              logo={user.logo}
              size={80}
              defaultLogo={user.aboutOthersType > 0 ? GroupOutlined : UserOutlined}
            />
          )}
          <SearchButton onClick={onClick} buttonText={user.name} />
        </>
      ) : (
        <SearchSection onClick={onClick} />
      )}
    </UserInfoWrapper>
  )
}

export default UserInfo

const UserInfoWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7px;
`
