import { TextCalibriBold } from 'modules/common/components/Typography'
import styled from 'styled-components/macro'
import { ITheme } from 'styles/types'

const Text = styled(TextCalibriBold)`
  font-size: 23px;
  display: block;
  margin-bottom: 7px;
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.md} {
    font-size: 19px;
  }
`
export default Text
