import React from 'react'
import { MultipleChoiseFrame } from './MultipleChoiseFrame'
import { ButtonWithProgressSvg } from 'assets/svg'
import styled from 'styled-components/macro'

export const MultipleChoiseAnswered = ({
  progress,
  opacity,
  startColor,
  stopColor,
  isAnswerCorrect,
  numberOfLines = 1,
  id = '',
}: {
  progress?: number
  startColor: string
  stopColor: string
  numberOfLines?: number
  opacity?: number
  id?: string
  isAnswerCorrect: boolean
}) => {
  return (
    <MultipleChoiseAnsweredWrapper>
      <div>
        <ButtonWithProgressSvg
          progress={progress}
          opacity={opacity}
          startColor={startColor}
          stopColor={stopColor}
          numberOfLines={numberOfLines}
          id={id}
        ></ButtonWithProgressSvg>
      </div>
      <FrameWrapper>
        <MultipleChoiseFrame isAnswerCorrect={isAnswerCorrect} numberOfLines={numberOfLines} />
      </FrameWrapper>
    </MultipleChoiseAnsweredWrapper>
  )
}

const MultipleChoiseAnsweredWrapper = styled.div`
  display: block;
  position: relative;
`
const FrameWrapper = styled.div`
  position: absolute;
  top: -5px;
  left: -1.2%;
`
