import React from 'react'

export const SearchLoop = ({ color = '#fff', opacity = 1 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="35.49"
      height="35.49"
      viewBox="0 0 35.49 35.49"
    >
      <g transform="matrix(1, 0, 0, 1, 0, 0)">
        <path
          id="Контур_1140-2"
          data-name="Контур 1140"
          d="M15.5,14h-.79l-.28-.27a6.51,6.51,0,1,0-.7.7l.27.28v.79l5,4.99L20.49,19Zm-6,0A4.5,4.5,0,1,1,14,9.5,4.494,4.494,0,0,1,9.5,14Z"
          transform="translate(6 3)"
          fill={color}
          opacity={opacity}
        />
      </g>
    </svg>
  )
}
