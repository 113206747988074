import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

const scaleFactor = 1.2

export const MoodBest = () => {
  const brandingColors = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      style={{ transform: `scale(${scaleFactor})` }}
    >
      <g id="Сгруппировать_1123" data-name="Сгруппировать 1123" transform="translate(1486 254)">
        <path
          id="Контур_1125"
          data-name="Контур 1125"
          d="M38.4,12.2a19.558,19.558,0,0,0-4.3-6.35,19.558,19.558,0,0,0-6.35-4.3A20.416,20.416,0,0,0,20,0a20.863,20.863,0,0,0-7.8,1.55,19.558,19.558,0,0,0-6.35,4.3,20.971,20.971,0,0,0-4.3,6.35,20.4,20.4,0,0,0,0,15.6,19.558,19.558,0,0,0,4.3,6.35,19.558,19.558,0,0,0,6.35,4.3,20.4,20.4,0,0,0,15.6,0,19.558,19.558,0,0,0,6.35-4.3,20.971,20.971,0,0,0,4.3-6.35A20.863,20.863,0,0,0,40,20,19.974,19.974,0,0,0,38.4,12.2ZM32.45,32.5A17.6,17.6,0,0,1,2.4,20,17.756,17.756,0,0,1,7.55,7.5,17.6,17.6,0,0,1,37.6,20,17.461,17.461,0,0,1,32.45,32.5Z"
          transform="translate(-1486 -254)"
          fill={brandingColors.iconOnBackground}
        />
        <path
          id="Контур_1126"
          data-name="Контур 1126"
          d="M30.176,51.82a11.315,11.315,0,0,1-7.8-3.4,1.159,1.159,0,0,0-1.55-.15h0a1.169,1.169,0,0,0-.15,1.75,12.858,12.858,0,0,0,5.55,3.5,13.244,13.244,0,0,0,4,.7,12.807,12.807,0,0,0,7.4-2.4,11.415,11.415,0,0,0,2.1-1.8,1.176,1.176,0,0,0-.15-1.75h0a1.159,1.159,0,0,0-1.55.15A11.642,11.642,0,0,1,30.176,51.82Z"
          transform="translate(-1496.176 -278.02)"
          fill={brandingColors.iconOnBackground}
        />
        <circle
          id="Эллипс_68"
          data-name="Эллипс 68"
          cx="2.95"
          cy="2.95"
          r="2.95"
          transform="translate(-1475.05 -241.6)"
          fill={brandingColors.iconOnBackground}
        />
        <circle
          id="Эллипс_69"
          data-name="Эллипс 69"
          cx="2.95"
          cy="2.95"
          r="2.95"
          transform="translate(-1462.85 -241.6)"
          fill={brandingColors.iconOnBackground}
        />
      </g>
    </svg>
  )
}
