import React from 'react'
import { Lottie } from '@crello/react-lottie'
import { animationData } from 'modules/common/components/Loader/animationData'
import styled from 'styled-components/macro'
import { flatten } from 'lottie-colorify'
import { useBrandingColors } from 'hooks/useBrandingColors'

interface IProps {
  isLoading: boolean
  height?: string
  width?: string
  onBackground?: boolean
}

const Loader: React.FC<IProps> = ({
  isLoading,
  onBackground = true,
  height = '60px',
  width = '60px',
}) => {
  const { icon, backgroundStart, backgroundEnd } = useBrandingColors()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: onBackground ? animationData : flatten(icon, animationData),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  if (!isLoading) return null

  return (
    <LoaderWrapper backgroundStart={backgroundStart} backgroundEnd={backgroundEnd}>
      <Lottie config={defaultOptions} height={height} width={width} style={{ margin: '0 auto' }} />
    </LoaderWrapper>
  )
}

export default Loader

const LoaderWrapper = styled.div<{ backgroundStart: string; backgroundEnd: string }>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ backgroundStart, backgroundEnd }) =>
    `linear-gradient(to bottom, ${backgroundStart}, ${backgroundEnd})`};
`
