import { Media } from './timelineMedia'
import { User } from '.'
import { GroupType } from './enums'

export enum TimelineMentionType {
  Compliment,
  Complaint,
  Suggestion,
  Note,
  Comment,
  Support,
}

export enum MentionChatType {
  Complaint,
  Compliment,
  Suggestion,
  Comment = 4,
}

export interface TimelineMention {
  id: string
  media: Media | null
  text: string
  messages?: number
  mentionType: TimelineMentionType
  isHandler: boolean | null
  recipient: User | null
  sender: User
  date?: string
  isAnon: boolean | null
  lastUpdated?: string
  withinGroup?: boolean
}

export interface TimelineCompressedMentions {
  mentionsAmount: number
  name: string
  id: string
  messagesAmount: number
  logo: string | null
  type: TimelineMentionType
  mentions: TimelineMention[]
  groupType: GroupType
}
