import { NewGroupRegistrationState, NewGroupRegistrationType } from './types'
import { createSlice, PayloadAction, isRejectedWithValue, isPending } from '@reduxjs/toolkit'
import { getNewGroupRegistrationSettings, register, getOrganizationUrl } from './thunk'
import { LOCAL_STORAGE_KEYS } from 'modules/common/constants/storageKeys'
import { saveToLocalStorage } from 'utils/storage'
import { setOrgUrl } from 'api/helpers'

const shouldOpenModal = (responseType: NewGroupRegistrationType) => {
  switch (responseType) {
    case NewGroupRegistrationType.Added:
    case NewGroupRegistrationType.CreatedAndActivated:
    case NewGroupRegistrationType.Created:
    case NewGroupRegistrationType.EmailTaken:
    case NewGroupRegistrationType.NameIsDifferent:
    case NewGroupRegistrationType.PhoneNumberAndEmailTaken:
    case NewGroupRegistrationType.PhoneNumberTaken:
      return true
    default:
      return false
  }
}

const initialState: NewGroupRegistrationState = {
  name: '',
  phoneNumber: null,
  email: null,
  isLoading: false,
  error: null,
  settings: {
    organizationLogo: null,
    socialImageLink: null,
    groupName: '',
    organizationName: '',
    name: null,
    email: null,
    phoneNumber: null,
    country: '',
  },
  responseType: null,
  showModal: false,
  confirmNameChange: false,
  currentOrgUrl: null,
}

const newGroupRegistrationSlice = createSlice({
  name: 'newGroupRegistration',
  initialState,
  reducers: {
    setName: (state: NewGroupRegistrationState, action: PayloadAction<string>) => {
      state.name = action.payload
      state.responseType = null
    },
    setEmail: (state: NewGroupRegistrationState, action: PayloadAction<string | null>) => {
      state.email = action.payload
      if (state.responseType === NewGroupRegistrationType.EmailTaken) {
        state.responseType = null
      }
      if (state.responseType === NewGroupRegistrationType.PhoneNumberAndEmailTaken) {
        state.responseType = NewGroupRegistrationType.PhoneNumberTaken
      }
    },
    setPhoneNumber: (state: NewGroupRegistrationState, action: PayloadAction<string | null>) => {
      state.phoneNumber = action.payload
      if (state.responseType === NewGroupRegistrationType.PhoneNumberTaken) {
        state.responseType = null
      }
      if (state.responseType === NewGroupRegistrationType.PhoneNumberAndEmailTaken) {
        state.responseType = NewGroupRegistrationType.EmailTaken
      }
    },
    setShowModal: (state: NewGroupRegistrationState, action: PayloadAction<boolean>) => {
      state.showModal = action.payload
    },
    setConfirmNameChange: (state: NewGroupRegistrationState, action: PayloadAction<boolean>) => {
      state.confirmNameChange = action.payload
    },
    setCurrentOrgUrl: (state: NewGroupRegistrationState, action: PayloadAction<string>) => {
      state.currentOrgUrl = action.payload
    },
    clearState: (state: NewGroupRegistrationState) => {
      if (state.currentOrgUrl) {
        setOrgUrl(`${state.currentOrgUrl}/`)
        saveToLocalStorage(LOCAL_STORAGE_KEYS.ORG_URL, `${state.currentOrgUrl}/`)
      }
      state = initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNewGroupRegistrationSettings.fulfilled, (state, action) => {
      state.isLoading = false
      state.settings = action.payload
      state.name = action.payload.name || ''
      state.email = action.payload.email
      state.phoneNumber = action.payload.phoneNumber
    })
    builder.addCase(register.fulfilled, (state, action) => {
      state.responseType = action.payload
      state.isLoading = false
      state.showModal = shouldOpenModal(action.payload)
    })
    builder.addMatcher(
      isRejectedWithValue(getNewGroupRegistrationSettings, register, getOrganizationUrl),
      (state, action) => {
        state.error = {
          message: action.payload.message,
        }
        state.isLoading = false
      }
    )
    builder.addMatcher(
      isPending(getNewGroupRegistrationSettings, register, getOrganizationUrl),
      (state) => {
        state.isLoading = true
      }
    )
  },
})

export const newGroupRegistrationReducer = newGroupRegistrationSlice.reducer
export const {
  setName,
  setEmail,
  setPhoneNumber,
  setShowModal,
  setConfirmNameChange,
  setCurrentOrgUrl,
  clearState,
} = newGroupRegistrationSlice.actions
