import { authInstanceOrg } from 'api/axios.config'
import { mapCourseInfo } from 'api/mappers/AboutCourseMapper'
import { mapInfoMention } from 'api/mappers/AboutMentionMapper'
import { mapResults } from 'api/mappers/AboutResultsMapper'
import { detailsMapper } from 'api/mappers/TimelineEpisodeMapper'
import { mapUserSearchResults } from 'api/mappers/UsersSearchMapper'
import { SearchQuery } from 'api/types/requestTypes'
import { jsonToFormData } from 'modules/common/helpers'
import { AboutOthersType } from 'store/types'
import {
  AboutCourseDetailsResponse,
  AboutMeListResponse,
  AboutMeResultsResponse,
  AboutMeUserInfoResponse,
  AboutOthersUserInfoResponse,
  CourseTabResponse,
  Language,
  MyAccountResponse,
  SearchUserAndGroupReponse,
} from './types/response'

export class UserApi {
  static checkPasswordRequest = async (password: string) => {
    return await authInstanceOrg.post<void>(`/user/checkPassword`, { password })
  }

  static getMyAccountRequest = async () => {
    return await authInstanceOrg.get<MyAccountResponse>(`/user/getMyAccount`)
  }

  static updateMyAccountRequest = async (model: MyAccountResponse) => {
    return await authInstanceOrg.put(`/user/updateMyAccount`, model)
  }

  static getAboutOthersUserInfoRequest = async (
    userId: string,
    aboutOthersType: AboutOthersType
  ) => {
    return await authInstanceOrg.get<AboutOthersUserInfoResponse>(
      `/user/getAboutOthersInfo?id=${userId}&aboutOthersType=${aboutOthersType}`
    )
  }

  static getAboutMeUserInfoRequest = async (questionId: string) => {
    return await authInstanceOrg.get<AboutMeUserInfoResponse>(
      `/user/getAboutMeInfo?questionId=${questionId}`
    )
  }
  static getMyCoursesUserInfoRequest = async () => {
    return await authInstanceOrg.get<AboutMeUserInfoResponse>(`/user/getMyCoursesUserInfo`)
  }

  static searchUsersRequest = async (query: SearchQuery) => {
    const { data } = await authInstanceOrg.post<SearchUserAndGroupReponse>(
      `/user/generateParticipantList`,
      query
    )
    return {
      users: mapUserSearchResults(data.data),
      total: data.total,
    }
  }

  static updateLastSeenTimelineRequest = async () => {
    return await authInstanceOrg.put<any>(`/user/updateLastSeenTimeLine`, {})
  }

  static getCurrentUserRequest = async () => {
    return await authInstanceOrg.get<AboutMeUserInfoResponse>(`/user/currentUser`)
  }

  static getOrganizationLanguagesRequest = async () => {
    return await authInstanceOrg.get<Language[]>(`/language/organizationLanguages`)
  }

  static changeUserLogoRequest = async (userData: any) => {
    return await authInstanceOrg.put<void>(`user/updateLogo`, jsonToFormData(userData))
  }

  static getCoursesRequest = async (query: SearchQuery) => {
    const { data } = await authInstanceOrg.post<CourseTabResponse>(
      `user/generateAboutMeCourseList`,
      query
    )

    return {
      data: mapCourseInfo(data.data),
      total: data.total,
    }
  }
  static getMyCoursesResultsRequest = async (query: SearchQuery) => {
    const { data } = await authInstanceOrg.post<AboutMeResultsResponse>(
      `user/generateMyCourseResultList`,
      query
    )

    return {
      data: mapResults(data.data),
      total: data.total,
    }
  }
  static getCourseDetailsRequest = async (courseId: string) => {
    const { data } = await authInstanceOrg.get<AboutCourseDetailsResponse>(
      `user/getAboutMeCourseDetails/${courseId}`
    )

    return {
      episodesDetails: data.episodesDetails.map((episode) => detailsMapper(episode)),
      episodeDates: data.episodeDates,
    }
  }
  static startCourseRequest = async (id: string) => {
    await authInstanceOrg.post<void>(`userEpisode/startCourse/${id}`, {})
  }
  static restartCourseRequest = async (id: string) => {
    await authInstanceOrg.post<void>(`userEpisode/restartCourse/${id}`, {})
  }

  static getPointsRequest = async (id: string) => {
    await authInstanceOrg.get<any[]>(`user/getPoints/${id}`)
  }

  static getMentionsRequest = async (query: SearchQuery) => {
    const { data } = await authInstanceOrg.post<AboutMeListResponse>(
      'user/generateAboutMeList',
      query
    )
    return {
      data: mapInfoMention(data.data),
      total: data.total,
    }
  }

  static getResultsRequest = async (query: SearchQuery) => {
    const { data } = await authInstanceOrg.post<AboutMeResultsResponse>(
      'user/generateAboutMeResultList',
      query
    )

    return {
      data: mapResults(data.data),
      total: data.total,
    }
  }

  static getOthersCoursesRequest = async (query: SearchQuery, id: string) => {
    const { data } = await authInstanceOrg.post<CourseTabResponse>(
      `user/generateAboutOtherCourseList?organizationUserId=${id}`,
      query
    )

    return {
      data: mapCourseInfo(data.data),
      total: data.total,
    }
  }

  static getOthersResultsRequest = async (
    query: SearchQuery,
    id: string,
    aboutOthersType: number
  ) => {
    const { data } = await authInstanceOrg.post<AboutMeResultsResponse>(
      `user/generateAboutOthersResultList?recipientId=${id}&aboutOthersType=${aboutOthersType}`,
      query
    )

    return {
      data: mapResults(data.data),
      total: data.total,
    }
  }

  static getOthersMentionsRequest = async (
    query: SearchQuery,
    id: string,
    aboutOthersType: number
  ) => {
    const { data } = await authInstanceOrg.post<AboutMeListResponse>(
      `user/generateAboutOthersList?recipientId=${id}&aboutOthersType=${aboutOthersType}`,
      query
    )
    return {
      data: mapInfoMention(data.data),
      total: data.total,
    }
  }

  static getTotalPointsRequest = async (id: string) => {
    const { data } = await authInstanceOrg.get<number>(`user/getPoints/${id}`)

    return data
  }

  static changePasswordRequest = async (currentPassword: string, newPassword: string) => {
    await authInstanceOrg.post<void>('user/changePassword', {
      currentPassword,
      newPassword,
    })
  }

  static checkUserIndividualRequest = async () => {
    const { data } = await authInstanceOrg.get<boolean>('user/individual')

    return data
  }

  static deleteUserAccountRequest = async () => {
    return await authInstanceOrg.delete<number>('user/deleteAccount')
  }
}
