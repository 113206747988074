import React from 'react'

export const PlayButtonHex = () => {
  return (
    <svg
      id="Group_2531"
      data-name="Group 2531"
      xmlns="http://www.w3.org/2000/svg"
      width="52.484"
      height="57.943"
      viewBox="0 0 52.484 57.943"
    >
      <path
        id="Path_7709"
        data-name="Path 7709"
        d="M298.716,375.641a12.314,12.314,0,0,1-6.258-1.7c-3.859-2.151-7.924-4.581-13.154-7.864a14.383,14.383,0,0,1-6.771-12.425c-.049-1.527-.033-3.133-.016-4.687.007-.8.016-1.6.016-2.408,0-.244-.07-6.322.3-9.513a13.114,13.114,0,0,1,6.35-9.88c2.91-1.856,5.967-3.633,8.924-5.351q1.7-.99,3.4-1.984a13.747,13.747,0,0,1,14.847.1c3.566,2.138,7.29,4.373,10.951,6.495,4.9,2.908,7.456,7.053,7.609,12.325.147,5.691.072,11.446-.22,17.107a12.189,12.189,0,0,1-5.586,9.56c-4.478,2.913-9.286,5.812-14.286,8.61A12.279,12.279,0,0,1,298.716,375.641Z"
        transform="translate(-272.5 -317.698)"
        fill="#1a1818"
        opacity="0.85"
      />
      <path
        id="Path_7710"
        data-name="Path 7710"
        d="M309.018,346.786l-13.7-9.088a1.144,1.144,0,0,0-1.776.953v17a1.144,1.144,0,0,0,1.716.991l13.7-7.914a1.143,1.143,0,0,0,.06-1.944Z"
        transform="translate(-272.5 -317.698)"
        fill="#fff"
      />
    </svg>
  )
}
