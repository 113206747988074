import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const QuestionDetailsArrow = () => {
  const { icon } = useBrandingColors()

  return (
    <svg width={57} height={35} fill={icon} viewBox="2 4 25 17.5">
      <path d="M7,10l7.5,7.5L22,10Z" fill={icon} />
    </svg>
  )
}
