import React from 'react'

export const AnswerIndicatorCorrect = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34.852"
      height="26.660"
      viewBox="0 0 26.852 18.273"
    >
      <path
        id="Path_10695"
        data-name="Path 10695"
        d="M278.994,275.286l-8.886,9.641-3.557-3.86a2.371,2.371,0,0,0-3.544,0,2.883,2.883,0,0,0,0,3.844l7.1,7.7,12.431-13.486a2.883,2.883,0,0,0,0-3.844A2.371,2.371,0,0,0,278.994,275.286Z"
        transform="translate(-261.597 -273.99)"
        fill="#28b573"
        stroke="#28b573"
        strokeWidth="1"
      />
    </svg>
  )
}
