import { Input } from 'antd'
import styled from 'styled-components/macro'
import { ITheme } from 'styles/types'

const { TextArea } = Input

const AutoGrowTextArea = styled(TextArea)<{ $textColor: string; $textColorFocused: string }>`
  &&& {
    border: none;
    border-radius: 8px;
    font-size: 23px;
    padding: 0px 10px;
    height: 36px;
    font-family: 'Calibri';
    background-color: rgba(255, 255, 255, 0.1);
    color: ${({ $textColor }) => $textColor};
    :focus {
      background: #fff;
      color: ${({ $textColorFocused }) => $textColorFocused};
    }
    text-align: left;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-weight: 700;

    @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
      font-size: 19px;
      height: 32px;
    }
  }
`

export default AutoGrowTextArea
