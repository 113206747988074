import { authInstanceOrg, unauthInstanceMaster, unauthInstanceOrg } from 'api/axios.config'
import { jsonToFormData } from 'modules/common/helpers'
import { BrandingColors } from 'store/types'
import {
  GetCurrentStepResponse,
  GetLanguagesResponse,
  MasterOrganizationType,
  OrganizationMentionsResponse,
  OrganizationResponse,
  SaveResponse,
  TokenResponse,
  UserResponse,
} from './types/response'

export class AuthApi {
  static forgotPasswordRequest = async (loginData: string, loginMethod: 0 | 1) => {
    return await unauthInstanceMaster.post<UserResponse>(`/user/resetPassword`, {
      loginData,
      loginMethod,
    })
  }

  static masterOrganizationRequest = async () => {
    return await unauthInstanceMaster.get<MasterOrganizationType>(`organization/master`, {
      params: { isMaster: true },
    })
  }
  static masterOrganizationColorsRequest = async () => {
    return await unauthInstanceMaster.get<BrandingColors>(`organization/master/settings/colors`, {
      params: { isMaster: true },
    })
  }
  static masterOrganizationSettingsRequest = async () => {
    return await unauthInstanceMaster.get<any>(`organization/master/settings/general`, {
      params: { isMaster: true },
    })
  }

  static masterOrganizationMentionsRequest = async (organizationId: string) => {
    return await unauthInstanceMaster.get<OrganizationMentionsResponse>(
      `organization/${organizationId}/settings/mentions`,
      {
        params: { isMaster: true },
      }
    )
  }

  static organizationColorsRequest = async (organizationId: string) => {
    return await unauthInstanceOrg.get<BrandingColors>(
      `organization/${organizationId}/settings/colors`
    )
  }

  static organizationMentionsRequest = async (organizationId: string) => {
    return await unauthInstanceOrg.get<OrganizationMentionsResponse>(
      `organization/${organizationId}/settings/mentions`
    )
  }

  static switchOrganizationRequest = async (organizationId: string, deviceId: string) => {
    const { data } = await authInstanceOrg.put<TokenResponse>(`/authorization/setOrg`, {
      organizationId,
      deviceId,
    })

    return data
  }

  static getCurrentStepRequest = async (registrationToken: string) => {
    const { data } = await unauthInstanceMaster.get<GetCurrentStepResponse>(
      `registration/getRegistrationStep/${registrationToken}`
    )

    return data
  }

  static sendAccessCodeRequest = async (loginData: string, loginMethod: 0 | 1) => {
    await unauthInstanceMaster.post<void>(`registration/sendAccessCode`, {
      loginData,
      loginMethod,
    })
  }

  static checkIsRegisteredRequest = async (
    loginData: string,
    loginMethod: 0 | 1,
    registrationToken: string
  ) => {
    const { data } = await unauthInstanceMaster.post<boolean>('authorization/isRegistered', {
      loginData,
      loginMethod,
      registrationToken,
    })

    return data
  }

  static getOrganizationsRequest = async (
    loginData: string,
    loginMethod: number,
    registrationToken: string,
    password: string
  ) => {
    const { data } = await unauthInstanceMaster.post<OrganizationResponse[]>(
      'authorization/login',
      {
        loginData,
        loginMethod,
        registrationToken,
        password,
      }
    )
    return data
  }

  static saveUserLogoRequest = async (userData: any) => {
    return await unauthInstanceMaster.post<any>('registration/saveLogo', jsonToFormData(userData))
  }

  static saveAccessCodeRequest = async (
    accessCode: string,
    loginData: string,
    loginMethod: 0 | 1
  ) => {
    const { data } = await unauthInstanceMaster.post<SaveResponse>('registration/saveAccessCode', {
      accessCode,
      loginData,
      loginMethod,
    })

    return data
  }

  static savePrivacyPolicyRequest = async (registrationToken: string) => {
    return await unauthInstanceMaster.post<any>(
      `registration/savePrivacyPolicy/${registrationToken}`
    )
  }

  static getLanguagesRequest = async (registrationToken: string) => {
    const { data } = await unauthInstanceMaster.get<GetLanguagesResponse>(
      `registration/getLanguages/${registrationToken}`
    )

    return data
  }

  static saveLanguageRequest = async (languageId: number, registrationToken: string) => {
    const { data } = await unauthInstanceMaster.post<SaveResponse>('registration/saveLanguage', {
      languageId,
      registrationToken,
    })

    return data
  }

  static savePasswordRequest = async (password: string, registrationToken: string) => {
    const { data } = await unauthInstanceMaster.post<SaveResponse>('registration/savePassword', {
      password,
      registrationToken,
    })

    return data
  }

  static loginToOrganizationRequest = async (
    loginData: string,
    loginMethod: number,
    registrationToken: string,
    password: string,
    organizationId: string,
    deviceId = null
  ) => {
    const { data } = await unauthInstanceOrg.post<TokenResponse>('authorization/tokenApp', {
      loginData,
      loginMethod,
      password,
      registrationToken,
      organizationId,
      deviceId,
    })

    return data
  }
}
