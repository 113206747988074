import React from 'react'

const BurgerButton = ({ color = '#fff' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.613"
      height="13.933"
      viewBox="0 0 21.613 13.933"
    >
      <g id="Group_213" transform="translate(-1111.042 259.539)">
        <path
          id="Path_326"
          d="M1112.042-246.606h19.613"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_327"
          d="M1112.042-258.539h19.613"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_328"
          d="M1112.042-252.572h19.613"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}

export default BurgerButton
