import { UserAndGroupResponse } from 'api/user/types/response'
import { GroupType, SearchUserAndGroup } from 'store/types'

export const mapGroupType = (type: number | null) => {
  switch (type) {
    case 0:
      return GroupType.Department
    case 1:
      return GroupType.Project
    case 2:
      return GroupType.Event
    default:
      return null
  }
}

export const mapUserSearchResults = (users: UserAndGroupResponse[]): SearchUserAndGroup[] =>
  users.map((user) => ({
    name: user.name,
    position: user.position,
    logo: user.logo,
    id: user.id,
    type: mapGroupType(user.type),
    aboutOthersType: user.aboutOthersType,
  }))
