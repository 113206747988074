import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const CommentsIcon = ({ isActive }: { isActive: boolean }) => {
  const { icon } = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52.747"
      height="58.811"
      viewBox="0 0 52.747 58.811"
    >
      <g id="Сгруппировать_4600" data-name="Сгруппировать 4600" transform="translate(-161.393 -4)">
        <g id="Сгруппировать_4599" data-name="Сгруппировать 4599" transform="translate(-8 -34)">
          <g
            id="Сгруппировать_2936"
            data-name="Сгруппировать 2936"
            transform="translate(169.393 38)"
            opacity={isActive ? '1' : '0.504'}
          >
            <path
              id="Контур_161"
              data-name="Контур 161"
              d="M44.567,13.379l13.187,7.889a13.679,13.679,0,0,1,6.593,11.774V48.819a13.536,13.536,0,0,1-6.593,11.774L44.567,68.481a12.648,12.648,0,0,1-13.187,0L18.193,60.593A13.679,13.679,0,0,1,11.6,48.819V33.042a13.536,13.536,0,0,1,6.593-11.774L31.38,13.379A12.649,12.649,0,0,1,44.567,13.379Z"
              transform="translate(-11.6 -11.525)"
              fill="#fff"
            />
          </g>
          <g
            id="Сгруппировать_3122"
            data-name="Сгруппировать 3122"
            transform="translate(178.858 51.317)"
          >
            <path
              id="Контур_7894"
              data-name="Контур 7894"
              d="M-1079.437,661.957c-9.382,0-16.988,6.909-16.988,15.432a14.717,14.717,0,0,0,5.013,10.944l-.618,6.377a1.02,1.02,0,0,0,1.5,1l6.494-3.461a18.646,18.646,0,0,0,4.6.574c9.382,0,16.988-6.909,16.988-15.432s-7.606-15.432-16.988-15.432m-8.321,13.338a2.1,2.1,0,0,1,2.095,2.095,2.1,2.1,0,0,1-2.095,2.1,2.1,2.1,0,0,1-2.1-2.1,2.1,2.1,0,0,1,2.1-2.095m8.322,4.191a2.1,2.1,0,0,1-2.095-2.1,2.1,2.1,0,0,1,2.095-2.095,2.1,2.1,0,0,1,2.1,2.095,2.1,2.1,0,0,1-2.1,2.1m8.321,0a2.1,2.1,0,0,1-2.1-2.1,2.1,2.1,0,0,1,2.1-2.095,2.1,2.1,0,0,1,2.095,2.095,2.1,2.1,0,0,1-2.095,2.1"
              transform="translate(1096.425 -661.957)"
              fill={isActive ? icon : '#fff'}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
