import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

const scaleFactor = 1.2

export const MoodCommon = () => {
  const brandingColors = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      style={{ transform: `scale(${scaleFactor})` }}
    >
      <g id="Сгруппировать_1121" data-name="Сгруппировать 1121" transform="translate(1292.9 254)">
        <path
          id="Контур_1131"
          data-name="Контур 1131"
          d="M231.5,12.2a19.557,19.557,0,0,0-4.3-6.35,19.558,19.558,0,0,0-6.35-4.3A20.415,20.415,0,0,0,213.1,0a20.863,20.863,0,0,0-7.8,1.55,19.558,19.558,0,0,0-6.35,4.3,20.971,20.971,0,0,0-4.3,6.35,20.4,20.4,0,0,0,0,15.6A20.286,20.286,0,0,0,205.3,38.45a20.4,20.4,0,0,0,15.6,0,19.558,19.558,0,0,0,6.35-4.3,20.971,20.971,0,0,0,4.3-6.35A20.863,20.863,0,0,0,233.1,20,19.974,19.974,0,0,0,231.5,12.2Zm-5.95,20.3A17.6,17.6,0,0,1,195.5,20a17.756,17.756,0,0,1,5.15-12.5A17.6,17.6,0,0,1,230.7,20,17.461,17.461,0,0,1,225.55,32.5Z"
          transform="translate(-1486 -254)"
          fill={brandingColors.iconOnBackground}
        />
        <path
          id="Контур_1132"
          data-name="Контур 1132"
          d="M215.4,54.8h16.45a1.217,1.217,0,0,0,1.2-1.2h0a1.217,1.217,0,0,0-1.2-1.2H215.4a1.217,1.217,0,0,0-1.2,1.2h0A1.216,1.216,0,0,0,215.4,54.8Z"
          transform="translate(-1496.55 -280.2)"
          fill={brandingColors.iconOnBackground}
        />
        <circle
          id="Эллипс_74"
          data-name="Эллипс 74"
          cx="2.95"
          cy="2.95"
          r="2.95"
          transform="translate(-1281.95 -241.6)"
          fill={brandingColors.iconOnBackground}
        />
        <circle
          id="Эллипс_75"
          data-name="Эллипс 75"
          cx="2.95"
          cy="2.95"
          r="2.95"
          transform="translate(-1269.75 -241.6)"
          fill={brandingColors.iconOnBackground}
        />
      </g>
    </svg>
  )
}
