import React from 'react'

export const UserFilled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.095"
      height="38.729"
      viewBox="0 0 24.095 38.729"
    >
      <g id="Group_3172" data-name="Group 3172" transform="translate(-678.167 -256.458)">
        <path
          id="Path_7934"
          data-name="Path 7934"
          d="M698.333,264.554a8.1,8.1,0,1,0-8.1,8.1,8.1,8.1,0,0,0,8.1-8.1"
          fill="#fff"
        />
        <path
          id="Path_7935"
          data-name="Path 7935"
          d="M700.59,280.742a9.706,9.706,0,0,0-1.717-1.547,15.991,15.991,0,0,0-17.308,0,9.669,9.669,0,0,0-1.717,1.547,6.411,6.411,0,0,0-1.681,4.207v10.238h24.1V284.949a6.415,6.415,0,0,0-1.679-4.207"
          fill="#fff"
        />
      </g>
    </svg>
  )
}
