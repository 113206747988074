import { authInstanceOrg } from 'api/axios.config'
import {
  AddComplaintRequestModel,
  AddMentionRequestModel,
  CommentRequestModel,
  CommentResponseModel,
} from 'api/types/requestTypes'
import { commentsMapper, mapMentionChat } from 'api/mappers/MentionChatMapper'
import { MentionInfo, MentionChatMessage, NewPositiveMentionInfo } from 'store/types'
import {
  CreateNotesResponse,
  GetCommentsResponse,
  MentionChatResponse,
  ComplaintResponse,
  NoteResponse,
} from './types/response'
import { ExtraTipsResponse, NoteModel } from 'store/slices/mentions/types'
import { extraTipsMapper } from 'api/mappers/ExtraTipsMapper'
import { NOBODY_ID } from 'modules/create-mention/components/UserInfo'

export class MentionsApi {
  static postComplaintRequest = async (model: AddComplaintRequestModel) => {
    const { data } = await authInstanceOrg.post<ComplaintResponse>(`mention/createComplaint`, model)
    return data
  }

  static postTipRequest = async (model: AddMentionRequestModel) => {
    const { data } = await authInstanceOrg.post<ComplaintResponse>(`mention/createTip`, model)
    return data
  }

  static postComplimentRequest = async (model: AddMentionRequestModel) => {
    const { data } = await authInstanceOrg.post<ComplaintResponse>(
      `mention/createCompliment`,
      model
    )
    return data
  }

  static getInfoRequest = async (recipientId: string, isCompliment: boolean) => {
    const { data } = await authInstanceOrg.get<NewPositiveMentionInfo>(
      `mention/getMentionUsers?recipientId=${recipientId}&isCompliment=${isCompliment}`
    )
    return data
  }

  static getChatRequest = async (mentionId: string) => {
    const { data } = await authInstanceOrg.get<MentionChatResponse>(
      `mention/getChat?mentionId=${mentionId}`
    )
    return mapMentionChat(data)
  }

  static sendMessageRequest = async (mentionId: string, text: string) => {
    const { data } = await authInstanceOrg.post<MentionChatMessage>('mention/sendMessage', {
      mentionId,
      text,
    })
    return data
  }

  static updateLastSeenRequest = async (chatId: string) => {
    await authInstanceOrg.put<void>('mention/updateLastSeenMessage', { id: chatId })
  }

  static getComplaintInfoRequest = async (
    recipientId: string,
    handlerId: string | null,
    isAnonymous: boolean
  ) => {
    if (handlerId !== NOBODY_ID) {
      const { data } = await authInstanceOrg.get<MentionInfo>(
        `mention/getComplaintInfo?recipientId=${recipientId}&handlerId=${handlerId}&isAnonymous=${isAnonymous}`
      )

      return data
    }
    const { data } = await authInstanceOrg.get<MentionInfo>(
      `mention/getComplaintInfo?recipientId=${recipientId}&isAnonymous=${isAnonymous}`
    )

    return data
  }

  static getComplaintChatPrivacyRequest = async (mentionId: string) => {
    const { data } = await authInstanceOrg.get<MentionInfo>(
      `mention/getComplaintPrivacy?mentionId=${mentionId}`
    )

    return data
  }

  static getMentionChatPrivacyRequest = async (mentionId: string) => {
    const { data } = await authInstanceOrg.get<NewPositiveMentionInfo>(
      `mention/getMentionPrivacy?mentionId=${mentionId}`
    )

    return data
  }

  static complaintIsHandledRequest = async (complaintId: string) => {
    await authInstanceOrg.put<void>(`mention/complaintIsHandled?complaintId=${complaintId}`, {})
  }

  static followMentionRequest = async (mentionId: string) => {
    await authInstanceOrg.put<void>(`mention/${mentionId}/follow`, {})
  }

  static reverseComplaintIsHandledRequest = async (complaintId: string) => {
    await authInstanceOrg.put<void>(
      `mention/reverseComplaintIsHandled?complaintId=${complaintId}`,
      {}
    )
  }

  static completeComplaintSatisfactionSurveyRequest = async (messageId: string, answer: string) => {
    await authInstanceOrg.put<{ id: string; answer: string }>(
      'mention/completeComplaintSatisfactionSurvey',
      {
        id: messageId,
        answer,
      }
    )
  }

  static deleteMessageRequest = async (messageId: string) => {
    await authInstanceOrg.put<void>(`mention/deleteMessage?messageId=${messageId}`, {})
  }

  static getCommentsRequest = async (questionId: string) => {
    const { data } = await authInstanceOrg.get<GetCommentsResponse>(
      `mention/comment?questionId=${questionId}`
    )
    return {
      data: data.data.map((comment) => commentsMapper(comment)),
      total: data.total,
    }
  }

  static createNotesRequest = async (id: string, questionId: string, text: string) => {
    const { data } = await authInstanceOrg.post<CreateNotesResponse>('mention/note', {
      id,
      questionId,
      text,
    })
    return data
  }

  static postNote = async (model: NoteModel) => {
    const { data } = await authInstanceOrg.post<NoteResponse>(`mention/note`, model)
    return {
      id: data.id,
      questionId: data.questionId,
      text: data.text,
    }
  }

  static updateNote = async (model: NoteModel) => {
    const { data } = await authInstanceOrg.put<NoteResponse>(`mention/note`, model)
    return {
      id: data.id,
      questionId: data.questionId,
      text: data.text,
    }
  }

  static getNote = async (questionId: string) => {
    const { data } = await authInstanceOrg.get<NoteModel>(`mention/note?questionId=${questionId}`)
    return {
      id: data.id,
      questionId: data.questionId,
      text: data.text,
    }
  }

  static postComment = async (model: CommentRequestModel) => {
    const { data } = await authInstanceOrg.post<CommentResponseModel>(`mention/comment`, model)
    return {
      recipientId: data.recipientId,
      giphy: data.giphy,
      text: data.text,
    }
  }

  static getExtraTipsRequest = async (questionId: string) => {
    const { data } = await authInstanceOrg.get<ExtraTipsResponse[]>(
      `question/getExtraTips/${questionId}`
    )
    return data.map((tip) => extraTipsMapper(tip))
  }
}
