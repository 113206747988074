import { createSlice, SerializedError } from '@reduxjs/toolkit'
import { changePassword } from './thunks'

interface AccountState {
  passwordError: null | string
  success: boolean
  error: SerializedError | null
  isLoading: boolean
}

const initialState: AccountState = {
  passwordError: null,
  success: false,
  error: null,
  isLoading: false,
}

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    clearAsyncMessage: (state) => {
      state.passwordError = initialState.passwordError
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changePassword.fulfilled, (state) => {
      state.passwordError = initialState.passwordError
      state.success = true
      state.isLoading = false
    })
    builder.addCase(changePassword.pending, (state) => {
      state.passwordError = initialState.passwordError
      state.success = false
      state.isLoading = true
    })
    builder.addCase(changePassword.rejected, (state, action) => {
      state.passwordError = initialState.passwordError
      if (action.payload) {
        state.error = {
          message: action.payload.message,
        }
      }
    })
  },
})

export const accountReducer = accountSlice.reducer
export const { clearAsyncMessage } = accountSlice.actions
