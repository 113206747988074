import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { NewGroupApi } from 'api/newGroup'
import { Error, NewGroupCreateResponse, NewGroupSettings } from './types'
import { NewGroupCreateRequest } from 'api/newGroup/types/request'

export const getNewGroupSettings = createAsyncThunk<
  NewGroupSettings,
  string,
  { rejectValue: Error }
>('newGroup/getNewGroupSettings', async (appSettingId: string, { rejectWithValue }) => {
  try {
    return await NewGroupApi.getNewGroupSettings(appSettingId)
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const validateGroupExist = createAsyncThunk<boolean, string, { rejectValue: Error }>(
  'newGroup/validateGroupExist',
  async (title, { rejectWithValue }) => {
    try {
      const { data: isExists } = await NewGroupApi.isGroupExist(title)
      return isExists
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const createNewGroup = createAsyncThunk<
  NewGroupCreateResponse,
  NewGroupCreateRequest,
  { rejectValue: Error }
>('newGroup/createNewGroup', async (model, { rejectWithValue }) => {
  try {
    const { data: url } = await NewGroupApi.createGroup(model)
    return url
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})
