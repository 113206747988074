import { TimelineAnswer, TimelineItemInfo } from 'store/types'
import {
  createSlice,
  isFulfilled,
  isRejectedWithValue,
  isPending,
  PayloadAction,
} from '@reduxjs/toolkit'
import {
  getTimelineItems,
  loadMoreTimelineItems,
  getQuestionPrivacySettings,
  getResultPrivacySettings,
  getAvailableAppSettings,
  toggleHighlightPostItem,
  readPost,
  answerPost,
  getGroupedResults,
  getGroupedEpisodes,
} from './thunks'
import { TimelineStateType } from './types'

const initialState: TimelineStateType = {
  timelineItems: [],
  availableAppSettings: [],
  expandedItemId: '',
  expandLoadingId: '',
  page: 0,
  currentQuestionPrivacySettings: {} as TimelineItemInfo,
  currentResultPrivacySettings: {} as TimelineItemInfo,
  isLastPage: false,
  details: [],
  isInitialLoadPending: false,
  isLoading: false,
  error: null,
  success: false,
}

const timelineSlice = createSlice({
  name: 'timeline',
  initialState,
  reducers: {
    setIsAnswered: (state: TimelineStateType, action: PayloadAction<string>) => {
      state.timelineItems = state.timelineItems.map((item) => {
        if (item.data.id === action.payload) {
          return {
            ...item,
            data: {
              ...item.data,
              answered: true,
            },
          }
        }
        return item
      })
    },

    setIsSaved: (state: TimelineStateType, action: PayloadAction<string>) => {
      state.timelineItems = state.timelineItems.map((item) => {
        if (item.data.id === action.payload) {
          return {
            ...item,
            data: {
              ...item.data,
              isSaved: true,
            },
          }
        }
        return item
      })
    },

    setQuestionAnswer: (
      state,
      action: PayloadAction<{ answer: TimelineAnswer | null; questionId: string }>
    ) => {
      state.timelineItems = state.timelineItems.map((item) => {
        if (item.data.id === action.payload.questionId) {
          return {
            ...item,
            data: {
              ...item.data,
              answer: action.payload.answer,
            },
          }
        }
        return item
      })
    },

    clearTimeline: (state: TimelineStateType) => {
      state.timelineItems = []
    },

    clearPrivacySettings: (state: TimelineStateType) => {
      state.currentQuestionPrivacySettings = initialState.currentQuestionPrivacySettings
      state.currentResultPrivacySettings = initialState.currentResultPrivacySettings
    },

    hideGroupedTimelineItems: (state: TimelineStateType) => {
      state.timelineItems = state.timelineItems.filter((item) => !item.data.groupParentId)
      state.expandedItemId = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTimelineItems.fulfilled, (state, action) => {
      state.timelineItems = action.payload.timelineItems
      state.isLastPage = action.payload.isLastPage
      state.page = action.payload.page
      state.isLoading = false
      state.success = true
      state.error = null
    })

    builder.addCase(getTimelineItems.pending, (state) => {
      state.success = false
      state.isLoading = true
      state.error = null
    })
    builder.addCase(getTimelineItems.rejected, (state, action) => {
      if (action.payload) {
        state.error = {
          message: action.payload.message,
        }
      }
      state.isLoading = false
      state.success = false
    })

    builder.addCase(loadMoreTimelineItems.fulfilled, (state, action) => {
      if (action.payload) {
        state.timelineItems = [...state.timelineItems, ...action.payload.timelineItems]
        state.isLastPage = action.payload.isLastPage
        state.page = action.payload.page
      }
    })

    builder.addCase(getGroupedResults.pending, (state, action) => {
      state.expandLoadingId = action.meta.arg.timelineItemId
    })
    builder.addCase(getGroupedResults.rejected, (state) => {
      state.expandLoadingId = ''
    })

    builder.addCase(getGroupedResults.fulfilled, (state, action) => {
      const timelineItemsDefault = state.timelineItems.filter((item) => !item.data.groupParentId)
      const groupParentIndex = timelineItemsDefault.findIndex(
        (item) => item.id === action.payload.timelineItemId
      )

      if (groupParentIndex < 0) return state

      state.expandedItemId = action.payload.timelineItemId
      state.timelineItems = [
        ...timelineItemsDefault.slice(0, groupParentIndex + 1),
        ...action.payload.timelineItems,
        ...timelineItemsDefault.slice(groupParentIndex + 1),
      ]
      state.expandLoadingId = ''
    })

    builder.addCase(getGroupedEpisodes.pending, (state, action) => {
      state.expandLoadingId = action.meta.arg.timelineItemId
    })
    builder.addCase(getGroupedEpisodes.rejected, (state) => {
      state.expandLoadingId = ''
    })
    builder.addCase(getGroupedEpisodes.fulfilled, (state, action) => {
      const timelineItemsDefault = state.timelineItems.filter((item) => !item.data.groupParentId)
      const groupParentIndex = timelineItemsDefault.findIndex(
        (item) => item.id === action.payload.timelineItemId
      )

      if (groupParentIndex < 0) return state

      state.expandedItemId = action.payload.timelineItemId
      state.timelineItems = [
        ...timelineItemsDefault.slice(0, groupParentIndex + 1),
        ...action.payload.timelineItems,
        ...timelineItemsDefault.slice(groupParentIndex + 1),
      ]
      state.expandLoadingId = ''
    })

    builder.addCase(getQuestionPrivacySettings.fulfilled, (state, action) => {
      state.currentQuestionPrivacySettings = action.payload
    })

    builder.addCase(getResultPrivacySettings.fulfilled, (state, action) => {
      state.currentResultPrivacySettings = action.payload
    })
    builder.addCase(getAvailableAppSettings.fulfilled, (state, action) => {
      state.availableAppSettings = action.payload
    })
    builder.addCase(toggleHighlightPostItem.fulfilled, (state, action) => {
      state.timelineItems = state.timelineItems.map((item) => {
        if (item.data.postId === action.payload) {
          return {
            ...item,
            data: {
              ...item.data,
              isHighlighted: !item.data.isHighlighted,
            },
          }
        }
        return item
      })
    })
    builder.addCase(readPost.fulfilled, (state, action) => {
      state.timelineItems = state.timelineItems.map((item) => {
        if (item.id === action.payload) {
          return {
            ...item,
            data: {
              ...item.data,
              isRead: true,
              unreadCommentsCount: 0,
            },
          }
        }
        return item
      })
    })
    builder.addCase(answerPost.fulfilled, (state, action) => {
      state.timelineItems = state.timelineItems.map((item) => {
        if (item.id === action.payload.timelineItemId) {
          return {
            ...item,
            data: {
              ...item.data,
              answer: action.payload.answer,
              isRead: true,
              unreadCommentsCount: 0,
            },
          }
        }
        return item
      })
    })

    builder.addMatcher(
      isFulfilled(getQuestionPrivacySettings, getResultPrivacySettings),
      (state) => {
        state.success = true
        state.isLoading = false
        state.error = null
      }
    )

    builder.addMatcher(isPending(getQuestionPrivacySettings, getResultPrivacySettings), (state) => {
      state.isLoading = true
      state.error = null
      state.success = false
    })
    builder.addMatcher(
      isRejectedWithValue(getQuestionPrivacySettings, getResultPrivacySettings),
      (state, action) => {
        state.error = {
          message: action.payload.message,
        }
        state.isLoading = false
        state.success = false
      }
    )
  },
})

export const timelineReducer = timelineSlice.reducer
export const {
  setIsAnswered,
  setIsSaved,
  setQuestionAnswer,
  clearPrivacySettings,
  clearTimeline,
  hideGroupedTimelineItems,
} = timelineSlice.actions
