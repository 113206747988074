import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

const scaleFactor = 1.2

export const MoodWorseOutlined = (props: { isSelected: boolean; id: string }) => {
  const brandingColors = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="74"
      height="80.623"
      viewBox="0 0 74 80.623"
      style={{ transform: `scale(${scaleFactor})` }}
    >
      <defs>
        {props.isSelected && (
          <linearGradient
            id={`mood-worse-gradient-${props.id}`}
            x1="0.5"
            x2="0.5"
            y2="1"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor={brandingColors.mood1Start} />
            <stop offset="1" stopColor={brandingColors.mood1End} />
          </linearGradient>
        )}
        <filter
          id="Контур_1181"
          x="0"
          y="0"
          width="74"
          height="80.623"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Сгруппировать_4568"
        data-name="Сгруппировать 4568"
        transform="translate(-14.889 -1318.691)"
      >
        <g transform="matrix(1, 0, 0, 1, 14.89, 1318.69)" filter="url(#Контур_1181)">
          <path
            id="Контур_1181-2"
            data-name="Контур 1181"
            d="M45.975,13.459l13.75,8.225A14.264,14.264,0,0,1,66.6,33.961V50.412a14.114,14.114,0,0,1-6.875,12.277l-13.75,8.225a13.189,13.189,0,0,1-13.75,0l-13.75-8.225A14.264,14.264,0,0,1,11.6,50.412V33.961a14.114,14.114,0,0,1,6.875-12.277l13.75-8.225A13.189,13.189,0,0,1,45.975,13.459Z"
            transform="translate(-2.1 -4.89)"
            fill={`url(#mood-worse-gradient-${props.id})`}
            stroke={!props.isSelected ? brandingColors.icon : 'transparent'}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
        <g
          id="Сгруппировать_1102"
          data-name="Сгруппировать 1102"
          transform="translate(1112.996 1590)"
        >
          <path
            id="Контур_1129"
            data-name="Контур 1129"
            d="M443.8,12.2a19.558,19.558,0,0,0-4.3-6.35,19.558,19.558,0,0,0-6.35-4.3A20.416,20.416,0,0,0,425.4,0a20.863,20.863,0,0,0-7.8,1.55,19.558,19.558,0,0,0-6.35,4.3,20.971,20.971,0,0,0-4.3,6.35,20.4,20.4,0,0,0,0,15.6A20.286,20.286,0,0,0,417.6,38.45a20.4,20.4,0,0,0,15.6,0,19.558,19.558,0,0,0,6.35-4.3,20.97,20.97,0,0,0,4.3-6.35A20.863,20.863,0,0,0,445.4,20,19.974,19.974,0,0,0,443.8,12.2Zm-5.95,20.3A17.6,17.6,0,0,1,407.8,20a17.756,17.756,0,0,1,5.15-12.5A17.6,17.6,0,0,1,443,20,17.461,17.461,0,0,1,437.85,32.5Z"
            transform="translate(-1486 -254)"
            fill={props.isSelected ? '#fff' : brandingColors.icon}
          />
          <path
            id="Контур_1130"
            data-name="Контур 1130"
            d="M439.576,48.5a13.244,13.244,0,0,0-4-.7,12.807,12.807,0,0,0-7.4,2.4,11.415,11.415,0,0,0-2.1,1.8,1.176,1.176,0,0,0,.15,1.75h0a1.159,1.159,0,0,0,1.55-.15,10.863,10.863,0,0,1,7.8-3.45,11.315,11.315,0,0,1,7.8,3.4,1.159,1.159,0,0,0,1.55.15h0a1.169,1.169,0,0,0,.15-1.75A13.068,13.068,0,0,0,439.576,48.5Z"
            transform="translate(-1496.176 -277.9)"
            fill={props.isSelected ? '#fff' : brandingColors.icon}
          />
          <circle
            id="Эллипс_72"
            data-name="Эллипс 72"
            cx="2.95"
            cy="2.95"
            r="2.95"
            transform="translate(-1069.65 -241.6)"
            fill={props.isSelected ? '#fff' : brandingColors.icon}
          />
          <circle
            id="Эллипс_73"
            data-name="Эллипс 73"
            cx="2.95"
            cy="2.95"
            r="2.95"
            transform="translate(-1057.45 -241.6)"
            fill={props.isSelected ? '#fff' : brandingColors.icon}
          />
        </g>
      </g>
    </svg>
  )
}
