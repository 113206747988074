import type { CategoryItem, MappedPostItemResponse } from './types/response'
import type { CategoryItemTranslates, PostItemTranslates } from '../../store/types'

const DEFAULT_EMPTY_VALUE = '00000000-0000-0000-0000-000000000000'

export const categoryItemTranslatesMapper = (
  translate: CategoryItemTranslates
): PostItemTranslates => ({
  id: DEFAULT_EMPTY_VALUE,
  postItemId: DEFAULT_EMPTY_VALUE,
  languageId: translate.languageId,
  text: translate.text,
})

export const categoryItemMapper = (
  categoryItem: CategoryItem,
  postId: string
): MappedPostItemResponse => ({
  id: DEFAULT_EMPTY_VALUE,
  postId,
  categoryItemId: categoryItem.id,
  type: categoryItem.type,
  link: categoryItem.link,
  isEmbedded: categoryItem.isEmbedded,
  order: categoryItem.order,
  languageId: categoryItem.languageId,
  text: categoryItem.text,
  translates: categoryItem.translates?.map((t: CategoryItemTranslates) =>
    categoryItemTranslatesMapper(t)
  ),
})
