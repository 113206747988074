import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

const scaleFactor = 1.2

export const MoodBad = () => {
  const brandingColors = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      style={{ transform: `scale(${scaleFactor})` }}
    >
      <g id="Сгруппировать_1120" data-name="Сгруппировать 1120" transform="translate(1188.8 254)">
        <path
          id="Контур_1133"
          data-name="Контур 1133"
          d="M335.6,12.2a19.558,19.558,0,0,0-4.3-6.35,19.558,19.558,0,0,0-6.35-4.3A20.415,20.415,0,0,0,317.2,0a20.863,20.863,0,0,0-7.8,1.55,19.558,19.558,0,0,0-6.35,4.3,20.971,20.971,0,0,0-4.3,6.35,20.4,20.4,0,0,0,0,15.6A20.286,20.286,0,0,0,309.4,38.45a20.4,20.4,0,0,0,15.6,0,19.558,19.558,0,0,0,6.35-4.3,20.97,20.97,0,0,0,4.3-6.35A20.863,20.863,0,0,0,337.2,20,19.974,19.974,0,0,0,335.6,12.2Zm-5.95,20.3A17.6,17.6,0,0,1,299.6,20a17.756,17.756,0,0,1,5.15-12.5A17.6,17.6,0,0,1,334.8,20,17.461,17.461,0,0,1,329.65,32.5Z"
          transform="translate(-1486 -254)"
          fill={brandingColors.iconOnBackground}
        />
        <path
          id="Контур_1134"
          data-name="Контур 1134"
          d="M318.273,53.75h0a1.2,1.2,0,0,0,1.55.5,19.257,19.257,0,0,1,7.85-1.7,18.848,18.848,0,0,1,7.85,1.7,1.212,1.212,0,0,0,1.55-.5h0a1.17,1.17,0,0,0-.55-1.6,21.058,21.058,0,0,0-17.7,0A1.17,1.17,0,0,0,318.273,53.75Z"
          transform="translate(-1496.473 -279.1)"
          fill={brandingColors.iconOnBackground}
        />
        <circle
          id="Эллипс_76"
          data-name="Эллипс 76"
          cx="2.95"
          cy="2.95"
          r="2.95"
          transform="translate(-1177.85 -241.6)"
          fill={brandingColors.iconOnBackground}
        />
        <circle
          id="Эллипс_77"
          data-name="Эллипс 77"
          cx="2.95"
          cy="2.95"
          r="2.95"
          transform="translate(-1165.65 -241.6)"
          fill={brandingColors.iconOnBackground}
        />
      </g>
    </svg>
  )
}
