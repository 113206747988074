import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const NotVisibleSvg = () => {
  const { iconOnBackground } = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.12"
      height="24.493"
      viewBox="0 0 32.12 24.493"
    >
      <g
        id="Сгруппировать_3102"
        data-name="Сгруппировать 3102"
        transform="translate(-266.533 -433.759)"
      >
        <path
          id="Контур_7871"
          data-name="Контур 7871"
          d="M267.121,436.541l.147.147.42.42.668.668.879.879,1.064,1.064,1.22,1.22,1.345,1.344,1.441,1.441,1.515,1.515,1.544,1.544,1.551,1.551,1.528,1.53,1.477,1.477,1.395,1.395,1.29,1.29,1.143,1.143.972.972.773.773.543.543.284.284.035.035a1.683,1.683,0,0,0,1.151.477,1.817,1.817,0,0,0,1.153-.477,1.645,1.645,0,0,0,.477-1.151,1.7,1.7,0,0,0-.477-1.151l-.147-.147-.42-.42-.668-.668-.881-.881-1.064-1.064-1.219-1.219-1.345-1.345-1.441-1.441-1.515-1.515-1.544-1.544-1.551-1.551-1.53-1.53-1.476-1.476-1.4-1.4-1.29-1.29-1.143-1.143-.972-.972-.772-.772-.543-.544-.285-.284-.035-.035a1.68,1.68,0,0,0-1.151-.477,1.241,1.241,0,0,0-.627.14,1.225,1.225,0,0,0-.524.337,1.634,1.634,0,0,0-.477,1.151,1.7,1.7,0,0,0,.477,1.153"
          transform="translate(0.038 0)"
          fill={iconOnBackground}
        />
        <path
          id="Контур_7872"
          data-name="Контур 7872"
          d="M296.636,443.155c-4.557-6.757-11.788-10.616-19.093-8.665a19.024,19.024,0,0,0-7.472,4.248l.282.285,1.275,1.291,1.379,1.395.878.887a7.854,7.854,0,1,1,9.755,9.871l.853.862,1.051,1.065.606.613a9.37,9.37,0,0,0,2.031-.816c.18-.1.377-.2.572-.3a21.46,21.46,0,0,0,7.9-7.274c1.029-1.575,1.031-1.9-.021-3.46"
          transform="translate(1.226 0.078)"
          fill={iconOnBackground}
        />
        <path
          id="Контур_7873"
          data-name="Контур 7873"
          d="M283.014,453.247l-1.29-1.29-1.33-1.33-.065-.065q-.739-.739-1.477-1.477l-1.53-1.528-.057-.058-.245-.245-.16-.16-.393-.393-.377-.377-.144-.144-.175-.175-.1-.1-.066-.066-.419-.419-.956-.955-1.513-1.515-1.442-1.441-1.344-1.345-.937-.937a19.8,19.8,0,0,0-1.4,1.758c-1.406,2.094-1.469,2.3.2,4.788a18.527,18.527,0,0,0,8.3,6.824,17.042,17.042,0,0,0,2.685.9,15.165,15.165,0,0,0,4.888.4Z"
          transform="translate(0 1.893)"
          fill={iconOnBackground}
        />
        <path
          id="Контур_7874"
          data-name="Контур 7874"
          d="M283.486,443.073a3.9,3.9,0,1,0-7.284,1.944l1,1,.44.44a3.9,3.9,0,0,0,5.846-3.381"
          transform="translate(3.009 1.875)"
          fill={iconOnBackground}
        />
        <path
          id="Контур_7875"
          data-name="Контур 7875"
          d="M276.068,443.513a3.924,3.924,0,0,0,1.438,1.437l-.44-.44-1-1"
          transform="translate(3.17 3.379)"
          fill={iconOnBackground}
        />
      </g>
    </svg>
  )
}
