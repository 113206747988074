import { useBrandingColors } from 'hooks/useBrandingColors'
import React from 'react'

export const HexagonItemIcon = (props: {
  logo?: string
  withoutGrad?: boolean
  startColor?: string
  stopColor?: string
  progress: number
  id: string
}) => {
  const { backgroundStart, backgroundEnd } = useBrandingColors()

  const max = 183.642
  const offset = ((100 - props.progress) / 100) * max

  return (
    <svg width="63.191" height="69.06" viewBox="0 0 63.191 69.06">
      <defs>
        <clipPath id={`shape-hex-${props.id}`}>
          <path d="M4.2,34.351c.161-3.452.08-6.825.482-10.277A13.043,13.043,0,0,1,11.025,14.2c4.416-2.81,8.993-5.379,13.489-8.029,4.978-2.971,9.956-2.81,14.854.08C43.382,8.659,47.4,11.068,51.412,13.4c4.737,2.81,7.547,6.9,7.708,12.445.161,6.182.08,12.445-.241,18.627a12.05,12.05,0,0,1-5.54,9.474c-5.058,3.292-10.277,6.423-15.577,9.394a12.22,12.22,0,0,1-12.285-.08c-4.9-2.73-9.635-5.62-14.372-8.591-4.577-2.89-6.664-7.226-6.825-12.525-.08-2.569,0-5.138,0-7.788Z" />
        </clipPath>
        <linearGradient id="grad" x1="0" y1="0" x2="1" y2="0">
          <stop
            offset="0"
            stopColor={props.withoutGrad ? backgroundStart : props.startColor}
            stopOpacity="1"
          />
          <stop
            offset="1"
            stopColor={props.withoutGrad ? backgroundEnd : props.stopColor}
            stopOpacity="1"
          />
        </linearGradient>
      </defs>

      {props.logo ? (
        <image
          width="100%"
          clipPath={`url(#shape-hex-${props.id})`}
          xlinkHref={props.logo}
          id={`logo-${props.id}`}
        />
      ) : (
        <g clipPath={`url(#shape-hex-${props.id})`}>
          <rect x="0" y="0" width="63.191" height="69.06" fill="url(#grad)" opacity={0.5} />
        </g>
      )}

      <path
        id={`hexBgWhite-${props.id}`}
        d="M 31.745 4 a 15.437 15.437 0 0 1 7.626 2.248 c 4.014 2.409 8.027 4.818 12.041 7.146 c 4.736 2.81 7.546 6.905 7.706 12.445 c 0.161 6.182 0.08 12.445 -0.241 18.628 a 12.051 12.051 0 0 1 -5.539 9.474 c -5.057 3.292 -10.275 6.423 -15.573 9.394 a 12.215 12.215 0 0 1 -12.282 -0.08 c -4.9 -2.73 -9.633 -5.62 -14.369 -8.591 c -4.576 -2.891 -6.663 -7.226 -6.823 -12.526 C 4.211 39.569 4.291 36.999 4.291 34.35 c 0.161 -3.452 0.08 -6.825 0.482 -10.277 A 13.043 13.043 0 0 1 11.025 14.2 c 4.416 -2.81 8.993 -5.379 13.489 -8.029 a 15.437 15.437 0 0 1 7.22 -2.171 Z"
        transform="translate(-0.01 0.042)"
        fill="none"
        stroke={props.withoutGrad ? 'url(#grad)' : '#fff'}
        strokeMiterlimit="10"
        strokeWidth="7"
        strokeOpacity="1"
      />
      <path
        id={`hexBg-${props.id}`}
        d="M 31.745 4 a 15.437 15.437 0 0 1 7.626 2.248 c 4.014 2.409 8.027 4.818 12.041 7.146 c 4.736 2.81 7.546 6.905 7.706 12.445 c 0.161 6.182 0.08 12.445 -0.241 18.628 a 12.051 12.051 0 0 1 -5.539 9.474 c -5.057 3.292 -10.275 6.423 -15.573 9.394 a 12.215 12.215 0 0 1 -12.282 -0.08 c -4.9 -2.73 -9.633 -5.62 -14.369 -8.591 c -4.576 -2.891 -6.663 -7.226 -6.823 -12.526 C 4.211 39.569 4.291 36.999 4.291 34.35 c 0.161 -3.452 0.08 -6.825 0.482 -10.277 A 13.043 13.043 0 0 1 11.025 14.2 c 4.416 -2.81 8.993 -5.379 13.489 -8.029 a 15.437 15.437 0 0 1 7.22 -2.171 Z"
        transform="translate(-0.01 0.042)"
        fill="none"
        stroke={props.withoutGrad ? '#fff' : 'url(#grad)'}
        strokeMiterlimit="10"
        strokeWidth="8"
        strokeOpacity="0.5"
      />

      <path
        id={`hex-${props.id}`}
        d="M 31.745 4 a 15.437 15.437 0 0 1 7.626 2.248 c 4.014 2.409 8.027 4.818 12.041 7.146 c 4.736 2.81 7.546 6.905 7.706 12.445 c 0.161 6.182 0.08 12.445 -0.241 18.628 a 12.051 12.051 0 0 1 -5.539 9.474 c -5.057 3.292 -10.275 6.423 -15.573 9.394 a 12.215 12.215 0 0 1 -12.282 -0.08 c -4.9 -2.73 -9.633 -5.62 -14.369 -8.591 c -4.576 -2.891 -6.663 -7.226 -6.823 -12.526 C 4.211 39.569 4.291 36.999 4.291 34.35 c 0.161 -3.452 0.08 -6.825 0.482 -10.277 A 13.043 13.043 0 0 1 11.025 14.2 c 4.416 -2.81 8.993 -5.379 13.489 -8.029 a 15.437 15.437 0 0 1 7.22 -2.171 Z"
        fill="none"
        strokeDashoffset={`${offset}`}
        strokeDasharray={`${max} ${max}`}
        stroke={props.withoutGrad ? '#fff' : 'url(#grad)'}
        strokeMiterlimit="10"
        strokeWidth="8"
        strokeOpacity="1"
      />

      <use xlinkHref="#hexBgWhite" />
      <use xlinkHref="#hexBg" />
      <use xlinkHref="#hex" />
    </svg>
  )
}
