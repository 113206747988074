import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const MoodWorseSmall = ({ isActive }: { isActive: boolean }) => {
  const { icon } = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="74.84"
      height="81.564"
      viewBox="0 0 74.84 81.564"
    >
      <defs>
        <filter
          id="Контур_1135"
          x="0"
          y="0"
          width="74.84"
          height="81.564"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Сгруппировать_4597"
        data-name="Сгруппировать 4597"
        transform="translate(-21.5 -738.318)"
      >
        <g transform="matrix(1, 0, 0, 1, 21.5, 738.32)" filter="url(#Контур_1135)">
          <path
            id="Контур_1135-2"
            data-name="Контур 1135"
            d="M46.5,13.488l13.96,8.351A14.481,14.481,0,0,1,67.44,34.3v16.7A14.33,14.33,0,0,1,60.46,63.47L46.5,71.821a13.39,13.39,0,0,1-13.96,0L18.58,63.47A14.481,14.481,0,0,1,11.6,51.006V34.3a14.33,14.33,0,0,1,6.98-12.464l13.96-8.351A13.39,13.39,0,0,1,46.5,13.488Z"
            transform="translate(-2.1 -4.89)"
            fill={isActive ? '#fff' : 'transparent'}
            stroke="#fff"
            opacity={isActive ? '1' : '0.5'}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
        <g
          id="Сгруппировать_988"
          data-name="Сгруппировать 988"
          transform="translate(39.063 756.082)"
        >
          <path
            id="Контур_1129"
            data-name="Контур 1129"
            d="M444.357,12.384a19.853,19.853,0,0,0-4.362-6.446,19.842,19.842,0,0,0-6.442-4.365A20.7,20.7,0,0,0,425.69,0a21.154,21.154,0,0,0-7.913,1.573,19.841,19.841,0,0,0-6.442,4.365,21.29,21.29,0,0,0-4.362,6.446,20.721,20.721,0,0,0,0,15.836,19.855,19.855,0,0,0,4.362,6.446,19.841,19.841,0,0,0,6.442,4.365,20.685,20.685,0,0,0,15.826,0,19.842,19.842,0,0,0,6.442-4.365,21.287,21.287,0,0,0,4.362-6.446A21.187,21.187,0,0,0,445.98,20.3,20.286,20.286,0,0,0,444.357,12.384ZM438.32,32.991A17.855,17.855,0,0,1,407.835,20.3a18.03,18.03,0,0,1,5.225-12.689A17.855,17.855,0,0,1,443.545,20.3,17.73,17.73,0,0,1,438.32,32.991Z"
            transform="translate(-405.4)"
            fill={isActive ? icon : '#fff'}
            opacity={isActive ? '1' : '0.5'}
          />
          <path
            id="Контур_1130"
            data-name="Контур 1130"
            d="M439.791,48.511a13.443,13.443,0,0,0-4.062-.711,13,13,0,0,0-7.515,2.439,11.6,11.6,0,0,0-2.133,1.829,1.2,1.2,0,0,0,.152,1.778h0a1.177,1.177,0,0,0,1.574-.152,11.03,11.03,0,0,1,7.921-3.506,11.487,11.487,0,0,1,7.921,3.455,1.177,1.177,0,0,0,1.574.152h0a1.189,1.189,0,0,0,.152-1.778A13.27,13.27,0,0,0,439.791,48.511Z"
            transform="translate(-415.439 -23.543)"
            fill={isActive ? icon : '#fff'}
            opacity={isActive ? '1' : '0.5'}
          />
          <ellipse
            id="Эллипс_72"
            data-name="Эллипс 72"
            cx="2.996"
            cy="2.998"
            rx="2.996"
            ry="2.998"
            transform="translate(11.107 12.585)"
            fill={isActive ? icon : '#fff'}
            opacity={isActive ? '1' : '0.5'}
          />
          <ellipse
            id="Эллипс_73"
            data-name="Эллипс 73"
            cx="2.996"
            cy="2.998"
            rx="2.996"
            ry="2.998"
            transform="translate(23.481 12.585)"
            fill={isActive ? icon : '#fff'}
            opacity={isActive ? '1' : '0.5'}
          />
        </g>
      </g>
    </svg>
  )
}
