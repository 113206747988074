import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import {
  getJourneys,
  getMoreJourneys,
  getJourneyDetails,
  answerPost,
  getMoreJourneyDetails,
} from './thunks'
import { GetJourneysResponse, GetJourneyDetailsResponse } from 'api/calendar/types/response'

interface CalendarState {
  journeys: GetJourneysResponse | null
  journeyDetails: Record<string, GetJourneyDetailsResponse> | null
  success: boolean
  error: SerializedError | null
  isLoading: boolean
  loadingJourneyId: string
  expandedItems: string[]
  isMoreItemsLoading: boolean
}

const initialState: CalendarState = {
  journeys: null,
  journeyDetails: null,
  success: false,
  error: null,
  isLoading: false,
  loadingJourneyId: '',
  expandedItems: [],
  isMoreItemsLoading: false,
}

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    resetState: (state: CalendarState) => {
      state.journeys = initialState.journeys
      state.journeyDetails = initialState.journeyDetails
    },

    collapseJourneyItem: (state: CalendarState, action: PayloadAction<string>) => {
      state.expandedItems = state.expandedItems.filter((item) => item !== action.payload)
    },

    collapseAllJourneyItems: (state: CalendarState) => {
      state.expandedItems = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getJourneys.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getJourneys.rejected, (state, action) => {
      state.isLoading = false
      if (action.payload) {
        state.error = {
          message: action.payload.message,
        }
      }
    })
    builder.addCase(getJourneys.fulfilled, (state, action) => {
      state.isLoading = false
      state.journeys = action.payload
    })

    builder.addCase(getMoreJourneys.pending, (state) => {
      state.isMoreItemsLoading = true
    })
    builder.addCase(getMoreJourneys.rejected, (state, action) => {
      state.isMoreItemsLoading = false
      if (action.payload) {
        state.error = {
          message: action.payload.message,
        }
      }
    })

    builder.addCase(getMoreJourneys.fulfilled, (state, action) => {
      state.isMoreItemsLoading = false
      if (state.journeys) {
        state.journeys = {
          ...state.journeys,
          data: [...state.journeys?.data, ...action.payload.data],
        }
      }
    })
    builder.addCase(getJourneyDetails.pending, (state, action) => {
      state.loadingJourneyId = action.meta.arg.journeyId
    })
    builder.addCase(getJourneyDetails.rejected, (state, action) => {
      state.loadingJourneyId = ''
      if (action.payload) {
        state.error = {
          message: action.payload.message,
        }
      }
    })

    builder.addCase(getJourneyDetails.fulfilled, (state, action) => {
      state.loadingJourneyId = ''
      state.journeyDetails = {
        ...state.journeyDetails,
        [action.payload.journeyId]: {
          data: action.payload.data,
          total: action.payload.total,
        },
      }
      state.expandedItems.push(action.payload.journeyId)
    })
    builder.addCase(answerPost.fulfilled, (state, action) => {
      if (!state.journeyDetails) return { ...state }

      const journeyDetails = state.journeyDetails[action.payload.journeyId]

      state.journeyDetails = {
        ...state.journeyDetails,
        [action.payload.journeyId]: {
          ...journeyDetails,
          data: journeyDetails.data.map((item) => {
            if (item.id === action.payload.itemId) {
              return {
                ...item,
                button: action.payload.button,
              }
            }
            return item
          }),
        },
      }
    })

    builder.addCase(getMoreJourneyDetails.pending, (state, action) => {
      state.loadingJourneyId = action.meta.arg.journeyId
    })
    builder.addCase(getMoreJourneyDetails.rejected, (state, action) => {
      state.loadingJourneyId = ''
      if (action.payload) {
        state.error = {
          message: action.payload.message,
        }
      }
    })

    builder.addCase(getMoreJourneyDetails.fulfilled, (state, action) => {
      if (state.journeyDetails) {
        const journeyItems = state.journeyDetails[action.payload.journeyId]

        state.journeyDetails[action.payload.journeyId] = {
          ...state.journeyDetails[action.payload.journeyId],
          data: [...journeyItems.data, ...action.payload.data],
        }
      }

      state.loadingJourneyId = ''
    })
  },
})

export const calendarReducer = calendarSlice.reducer
export const { resetState, collapseJourneyItem, collapseAllJourneyItems } = calendarSlice.actions
