import { authInstanceOrg } from 'api/axios.config'
import { GetJourneyDetailsResponse, GetJourneysResponse } from './types/response'

export class CalendarApi {
  static getJourneysRequest = async ({
    currentPage,
    isUpcoming = false,
    pageSize = 20,
  }: {
    currentPage: number
    isUpcoming: boolean
    pageSize?: number
  }) => {
    const { data } = await authInstanceOrg.post<GetJourneysResponse>(`calendar/getJourneys`, {
      isUpcoming,
      pager: {
        current: currentPage,
        pageSize,
      },
    })
    return data
  }
  static getJourneyDetailsRequest = async ({
    journeyId,
    currentPage,
    isUpcoming = false,
    pageSize = 20,
  }: {
    journeyId: string
    currentPage: number
    isUpcoming: boolean
    pageSize?: number
  }) => {
    const { data } = await authInstanceOrg.post<GetJourneyDetailsResponse>(
      `calendar/getJourneyDetails`,
      {
        journeyId,
        isUpcoming,
        pager: {
          current: currentPage,
          pageSize,
        },
      }
    )
    return data
  }
}
