import React from 'react'
import styled from 'styled-components/macro'
import { TextCalibriBold } from 'modules/common/components/Typography'
import { useBrandingColors } from 'hooks/useBrandingColors'
import { clearMessage, selectMessage } from 'store/slices/feedback/slice'
import { useAppDispatch } from 'store'
import { useSelector } from 'react-redux'
import { Modal } from 'antd'
import { ITheme } from 'styles/types'

const CommonFeedback: React.FC = () => {
  const { text } = useBrandingColors()
  const dispatch = useAppDispatch()
  const message = useSelector(selectMessage)

  const hideModal = () => {
    dispatch(clearMessage())
  }

  return (
    <StyledModal
      open={!!message}
      onOk={hideModal}
      color={text}
      wrapClassName="common-feedback"
      okText="OK"
    >
      <Text textColor={text}>{message}</Text>
    </StyledModal>
  )
}

export default CommonFeedback

const Text = styled(TextCalibriBold)<{ textColor: string }>`
  font-size: 19px;
  color: ${({ textColor }) => textColor};
  display: block;
  text-align: center;
  padding-bottom: 26px;
`
export const StyledModal = styled(Modal)<{ color: string }>`
  top: 40%;

  &&& {
    .ant-modal-content {
      border-radius: 17px;
      width: 415px;
      margin: auto;

      @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
        width: 300px;
      }
    }
    .ant-modal-header {
      display: none;
    }
    .ant-modal-close-x {
      display: none;
    }

    .ant-modal-body {
      padding: 26px 29px 0 29px;
    }

    .ant-modal-footer {
      padding: 0;
      border: none;
      width: 100%;
      .ant-btn:first-child {
        display: none;
      }
      .ant-btn {
        width: 100%;
        margin: 0;
        border-radius: 0 0 17px 17px;
        background-color: ${({ color }) => color};
        border: none;
        height: 43px;
        font-family: AvenirNextCondensedBold;
        font-size: 15px;
      }

      .ant-btn-primary {
        margin: 0;
      }
    }

    .ant-switch {
      background-color: ${({ color }) => color};
      opacity: 0.3;
    }
    .ant-switch-checked {
      background-color: ${({ color }) => color};
      opacity: 1;
    }
  }
`
