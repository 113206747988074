import { getFromLocalStorage, saveToLocalStorage } from 'utils/storage'
import { LOCAL_STORAGE_KEYS } from 'modules/common/constants/storageKeys'
import { PostCategoryType } from 'store/types/post'
import cancellationIcon from 'assets/icons/categorie_icons_Cancellation.svg'
import changeOfPlanningIcon from 'assets/icons/categorie_icons_Change_of_Planning.svg'
import confirmationIcon from 'assets/icons/categorie_icons_Confirmation.svg'
import inviteIcon from 'assets/icons/categorie_icons_Invite.svg'
import reminderIcon from 'assets/icons/categorie_icons_Reminder.svg'
import inviteReminderIcon from 'assets/icons/categorie_icons_Invite_Reminder.svg'

/* eslint-disable */
function b64toBlob(b64Data, contentType, sliceSize?) {
  contentType = contentType || ''
  sliceSize = sliceSize || 512

  // eslint-disable-next-line no-undef
  const byteCharacters = atob(b64Data)
  const byteArrays: any[] = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < slice.length; ++i) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const base64ToBlob = (image) => {
  const block = image.split(';')

  // Get the content type of the image
  const contentType = block[0].split(':')[1]

  // get the real base64 content of the file
  const realData = block[1].split(',')[1]

  // Convert it to a blob to upload
  const blob = b64toBlob(realData, contentType)

  return blob
}

// export const jsonToFormData = (jsonObject) => {
//   const form_data = new FormData()

//   Object.keys(jsonObject).forEach((key) => {
//     jsonObject[key] = jsonObject[key] === null ? '' : jsonObject[key]
//     form_data.append(key, jsonObject[key])
//   })

//   return form_data
// }

export const jsonToFormData = (data: any, form?: any, parentKey?: any) => {
  let formData = form || new FormData()

  if (
    data &&
    typeof data === 'object' &&
    !('filename' in data) &&
    !('imageFilename' in data) &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    !(data instanceof Blob)
  ) {
    Object.keys(data).forEach((key) => {
      jsonToFormData(data[key], formData, parentKey ? `${parentKey}[${key}]` : key)
    })
  } else {
    const value = data == null ? '' : data

    if (typeof value === 'object' && value.filename) {
      formData.append('file', value.file, value.filename)
    }

    if (typeof value === 'object' && value.imageFilename) {
      formData.append('Logo', value.file, value.imageFilename)
    }

    if (typeof value !== 'object' || !value.filename || !value.imageFilename) {
      formData.append(parentKey, value)
    }
  }

  return formData
}

export const getCurrentUser = () => {
  const user = getFromLocalStorage(LOCAL_STORAGE_KEYS.USER)

  const userData = user && JSON.parse(user?.currentUser)

  if (userData?.accessToken?.token) {
    return userData
  }
  return null
}

export const updateCurrentUser = (data: any) => {
  saveToLocalStorage(LOCAL_STORAGE_KEYS.USER, {
    ...getFromLocalStorage(LOCAL_STORAGE_KEYS.USER),
    ...data,
  })
}

export const getDefaultCategoryIcon = (categoryType: number) => {
  switch (categoryType) {
    case PostCategoryType.Invite:
      return inviteIcon
    case PostCategoryType.Reminder:
      return reminderIcon
    case PostCategoryType.Cancellation:
      return cancellationIcon
    case PostCategoryType.ChangeOfPlanning:
      return changeOfPlanningIcon
    case PostCategoryType.Confirmation:
      return confirmationIcon
    case PostCategoryType.InviteReminder:
      return inviteReminderIcon
    default:
      return ''
  }
}
