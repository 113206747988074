import { authInstanceOrg } from 'api/axios.config'
import { postMapper } from 'api/mappers/TimelinePostMapper'
import { questionMapper } from 'api/mappers/TimelineQuestionMapper'
import { SearchQuery } from 'api/types/requestTypes'
import {
  SearchCategoriesResponse,
  SearchCourseResponse,
  SearchPostResponse,
} from './types/response'

export class SearchApi {
  static getSearchRequest = async () => {
    const { data } = await authInstanceOrg.get<SearchCategoriesResponse[]>(`search/getSearch`)
    return data
  }
  static getSearchPostsRequest = async (searchQuery: SearchQuery) => {
    const { data } = await authInstanceOrg.post<SearchPostResponse>(
      `search/getSearchPosts`,
      searchQuery
    )
    return {
      ...data,
      posts: data.posts.map((post) => postMapper(post)),
    }
  }
  static getSearchCoursesRequest = async (searchQuery: SearchQuery) => {
    const { data } = await authInstanceOrg.post<SearchCourseResponse>(
      `search/getSearchCourses`,
      searchQuery
    )

    return {
      ...data,
      questions: data.questions.map((question) => questionMapper(question)),
    }
  }
}
