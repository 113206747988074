import React from 'react'
import styled from 'styled-components/macro'
import { TextCalibriBold } from 'modules/common/components/Typography'
import { ITheme } from 'styles/types'

interface IProps {
  text: string
}

const CreateMentionHeader: React.FC<IProps> = ({ text }) => {
  return (
    <PageHeader>
      <Text>{text}</Text>
    </PageHeader>
  )
}

export default CreateMentionHeader

export const PageHeader = styled.div`
  margin-bottom: 25px;
  white-space: pre-wrap;
`

export const Text = styled(TextCalibriBold)`
  font-size: 23px;
  display: block;
  line-height: 25px;
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.md} {
    font-size: 19px;
  }
`
