import React from 'react'

export const PhoneSvg = () => {
  return (
    <svg
      width="21"
      height="19"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0.057 21 18.886"
      fill="#fff"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="19"
        viewBox="0.918 0.245 19.834 17.837"
      >
        <path
          paintOrder="stroke fill markers"
          fillRule="evenodd"
          d="M.918 6.161c0-.21.005-.421.003-.632a5.77 5.77 0 0 1 .602-2.587c.253-.523.575-.997.978-1.42.214-.226.386-.49.591-.725C3.63.18 4.252.073 4.963.509c.709.433 1.239 1.061 1.774 1.678.308.355.604.728.853 1.126.548.875.535 1.736-.21 2.552-.34.375-.687.747-.996 1.147-.315.407-.398.87-.225 1.378.284.836.864 1.455 1.472 2.052a17.106 17.106 0 0 0 2.447 1.98c.542.364 1.114.683 1.742.874.45.138.919.182 1.35-.046.204-.108.382-.29.533-.472.403-.484.757-1.013 1.183-1.475.493-.536 1.144-.621 1.83-.459.667.159 1.258.488 1.836.846.368.228.755.428 1.103.683.395.289.785.591.99 1.072.172.408.135.805-.106 1.135-.462.63-.938 1.259-1.478 1.82a5.053 5.053 0 0 1-2.296 1.368c-.16.045-.316.106-.474.16l-.761.134c-.022-.009-.044-.026-.065-.025-.316.014-.632.048-.947.044-1-.015-1.962-.23-2.895-.575a14.103 14.103 0 0 1-2.304-1.104 21.425 21.425 0 0 1-2.024-1.347 28.773 28.773 0 0 1-2.298-1.916c-.9-.843-1.733-1.745-2.426-2.77-.542-.801-1.015-1.637-1.29-2.572-.038-.13-.094-.253-.142-.38L.918 6.161z"
        />
      </svg>
    </svg>
  )
}
