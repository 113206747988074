import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const HexagonResult = (props: { progress: number }) => {
  const brandingColor = useBrandingColors()

  const fillRatio = `${props.progress * 100}%`

  return (
    <svg width={45} height={50} viewBox="0 0 32 35" preserveAspectRatio="none">
      <defs>
        <clipPath id="hex-result-clip">
          <path
            d="M.763,11.439A7.22,7.22,0,0,1,4.232,6.06C6.647,4.53,9.15,3.13,11.609,1.687a7.563,7.563,0,0,1,8.167.044c2.2,1.312,4.391,2.624,6.586,3.892A7.843,7.843,0,0,1,30.577,12.4c.088,3.367.044,6.778-.132,10.146a6.559,6.559,0,0,1-3.03,5.16c-2.766,1.793-5.62,3.5-8.518,5.117a6.706,6.706,0,0,1-6.718-.044C9.5,31.294,6.911,29.719,4.32,28.1A7.85,7.85,0,0,1,.588,21.279C.566,20.568.544,13.32.763,11.439Z"
            fill={`${brandingColor.iconOnBackground}80`}
          />
        </clipPath>
      </defs>
      <g clipPath="url(#hex-result-clip)">
        <path
          d="M.763,11.439A7.22,7.22,0,0,1,4.232,6.06C6.647,4.53,9.15,3.13,11.609,1.687a7.563,7.563,0,0,1,8.167.044c2.2,1.312,4.391,2.624,6.586,3.892A7.843,7.843,0,0,1,30.577,12.4c.088,3.367.044,6.778-.132,10.146a6.559,6.559,0,0,1-3.03,5.16c-2.766,1.793-5.62,3.5-8.518,5.117a6.706,6.706,0,0,1-6.718-.044C9.5,31.294,6.911,29.719,4.32,28.1A7.85,7.85,0,0,1,.588,21.279C.566,20.568.544,13.32.763,11.439Z"
          fill="none"
          stroke={brandingColor.iconOnBackground}
          strokeWidth={2}
        />
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          fill={brandingColor.iconOnBackground}
          opacity="0"
        />
        <rect
          x="0"
          y="0"
          width={fillRatio}
          height="100%"
          fill={brandingColor.iconOnBackground}
          opacity="0.5"
        />
      </g>
    </svg>
  )
}
