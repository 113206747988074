import React, { CSSProperties } from 'react'

export const GroupFilled = () => GroupFilledStyled({})

export const GroupFilledStyled = ({ style = {} }: { style?: CSSProperties }) => {
  const color = style?.color ?? '#fff'
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="39.685"
      height="26.218"
      viewBox="0 0 39.685 26.218"
    >
      <g
        id="Сгруппировать_3163"
        data-name="Сгруппировать 3163"
        transform="translate(-475.635 -567.088)"
      >
        <path
          id="Контур_7930"
          data-name="Контур 7930"
          d="M482.989,580.672a5.134,5.134,0,1,0-5.134-5.134,5.133,5.133,0,0,0,5.134,5.134"
          fill={color}
        />
        <path
          id="Контур_7931"
          data-name="Контур 7931"
          d="M487.272,582.668a9.441,9.441,0,0,0-4.283-.982h0c-4.062,0-7.354,2.349-7.354,5.247v6.338h10.29v-6.992a5.667,5.667,0,0,1,1.347-3.611"
          fill={color}
        />
        <path
          id="Контур_7932"
          data-name="Контур 7932"
          d="M507.966,580.672a5.134,5.134,0,1,0-5.135-5.134,5.133,5.133,0,0,0,5.135,5.134"
          fill={color}
        />
        <path
          id="Контур_7933"
          data-name="Контур 7933"
          d="M515.32,586.968c0-2.9-3.292-5.246-7.354-5.246a9.454,9.454,0,0,0-4.283.981,5.669,5.669,0,0,1,1.347,3.611v6.992h10.29Z"
          fill={color}
        />
        <path
          id="Контур_7934"
          data-name="Контур 7934"
          d="M500.951,572.562a5.473,5.473,0,1,0-5.474,5.473,5.479,5.479,0,0,0,5.474-5.473"
          fill={color}
        />
        <path
          id="Контур_7935"
          data-name="Контур 7935"
          d="M502.477,583.505a6.564,6.564,0,0,0-1.161-1.046,10.811,10.811,0,0,0-11.7,0,6.563,6.563,0,0,0-1.161,1.046,4.336,4.336,0,0,0-1.136,2.844v6.921h16.288v-6.921a4.334,4.334,0,0,0-1.135-2.844"
          fill={color}
        />
      </g>
    </svg>
  )
}
