/* eslint-disable react/display-name */

import HexagonsBackground from 'layouts/HexagonsBackground'
import Loader from 'modules/common/components/Loader'
import React, { Suspense } from 'react'

export const WaitingComponent = (Component: React.ComponentType) => {
  return (props: any) => (
    <Suspense
      fallback={
        <HexagonsBackground>
          <Loader isLoading={true} />
        </HexagonsBackground>
      }
    >
      <Component {...props} />
    </Suspense>
  )
}
