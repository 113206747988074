import styled from 'styled-components/macro'
import { ITheme } from 'styles/types'
import { getScaledValue } from 'utils/scale'

const SendButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${getScaledValue(15, 'px')};

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    margin-bottom: 15px;
  }

  @supports (-webkit-touch-callout: none) {
    margin-bottom: 100px;
  }
`
export default SendButtonWrapper
