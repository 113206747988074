import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const MoodBestSmall = ({ isActive }: { isActive: boolean }) => {
  const { icon } = useBrandingColors()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="74.84"
      height="81.564"
      viewBox="0 0 74.84 81.564"
    >
      <defs>
        <filter
          id="Контур_1139"
          x="0"
          y="0"
          width="74.84"
          height="81.564"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Сгруппировать_4601"
        data-name="Сгруппировать 4601"
        transform="translate(-286.5 -740.318)"
      >
        <g transform="matrix(1, 0, 0, 1, 286.5, 740.32)" filter="url(#Контур_1139)">
          <path
            id="Контур_1139-2"
            data-name="Контур 1139"
            d="M46.5,13.488l13.96,8.351A14.481,14.481,0,0,1,67.44,34.3v16.7A14.33,14.33,0,0,1,60.46,63.47L46.5,71.821a13.39,13.39,0,0,1-13.96,0L18.58,63.47A14.481,14.481,0,0,1,11.6,51.006V34.3a14.33,14.33,0,0,1,6.98-12.464l13.96-8.351A13.39,13.39,0,0,1,46.5,13.488Z"
            transform="translate(-2.1 -4.89)"
            fill={isActive ? '#fff' : 'transparent'}
            stroke="#fff"
            opacity={isActive ? '1' : '0.5'}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
        <g id="Сгруппировать_993" data-name="Сгруппировать 993" transform="translate(304 758.082)">
          <path
            id="Контур_1125"
            data-name="Контур 1125"
            d="M38.957,12.377a19.841,19.841,0,0,0-4.362-6.442,19.841,19.841,0,0,0-6.442-4.362A20.712,20.712,0,0,0,20.29,0a21.165,21.165,0,0,0-7.913,1.572A19.841,19.841,0,0,0,5.935,5.935a21.275,21.275,0,0,0-4.362,6.442,20.7,20.7,0,0,0,0,15.826,19.841,19.841,0,0,0,4.362,6.442,19.841,19.841,0,0,0,6.442,4.362,20.7,20.7,0,0,0,15.826,0,19.841,19.841,0,0,0,6.442-4.362A21.275,21.275,0,0,0,39.008,28.2,21.165,21.165,0,0,0,40.58,20.29,20.264,20.264,0,0,0,38.957,12.377ZM32.921,32.971A17.855,17.855,0,0,1,2.435,20.29,18.013,18.013,0,0,1,7.659,7.609,17.855,17.855,0,0,1,38.145,20.29,17.714,17.714,0,0,1,32.921,32.971Z"
            transform="translate(0 0)"
            fill={isActive ? icon : '#fff'}
            opacity={isActive ? '1' : '0.5'}
          />
          <path
            id="Контур_1126"
            data-name="Контур 1126"
            d="M30.369,51.895a11.537,11.537,0,0,1-7.953-3.467,1.182,1.182,0,0,0-1.58-.153h0a1.192,1.192,0,0,0-.153,1.784,13.111,13.111,0,0,0,5.659,3.569,13.5,13.5,0,0,0,4.079.714,13.059,13.059,0,0,0,7.545-2.447,11.64,11.64,0,0,0,2.141-1.835,1.2,1.2,0,0,0-.153-1.784h0a1.182,1.182,0,0,0-1.58.153A11.87,11.87,0,0,1,30.369,51.895Z"
            transform="translate(-10.079 -23.695)"
            fill={isActive ? icon : '#fff'}
            opacity={isActive ? '1' : '0.5'}
          />
          <circle
            id="Эллипс_68"
            data-name="Эллипс 68"
            cx="3.008"
            cy="3.008"
            r="3.008"
            transform="translate(11.099 12.569)"
            fill={isActive ? icon : '#fff'}
            opacity={isActive ? '1' : '0.5'}
          />
          <circle
            id="Эллипс_69"
            data-name="Эллипс 69"
            cx="3.008"
            cy="3.008"
            r="3.008"
            transform="translate(23.465 12.569)"
            fill={isActive ? icon : '#fff'}
            opacity={isActive ? '1' : '0.5'}
          />
        </g>
      </g>
    </svg>
  )
}
