import React from 'react'

export const EmailSvg = () => {
  return (
    <svg
      width="31"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="43 412 31 20"
    >
      <svg
        width="1"
        height="0"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        fill="#fff"
        x="58"
        y="431"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="1"
          height="0"
        >
          <path paintOrder="stroke fill markers" fillRule="evenodd" d="M.017.485H.003h.014z" />
        </svg>
      </svg>
      <svg
        width="30"
        height="10"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0.422 30 9.156"
        fill="#fff"
        x="43"
        y="422"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="30"
          height="10"
          viewBox="0.778 0.825 28.376 8.66"
        >
          <path
            paintOrder="stroke fill markers"
            fillRule="evenodd"
            d="M29.154 9.182l-.012-.211c-.178-.137-.354-.277-.535-.411-3.372-2.496-6.749-4.983-10.107-7.497-.405-.303-.67-.32-1.046.013-.825.726-1.741 1.222-2.911 1.064-.848-.114-1.472-.611-2.09-1.114-.356-.29-.597-.251-.95.014-2.718 2.033-5.453 4.04-8.18 6.059-.851.63-1.697 1.267-2.545 1.9l.017.19c.532.099 1.063.282 1.595.283 4.204.009 8.41.013 12.613.013h.014c4.205 0 8.41-.005 12.614-.014.508-.001 1.015-.188 1.523-.29z"
          />
        </svg>
      </svg>
      <svg
        width="23"
        height="1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0.452 23 0.096"
        fill="#fff"
        x="47"
        y="412"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="23"
          height="1"
          viewBox="0.54 0.515 21.738 0.091"
        >
          <path
            paintOrder="stroke fill markers"
            fillRule="evenodd"
            d="M.54.606h21.738C17.336.482 6.093.488.54.606z"
          />
        </svg>
      </svg>
      <svg
        width="28"
        height="11"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0.408 28 10.184"
        fill="#fff"
        x="44"
        y="412"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="28"
          height="11"
          viewBox="0.329 0.606 27.343 9.945"
        >
          <path
            paintOrder="stroke fill markers"
            fillRule="evenodd"
            d="M.801 1.148c4 2.964 8.001 5.926 12.003 8.887.937.693 1.42.689 2.376-.018 3.987-2.948 7.972-5.898 11.956-8.848.171-.127.331-.267.536-.434-.172-.053-1.05-.096-2.394-.13H3.54C1.698.646.481.696.329.76c.17.14.315.272.472.388z"
          />
        </svg>
      </svg>
      <svg
        width="12"
        height="17"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0.248 0 11.504 17"
        fill="#fff"
        x="62"
        y="413"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="12"
          height="17"
          viewBox="0.336 0.824 10.664 15.759"
        >
          <path
            paintOrder="stroke fill markers"
            fillRule="evenodd"
            d="M10.965.824L.336 8.693C3.893 11.323 7.39 13.91 11 16.583V1.149c0-.112-.012-.22-.035-.325z"
          />
        </svg>
      </svg>
      <svg
        width="11"
        height="17"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0.369 11 16.262"
        fill="#fff"
        x="43"
        y="413"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="17"
          viewBox="0 0.848 10.625 15.708"
        >
          <path
            paintOrder="stroke fill markers"
            fillRule="evenodd"
            d="M.03.848c-.019.098-.03.198-.03.3v15.408L10.625 8.69.03.848z"
          />
        </svg>
      </svg>
    </svg>
  )
}
