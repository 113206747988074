import { createAsyncThunk } from '@reduxjs/toolkit'
import { Error, UsersAndGroupSearch } from './types'
import { AxiosError } from 'axios'
import { MasterUserApi } from 'api/masterUser'
import { setOrgInfo } from './slice'
import { setMessage } from '../feedback/slice'
import { logout } from '../auth/thunks'
import { AppRoutes } from 'router/routesConfig'
import history from 'utils/history'
import { UserApi } from 'api/user'

export const checkOrganizations = createAsyncThunk<void, void, { rejectValue: Error }>(
  'user/checkOrganizations',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data: organizations } = await MasterUserApi.getUserOrganizations()

      if (organizations.length === 1) {
        dispatch(setOrgInfo(true))
      } else {
        dispatch(setOrgInfo(false))
      }
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)
export const switchOrganization = createAsyncThunk<void, void, { rejectValue: Error }>(
  'user/switchOrganization',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data: organizations } = await MasterUserApi.getUserOrganizations()

      if (organizations.length === 0) {
        dispatch(setMessage('Your organization is not active'))
        dispatch(logout())
      }

      history.push(AppRoutes.ORGANIZATIONS)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const checkPassword = createAsyncThunk<void, string, { rejectValue: Error }>(
  'user/checkPassword',
  async (password, { rejectWithValue }) => {
    try {
      await UserApi.checkPasswordRequest(password)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const checkUserIndividual = createAsyncThunk<boolean, void, { rejectValue: Error }>(
  'user/checkUserIndividual',
  async (_, { rejectWithValue }) => {
    try {
      return await UserApi.checkUserIndividualRequest()
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

const searchQuery = (page: number, text: string, id: string) => ({
  search: {
    fields: ['name'],
    value: text,
  },
  pager: {
    current: page,
    pageSize: 12,
  },
  sorter: [
    {
      field: 'name',
      order: 'asc',
    },
    {
      field: 'aboutOthersType',
      order: 'desc',
    },
  ],
  filters: [
    {
      operator: 'neq',
      field: 'id',
      value: id,
      logic: 'and',
    },
  ],
})

const searchQueryWithoutGroups = (page: number, text: string, id: string) => ({
  search: {
    fields: ['name'],
    value: text,
  },
  pager: {
    current: page,
    pageSize: 12,
  },
  sorter: [
    {
      field: 'name',
      order: 'asc',
    },
    {
      field: 'aboutOthersType',
      order: 'desc',
    },
  ],
  filters: [
    {
      operator: 'neq',
      field: 'id',
      value: id,
      logic: 'and',
    },
    {
      operator: 'neq',
      field: 'aboutOthersType',
      value: 1,
      logic: 'and',
    },
    {
      operator: 'neq',
      field: 'aboutOthersType',
      value: 2,
      logic: 'and',
    },
  ],
})

const searchQueryWithoutOrganizations = (page: number, text: string, id: string) => ({
  search: {
    fields: ['name'],
    value: text,
  },
  pager: {
    current: page,
    pageSize: 12,
  },
  sorter: [
    {
      field: 'name',
      order: 'asc',
    },
    {
      field: 'aboutOthersType',
      order: 'desc',
    },
  ],
  filters: [
    {
      operator: 'neq',
      field: 'id',
      value: id,
      logic: 'and',
    },
    {
      operator: 'neq',
      field: 'aboutOthersType',
      value: 2,
      logic: 'and',
    },
  ],
})

export const getUsersList = createAsyncThunk<
  UsersAndGroupSearch,
  {
    searchText: string
    userId: string
    page: number
    withoutGroups?: boolean
    withoutOrganizations?: boolean
    isSearch?: boolean
  },
  { rejectValue: Error }
>(
  'user/getUsersList',
  async (
    { searchText, userId, page, withoutGroups, withoutOrganizations, isSearch = false },
    { rejectWithValue }
  ) => {
    try {
      let query: any = searchQuery(page, searchText, userId)
      if (withoutGroups) {
        query = searchQueryWithoutGroups(page, searchText, userId)
      }

      if (withoutOrganizations) {
        query = searchQueryWithoutOrganizations(page, searchText, userId)
      }
      const data = await UserApi.searchUsersRequest(query)
      return { users: data.users, total: data.total, searchPage: page + 1, isSearch }
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)
