import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const CapIconTransparent = ({ isActive }: { isActive: boolean }) => {
  const { icon } = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="52.64"
      height="58.691"
      viewBox="0 0 52.64 58.691"
    >
      <defs>
        <clipPath id="clip-path-cap-tp">
          <path
            id="Контур_160"
            data-name="Контур 160"
            d="M44.5,13.376l13.16,7.872A13.651,13.651,0,0,1,64.24,33V48.743a13.509,13.509,0,0,1-6.58,11.75L44.5,68.365a12.623,12.623,0,0,1-13.16,0L18.18,60.493a13.651,13.651,0,0,1-6.58-11.75V33a13.509,13.509,0,0,1,6.58-11.75l13.16-7.872A12.623,12.623,0,0,1,44.5,13.376Z"
            transform="translate(-11.6 -11.525)"
          />
        </clipPath>
      </defs>
      <g id="Сгруппировать_2769" data-name="Сгруппировать 2769">
        <g
          id="Сгруппировать_53"
          data-name="Сгруппировать 53"
          transform="translate(0 0)"
          clipPath="url(#clip-path-cap-tp)"
        >
          <rect
            id="Прямоугольник_7"
            data-name="Прямоугольник 7"
            width="64.625"
            height="70.5"
            transform="translate(-5.992 -5.904)"
            fill="none"
          />
        </g>
        <path
          id="Контур_161"
          data-name="Контур 161"
          d="M44.5,13.376l13.16,7.872A13.651,13.651,0,0,1,64.24,33V48.743a13.509,13.509,0,0,1-6.58,11.75L44.5,68.365a12.623,12.623,0,0,1-13.16,0L18.18,60.493a13.651,13.651,0,0,1-6.58-11.75V33a13.509,13.509,0,0,1,6.58-11.75l13.16-7.872A12.623,12.623,0,0,1,44.5,13.376Z"
          transform="translate(-11.6 -11.525)"
          fill="#fff"
          opacity={isActive ? '1' : '0.5'}
        />
      </g>
      <g
        id="Сгруппировать_3139"
        data-name="Сгруппировать 3139"
        transform="translate(1003.91 -693.632)"
      >
        <g
          id="Сгруппировать_3129"
          data-name="Сгруппировать 3129"
          transform="translate(-997.953 710.162)"
        >
          <g id="Сгруппировать_4588" data-name="Сгруппировать 4588">
            <path
              id="Контур_7899"
              data-name="Контур 7899"
              d="M-957.559,716.755q-8.448-3.036-16.884-6.1a7.521,7.521,0,0,0-5.36.009c-3.26,1.2-6.532,2.373-9.8,3.555q-3.658,1.322-7.318,2.635a1.3,1.3,0,0,0-1.032,1.279,1.279,1.279,0,0,0,1.019,1.206c.13.044.258.094.388.14q2.906,1.053,5.812,2.1,5.536,2,11.069,4.009a7.332,7.332,0,0,0,5.2-.029q2.912-1.073,5.828-2.124c1.505-.544,3.012-1.084,4.568-1.64l.576-.209,5.577-2.012.341-.123c.687-.246,1.3-.544,1.3-1.343s-.595-1.1-1.289-1.35"
              transform="translate(997.953 -710.162)"
              fill={isActive ? icon : '#fff'}
            />
            <path
              id="Контур_7900"
              data-name="Контур 7900"
              d="M-970.452,722.642q-2.917,1.055-5.828,2.124a7.332,7.332,0,0,1-5.2.031q-5.532-2.011-11.069-4.009V727.9a3.907,3.907,0,0,0,4.014,3.791h19.215a3.909,3.909,0,0,0,4.016-3.791v-7.106l-.576.207c-1.556.557-3.062,1.1-4.568,1.64"
              transform="translate(999.772 -706.583)"
              fill={isActive ? icon : '#fff'}
            />
            <path
              id="Контур_7901"
              data-name="Контур 7901"
              d="M-966.441,727.568a2.688,2.688,0,0,0-1.576-2.58v-9.936h-1.3v9.938a2.685,2.685,0,0,0-1.575,2.579c0,.088,0,.175.011.261a1.358,1.358,0,0,0-.012.166v1.349a1.278,1.278,0,0,0,1.279,1.278h1.888a1.278,1.278,0,0,0,1.279-1.278v-1.349a1.275,1.275,0,0,0-.009-.144c.008-.092.013-.186.013-.282"
              transform="translate(1007.067 -708.515)"
              fill={isActive ? icon : '#fff'}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
