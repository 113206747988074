import { authInstanceOrg } from 'api/axios.config'
import { AttachInfoType } from 'store/types/upload'
import { InitUploadResponse, PreSignResponse } from './types/response'
import { PreSignRequest } from './types/request'
import axios from 'axios'

export class UploadApi {
  static initMultipartUploadRequest = async (attachInfo: AttachInfoType) => {
    const { data } = await authInstanceOrg.post<InitUploadResponse>(
      '/upload/initializeMultipartUpload',
      attachInfo
    )
    return data
  }

  static uploadFileRequest = async (file: any) => {
    const { data } = await authInstanceOrg.post<string>('/upload/uploadFile', file)
    return data
  }

  static getPreSignedUrl = async (requestData: PreSignRequest) => {
    const { data } = await authInstanceOrg.post<PreSignResponse>(
      '/upload/getPreSignedUrl',
      requestData
    )
    return data
  }

  static uploadFileToUrlRequest = async (url: string, file: any) => {
    const response = await axios.put(url, file, {
      headers: {
        'Content-Type': file.type,
      },
    })

    return response
  }
}
