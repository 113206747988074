import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { PostsApi } from 'api/posts'
import { GetPostsResponse } from 'api/posts/types/response'
import { SubTabsEnum } from '../../../api/posts/enums'

export const getPosts = createAsyncThunk<
  GetPostsResponse,
  { tab: SubTabsEnum; page?: number; query?: string },
  { rejectValue: { message: string } }
>('post/getAll', async ({ tab, page, query }, { rejectWithValue }) => {
  try {
    return await PostsApi.getPostsRequest({
      currentPage: page || 1,
      isActive: tab === SubTabsEnum.Active,
      text: query,
    })
  } catch (err) {
    let error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})
