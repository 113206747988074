import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const ResultLike = () => {
  const brandingColors = useBrandingColors()

  return (
    <svg width={32.06} height={53.198} viewBox="0 0 32.06 53.198">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0"
          x2="0"
          y1="0"
          y2="100%"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#09af43" />
          <stop offset="1" stopColor="#79d80c" />
        </linearGradient>
      </defs>
      <g transform="translate(1.108 1.125)">
        <path
          d="M14.2,79.806S24.9,64.766,18.887,43.3"
          transform="translate(-4.565 -28.833)"
          fill="none"
          stroke={brandingColors.iconOnBackground}
          strokeWidth="2.2"
        />
        <path
          d="M41.1,23.989s7.1,3.252,10.037-.459S48.168,13.033,41.1,23.989Z"
          transform="translate(-27.388 -12.49)"
          stroke={brandingColors.iconOnBackground}
          strokeWidth="2.2"
          fill="url(#linear-gradient)"
        />
        <path
          d="M38.51,13.37s6.715-3.994,5.337-8.518S33.456,1.318,38.51,13.37Z"
          transform="translate(-24.727 -2.366)"
          stroke={brandingColors.iconOnBackground}
          strokeWidth="2.2"
          fill="url(#linear-gradient)"
        />
        <path
          d="M13.328,26.642S6.577,30.53,3.29,27.1,5.3,16.358,13.328,26.642Z"
          transform="translate(-2.301 -14.649)"
          stroke={brandingColors.iconOnBackground}
          strokeWidth="2.2"
          fill="url(#linear-gradient)"
        />
        <path
          d="M22.558,13.5S15.277,10.705,15.843,6,25.456.774,22.558,13.5Z"
          transform="translate(-11.036 -2.742)"
          stroke={brandingColors.iconOnBackground}
          strokeWidth="2.2"
          fill="url(#linear-gradient)"
        />
        <path
          d="M45.2,96.592s3.852-8.21,10.391-7.579S62.871,99.836,45.2,96.592Z"
          transform="translate(-30.038 -59.987)"
          fill="none"
          stroke={brandingColors.iconOnBackground}
          strokeWidth="2.2"
        />
        <path
          d="M13.483,38.838s-7.351-2.615-9.967,1.308S7.439,50.395,13.483,38.838Z"
          transform="translate(-2.704 -25.395)"
          stroke={brandingColors.iconOnBackground}
          strokeWidth="2.2"
          fill="url(#linear-gradient)"
        />
        <path
          d="M24.974,43.8s-6.609,4.17-5.089,8.659S30.311,55.71,24.974,43.8Z"
          transform="translate(-13.523 -29.156)"
          stroke={brandingColors.iconOnBackground}
          strokeWidth="2.2"
          fill="url(#linear-gradient)"
        />
        <path
          d="M41,37.574s6.75-3.888,10.037-.459S49.023,47.859,41,37.574Z"
          transform="translate(-27.323 -23.92)"
          stroke={brandingColors.iconOnBackground}
          strokeWidth="2.2"
          fill="url(#linear-gradient)"
        />
        <path
          d="M38.575,44.5s7.281,2.792,6.715,7.493S35.712,57.223,38.575,44.5Z"
          transform="translate(-25.393 -29.609)"
          stroke={brandingColors.iconOnBackground}
          strokeWidth="2.2"
          fill="url(#linear-gradient)"
        />
        <ellipse
          cx="4.691"
          cy="4.691"
          rx="4.691"
          ry="4.691"
          transform="translate(7.643 7.938)"
          stroke={brandingColors.iconOnBackground}
          strokeWidth="2.2"
          fill="url(#linear-gradient)"
        />
      </g>
    </svg>
  )
}
