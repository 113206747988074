import React from 'react'

export const ResultStarsSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="93.284"
      height="96.132"
      viewBox="0 0 93.284 96.132"
    >
      <g
        id="Сгруппировать_2713"
        data-name="Сгруппировать 2713"
        transform="translate(-3.358 -1.934)"
      >
        <path
          id="Контур_7741"
          data-name="Контур 7741"
          d="M8.975,22.843l9.045,4.394a2.274,2.274,0,0,1,1.253,1.7l1.507,9.941a1.777,1.777,0,0,0,3.039.967l6.974-7.243A2.277,2.277,0,0,1,32.8,31.94l9.921,1.638a1.778,1.778,0,0,0,1.858-2.591l-4.734-8.871A2.273,2.273,0,0,1,39.833,20l4.624-8.929A1.778,1.778,0,0,0,42.567,8.5l-9.9,1.761a2.277,2.277,0,0,1-2.017-.642L23.589,2.467a1.741,1.741,0,0,0-1.251-.533,1.841,1.841,0,0,0-.575.094,1.747,1.747,0,0,0-1.2,1.443l-1.384,9.958a2.272,2.272,0,0,1-1.233,1.72L8.956,19.655a1.778,1.778,0,0,0,.019,3.188Z"
          fill="#fff"
        />
        <path
          id="Контур_7742"
          data-name="Контур 7742"
          d="M69.961,33.066a1.271,1.271,0,0,0,1.505,1.714l7.29-1.954a1.772,1.772,0,0,1,1.606.362l5.742,4.9a1.274,1.274,0,0,0,2.1-.9l.394-7.536a1.764,1.764,0,0,1,.841-1.415l6.432-3.948a1.273,1.273,0,0,0-.21-2.273l-7.046-2.7a1.766,1.766,0,0,1-1.086-1.237l-1.768-7.337a1.273,1.273,0,0,0-2.227-.5L78.784,16.1a1.748,1.748,0,0,1-1.512.65l-7.524-.586a1.273,1.273,0,0,0-1.166,1.963l4.111,6.329a1.766,1.766,0,0,1,.151,1.639Z"
          fill="#fff"
        />
        <path
          id="Контур_7743"
          data-name="Контур 7743"
          d="M6.615,83.181a1.4,1.4,0,0,1,.119,1.3L4.543,89.761A.9.9,0,0,0,5.611,90.98l5.525-1.474a1.43,1.43,0,0,1,.361-.046,1.4,1.4,0,0,1,.91.336l4.346,3.715a.9.9,0,0,0,1.491-.639l.305-5.71a1.4,1.4,0,0,1,.668-1.119l4.877-2.985a.9.9,0,0,0-.147-1.615l-5.336-2.055a1.4,1.4,0,0,1-.859-.981L16.42,72.846a.9.9,0,0,0-1.581-.358l-3.6,4.439a1.392,1.392,0,0,1-1.2.513l-5.7-.451a.9.9,0,0,0-.831,1.393Z"
          fill="#fff"
        />
        <path
          id="Контур_7744"
          data-name="Контур 7744"
          d="M13.536,57.051a3.375,3.375,0,1,0,3.375-3.375A3.379,3.379,0,0,0,13.536,57.051Z"
          fill="#fff"
        />
        <path
          id="Контур_7745"
          data-name="Контур 7745"
          d="M54.649,33.737A3.533,3.533,0,1,0,58.182,30.2,3.537,3.537,0,0,0,54.649,33.737Z"
          fill="#fff"
        />
        <path
          id="Контур_7746"
          data-name="Контур 7746"
          d="M33.467,89.017c-1.954-1.192-3.554-1.256-4.627-.183a3.289,3.289,0,0,0-.853,1.811c-.019.11-.033.218-.045.323a3.866,3.866,0,0,0,3.466,4.2A44.742,44.742,0,0,1,39.9,97.6c.421.151.807.29,1.147.409a.86.86,0,0,0,.994-.305.85.85,0,0,0,.014-1.028A34.06,34.06,0,0,0,33.467,89.017Z"
          fill="#fff"
        />
        <path
          id="Контур_7747"
          data-name="Контур 7747"
          d="M52.455,98.044a.931.931,0,0,0,.676-1.079A51.076,51.076,0,0,0,38.172,68.623a34.079,34.079,0,0,1-2.6-2.868,4.9,4.9,0,0,0-3.79-1.774,5.119,5.119,0,0,0-.934.085,4.8,4.8,0,0,0-3.94,5.478,4.99,4.99,0,0,0,3.648,4.008A18.234,18.234,0,0,1,34.6,75.445C45.717,82.631,50.28,94.116,51.414,97.464A.9.9,0,0,0,52.455,98.044Z"
          fill="#fff"
        />
        <path
          id="Контур_7748"
          data-name="Контур 7748"
          d="M63.789,98.066a.907.907,0,0,0,.682-.311.894.894,0,0,0,.213-.708C61.424,73.295,50.06,53.9,46.655,48.524c-.266-.419-.5-.857-.737-1.28l-.234-.426a4.749,4.749,0,0,0-4.163-2.448,5.026,5.026,0,0,0-.572.033,4.755,4.755,0,0,0-2.474,8.366c16.67,17.147,23.287,40.182,24.443,44.626A.9.9,0,0,0,63.789,98.066Z"
          fill="#fff"
        />
        <path
          id="Контур_7749"
          data-name="Контур 7749"
          d="M65.813,73.588a1.055,1.055,0,0,0,.659-1.254C65.6,68.9,62.833,57.092,63.8,49.047a3.282,3.282,0,0,0,.094-1.412,3.322,3.322,0,0,0-3.23-2.771q-.058,0-.117,0a3.248,3.248,0,0,0-3.047,4c.491,3.9,2.437,17.082,7.057,24.3A1.053,1.053,0,0,0,65.813,73.588Z"
          fill="#fff"
        />
        <path
          id="Контур_7750"
          data-name="Контур 7750"
          d="M83,48.5a4.165,4.165,0,0,0,.438-2.006,4.269,4.269,0,0,0-4.147-4.085h-.073a4.221,4.221,0,0,0-4.222,4.222c-.039.333-3.679,30.493.476,50.847a.737.737,0,0,0,.723.583.733.733,0,0,0,.733-.8,128.212,128.212,0,0,1,6.059-48.733Z"
          fill="#fff"
        />
        <path
          id="Контур_7751"
          data-name="Контур 7751"
          d="M95.19,79.887a3.945,3.945,0,1,0-6.019-4.824,51.906,51.906,0,0,0-4.484,22.046.97.97,0,0,0,.961.956.954.954,0,0,0,.945-.794C87.15,93.965,89.1,85.652,95.19,79.887Z"
          fill="#fff"
        />
      </g>
    </svg>
  )
}
