import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const AnonymousSvg = () => {
  const { iconOnBackground } = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.777"
      height="28.279"
      viewBox="0 0 40.777 28.279"
    >
      <g id="Сгруппировать_1441" data-name="Сгруппировать 1441" transform="translate(-355 3385)">
        <g id="Сгруппировать_1436" data-name="Сгруппировать 1436" transform="translate(355 -3385)">
          <g
            id="Сгруппировать_1430"
            data-name="Сгруппировать 1430"
            transform="translate(42.777 28.279)"
          >
            <path
              id="Контур_1736"
              data-name="Контур 1736"
              d="M50,58.754"
              transform="translate(-50 -58.754)"
              fill={iconOnBackground}
            />
          </g>
          <g
            id="Сгруппировать_1431"
            data-name="Сгруппировать 1431"
            transform="translate(42.777 28.279)"
          >
            <path
              id="Контур_1737"
              data-name="Контур 1737"
              d="M50,58.754"
              transform="translate(-50 -58.754)"
              fill={iconOnBackground}
            />
          </g>
          <path
            id="Контур_1738"
            data-name="Контур 1738"
            d="M53.714,59.275a.566.566,0,0,0-.708.21l-.55.97c-.918-.918-2.571-1.075-4.092-1.075a11.613,11.613,0,0,0-2.36.21h-.026a8.484,8.484,0,0,0-6.741-.026,10.517,10.517,0,0,0-2.23-.184c-1.521,0-3.174.158-4.092,1.075l-.55-.97a.567.567,0,0,0-.709-.21.441.441,0,0,0-.236.63l.892,1.573a3.072,3.072,0,0,0-.105.735v.1a4.542,4.542,0,0,0,4.8,4.249,4.559,4.559,0,0,0,4.8-4.249,2.454,2.454,0,0,0-1.181-2.256,7.294,7.294,0,0,1,4.039.027,2.456,2.456,0,0,0-1.128,2.229,4.542,4.542,0,0,0,4.8,4.249,4.559,4.559,0,0,0,4.8-4.249v-.1a3.072,3.072,0,0,0-.105-.735l.892-1.573A.416.416,0,0,0,53.714,59.275Z"
            transform="translate(-22.72 -39.754)"
            fill={iconOnBackground}
          />
          <g id="Сгруппировать_1432" data-name="Сгруппировать 1432" transform="translate(0 0)">
            <path
              id="Контур_1739"
              data-name="Контур 1739"
              d="M34.472,38.072c.081.027.135.027.217.054a39.018,39.018,0,0,0,18.887,0c.054-.027.135-.027.217-.054.054-.027.135-.027.189-.055a.347.347,0,0,1,.163-.027V33a4.866,4.866,0,0,0-6.105-4.315l-2.144.57a6.749,6.749,0,0,1-3.446,0l-2.144-.57a4.861,4.861,0,0,0-4.206.841,4.781,4.781,0,0,0-1.9,3.853v2.714h0a.343.343,0,0,0-.027.162v1.764c.054,0,.109.028.19.028A.274.274,0,0,1,34.472,38.072Z"
              transform="translate(-24.32 -28.515)"
              fill={iconOnBackground}
            />
            <path
              id="Контур_1740"
              data-name="Контур 1740"
              d="M57.816,48.49a.571.571,0,0,1-.4.536,73.585,73.585,0,0,1-19.57,2.654,73.288,73.288,0,0,1-19.57-2.654.525.525,0,0,1-.4-.536.517.517,0,0,1,.4-.51l8.847-2.2.4-.108a.458.458,0,0,1,.268,0,2.3,2.3,0,0,1,.4.108,39.966,39.966,0,0,0,19.329,0,2.191,2.191,0,0,0,.4-.108.458.458,0,0,1,.268,0l.4.108,8.847,2.171A.583.583,0,0,1,57.816,48.49Z"
              transform="translate(-17.872 -34.817)"
              fill={iconOnBackground}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
