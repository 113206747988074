import { mapMedia } from './TimeLineMapper'
import { mapMentionType } from './TimelimeMentionMapper'
import { mapGroupType } from './UsersSearchMapper'
import { TimelineItemType } from 'store/types'
import { GroupMentionResponse, TimelineMentionResponse } from 'api/timeline/types/response'

const COMPLIMENT_TYPE = 1
const COMPLAINT_TYPE = 0
const TIP_TYPE = 2

const mapMentions = (
  mentions: TimelineMentionResponse[],
  compressedMention: GroupMentionResponse,
  type: number
) => {
  if (mentions.length > 0) {
    let messagesAmount = 0
    mentions.forEach((ment) => {
      messagesAmount += ment.messages
    })
    return {
      type: TimelineItemType.Compressed,
      id: `${compressedMention.groupId}${type}`,
      data: {
        mentionsAmount: mentions.length,
        name: compressedMention.name,
        messagesAmount,
        id: mentions[0].id,
        logo: compressedMention.logo,
        groupType: mapGroupType(compressedMention.groupType),
        mentions: mentions.map((item) => ({
          id: item.id,
          withinGroup: item.recipient !== null,
          media: mapMedia(item.attachment),
          text: item.text,
          messages: item.messages,
          mentionType: mapMentionType(item.mentionType),
          isHandler: item.isHandler,
          isAnon: item.isAnonymous,
          recipient: item.recipient
            ? item.recipient
            : {
                id: compressedMention.groupId,
                logo: compressedMention.logo,
                fullName: compressedMention.name,
                position: null,
                phoneNumber: '',
              },
          sender: item.sender,
        })),
        type: mapMentionType(mentions[0].mentionType),
      },
    }
  }
  return null
}

export const compressedMentionMapper = (compressedMention: GroupMentionResponse) => {
  const compliments = compressedMention.mentions.filter(
    (mention) => mention.mentionType === COMPLIMENT_TYPE
  )
  const complaints = compressedMention.mentions.filter(
    (mention) => mention.mentionType === COMPLAINT_TYPE
  )
  const tips = compressedMention.mentions.filter((mention) => mention.mentionType === TIP_TYPE)

  const compressedMentions = [] as any

  const comressedCompliments = mapMentions(compliments, compressedMention, COMPLIMENT_TYPE)
  const comressedComplaints = mapMentions(complaints, compressedMention, COMPLAINT_TYPE)
  const comressedTips = mapMentions(tips, compressedMention, TIP_TYPE)
  if (comressedCompliments) {
    compressedMentions.push(comressedCompliments)
  }
  if (comressedTips) {
    compressedMentions.push(comressedTips)
  }
  if (comressedComplaints) {
    compressedMentions.push(comressedComplaints)
  }
  return compressedMentions
}
