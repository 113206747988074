import React from 'react'

export const ArrowSelect = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="7.5" viewBox="0 0 15 7.5">
      <path
        id="Контур_10272"
        data-name="Контур 10272"
        d="M7,10l7.5,7.5L22,10Z"
        transform="translate(-7 -10)"
        fill={'white'}
      />
    </svg>
  )
}
