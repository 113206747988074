// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import nl from './locales/nl'
import en from './locales/en'
import es from './locales/es'

const DETECTION_OPTIONS = {
  order: ['localStorage'],
  lookupLocalStorage: 'user_language',
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: DETECTION_OPTIONS,
    resources: {
      ['en-US']: { translation: en },
      ['nl-NL']: { translation: nl },
      ['es-ES']: { translation: es },
    },
    fallbackLng: 'en-US',
    ns: 'translation',
    interpolation: {
      formatSeparator: '.',
    },
    react: {
      wait: true,
    },
  })

export default i18n
