import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const PrivacyLogoSvg = ({ onBackground }: { onBackground?: boolean }) => {
  const { backgroundStart, iconOnBackground } = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <g
        id="Сгруппировать_2543"
        data-name="Сгруппировать 2543"
        transform="translate(-147.174 -18.663)"
      >
        <circle
          id="Ellipse_13-2"
          data-name="Ellipse 13-2"
          cx="14"
          cy="14"
          r="14"
          transform="translate(147.174 18.663)"
          fill={onBackground ? iconOnBackground : backgroundStart}
          opacity={onBackground ? 0.3 : 1}
        />
      </g>
      <g id="Сгруппировать_3093" data-name="Сгруппировать 3093" transform="translate(-7085 -2119)">
        <g
          id="Сгруппировать_3090"
          data-name="Сгруппировать 3090"
          transform="translate(7087.997 2125.927)"
        >
          <g id="Сгруппировать_3089" data-name="Сгруппировать 3089" transform="translate(0 0)">
            <g id="Сгруппировать_3088" data-name="Сгруппировать 3088">
              <g id="Сгруппировать_4576" data-name="Сгруппировать 4576">
                <path
                  id="Контур_7861"
                  data-name="Контур 7861"
                  d="M448.65,285.461c-3.2-4.684-8.271-7.36-13.4-6.007-4.654,1.253-7.658,5.526-7.814,5.76-.976,1.452-1.019,1.593.14,3.321a12.84,12.84,0,0,0,5.754,4.73,11.575,11.575,0,0,0,1.861.624,11.212,11.212,0,0,0,5.315-.023,7.719,7.719,0,0,0,2.207-.751c.127-.067.264-.136.4-.21a15,15,0,0,0,5.546-5.044c.721-1.093.724-1.317-.015-2.4m-10.694,6.688a5.5,5.5,0,1,1,5.5-5.5,5.5,5.5,0,0,1-5.5,5.5"
                  transform="translate(-426.71 -279.103)"
                  fill="#fff"
                />
                <path
                  id="Контур_7862"
                  data-name="Контур 7862"
                  d="M435.81,282.729a2.7,2.7,0,1,0,2.7,2.7,2.7,2.7,0,0,0-2.7-2.7"
                  transform="translate(-424.563 -277.886)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
