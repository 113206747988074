export const getLanguageCodeById = (id: number): string => {
  const languages = {
    1: 'en-US',
    2: 'nl-NL',
    3: 'es-ES',
  }

  return languages[id]
}

export const getLanguageCodeByName = (name: string): string => {
  const languages = {
    English: 'en-US',
    Dutch: 'nl-NL',
    Spanish: 'es-ES',
  }

  return languages[name]
}
