import { DropDownResponse } from './../types'
import { DropDownModel } from 'store/types'

export const DropDownMapper = (data: DropDownResponse[] | null): DropDownModel[] | null => {
  return (
    data &&
    data.map((x) => {
      return { id: x.key, name: x.value }
    })
  )
}
