import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const PlaneSvg = ({ onBackground = false }) => {
  const { icon, iconOnBackground } = useBrandingColors()

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="18" viewBox="0 0 21 18">
      <path
        id="Контур_195"
        data-name="Контур 195"
        d="M2.01,21,23,12,2.01,3,2,10l15,2L2,14Z"
        transform="translate(-2 -3)"
        fill={onBackground ? iconOnBackground : icon}
      />
    </svg>
  )
}
