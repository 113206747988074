import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const CapSvg = () => {
  const { icon, frame } = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="68.818"
      height="74.229"
      viewBox="0 0 68.818 74.229"
    >
      <defs>
        <filter
          id="Контур_7131"
          x="0"
          y="0"
          width="68.818"
          height="74.229"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Сгруппировать_4594"
        data-name="Сгруппировать 4594"
        transform="translate(-198.895 -95.9)"
      >
        <g transform="matrix(1, 0, 0, 1, 198.9, 95.9)" filter="url(#Контур_7131)">
          <path
            id="Контур_7131-2"
            data-name="Контур 7131"
            d="M0,27.891c.148-3.172.074-6.271.443-9.444A11.986,11.986,0,0,1,6.271,9.373c4.058-2.582,8.263-4.943,12.395-7.378,4.574-2.73,9.149-2.582,13.723.074C36.078,4.282,39.767,6.5,43.456,8.635c4.353,2.582,6.935,6.345,7.083,11.436.148,5.681.074,11.436-.221,17.117a11.073,11.073,0,0,1-5.091,8.706c-4.648,3.025-9.444,5.9-14.313,8.632a11.229,11.229,0,0,1-11.288-.074c-4.5-2.508-8.853-5.165-13.206-7.894C2.213,43.9.3,39.917.148,35.048c-.074-2.361,0-4.722,0-7.157Z"
            transform="translate(9.1 6.09)"
            fill="#fff"
            stroke={frame}
            strokeWidth="0.2"
          />
        </g>
        <g
          id="Сгруппировать_3120"
          data-name="Сгруппировать 3120"
          transform="translate(1409.852 -624.41)"
        >
          <g
            id="Сгруппировать_3119"
            data-name="Сгруппировать 3119"
            transform="translate(-1195.381 742.621)"
          >
            <g id="Сгруппировать_4593" data-name="Сгруппировать 4593">
              <path
                id="Контур_7889"
                data-name="Контур 7889"
                d="M-1159.332,748.566q-7.538-2.738-15.067-5.5a6.651,6.651,0,0,0-4.784.007c-2.911,1.082-5.829,2.141-8.745,3.206q-3.264,1.19-6.531,2.375a1.172,1.172,0,0,0-.921,1.154,1.151,1.151,0,0,0,.909,1.087c.116.039.231.085.347.127q2.592.948,5.187,1.894,4.94,1.8,9.879,3.614a6.479,6.479,0,0,0,4.644-.027q2.6-.966,5.2-1.914c1.343-.49,2.688-.977,4.076-1.479l.513-.187,4.977-1.815.3-.11c.613-.222,1.161-.49,1.161-1.211s-.531-.993-1.151-1.218"
                transform="translate(1195.381 -742.621)"
                fill={icon}
              />
              <path
                id="Контур_7890"
                data-name="Контур 7890"
                d="M-1170.932,753.8q-2.59.95-5.173,1.916a6.417,6.417,0,0,1-4.619.026q-4.911-1.813-9.825-3.614v6.414a3.493,3.493,0,0,0,3.563,3.419h17.055a3.493,3.493,0,0,0,3.563-3.419v-6.407l-.51.187c-1.38.5-2.719.989-4.054,1.479"
                transform="translate(1197.059 -739.321)"
                fill={icon}
              />
              <path
                id="Контур_7891"
                data-name="Контур 7891"
                d="M-1167.155,758.282a2.423,2.423,0,0,0-1.421-2.327V747h-1.169v8.96a2.424,2.424,0,0,0-1.421,2.327c0,.079,0,.158.011.234a1.142,1.142,0,0,0-.011.15v1.216a1.154,1.154,0,0,0,1.153,1.153h1.7a1.154,1.154,0,0,0,1.153-1.153v-1.216c0-.043,0-.088-.008-.129.007-.083.011-.168.011-.255"
                transform="translate(1203.403 -741.102)"
                fill={icon}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
