import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const UploadSvg = () => {
  const { icon } = useBrandingColors()

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="72.3" height="79.996" viewBox="0 0 72.3 79.996">
      <g id="Сгруппировать_4598" data-name="Сгруппировать 4598" transform="translate(-125 -486)">
        <path
          id="Контур_7708"
          data-name="Контур 7708"
          d="M0,39.838c.211-4.532.105-8.958.632-13.49A17.122,17.122,0,0,1,8.958,13.384c5.8-3.689,11.8-7.061,17.706-10.539,6.534-3.9,13.069-3.689,19.6.105,5.27,3.162,10.539,6.324,15.809,9.38,6.218,3.689,9.907,9.064,10.118,16.336.211,8.115.105,16.336-.316,24.451a15.818,15.818,0,0,1-7.272,12.436c-6.64,4.321-13.49,8.431-20.446,12.331-5.27,2.951-10.856,2.74-16.125-.105C21.606,74.2,15.387,70.4,9.169,66.5,3.162,62.708.422,57.017.211,50.061c-.105-3.373,0-6.745,0-10.223Z"
          transform="translate(125 485.988)"
          opacity="0.2"
          fill="#fff"
        />
        <g
          id="Сгруппировать_3114"
          data-name="Сгруппировать 3114"
          transform="translate(138.332 508.665)"
        >
          <path
            id="Контур_7885"
            data-name="Контур 7885"
            d="M141.921,447.361h-7.356v-.009a4.5,4.5,0,0,0-4.5-4.5h-13.3a4.5,4.5,0,0,0-4.5,4.5v.009h-7.356a4.172,4.172,0,0,0-4.172,4.172v21.083a4.172,4.172,0,0,0,4.172,4.172h37.009a4.172,4.172,0,0,0,4.172-4.172V451.533a4.172,4.172,0,0,0-4.172-4.172m-18.5,24.112a9.449,9.449,0,1,1,9.449-9.449,9.449,9.449,0,0,1-9.449,9.449M138.281,454.7a1.774,1.774,0,1,1,1.775-1.775,1.775,1.775,0,0,1-1.775,1.775"
            transform="translate(-100.74 -442.855)"
            fill={icon}
          />
        </g>
      </g>
    </svg>
  )
}
