/*eslint-disable react/display-name*/
import React, { forwardRef } from 'react'
import styled from 'styled-components/macro'
import { PlayButtonHex } from 'assets/svg'
import { MdPause, MdReplay } from 'react-icons/md'

interface IProps {
  playing: boolean
  isEnded: boolean
  onReplay: () => void
  onTogglePlay: () => void
}

const CONTROL_ICONS_SIZE = '28px'

const Controls = forwardRef<HTMLDivElement, IProps>(
  ({ playing, isEnded, onTogglePlay, onReplay }, ref) => {
    return (
      <ControlsWrapper ref={ref}>
        <VideoOverlay>
          {!isEnded && (
            <CenterControls>
              {playing && !isEnded ? (
                <IconButton onClick={onTogglePlay}>
                  <MdPause style={{ fontSize: CONTROL_ICONS_SIZE }} />
                </IconButton>
              ) : !playing && !isEnded ? (
                <IconButton onClick={onTogglePlay}>
                  <PlayButtonHex />
                </IconButton>
              ) : isEnded ? (
                <IconButton onClick={onReplay}>
                  <MdReplay style={{ fontSize: CONTROL_ICONS_SIZE }} />
                </IconButton>
              ) : null}
            </CenterControls>
          )}
        </VideoOverlay>
      </ControlsWrapper>
    )
  }
)

export default Controls

const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  cursor: pointer;
`

const VideoOverlay = styled.div`
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
`

const CenterControls = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3 ease-in;
`

const IconButton = styled.div<{ isSubtitlesShown?: boolean }>`
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #fff;
  transition: transform 0.2s ease-in;
  &:hover {
    transform: scale(1.2);
  }
`
