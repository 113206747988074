export enum LOCAL_STORAGE_KEYS {
  TOKEN = 'token',
  REFRESH_TOKEN = 'refresh_token',
  AUTH_DATA = 'authData',
  USER = 'persist:user',
  PASSWORD = 'password',
  BRANDING_COLORS = 'brandingColors',
  MASTER_BRANDING_COLORS = 'masterBrandingColors',
  MASTER_SETTINGS = 'masterSettings',
  ORG_URL = 'orgUrl',
  EXPIRE = 'tokenExpire',
  ORGANIZATION_ID = 'organizationId',
  LANGUAGE_CODE = 'languageCode',
  SHARED_DEVICE = 'sharedDevice',
  MASTER_ORGANIZATION = 'masterOrganization',
  ORGANIZATION_MENTIONS = 'organizationMentions',
  USER_LANGUAGE = 'user_language',
}

export enum SESSION_STORAGE_KEYS {
  USER_EMAIL = 'user_email',
  CURRENT_LANGUAGE = 'current_language',
  AUTH_DATA = 'auth_data',
}
