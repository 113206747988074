import React from 'react'
import IdleTimer from 'react-idle-timer'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import { logout } from 'store/slices/auth/thunks'
import { selectIsSharedDevice } from 'store/slices/UI/selectors'

const IdleTimerComponent = () => {
  const dispatch = useAppDispatch()
  const isSharedDevice = useSelector(selectIsSharedDevice)

  const onIdle = () => {
    if (isSharedDevice) {
      dispatch(logout())
    }
  }

  return <IdleTimer element={document} onIdle={onIdle} debounce={250} timeout={60000} />
}

export default IdleTimerComponent
