import React from 'react'

export const ButtonWithoutBackground = ({
  opacity = 1,
  numberOfLines = 1,
  id = '',
}: {
  opacity?: number
  numberOfLines?: number
  id?: string
}) => {
  const itemHeight = numberOfLines * 50

  return (
    <svg width="100%" height={itemHeight} viewBox="0 0 311.2 44.229" preserveAspectRatio="none">
      <defs>
        <clipPath id={`shape-hex-progress-${id}`}>
          <path d="M294.217.577L17.017.5a9.354 9.354 0 0 0-8.153 4.735l-7 12.126a9.4 9.4 0 0 0 0 9.431l7 12.126a9.447 9.447 0 0 0 8.153 4.735l277.162.076a9.354 9.354 0 0 0 8.153-4.735l7-12.164a9.4 9.4 0 0 0 0-9.431l-7-12.164a9.347 9.347 0 0 0-8.115-4.658z" />
        </clipPath>
      </defs>
      <defs>
        <linearGradient id={`grad-hex-progress-${id}`} x1="0" y1="0" x2="1" y2="0">
          <stop offset="0" stopColor="#fff" stopOpacity={opacity} />
          <stop offset="1" stopColor="#fff" stopOpacity={opacity} />
        </linearGradient>
      </defs>
      <g clipPath={`url(#shape-hex-progress-${id})`}>
        <rect x="0" y="0" width="100%" height="100%" fill={`url(#grad-hex-progress-${id})`} />
        <path
          d="M294.217.577L17.017.5a9.354 9.354 0 0 0-8.153 4.735l-7 12.126a9.4 9.4 0 0 0 0 9.431l7 12.126a9.447 9.447 0 0 0 8.153 4.735l277.162.076a9.354 9.354 0 0 0 8.153-4.735l7-12.164a9.4 9.4 0 0 0 0-9.431l-7-12.164a9.347 9.347 0 0 0-8.115-4.658z"
          fill="#fff"
          opacity={opacity}
        />
      </g>
    </svg>
  )
}
