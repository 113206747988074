import { useTranslation } from 'react-i18next'
import { LOCAL_STORAGE_KEYS } from 'modules/common/constants/storageKeys'
import { useAppDispatch } from 'store'
import { MediaType } from 'store/types'
import { useUploadWorker } from 'hooks/useUploadWorker'
import { base64ToBlob, jsonToFormData } from 'modules/common/helpers'
import { initMultipartUpload, uploadFile } from 'store/slices/upload/thunks'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'store/slices/user/selectors'
import { setMentionMedia } from 'store/slices/mentions/slice'
import { useState } from 'react'
import { setIsMediaLoading } from 'store/slices/upload/slice'
import { getFromLocalStorage } from 'utils/storage'
import { setMessage } from 'store/slices/feedback/slice'

const IMAGE_MAX_SIZE = 1024 * 1024 * 4
const VIDEO_MAX_SIZE = 1024 * 1024 * 100

export const useUploadMedia = (onChange?: (data: { source: string; type: number }) => void) => {
  const orgUrl = getFromLocalStorage(LOCAL_STORAGE_KEYS.ORG_URL)
  const {
    accessToken: { token },
  } = useSelector(selectCurrentUser)

  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const uploadWorker = useUploadWorker()
  const [inputKey, setInputKey] = useState(0)

  uploadWorker.onmessage = ({ data }) => {
    dispatch(setIsMediaLoading(false))
    if (data.uploadSuccess) {
      const mentionMediaData = { source: data.fileUrl, type: MediaType.VIDEO_FROM_DEVICE }
      dispatch(setMentionMedia(mentionMediaData))
      onChange && onChange(mentionMediaData)
    }
  }

  const beforeUpload = (file: File) => {
    const type = file.type.slice(0, file.type.indexOf('/'))

    if (type === 'image') {
      if (file.size < IMAGE_MAX_SIZE) {
        // eslint-disable-next-line no-undef
        const reader = new FileReader()

        reader.onloadend = () => {
          const data = jsonToFormData({
            file: base64ToBlob(reader.result),
            filename: file.name,
          })

          dispatch(uploadFile(data)).then((result) => {
            setInputKey(Date.now())
            onChange && onChange(result?.payload as { source: string; type: number })
          })
        }
        reader.readAsDataURL(file)
      } else {
        dispatch(setMessage(t('ADD_MENTION.MAX_IMAGE_SIZE')))
      }
    }

    if (type === 'video') {
      if (file.size < VIDEO_MAX_SIZE) {
        dispatch(setIsMediaLoading(true))
        const attachInfo = {
          fileName: file.name,
          size: file.size,
          bucketType: 4,
          contentType: 'application/octet-stream',
          file,
        }

        dispatch(initMultipartUpload(attachInfo)).then(({ payload }: { payload: any }) => {
          uploadWorker.uploadMultipartFile({
            file,
            partCount: payload.partCount,
            bucketType: 4,
            fileKey: payload.fileKey,
            partUrls: payload.partUrls,
            uploadId: payload.uploadId,
            fileUrl: payload.fileUrl,
            completeUploadUrl: `${orgUrl}upload/completeMultipartUpload`,
            token,
          })
        })
      } else {
        dispatch(setMessage(t('ADD_MENTION.MAX_VIDEO_SIZE')))
      }
    }

    return false
  }

  return { beforeUpload, inputKey }
}
