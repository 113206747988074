import React from 'react'
import { VisibleSvg, NotVisibleSvg, AnonymousSvg } from 'assets/svg'
import { getScaledValue } from 'utils/scale'
import { TextAvenirNextRegular } from 'modules/common/components/Typography'
import { useBrandingColors } from 'hooks/useBrandingColors'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { ITheme } from 'styles/types'

export const PrivacyHeader = ({ visible = true }: { visible?: boolean }) => {
  const brandingColors = useBrandingColors()
  const { t } = useTranslation()

  return (
    <HeaderContainer textColor={brandingColors.textOnBackground} visible={visible}>
      <Icon>
        <IconWrapper>
          <VisibleSvg />
        </IconWrapper>
        <Text>{t('PRIVACY.VISIBLE')}</Text>
      </Icon>
      <Icon>
        <IconWrapper>
          <AnonymousSvg />
        </IconWrapper>
        <Text>{t('PRIVACY.ANONYMOUS')}</Text>
      </Icon>
      {!visible && (
        <Icon>
          <IconWrapper>
            <NotVisibleSvg />
          </IconWrapper>
          <Text>{t('PRIVACY.NOT_VISIBLE')}</Text>
        </Icon>
      )}
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div<{ textColor: string; visible?: boolean }>`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: ${(props) => (props.visible ? 'space-around' : 'space-between')};
  padding: ${getScaledValue(10, 'px')} ${getScaledValue(45, 'px')} ${getScaledValue(10, 'px')};
  color: ${({ textColor }) => textColor};

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    padding: 5px 45px 5px;
  }
`

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    transform: scale(0.9);
  }
`

const Text = styled(TextAvenirNextRegular)`
  font-size: ${getScaledValue(13, 'px')};
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    font-size: 13px;
  }
`

const IconWrapper = styled.div`
  line-height: 0;
  flex-basis: 30px;
  display: flex;
  align-items: center;
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    padding: 0 20px;
    transform: scale(0.9);
  }
`
