import { createAsyncThunk } from '@reduxjs/toolkit'
import { Error, PostUsersSearch } from './types'
import { AxiosError } from 'axios'
import { PostsApi } from 'api/posts'

const searchQuery = (page: number, text: string) => ({
  search: {
    fields: ['name'],
    value: text,
  },
  pager: {
    current: page,
    pageSize: 20,
  },
  sorter: [
    {
      field: 'name',
      order: 'asc',
    },
  ],
  filters: [],
})

export const getParticipantPostUsers = createAsyncThunk<
  PostUsersSearch,
  {
    searchText: string
    page: number
    isSearch?: boolean
  },
  { rejectValue: Error }
>('participant/postUsers', async ({ searchText, page, isSearch = false }, { rejectWithValue }) => {
  try {
    let query: any = searchQuery(page, searchText)

    const data = await PostsApi.getPostUsers(query)

    return { users: data.data, total: data.total, searchPage: page + 1, isSearch }
  } catch (err) {
    let error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})
