import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { MentionsApi } from 'api/mentions'
import {
  AddComplaintRequestModel,
  AddMentionRequestModel,
  CommentRequestModel,
} from 'api/types/requestTypes'
import { CommentModel, Error, NoteModel } from './types'
import { resetMention, setNote } from './slice'
import { MentionInfo, NewPositiveMentionInfo, SupportsModel } from 'store/types'
import { NoteResponse } from 'api/mentions/types/response'

export const createComplaint = createAsyncThunk<
  void,
  AddComplaintRequestModel,
  { rejectValue: Error }
>('mentions/createComplaint', async (complaintData, { rejectWithValue, dispatch }) => {
  try {
    await MentionsApi.postComplaintRequest(complaintData)
    dispatch(resetMention())
  } catch (err) {
    let error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const createTip = createAsyncThunk<void, AddMentionRequestModel, { rejectValue: Error }>(
  'mentions/createTip',
  async (tipData, { rejectWithValue, dispatch }) => {
    try {
      await MentionsApi.postTipRequest(tipData)
      dispatch(resetMention())
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const createCompliment = createAsyncThunk<
  void,
  AddMentionRequestModel,
  { rejectValue: Error }
>('mentions/createCompliment', async (complimentData, { rejectWithValue, dispatch }) => {
  try {
    await MentionsApi.postComplimentRequest(complimentData)
    dispatch(resetMention())
  } catch (err) {
    let error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getNote = createAsyncThunk<any, string, { rejectValue: Error }>(
  'mentions/getNote',
  async (questionId, { rejectWithValue, dispatch }) => {
    try {
      const data = await MentionsApi.getNote(questionId)
      dispatch(setNote(data))
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const saveNote = createAsyncThunk<NoteResponse, NoteModel, { rejectValue: Error }>(
  'mentions/saveNote',
  async (note, { rejectWithValue }) => {
    try {
      if (note.id === '00000000-0000-0000-0000-000000000000') {
        return await MentionsApi.postNote(note)
      }

      return await MentionsApi.updateNote(note)
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const addComment = createAsyncThunk<any, CommentRequestModel, { rejectValue: Error }>(
  'mentions/addComment',
  async (comment, { rejectWithValue }) => {
    try {
      return await MentionsApi.postComment(comment)
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getComments = createAsyncThunk<CommentModel, string, { rejectValue: Error }>(
  'mentions/getComments',
  async (questionId, { rejectWithValue }) => {
    try {
      const { data, total } = await MentionsApi.getCommentsRequest(questionId)
      return {
        data,
        total,
      }
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getExtraTips = createAsyncThunk<SupportsModel, string, { rejectValue: Error }>(
  'mentions/getExtraTips',
  async (questionId, { rejectWithValue }) => {
    try {
      const data = await MentionsApi.getExtraTipsRequest(questionId)

      return {
        data,
        total: data.length,
      }
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getMentionPrivacySettings = createAsyncThunk<
  NewPositiveMentionInfo,
  { recipientId: string; isCompliment: boolean },
  { rejectValue: Error }
>(
  'mentions/getMentionPrivacySettings',
  async ({ recipientId, isCompliment }, { rejectWithValue }) => {
    try {
      return await MentionsApi.getInfoRequest(recipientId, isCompliment)
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getComplaintPrivacySettings = createAsyncThunk<
  MentionInfo,
  { recipientId: string; handlerId: string; isAnonymous: boolean },
  { rejectValue: Error }
>(
  'mentions/getComplaintPrivacySettings',
  async ({ recipientId, handlerId, isAnonymous }, { rejectWithValue }) => {
    try {
      return await MentionsApi.getComplaintInfoRequest(recipientId, handlerId, isAnonymous)
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getComplaintChatPrivacySettings = createAsyncThunk<
  MentionInfo,
  string,
  { rejectValue: Error }
>('mentions/getComplaintChatPrivacySettings', async (mentionId, { rejectWithValue }) => {
  try {
    return await MentionsApi.getComplaintChatPrivacyRequest(mentionId)
  } catch (err) {
    let error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getMentionChatPrivacySettings = createAsyncThunk<
  NewPositiveMentionInfo,
  string,
  { rejectValue: Error }
>('mentions/getMentionChatPrivacySettings', async (mentionId, { rejectWithValue }) => {
  try {
    return await MentionsApi.getMentionChatPrivacyRequest(mentionId)
  } catch (err) {
    let error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})
