import { SerializedError } from '@reduxjs/toolkit'

export interface NewGroupRegistrationState {
  name: string
  phoneNumber: string | null
  email: string | null
  isLoading: boolean
  error: SerializedError | null
  settings: NewGroupRegistrationSettings
  responseType: NewGroupRegistrationType | null
  showModal: boolean
  confirmNameChange: boolean
  currentOrgUrl: string | null
}

export interface NewGroupRegistrationSettings {
  organizationLogo: string | null
  socialImageLink: string | null
  groupName: string
  organizationName: string
  name: string | null
  email: string | null
  phoneNumber: string | null
  country: string
}

export interface Error {
  message: string
}

export enum NewGroupRegistrationType {
  NameIsDifferent = 0,
  EmailTaken = 1,
  PhoneNumberTaken = 2,
  PhoneNumberAndEmailTaken = 3,
  UserValid = 4,
  MissedRequiredData = 5,
  CreatedAndActivated = 6,
  Created = 7,
  Added = 8,
  TokenNotValid = 9,
}
