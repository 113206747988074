import { RootState } from 'store'
import { createSelector } from '@reduxjs/toolkit'

const newGroupState = (state: RootState) => state.newGroup

export const selectNewGroupSettings = createSelector(newGroupState, (state) => state.settings)

export const selectNewGroupTitle = createSelector(newGroupState, (state) => state.title)
export const selectJourneyId = createSelector(newGroupState, (state) => state.journeyId)
export const selectAddManagerGroupId = createSelector(
  newGroupState,
  (state) => state.addManagerGroupId
)
export const selectIsChangeNameModalVisible = createSelector(
  newGroupState,
  (state) => state.isChangeNameModalVisible
)
export const selectStep = createSelector(newGroupState, (state) => state.step)
export const selectTitleInFocus = createSelector(newGroupState, (state) => state.titleInFocus)

export const selectManagerName = createSelector(newGroupState, (state) => state.managerName)
export const selectManagerEmail = createSelector(newGroupState, (state) => state.managerEmail)
export const selectManagerPhoneNumber = createSelector(
  newGroupState,
  (state) => state.managerPhoneNumber
)
export const selectConfirmManagerNameChange = createSelector(
  newGroupState,
  (state) => state.confirmManagerNameChange
)
export const selectShowNewGroupRegistrationModal = createSelector(
  newGroupState,
  (state) => state.showNewGroupRegistrationModal
)

export const selectResponse = createSelector(newGroupState, (state) => state.response)
export const selectIsLoading = (state: RootState) => state.newGroup.isLoading
