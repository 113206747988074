import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { uploadFile } from './thunks'
import { UploadStateType } from './types'

const initialState: UploadStateType = {
  isLoading: false,
  error: null,
}

const upload = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setIsMediaLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadFile.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(uploadFile.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(uploadFile.rejected, (state, action) => {
      if (action.payload) {
        state.error = {
          message: action.payload.message,
        }
      }
      state.isLoading = false
    })
  },
})

export const uploadReducer = upload.reducer
export const { setIsMediaLoading } = upload.actions
