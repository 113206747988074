import {
  createSlice,
  isFulfilled,
  isPending,
  isRejectedWithValue,
  PayloadAction,
} from '@reduxjs/toolkit'
import { MentionChat } from 'store/types'
import {
  completeSatisfactionSurvey,
  deleteMessage,
  followMention,
  getMentionChat,
  notifyComplaintIsHandled,
  reverseComplaintIsHandled,
  sendMentionChatMessage,
  updateLastSeen,
} from './thunks'
import { MentionChatState } from './types'

const initialState: MentionChatState = {
  mention: null,
  chat: {} as MentionChat,
  newMessage: '',
  isLoading: false,
  error: null,
  success: false,
}

const mentionChatSlice = createSlice({
  name: 'mention-chat',
  initialState,
  reducers: {
    toggleFollowMention: (state) => {
      state.chat.follow = !state.chat.follow
    },
    deleteMessageById: (state, action: PayloadAction<string>) => {
      state.chat.messages = state.chat.messages.filter(({ id }) => id !== action.payload)
    },
    toggleIsHandled: (state) => {
      state.chat.isHandled = !state.chat.isHandled
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMentionChat.fulfilled, (state, action) => {
      state.chat = action.payload
    })

    builder.addCase(sendMentionChatMessage.fulfilled, (state, action) => {
      state.chat.messages.push(action.payload)
    })

    builder.addMatcher(
      isFulfilled(
        getMentionChat,
        sendMentionChatMessage,
        updateLastSeen,
        notifyComplaintIsHandled,
        followMention,
        completeSatisfactionSurvey,
        reverseComplaintIsHandled,
        deleteMessage
      ),
      (state) => {
        state.isLoading = false
        state.error = null
      }
    )
    builder.addMatcher(
      isPending(
        getMentionChat,
        sendMentionChatMessage,
        updateLastSeen,
        notifyComplaintIsHandled,
        followMention,
        completeSatisfactionSurvey,
        reverseComplaintIsHandled,
        deleteMessage
      ),
      (state) => {
        state.isLoading = true
        state.error = null
        state.success = false
      }
    )
    builder.addMatcher(
      isRejectedWithValue(
        getMentionChat,
        sendMentionChatMessage,
        updateLastSeen,
        notifyComplaintIsHandled,
        followMention,
        completeSatisfactionSurvey,
        reverseComplaintIsHandled,
        deleteMessage
      ),
      (state, action) => {
        state.error = {
          message: action.payload.message,
        }
        state.isLoading = false
        state.success = false
      }
    )
  },
})

export const mentionChatReducer = mentionChatSlice.reducer
export const { toggleFollowMention, deleteMessageById, toggleIsHandled } = mentionChatSlice.actions
