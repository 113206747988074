import React from 'react'
import styled from 'styled-components/macro'
import { MediaContent } from 'modules/timeline/components/ComponentsTimeline/MediaContent'
import { TrashBinOutlined } from 'assets/svg'

interface IProps {
  itemId: string
  mentionMedia: {
    source: string
    type: number
  }
  handleDeleteMedia: () => void
}

const MediaComponent: React.FC<IProps> = ({ itemId, mentionMedia, handleDeleteMedia }) => {
  return (
    <MediaWrapper>
      <MediaContent media={mentionMedia} itemId={itemId} hasControls={false} />
      <DeleteBtnWrapper onClick={handleDeleteMedia}>
        <TrashBinOutlined />
      </DeleteBtnWrapper>
    </MediaWrapper>
  )
}

export default MediaComponent

const MediaWrapper = styled.div`
  position: relative;
`

const DeleteBtnWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 0;
  cursor: pointer;
`
