import { authInstanceOrg, authInstanceMaster } from 'api/axios.config'
import { RegistrationRequest } from './types/request'
import { NewGroupRegistrationTokenReponse } from './types/response'

export class NewGroupRegistrationApi {
  static getNewGroupRegistrationSettings = async (token: string) => {
    const { data } = await authInstanceOrg.get<NewGroupRegistrationTokenReponse>(
      `/group/getNewGroupRegistrationSettings?token=${encodeURIComponent(token)}`
    )
    return data
  }

  static getOrganizationUrl = async (token: string) => {
    const { data } = await authInstanceMaster.get<string>(
      `/group/getOrganizationUrlByNewGroupRegistrationToken?token=${encodeURIComponent(token)}`
    )
    return data
  }

  static registerByNewGroup = async (model: RegistrationRequest) => {
    const { data } = await authInstanceOrg.post<number>(`/group/registerByNewGroup`, model)
    return data
  }
}
