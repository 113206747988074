import { LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from 'modules/common/constants/storageKeys'

export const saveToLocalStorage = (key: LOCAL_STORAGE_KEYS, value: any) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const getFromLocalStorage = (key: LOCAL_STORAGE_KEYS) => {
  const item = localStorage.getItem(key)

  if (typeof item === 'string') {
    return JSON.parse(item)
  }

  return null
}

export const removeFromLocalStorage = (key: LOCAL_STORAGE_KEYS) => {
  localStorage.removeItem(key)
}

export const saveToSessionStorage = (key: SESSION_STORAGE_KEYS, value: any) => {
  sessionStorage.setItem(key, JSON.stringify(value))
}

export const getFromSessionStorage = (key: SESSION_STORAGE_KEYS) => {
  const item = sessionStorage.getItem(key)

  if (typeof item === 'string') {
    return JSON.parse(item)
  }

  return null
}

export const removeFromSessionStorage = (key: SESSION_STORAGE_KEYS) => {
  sessionStorage.removeItem(key)
}
