import { SerializedError } from '@reduxjs/toolkit'
import { GroupType, DropDownModel } from 'store/types'
import { NewGroupRegistrationType } from '../new-group-registration/types'

export interface Error {
  message: string
}

export interface NewGroupSettings {
  organizationLogo: string
  groupType: GroupType
  addManagerOfGroups: DropDownModel[] | null
  journeys: DropDownModel[] | null
  managerDetails: NewGroupManagerDetails
}

export interface NewGroupState {
  settings: NewGroupSettings
  title: string
  journeyId: string | null
  addManagerGroupId: string | null
  managerName: string
  managerPhoneNumber: string | null
  managerEmail: string | null
  response: NewGroupCreateResponse
  isLoading: boolean
  isChangeNameModalVisible: boolean
  error: SerializedError | null
  step: NewGroupStep
  titleInFocus: boolean
  confirmManagerNameChange: boolean
  showNewGroupRegistrationModal: boolean
}

export enum NewGroupStep {
  Settings = 0,
  Manager = 1,
  Link = 2,
}

export interface NewGroupManagerDetails {
  managerIsCreator: boolean
  managerName: string | null
  managerEmail: string | null
  managerPhoneNumber: string | null
}

export interface NewGroupCreateResponse {
  joinUrl: string | null
  errorType: NewGroupRegistrationType | null
}
