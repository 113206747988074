import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import * as React from 'react'
import { render } from 'react-dom'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import App from './App'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import { store, persistor } from 'store/index'
import { PersistGate } from 'redux-persist/lib/integration/react'
import history from 'utils/history'

render(
  <I18nextProvider i18n={i18n}>
    <PersistGate persistor={persistor}>
      <Router history={history}>
        <Provider store={store}>
          <App />
        </Provider>
      </Router>
    </PersistGate>
  </I18nextProvider>,
  document.getElementById('root')
)
