import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const LightbulbIconTransparent = ({ isActive }: { isActive: boolean }) => {
  const { icon } = useBrandingColors()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="53.25"
      height="59.371"
      viewBox="0 0 53.25 59.371"
    >
      <defs>
        <clipPath id="clip-path-lb-tp">
          <path
            id="Контур_166"
            data-name="Контур 166"
            d="M44.881,13.4l13.313,7.964A13.81,13.81,0,0,1,64.85,33.247V49.174a13.665,13.665,0,0,1-6.656,11.886L44.881,69.024a12.769,12.769,0,0,1-13.312,0L18.256,61.061A13.81,13.81,0,0,1,11.6,49.174V33.247a13.665,13.665,0,0,1,6.656-11.886L31.569,13.4A12.769,12.769,0,0,1,44.881,13.4Z"
            transform="translate(-11.6 -11.525)"
          />
        </clipPath>
      </defs>
      <g
        id="Сгруппировать_4586"
        data-name="Сгруппировать 4586"
        transform="translate(-231.393 -163)"
      >
        <g id="Сгруппировать_4585" data-name="Сгруппировать 4585" transform="translate(-37 4)">
          <g id="Сгруппировать_4584" data-name="Сгруппировать 4584" transform="translate(34 -16)">
            <g
              id="Сгруппировать_61"
              data-name="Сгруппировать 61"
              transform="translate(234.393 175)"
            >
              <g
                id="Сгруппировать_60"
                data-name="Сгруппировать 60"
                transform="translate(0 0)"
                clipPath="url(#clip-path-lb-tp)"
              >
                <rect
                  id="Прямоугольник_10"
                  data-name="Прямоугольник 10"
                  width="65.374"
                  height="71.317"
                  transform="translate(-6.062 -5.973)"
                  fill="none"
                  stroke="#fff"
                  strokeMiterlimit="10"
                  strokeWidth="1"
                />
              </g>
              <path
                id="Контур_167"
                data-name="Контур 167"
                d="M44.881,13.4l13.313,7.964A13.81,13.81,0,0,1,64.85,33.247V49.174a13.665,13.665,0,0,1-6.656,11.886L44.881,69.024a12.769,12.769,0,0,1-13.312,0L18.256,61.061A13.81,13.81,0,0,1,11.6,49.174V33.247a13.665,13.665,0,0,1,6.656-11.886L31.569,13.4A12.769,12.769,0,0,1,44.881,13.4Z"
                transform="translate(-11.6 -11.525)"
                fill="#fff"
                opacity={isActive ? '1' : '0.5'}
              />
            </g>
          </g>
        </g>
        <g
          id="Сгруппировать_3134"
          data-name="Сгруппировать 3134"
          transform="translate(1132.451 -411.701)"
        >
          <path
            id="Контур_7907"
            data-name="Контур 7907"
            d="M-872.526,611.174h-6.958a.917.917,0,0,0-.916.917V613.7a.917.917,0,0,0,.916.916c1.142.078,1.189.682,1.189.894,0,.686,1.026,1.358,2.29,1.358s2.29-.744,2.29-1.431c0-.211-.028-.7,1.189-.821a.917.917,0,0,0,.916-.916v-1.608a.917.917,0,0,0-.916-.917"
            transform="translate(1.616 7.508)"
            fill={isActive ? icon : '#fff'}
          />
          <path
            id="Контур_7908"
            data-name="Контур 7908"
            d="M-863.231,596.715a11.023,11.023,0,0,0-3.751-6.35,11.021,11.021,0,0,0-6.762-2.65,10.732,10.732,0,0,0-3.908.528,11.084,11.084,0,0,0-6.153,4.841,10.958,10.958,0,0,0-1.613,4.992,10.822,10.822,0,0,0,.22,3.2,10.41,10.41,0,0,0,2.046,4.148c.763.977,1.565,1.923,2.339,2.893a10.179,10.179,0,0,1,2.023,3.833,4.593,4.593,0,0,1,.165,1.216v1.806a1.3,1.3,0,0,0,1.292,1.3l6.162,0a1.294,1.294,0,0,0,1.295-1.295v-1.819a4.723,4.723,0,0,1,.26-1.388,10.763,10.763,0,0,1,2.126-3.857c.886-1.114,1.806-2.2,2.646-3.348a9.434,9.434,0,0,0,1.784-4.7,11.04,11.04,0,0,0-.173-3.339m-15.667-3.007a6.186,6.186,0,0,0-1.849,2.823,8.722,8.722,0,0,0-.424,4.073,1.077,1.077,0,0,1-1.07,1.069,1.1,1.1,0,0,1-1.069-1.069,8.564,8.564,0,0,1-.046-1.247A10.551,10.551,0,0,1-881.8,593.8a8.913,8.913,0,0,1,7.247-3.735c1.377-.08,1.367,2.06,0,2.14a7.383,7.383,0,0,0-4.348,1.5"
            transform="translate(0)"
            fill={isActive ? icon : '#fff'}
          />
        </g>
      </g>
    </svg>
  )
}
