export enum LoaderType {
  DEFAULT_LOADER = 'DEFAULT_LOADER',
  BRANDED_LOADER = 'BRANDED_LOADER',
  TRANSPARENT_LOADER = 'TRANSPARENT_LOADER',
  NONE = 'NONE',
}

export enum AboutOthersType {
  User = 0,
  Group = 1,
  Organization = 2,
}

export enum ActiveInfoItem {
  Results = 'results',
  Compliments = 'compliments',
  Complaints = 'complaints',
  Tips = 'tips',
  Notes = 'notes',
  Comments = 'comments',
  Supports = 'supports',
}

export enum GroupType {
  Department,
  Project,
  Event,
  Norm,
  IndividualUsers,
}

export enum ChatMessageType {
  Default,
  SenderMessage,
  AnsweredSenderMessage,
  Comment,
}

export enum CourseFilterType {
  All,
  Active,
  Completed,
}

export enum PasswordChangeError {
  OldPasswordIncorrect,
}

export enum UserContentType {
  AboutMe,
  AboutOthers,
  AboutQuestion,
}

export enum CompleteEpisodeType {
  ActivePageCompleted,
  EpisodeCompleted,
  EpisodeCompletedNoResult,
}

export enum InfoMentionType {
  COMPLIMENT = 1,
  COMPLAINT = 0,
  SUGGESTION = 2,
}

export enum InfoSubtubs {
  Received,
  Given,
  Handled,
}

export enum LanguagesType {
  English = 1,
  Dutch = 2,
  German = 3,
  Polish = 4,
}

export enum CategoryTimelineType {
  Never = 0,
  One = 1,
  Condition = 2,
}
