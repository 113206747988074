import { getCurrentUser } from 'modules/common/helpers'
import React from 'react'
import { Redirect, Route, RouteComponentProps, useLocation } from 'react-router-dom'
import { AppRoutes, AuthRoutes } from './routesConfig'

interface IProps {
  component: React.ComponentType<RouteComponentProps<any>>
  exact: boolean
  path: AppRoutes
}

export const PrivateRoute: React.FC<IProps> = ({ component: Component, ...rest }) => {
  const location = useLocation()
  const user = getCurrentUser()

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: AuthRoutes.EMAIL, state: { from: location } }} />
        )
      }
    />
  )
}
