import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import AppContentLayout from 'layouts/AppContentLayout'
import { useHistory, useParams } from 'react-router-dom'
import { getScaledValue } from 'utils/scale'
import { useAppDispatch } from 'store'
import { useSelector } from 'react-redux'
import { TextCalibriBold } from 'modules/common/components/Typography'
import { useBrandingColors } from 'hooks/useBrandingColors'
import { PrivacyHeader } from 'modules/timeline/components/ComponentsTimeline/PrivacyHeader'
import { selectComplaintPrivacySettings } from 'store/slices/mentions/selectors'
import { getComplaintPrivacySettings } from 'store/slices/mentions/thunks'
import PrivacyViewer from 'modules/common/components/PrivacyViewer'
import { User } from 'store/types'
import { useTranslation } from 'react-i18next'
import { ITheme } from 'styles/types'

const ComplaintPrivacySettings = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const brandingColors = useBrandingColors()
  const dispatch = useAppDispatch()
  const { recipientId, handlerId, isAnonymous } = useParams<{
    recipientId: string
    handlerId: string
    isAnonymous: string
  }>()

  const complaintPrivacySettings = useSelector(selectComplaintPrivacySettings)

  useEffect(() => {
    dispatch(
      getComplaintPrivacySettings({ recipientId, handlerId, isAnonymous: isAnonymous === 'true' })
    )
  }, [])

  const handleBackBtnClick = () => {
    history.goBack()
  }

  const viewers = complaintPrivacySettings.viewers ? complaintPrivacySettings.viewers : []
  const anonymousViewers = complaintPrivacySettings.anonymousViewers
    ? complaintPrivacySettings.anonymousViewers
    : []
  const handler = complaintPrivacySettings.handler ? [complaintPrivacySettings.handler] : []

  const privacyViewers: User[] = [...viewers, ...handler]

  return (
    <AppContentLayout screenTitle={t('SCREEN_TITLES.PRIVACY')} onBackBtnClick={handleBackBtnClick}>
      <PrivacyWrapper>
        <PrivacyHeader />
        <Title textColor={brandingColors.textOnBackground}>
          {t('PRIVACY.ACCESS_COMPLAINT_VIEWERS')}
        </Title>
        <ViewersWrapper>
          {privacyViewers.map((viewer) => {
            return (
              <PrivacyViewer
                key={viewer.id}
                viewer={viewer}
                isHandler={handler.length > 0 && handlerId === viewer.id}
              />
            )
          })}
          {anonymousViewers.map((viewer) => {
            return <PrivacyViewer key={viewer.id} viewer={viewer} isAnonymous />
          })}
        </ViewersWrapper>
      </PrivacyWrapper>
    </AppContentLayout>
  )
}

export default ComplaintPrivacySettings

const Title = styled(TextCalibriBold)<{ textColor: string }>`
  font-size: ${getScaledValue(19, 'px')};
  color: ${({ textColor }) => textColor};
  display: block;
  margin: ${getScaledValue(12, 'px')} 0 ${getScaledValue(20, 'px')};
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    font-size: 19px;
  }
`

const PrivacyWrapper = styled.div`
  padding: 0 35px;
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    padding: 0 10px;
  }
`
const ViewersWrapper = styled.div`
  height: calc(100vh - 260px);
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  @supports (-webkit-touch-callout: none) {
    height: calc(100vh - 350px);
  }
`
