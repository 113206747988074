import React from 'react'
import styled from 'styled-components/macro'
import GiphyIcon from 'modules/common/components/GiphyIcon'
import { UploadSvg } from 'assets/svg'
import { getScaledValue } from 'utils/scale'
import { Upload } from 'antd'
import { useUploadMedia } from 'hooks/useUploadMedia'
import { ITheme } from 'styles/types'

interface IProps {
  handleOpenGiphy: () => void
  handleOnChange?: (data: { source: string; type: number }) => void
}

const UploadMedia: React.FC<IProps> = ({ handleOpenGiphy, handleOnChange }) => {
  const { beforeUpload, inputKey } = useUploadMedia(handleOnChange)
  return (
    <UploadMediaWrapper>
      <UploadWrapper
        onChange={() => false}
        beforeUpload={beforeUpload}
        accept="video/mp4,video/quicktime,image/*"
        showUploadList={false}
        multiple={false}
        key={inputKey}
      >
        <UploadSvg />
      </UploadWrapper>
      <GiphyWrapper onClick={handleOpenGiphy}>
        <GiphyIcon />
      </GiphyWrapper>
    </UploadMediaWrapper>
  )
}

export default UploadMedia

const UploadMediaWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: ${getScaledValue(12, 'px')} 0;

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    padding: 3px 0;
  }
`

const UploadWrapper = styled(Upload)`
  line-height: 0;
  margin-right: ${getScaledValue(25, 'px')};
  cursor: pointer;
  transform: scale(0.9);
`
const GiphyWrapper = styled.div`
  line-height: 0;
  cursor: pointer;
  transform: scale(0.9);
`
