import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const VisibleSvg = () => {
  const { iconOnBackground } = useBrandingColors()

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.7" height="18.214" viewBox="0 0 33.7 18.214">
      <g
        id="Сгруппировать_2957"
        data-name="Сгруппировать 2957"
        transform="translate(46.743 -6.355)"
        opacity="0.802"
      >
        <g
          id="Сгруппировать_4571"
          data-name="Сгруппировать 4571"
          transform="translate(-46.743 6.355)"
        >
          <path
            id="Контур_7814"
            data-name="Контур 7814"
            d="M135.265,301.774a20.84,20.84,0,0,0-20.066-7.249,23.283,23.283,0,0,0-11.708,6.95c-1.461,1.752-1.526,1.923.212,4.006a18.963,18.963,0,0,0,8.619,5.708,20.413,20.413,0,0,0,10.75.725,13.574,13.574,0,0,0,3.306-.906c.19-.08.4-.165.6-.254a21.5,21.5,0,0,0,8.309-6.085c1.081-1.318,1.084-1.59-.022-2.9m-16.018,8.849a7.365,7.365,0,1,1,7.365-7.365,7.365,7.365,0,0,1-7.365,7.365"
            transform="translate(-102.397 -294.102)"
            fill={iconOnBackground}
          />
          <path
            id="Контур_7815"
            data-name="Контур 7815"
            d="M412.744,419.3a3.8,3.8,0,1,0,3.8,3.8,3.8,3.8,0,0,0-3.8-3.8"
            transform="translate(-395.88 -413.929)"
            fill={iconOnBackground}
          />
        </g>
      </g>
    </svg>
  )
}
