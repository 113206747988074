import React from 'react'
import './App.css'
import { AppRouter } from 'router/Routes'
import LoaderContainer from 'modules/common/containers/Loader'
import CommonFeedback from 'modules/common/components/Modals/CommonFeedback'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from 'styles/global'
import useTheme from 'styles/useTheme'
import IdleTimerComponent from 'modules/common/components/IdleTimer'
import 'react-phone-input-2/lib/style.css'

const App: React.FC = () => {
  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AppRouter />
      <CommonFeedback />
      <LoaderContainer />
      <IdleTimerComponent />
    </ThemeProvider>
  )
}

export default App
