import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getMoreSearchCourses,
  getMoreSearchPosts,
  getSearchCategories,
  getSearchCourses,
  getSearchPosts,
  getSearchResult,
} from './thunks'
import { SearchCategoriesResponse } from 'api/search/types/response'
import { SearchStateType } from './types'

const initialState: SearchStateType = {
  searchCategories: [] as SearchCategoriesResponse[],
  expandedCategories: [],
  courses: null,
  posts: null,
  isLoading: false,
  error: null,
  loadingCategoryId: '',
}

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    toggleExpandCategory: (state: SearchStateType, action: PayloadAction<string>) => {
      const isExpanded = state.expandedCategories.includes(action.payload)

      if (!isExpanded) {
        state.expandedCategories.push(action.payload)
      } else {
        const updatedCategories = state.expandedCategories.filter(
          (categoryId) => categoryId !== action.payload
        )
        state.expandedCategories = updatedCategories
      }
    },

    resetState: (state: SearchStateType) => {
      state.expandedCategories = initialState.expandedCategories
      state.searchCategories = initialState.searchCategories
      state.courses = initialState.courses
      state.posts = initialState.posts
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSearchCategories.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(getSearchCategories.fulfilled, (state, action) => {
      state.searchCategories = action.payload
      state.expandedCategories = []
      state.isLoading = false
    })

    builder.addCase(getSearchPosts.pending, (state, action) => {
      state.loadingCategoryId = action.meta.arg.id
    })
    builder.addCase(getSearchPosts.rejected, (state, action) => {
      state.loadingCategoryId = ''
      state.error = action.error
    })
    builder.addCase(getSearchPosts.fulfilled, (state, action) => {
      state.posts = {
        ...state.posts,
        [action.payload.categoryId]: action.payload,
      }

      state.loadingCategoryId = ''
    })
    builder.addCase(getMoreSearchPosts.fulfilled, (state, action) => {
      if (state.posts) {
        const posts = state.posts[action.payload.categoryId]

        state.posts[action.payload.categoryId] = {
          ...state.posts[action.payload.categoryId],
          posts: [...posts.posts, ...action.payload.posts],
        }
      }

      state.loadingCategoryId = ''
    })
    builder.addCase(getSearchCourses.pending, (state, action) => {
      state.loadingCategoryId = action.meta.arg.id
    })
    builder.addCase(getSearchCourses.rejected, (state, action) => {
      state.loadingCategoryId = ''
      state.error = action.error
    })
    builder.addCase(getSearchCourses.fulfilled, (state, action) => {
      state.courses = {
        ...state.courses,
        [action.payload.categoryId]: action.payload,
      }

      state.loadingCategoryId = ''
    })
    builder.addCase(getMoreSearchCourses.fulfilled, (state, action) => {
      if (state.courses) {
        const courses = state.courses[action.payload.categoryId]

        state.courses[action.payload.categoryId] = {
          ...state.courses[action.payload.categoryId],
          questions: [...courses.questions, ...action.payload.questions],
        }
      }
      state.loadingCategoryId = ''
    })

    builder.addCase(getSearchResult.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getSearchResult.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getSearchResult.fulfilled, (state, action) => {
      state.posts = action.payload.posts
      state.courses = action.payload.courses
      state.searchCategories = action.payload.searchCategories
      state.expandedCategories = [action.payload.searchCategories[0].id]
      state.isLoading = false
    })
  },
})

export const searchReducer = searchSlice.reducer

export const { toggleExpandCategory, resetState } = searchSlice.actions
