import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

const scaleFactor = 1.2

export const MoodCommonOutlined = (props: { isSelected: boolean; id: string }) => {
  const brandingColors = useBrandingColors()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="74"
      height="80.623"
      viewBox="0 0 74 80.623"
      style={{ transform: `scale(${scaleFactor})` }}
    >
      <defs>
        {props.isSelected && (
          <linearGradient
            id={`mood-common-gradient-${props.id}`}
            x1="0.5"
            x2="0.5"
            y2="1"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor={brandingColors.mood3Start} />
            <stop offset="1" stopColor={brandingColors.mood3End} />
          </linearGradient>
        )}

        <filter
          id="Контур_1183"
          x="0"
          y="0"
          width="74"
          height="80.623"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Сгруппировать_4570"
        data-name="Сгруппировать 4570"
        transform="translate(-149.889 -1313.691)"
      >
        <g transform="matrix(1, 0, 0, 1, 149.89, 1313.69)" filter="url(#Контур_1183)">
          <path
            id="Контур_1183-2"
            data-name="Контур 1183"
            d="M45.975,13.459l13.75,8.225A14.264,14.264,0,0,1,66.6,33.961V50.412a14.114,14.114,0,0,1-6.875,12.277l-13.75,8.225a13.189,13.189,0,0,1-13.75,0l-13.75-8.225A14.264,14.264,0,0,1,11.6,50.412V33.961a14.114,14.114,0,0,1,6.875-12.277l13.75-8.225A13.189,13.189,0,0,1,45.975,13.459Z"
            transform="translate(-2.1 -4.89)"
            fill={`url(#mood-common-gradient-${props.id})`}
            stroke={!props.isSelected ? brandingColors.icon : 'transparent'}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
        <g
          id="Сгруппировать_1099"
          data-name="Сгруппировать 1099"
          transform="translate(1459.996 1585)"
        >
          <path
            id="Контур_1131"
            data-name="Контур 1131"
            d="M231.5,12.2a19.557,19.557,0,0,0-4.3-6.35,19.558,19.558,0,0,0-6.35-4.3A20.415,20.415,0,0,0,213.1,0a20.863,20.863,0,0,0-7.8,1.55,19.558,19.558,0,0,0-6.35,4.3,20.971,20.971,0,0,0-4.3,6.35,20.4,20.4,0,0,0,0,15.6A20.286,20.286,0,0,0,205.3,38.45a20.4,20.4,0,0,0,15.6,0,19.558,19.558,0,0,0,6.35-4.3,20.971,20.971,0,0,0,4.3-6.35A20.863,20.863,0,0,0,233.1,20,19.974,19.974,0,0,0,231.5,12.2Zm-5.95,20.3A17.6,17.6,0,0,1,195.5,20a17.756,17.756,0,0,1,5.15-12.5A17.6,17.6,0,0,1,230.7,20,17.461,17.461,0,0,1,225.55,32.5Z"
            transform="translate(-1486 -254)"
            fill={props.isSelected ? '#fff' : brandingColors.icon}
          />
          <path
            id="Контур_1132"
            data-name="Контур 1132"
            d="M215.4,54.8h16.45a1.217,1.217,0,0,0,1.2-1.2h0a1.217,1.217,0,0,0-1.2-1.2H215.4a1.217,1.217,0,0,0-1.2,1.2h0A1.216,1.216,0,0,0,215.4,54.8Z"
            transform="translate(-1496.55 -280.2)"
            fill={props.isSelected ? '#fff' : brandingColors.icon}
          />
          <circle
            id="Эллипс_74"
            data-name="Эллипс 74"
            cx="2.95"
            cy="2.95"
            r="2.95"
            transform="translate(-1281.95 -241.6)"
            fill={props.isSelected ? '#fff' : brandingColors.icon}
          />
          <circle
            id="Эллипс_75"
            data-name="Эллипс 75"
            cx="2.95"
            cy="2.95"
            r="2.95"
            transform="translate(-1269.75 -241.6)"
            fill={props.isSelected ? '#fff' : brandingColors.icon}
          />
        </g>
      </g>
    </svg>
  )
}
