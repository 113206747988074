import React from 'react'
import { getScaledValue } from 'utils/scale'
import { useBrandingColors } from 'hooks/useBrandingColors'
import styled from 'styled-components/macro'
import { SearchLoop } from 'assets/svg'
import { TextAvenirNextRegular } from 'modules/common/components/Typography'
import { ITheme } from 'styles/types'

interface IProps {
  onClick: () => void
  buttonText: string
}

const SearchButton: React.FC<IProps> = ({ onClick, buttonText }) => {
  const { textOnBackground, iconOnBackground } = useBrandingColors()

  return (
    <ButtonWrapper onClick={onClick}>
      <Placeholder textColor={textOnBackground}>{buttonText}</Placeholder>
      <IconWrapper>
        <SearchLoop color={iconOnBackground} />
      </IconWrapper>
    </ButtonWrapper>
  )
}

export default SearchButton

const ButtonWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  margin-top: ${getScaledValue(12, 'px')};
  border-radius: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    margin-top: 12px;
  }
`

const Placeholder = styled(TextAvenirNextRegular)<{ textColor: string }>`
  color: ${({ textColor }) => textColor};
  font-size: ${getScaledValue(18, 'px')};
  margin-bottom: 0;
  line-height: 1.5;

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    font-size: 18px;
    line-height: 1.8;
  }
`

const IconWrapper = styled.div`
  position: absolute;
  top: 3px;
  right: 0px;
  line-height: 0;

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    transform: scale(0.9);
  }
`
