import { AttachmentResponse } from './objects'
import { TimelineAnswer } from './timeline'
import { Media, VideoMedia } from './timelineMedia'

export enum EpisodeItemType {
  Multiple = 0,
  Mood = 1,
  LikeDislike = 2,
  YesNo = 3,
  Slider = 4,
  Text = 7,
  Visual = 8,
  Attachment = 9,
  Open = 10,
  Tip = 11,
}

export interface EpisodeItemProperties {
  minTime?: string
  episodeSubtitles?: string | null
  attachmentDocument?: {
    fileName: string
    content: string
  }
  attachmentLabel?: string
  attachmentImage?: string
  uid: string
  index: number
}

export interface EpisodeItem {
  id: string
  questionId: string
  date: string
  media: Media | VideoMedia | null
  text: string
  variants: TimelineAnswer[]
  answer: TimelineAnswer | null
  isSaved: boolean
  isSaveInProgress: boolean
  value: number | null
  itemType: EpisodeItemType
  answered: boolean
  min?: string
  max?: string
  unreadCommentsCount: number
  hasNote: boolean
  hasWrongAnswer: boolean
  properties: EpisodeItemProperties
  isCommentsFollowed: boolean
  isHighlighted: boolean
  questionProgress: number
  isSeriesQuestion: boolean
}

export interface EpisodeQuestionType {
  id: string
  questionId: string
  dateActiveFrom: string
  text: string
  attachment: AttachmentResponse
  type: number
  answerOptions: {
    id: string
    value: string
    key: string
  }[]
  answer: {
    id: string
    value: string
    key: string
    isAnswerCorrect: boolean
    isAnswerSaved: boolean
  } | null
  value: number | null
  min?: string
  max?: string
  hasWrongAnswer: boolean
  unreadCommentsCount: number
  isHighlighted: boolean
  isSeriesQuestion: boolean
  hasNote: boolean
  isCommentsFollowed: boolean
  properties: string
  questionProgress: number
}

export interface BaseEpisodeItem<T> {
  id: string
  type: EpisodeItemType
  data: T
}

export interface EpisodePropertiesType {
  episodeLogo?: string | null
  episodeName: string
  index: number
  textTypes: TextTypesType[]
  uid: string
}

export interface EpisodeResults {
  id: string
  media: Media | VideoMedia | null
  episodeLogo?: string | null
  episodeName: string
  index: number
  seriesName: string
  progress: number
  type: number
  unreadMessageCount: number
  userSeriesVersionId: string
  textTypes: TextTypesType[]
  date: string
  itemType: EpisodeItemType
  isCommentsFollowed: boolean
  groupParentId: string | null
}

export interface TextTypesType {
  id: 2 | 3 | 4 | 5
  text: string | null
}

export interface EpisodeDateItem {
  name: string | null
  start: string
  end: string
}
