import React, { useEffect } from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'
import {
  SlidingMenuContainer,
  Background,
  AboutMe,
  AboutOthers,
  AvatarIcon,
  MenuTitle,
  MenuContainer,
  AboutOrganization,
  MenuItem,
  DefaultLogo,
} from './styles'
import { batch, useSelector } from 'react-redux'
import {
  selectCurrentUser,
  hasOneOrganization,
  selectUserIndividual,
} from 'store/slices/user/selectors'
import { UserFilled, GroupFilled } from 'assets/svg'
import defaultLogo from 'assets/images/wizzflix_logo.png'
import { useHistory } from 'react-router-dom'
import { AppRoutes } from 'router/routesConfig'
import { getUserOrganizations, logout } from 'store/slices/auth/thunks'
import { useAppDispatch } from 'store'
import { resetMention } from 'store/slices/mentions/slice'
import { useTranslation } from 'react-i18next'
import { resetPasswordConfirmed } from 'store/slices/password-check/slice'
import { selectMasterOrganizatonLogo, selectMasterOrganizatonName } from 'store/slices/UI/selectors'
import { checkUserIndividual } from 'store/slices/user/thunks'

export const SlidingMenu = () => {
  const user = useSelector(selectCurrentUser)
  const userHasOneOrganization = useSelector(hasOneOrganization)
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const masterOrgName = useSelector(selectMasterOrganizatonName)
  const masterOrgLogo = useSelector(selectMasterOrganizatonLogo)
  const isIndividual = useSelector(selectUserIndividual)
  const { backgroundStart, backgroundEnd } = useBrandingColors()

  useEffect(() => {
    dispatch(checkUserIndividual())
  }, [])

  const handleAboutMeClick = () => {
    batch(() => {
      dispatch(resetMention())
      dispatch(resetPasswordConfirmed())
    })
    history.push(AppRoutes.ABOUT_ME)
  }
  const handleAboutOthersClick = () => {
    dispatch(resetMention())
    history.push(AppRoutes.ABOUT_OTHERS_USER_SEARCH)
  }

  const handleAboutOrganizationClick = () => {
    batch(() => {
      dispatch(resetMention())
      dispatch(resetPasswordConfirmed())
    })
    history.push(AppRoutes.ORGANIZATION)
  }
  const handleChangePasswordClick = () => {
    batch(() => {
      dispatch(resetMention())
      dispatch(resetPasswordConfirmed())
    })
    history.push(AppRoutes.CHANGE_PASSWORD)
  }

  const handleSwitchOrganization = () => {
    batch(() => {
      dispatch(resetMention())
      dispatch(getUserOrganizations())
      dispatch(resetPasswordConfirmed())
    })
  }

  const handleLogout = () => {
    dispatch(logout())
  }

  const defaultOrganizationLogo = () => {
    return <DefaultLogo src={defaultLogo} alt="avatar-logo" />
  }

  return (
    <SlidingMenuContainer>
      <Background startColor={backgroundStart} stopColor={backgroundEnd} />
      <MenuContainer>
        <AboutMe onClick={handleAboutMeClick}>
          <AvatarIcon
            logo={user && user.credentialsInfo && user.credentialsInfo.userLogo}
            defaultLogo={UserFilled}
            size={64}
          />
          <MenuTitle>{t('SLIDING_MENU.ABOUT_ME')}</MenuTitle>
        </AboutMe>
        <AboutOthers
          isIndividual={isIndividual}
          onClick={!isIndividual ? handleAboutOthersClick : () => {}}
        >
          <AvatarIcon logo={null} defaultLogo={GroupFilled} size={64} />
          <MenuTitle>{t('SLIDING_MENU.ABOUT_OTHERS')}</MenuTitle>
        </AboutOthers>
        <AboutOrganization onClick={handleAboutOrganizationClick}>
          <AvatarIcon logo={masterOrgLogo} defaultLogo={defaultOrganizationLogo} size={64} />
          <MenuTitle>{masterOrgName || 'Wizzflix'}</MenuTitle>
        </AboutOrganization>
        <MenuItem onClick={handleLogout}>{t('SLIDING_MENU.LOGOUT')}</MenuItem>
        <MenuItem onClick={handleChangePasswordClick}>{t('SLIDING_MENU.CHANGE_PASSWORD')}</MenuItem>
        {!userHasOneOrganization && (
          <MenuItem onClick={handleSwitchOrganization}>
            {t('SLIDING_MENU.SWITCH_ORGANIZATION')}
          </MenuItem>
        )}
      </MenuContainer>
    </SlidingMenuContainer>
  )
}
