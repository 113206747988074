import React from 'react'

export const SendBtnBg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="149.973"
      height="40.191"
      viewBox="0 0 149.973 40.191"
    >
      <path
        id="Контур_7815"
        data-name="Контур 7815"
        d="M40.687-188.565l.036-117.144a9.506,9.506,0,0,0-4.406-8.159l-11.284-7a8.216,8.216,0,0,0-8.776,0l-11.284,7a9.6,9.6,0,0,0-4.406,8.159L.531-188.6a9.507,9.507,0,0,0,4.406,8.159l11.32,7a8.216,8.216,0,0,0,8.776,0l11.32-7A9.508,9.508,0,0,0,40.687-188.565Z"
        transform="translate(322.143 40.723) rotate(-90)"
        fill="#fff"
        opacity="0.199"
      />
    </svg>
  )
}
