export const animationData = {
  v: '5.4.4',
  fr: 25,
  ip: 0,
  op: 55,
  w: 800,
  h: 800,
  nm: 'Comp 1',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Shape Layer 4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [384, 364, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'sr',
              sy: 2,
              d: 1,
              pt: { a: 0, k: 6, ix: 3 },
              p: { a: 0, k: [0, 0], ix: 4 },
              r: { a: 0, k: 0, ix: 5 },
              or: { a: 0, k: 320, ix: 7 },
              os: { a: 0, k: 0, ix: 9 },
              ix: 1,
              nm: 'Polystar Path 1',
              mn: 'ADBE Vector Shape - Star',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 46, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [8, 40], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Polystar 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'rd',
          nm: 'Round Corners 1',
          r: { a: 0, k: 50, ix: 1 },
          ix: 2,
          mn: 'ADBE Vector Filter - RC',
          hd: false,
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.6], y: [0.994] },
                o: { x: [0.602], y: [0.009] },
                t: 0,
                s: [0],
                e: [100],
              },
              { t: 25 },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.5], y: [0.995] },
                o: { x: [0.5], y: [-0.003] },
                t: 25,
                s: [0],
                e: [100],
              },
              { t: 55 },
            ],
            ix: 2,
          },
          o: { a: 0, k: 8, ix: 3 },
          m: 1,
          ix: 3,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
      ],
      ip: 0,
      op: 75,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Shape Layer 3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [384, 364, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'sr',
              sy: 2,
              d: 1,
              pt: { a: 0, k: 6, ix: 3 },
              p: { a: 0, k: [0, 0], ix: 4 },
              r: { a: 0, k: 0, ix: 5 },
              or: { a: 0, k: 320, ix: 7 },
              os: { a: 0, k: 0, ix: 9 },
              ix: 1,
              nm: 'Polystar Path 1',
              mn: 'ADBE Vector Shape - Star',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 20, ix: 4 },
              w: { a: 0, k: 46, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [8, 40], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Polystar 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'rd',
          nm: 'Round Corners 1',
          r: { a: 0, k: 50, ix: 1 },
          ix: 2,
          mn: 'ADBE Vector Filter - RC',
          hd: false,
        },
      ],
      ip: 0,
      op: 75,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
}
