import { RootState } from 'store'
import { createSelector } from '@reduxjs/toolkit'

const authState = (state: RootState) => state.auth

export const selectIsSuccess = (state: RootState) => state.auth.success
export const selectIsLoading = (state: RootState) => state.auth.isLoading
export const selectError = (state: RootState) => state.auth.error

export const selectAvailableOrganizations = createSelector(
  authState,
  (state) => state.organizations
)
export const selectCurrentUserLoginDetails = createSelector(
  authState,
  (state) => state.currentUserLoginDetails
)
export const selectCurrentStep = createSelector(authState, (state) => state.currentStep)
export const selectLanguages = createSelector(authState, (state) => state.languages)
export const selectDefaultUserLanguage = createSelector(
  authState,
  (state) => state.defaultUserLanguage
)
export const selectUserLogo = createSelector(authState, (state) => state.logo)
