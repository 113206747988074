import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserApi } from 'api/user'
import { AxiosError } from 'axios'

interface Error {
  message: string
}

export const checkPassword = createAsyncThunk<void, string, { rejectValue: Error }>(
  'password-check/checkPassword',
  async (password, { rejectWithValue }) => {
    try {
      await UserApi.checkPasswordRequest(password)
    } catch (err) {
      let error = err as AxiosError

      if (error) {
        if (error.response && error.response.status === 422) {
          return rejectWithValue({ message: 'Incorrect password' })
        }

        return rejectWithValue({ message: error.message })
      }
      throw err
    }
  }
)
