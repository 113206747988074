import {
  // CourseResultResponse,
  SeriesResultResponse,
  TimeLineQuestionResponse,
  TimelineResultResponse,
} from 'api/timeline/types/response'

const ITEM_QUESTION = 0
const ITEM_MENTION = 1
const ITEM_RESULT = 2
const ITEM_SERIES_RESULT = 3
const ITEM_COMRESSED_MENTION = 4
const ITEM_EPISODE = 5
const ITEM_COURSE_RESULT = 6
const ITEM_POST = 7
const ITEM_GROUPED_SERIES_RESULT = 8
const ITEM_GROUPED_EPISODE = 9

const isTimelineQuestionValid = (question: TimeLineQuestionResponse) => {
  const { text, type, answerOptions } = question

  if (!text) {
    return false
  }
  if (type > 4) {
    return false
  }
  for (let i = 0; i < answerOptions.length; i += 1) {
    if (!answerOptions[i].value) {
      return false
    }
  }
  if (answerOptions.length < 2) {
    return false
  }
  return true
}

const isTimelineResultValid = (result: TimelineResultResponse) => {
  if (result.resultOptions.length === 0) {
    return false
  }
  return true
}

const isSeriesResultValid = (result: SeriesResultResponse) => {
  if (result.resultOptions.length === 0) {
    return false
  }
  return true
}

// const isCourseResultValid = (result: CourseResultResponse) => {
//   if (result.resultOptions.length === 0) {
//     return false
//   }
//   return true
// }

export const isTimelineItemValid = (item: any) => {
  switch (item.type) {
    case ITEM_QUESTION:
      return isTimelineQuestionValid(item.question as TimeLineQuestionResponse)
    case ITEM_MENTION:
      return true
    case ITEM_RESULT:
      return isTimelineResultValid(item.questionResult as TimelineResultResponse)
    case ITEM_SERIES_RESULT:
      return isSeriesResultValid(item.seriesResult as SeriesResultResponse)
    case ITEM_COMRESSED_MENTION:
      return true
    case ITEM_EPISODE:
      return true
    case ITEM_COURSE_RESULT:
      return true
    case ITEM_POST:
      return true
    case ITEM_GROUPED_SERIES_RESULT:
      return true
    case ITEM_GROUPED_EPISODE:
      return true
    default:
      return false
  }
}
