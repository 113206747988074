import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getParticipantPostUsers } from './thunks'
import { PostUsersState } from './types'

const initialState: PostUsersState = {
  users: [],
  searchPage: 1,
  total: 0,
  isLoading: false,
  error: null,
}

const postUsersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUsersSearchList: (state) => {
      state.users = initialState.users
      state.searchPage = initialState.searchPage
      state.total = initialState.total
    },
    setTotalUsers: (state, action: PayloadAction<number>) => {
      state.total = action.payload
    },
    clear: (state) => {
      state.users = []
      state.searchPage = 1
      state.total = 0
      state.isLoading = false
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getParticipantPostUsers.fulfilled, (state, action) => {
      if (action.payload.isSearch) {
        state.users = action.payload.users
      }

      if (!action.payload.isSearch) {
        state.users = [...state.users, ...action.payload.users]
      }

      state.total = action.payload.total
      state.searchPage = action.payload.searchPage
      state.isLoading = false
    })
    builder.addCase(getParticipantPostUsers.pending, (state) => {
      if (state.searchPage === 1) {
        state.isLoading = true
      }
    })
    builder.addCase(getParticipantPostUsers.rejected, (state, action) => {
      state.isLoading = false
      if (action.payload) {
        state.error = {
          message: action.payload.message,
        }
      }
    })
  },
})

export const postUsersReducer = postUsersSlice.reducer
export const { setTotalUsers, clearUsersSearchList } = postUsersSlice.actions
