import { useDispatch } from 'react-redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// import logger from 'redux-logger'
import { configureStore, Action, ThunkAction, getDefaultMiddleware } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { authReducer } from './slices/auth/slice'
import { feedbackReducer } from './slices/feedback/slice'
import { uiReducer } from './slices/UI/slice'
import { timelineReducer } from './slices/timeline/slice'
import { userReducer } from './slices/user/slice'
import { episodeReducer } from './slices/episode/slice'
import { aboutMeReducer } from './slices/about-me/slice'
import { passwordCheckReducer } from './slices/password-check/slice'
import { mentionsReducer } from './slices/mentions/slice'
import { aboutOthersReducer } from './slices/about-others/slice'
import { accountReducer } from './slices/account/slice'
import { mentionChatReducer } from './slices/mention-chat/slice'
import { uploadReducer } from './slices/upload/slice'
import { itemDetailsReducer } from './slices/item-details/slice'
import { searchReducer } from './slices/search/slice'
import { calendarReducer } from './slices/calendar/slice'
import { newGroupReducer } from './slices/new-group/slice'
import { newGroupRegistrationReducer } from './slices/new-group-registration/slice'
import { postsReducer } from './slices/posts/slice'
import { postUsersReducer } from './slices/postUsers/slice'
import { participantGroupsReducer } from './slices/participantGroups/slice'

const uiPersistConfig = {
  key: 'ui',
  storage,
  blacklist: ['expandedItemId', 'playingVideoId'],
}

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['currentUser', 'phoneNumber', 'email'],
}

const passwordCheckPersistConfig = {
  key: 'password-check',
  storage,
  whitelist: ['isPasswordConfirmed'],
}

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['confirmedUsers', 'currentUserLoginDetails'],
}

export const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  ui: persistReducer(uiPersistConfig, uiReducer),
  timeline: timelineReducer,
  episode: episodeReducer,
  aboutMe: aboutMeReducer,
  aboutOthers: aboutOthersReducer,
  user: persistReducer(userPersistConfig, userReducer),
  feedback: feedbackReducer,
  passwordCheck: persistReducer(passwordCheckPersistConfig, passwordCheckReducer),
  mentions: mentionsReducer,
  mentionChat: mentionChatReducer,
  account: accountReducer,
  upload: uploadReducer,
  itemDetails: itemDetailsReducer,
  search: searchReducer,
  calendar: calendarReducer,
  posts: postsReducer,
  postUsers: postUsersReducer,
  newGroup: newGroupReducer,
  newGroupRegistration: newGroupRegistrationReducer,
  participantGroups: participantGroupsReducer,
})

export const store = configureStore({
  reducer: rootReducer,
  // middleware: [thunk] as const,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
})
export const persistor = persistStore(store)

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
