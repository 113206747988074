import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const TrashBinSvg = ({ onBackground = false }: { onBackground?: boolean }) => {
  const { iconOnBackground } = useBrandingColors()
  return (
    <svg
      id="baseline-delete-24px"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        id="Контур_189"
        data-name="Контур 189"
        d="M6.326,24.282a2.664,2.664,0,0,0,2.653,2.66H19.591a2.664,2.664,0,0,0,2.653-2.66V8.32H6.326ZM23.57,4.33H18.928L17.6,3H10.969L9.643,4.33H5V6.99H23.57Z"
        transform="translate(1.715 1.029)"
        fill={onBackground ? iconOnBackground : 'white'}
      />
      <path id="Контур_190" data-name="Контур 190" d="M0,0H32V32H0Z" fill="none" />
    </svg>
  )
}
