import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const GroupOutlinedSmall = () => {
  const { frame } = useBrandingColors()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="74"
      height="74"
      viewBox="0 0 74 74"
    >
      <defs>
        <filter id="Эллипс_78" x="0" y="0" width="74" height="74" filterUnits="userSpaceOnUse">
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Сгруппировать_4574" data-name="Сгруппировать 4574" transform="translate(-26 -1015)">
        <g id="Сгруппировать_4569" data-name="Сгруппировать 4569" transform="translate(-97 -15)">
          <g id="Сгруппировать_4571" data-name="Сгруппировать 4571" transform="translate(86 16)">
            <g id="Сгруппировать_4570" data-name="Сгруппировать 4570">
              <g transform="matrix(1, 0, 0, 1, 37, 1014)" filter="url(#Эллипс_78)">
                <circle
                  id="Эллипс_78-2"
                  data-name="Эллипс 78"
                  cx="28"
                  cy="28"
                  r="28"
                  transform="translate(9 6)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="Сгруппировать_1030" data-name="Сгруппировать 1030" transform="translate(1573 4970)">
          <g id="Сгруппировать_4573" data-name="Сгруппировать 4573">
            <g
              id="Эллипс_45"
              data-name="Эллипс 45"
              transform="translate(-1538 -3949)"
              fill="none"
              stroke={frame}
              strokeWidth="0.5"
            >
              <circle cx="28" cy="28" r="28" stroke="none" />
              <circle cx="28" cy="28" r="27.75" fill="none" />
            </g>
          </g>
          <g id="Сгруппировать_4572" data-name="Сгруппировать 4572">
            <g
              id="Сгруппировать_388"
              data-name="Сгруппировать 388"
              transform="translate(-1528.926 -3935.668)"
            >
              <circle
                id="Эллипс_46"
                data-name="Эллипс 46"
                cx="5.69"
                cy="5.69"
                r="5.69"
                transform="translate(2.83 8.051)"
                fill="none"
                stroke={frame}
                strokeMiterlimit="10"
                strokeWidth="0.8"
              />
              <path
                id="Контур_546"
                data-name="Контур 546"
                d="M3,144.949a10.183,10.183,0,0,1,8.5-4.549h0a10.183,10.183,0,0,1,8.473,4.5"
                transform="translate(-3 -118.905)"
                fill="none"
                stroke={frame}
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="0.8"
              />
              <circle
                id="Эллипс_47"
                data-name="Эллипс 47"
                cx="5.69"
                cy="5.69"
                r="5.69"
                transform="translate(23.668 8.051)"
                fill="none"
                stroke={frame}
                strokeMiterlimit="10"
                strokeWidth="0.8"
              />
              <path
                id="Контур_547"
                data-name="Контур 547"
                d="M136.3,144.949a10.183,10.183,0,0,1,8.5-4.549h0a10.183,10.183,0,0,1,8.473,4.5"
                transform="translate(-115.461 -118.905)"
                fill="none"
                stroke={frame}
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="0.8"
              />
              <circle
                id="Эллипс_48"
                data-name="Эллипс 48"
                cx="5.69"
                cy="5.69"
                r="5.69"
                transform="translate(12.881)"
                fill="none"
                stroke={frame}
                strokeMiterlimit="10"
                strokeWidth="0.8"
              />
              <path
                id="Контур_548"
                data-name="Контур 548"
                d="M102.5,89.44a10.541,10.541,0,0,1,3-.578h0a10.3,10.3,0,0,1,3.689.532"
                transform="translate(-86.945 -75.418)"
                fill="none"
                stroke={frame}
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="0.8"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
