import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { Error } from './types'
import { EpisodeDetailsTimeline } from 'store/types'
import { EpisodeApi } from 'api/episode'
import { UserApi } from 'api/user'
import { setExpandedItemId, setLoadingItemId } from './slice'

export const getEpisodeDetails = createAsyncThunk<
  { episodeDetails: EpisodeDetailsTimeline[]; isCourseCompleted: boolean },
  string,
  { rejectValue: { message: string } }
>('item-details/getEpisodeDetails', async (seriesVersionId, { rejectWithValue, dispatch }) => {
  try {
    dispatch(setLoadingItemId(seriesVersionId))
    const { episodeDetails, isCourseCompleted } = await EpisodeApi.getEpisodeDetailsRequest(
      seriesVersionId
    )
    dispatch(setExpandedItemId(seriesVersionId))
    return {
      episodeDetails,
      isCourseCompleted,
    }
  } catch (err) {
    let error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getCourseDetails = createAsyncThunk<
  {
    episodesDetails: EpisodeDetailsTimeline[]
    episodeDates: { name: string | null; start: string; end: string }[]
  },
  string,
  { rejectValue: Error }
>('item-details/getCourseDetails', async (id, { rejectWithValue, dispatch }) => {
  try {
    dispatch(setLoadingItemId(id))
    const courseDetails = await UserApi.getCourseDetailsRequest(id)
    dispatch(setExpandedItemId(id))

    return courseDetails
  } catch (err) {
    let error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})
