import { createSlice, isFulfilled, isPending, isRejectedWithValue } from '@reduxjs/toolkit'
import { ActiveInfoItem } from 'store/types'
import {
  getAboutOthersUserInfo,
  getCourses,
  getMoreCourses,
  getMentions,
  getMoreMentions,
  searchMentions,
  getResults,
  searchResults,
  getMoreResults,
} from './thunks'
import { AboutOthersStateType } from './types'

const initialState: AboutOthersStateType = {
  info: {
    complaintsCount: 0,
    complimentsCount: 0,
    tipsCount: 0,
    resultsCount: 0,
    courseCount: 0,
    id: '',
    logo: '',
    fullName: '',
    aboutOthersType: 0,
    type: 0,
  },
  organizationLanguages: [],
  results: null,
  courses: null,
  coursesTotal: 0,
  mentions: null,
  totalMentionsCount: 0,
  comments: null,
  extraTips: null,
  searchText: '',
  currentPage: 1,
  listEnded: false,
  totalPoints: 0,
  activeInfoItem: ActiveInfoItem.Results,
  isLoading: false,
  isTabContentLoading: false,
  error: null,
  isActive: false,
}

const aboutOthersSlice = createSlice({
  name: 'about-others',
  initialState,
  reducers: {
    resetUserInfo: (state) => {
      state.info = initialState.info
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAboutOthersUserInfo.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAboutOthersUserInfo.fulfilled, (state, action) => {
      state.info = action.payload
      state.isLoading = false
    })
    builder.addCase(getAboutOthersUserInfo.rejected, (state, action) => {
      if (action.payload) {
        state.error = {
          message: action.payload.message,
        }
      }

      state.isLoading = false
    })

    builder.addCase(getCourses.fulfilled, (state, action) => {
      state.courses = action.payload.data
      state.coursesTotal = action.payload.total
    })

    builder.addCase(getMoreCourses.fulfilled, (state, action) => {
      if (state.courses) {
        state.courses = [...state.courses, ...action.payload.data]
        state.coursesTotal = action.payload.total
      }
    })

    builder.addCase(getMentions.fulfilled, (state, action) => {
      state.mentions = action.payload.data
      state.totalMentionsCount = action.payload.total
    })

    builder.addCase(getMoreMentions.fulfilled, (state, action) => {
      if (state.mentions) {
        state.mentions = [...state.mentions, ...action.payload.data]
      }
    })

    builder.addCase(searchMentions.fulfilled, (state, action) => {
      state.mentions = action.payload.data
    })

    builder.addCase(getResults.fulfilled, (state, action) => {
      state.results = action.payload.results.data
      state.totalPoints = action.payload.totalPoints
    })
    builder.addCase(getMoreResults.fulfilled, (state, action) => {
      if (state.results) {
        state.results = [...state.results, ...action.payload.results.data]
      }
    })

    builder.addCase(searchResults.fulfilled, (state, action) => {
      state.results = action.payload.data
    })

    builder.addMatcher(
      isFulfilled(
        getAboutOthersUserInfo,
        getCourses,
        getMentions,
        searchMentions,
        getResults,
        searchResults
      ),
      (state) => {
        state.isTabContentLoading = false
        state.error = null
      }
    )

    builder.addMatcher(
      isPending(
        getAboutOthersUserInfo,
        getCourses,
        getMentions,
        searchMentions,
        getResults,
        searchResults
      ),
      (state) => {
        state.isTabContentLoading = true
        state.error = null
      }
    )
    builder.addMatcher(
      isRejectedWithValue(
        getAboutOthersUserInfo,
        getCourses,
        getMentions,
        searchMentions,
        getResults,
        searchResults
      ),
      (state, action) => {
        state.error = {
          message: action.payload.message,
        }
        state.isTabContentLoading = false
      }
    )
  },
})

export const aboutOthersReducer = aboutOthersSlice.reducer

export const { resetUserInfo } = aboutOthersSlice.actions
