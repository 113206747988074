import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { NewGroupRegistrationApi } from 'api/newGroupRegistration'
import { Error, NewGroupRegistrationSettings } from './types'
import { RegistrationRequest } from 'api/newGroupRegistration/types/request'

export const getNewGroupRegistrationSettings = createAsyncThunk<
  NewGroupRegistrationSettings,
  string,
  { rejectValue: Error }
>(
  'newGroupRegistration/getNewGroupRegistrationSettings',
  async (token: string, { rejectWithValue }) => {
    try {
      return await NewGroupRegistrationApi.getNewGroupRegistrationSettings(token)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getOrganizationUrl = createAsyncThunk<string, string, { rejectValue: Error }>(
  'newGroupRegistration/getOrganizationUrl',
  async (token: string, { rejectWithValue }) => {
    try {
      return await NewGroupRegistrationApi.getOrganizationUrl(token)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const register = createAsyncThunk<number, RegistrationRequest, { rejectValue: Error }>(
  'newGroupRegistration/register',
  async (model, { rejectWithValue }) => {
    try {
      return await NewGroupRegistrationApi.registerByNewGroup(model)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)
