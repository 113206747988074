import { RootState } from 'store'
import { createSelector } from '@reduxjs/toolkit'

const aboutOthersState = (state: RootState) => state.aboutOthers

export const selectAboutOthersUserInfo = createSelector(aboutOthersState, (state) => state.info)

export const selectUserId = createSelector(aboutOthersState, (state) => state.info.id)

export const selectUserLogo = createSelector(aboutOthersState, (state) => state.info.logo)

export const selectUserName = createSelector(aboutOthersState, (state) => state.info.fullName)

export const selectTotalPoints = createSelector(aboutOthersState, (state) => state.totalPoints)

export const selectOrgLanguages = createSelector(
  aboutOthersState,
  (state) => state.organizationLanguages
)

export const selectCourseCount = createSelector(aboutOthersState, (state) => state.info.courseCount)
export const selectComplimentsCount = createSelector(
  aboutOthersState,
  (state) => state.info.complimentsCount
)
export const selectComplaintsCount = createSelector(
  aboutOthersState,
  (state) => state.info.complaintsCount
)

export const selectSuggestionsCount = createSelector(
  aboutOthersState,
  (state) => state.info.tipsCount
)
export const selectResultsCount = createSelector(
  aboutOthersState,
  (state) => state.info.resultsCount
)

export const selectCourses = createSelector(aboutOthersState, (state) => state.courses)
export const selectCoursesTotal = createSelector(aboutOthersState, (state) => state.coursesTotal)
export const selectCourseDetails = createSelector(aboutOthersState, (state) => state.courseDetails)
export const selectCourseDates = createSelector(aboutOthersState, (state) => state.courseDates)
export const selectIsOtherScreen = createSelector(aboutOthersState, (state) => state.isActive)

export const selectMentions = createSelector(aboutOthersState, (state) => state.mentions)
export const selectMentionsCount = createSelector(
  aboutOthersState,
  (state) => state.totalMentionsCount
)
export const selectResults = createSelector(aboutOthersState, (state) => state.results)

export const selectIsLoading = (state: RootState) => state.aboutOthers.isLoading

export const selectIsTabContentLoading = (state: RootState) => state.aboutOthers.isTabContentLoading
