import React from 'react'

export const HexShadow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="68.805"
      height="74.216"
      viewBox="0 0 68.805 74.216"
    >
      <defs>
        <filter
          id="Path_8403"
          x="0"
          y="0"
          width="68.805"
          height="74.216"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_8403)">
        <path
          id="Path_8403-2"
          data-name="Path 8403"
          d="M0,27.885c.148-3.172.074-6.27.443-9.441A11.983,11.983,0,0,1,6.27,9.371c4.057-2.582,8.261-4.942,12.392-7.376,4.573-2.729,9.146-2.582,13.72.074,3.688,2.213,7.376,4.426,11.064,6.565,4.352,2.582,6.934,6.343,7.081,11.433.148,5.68.074,11.433-.221,17.113a11.07,11.07,0,0,1-5.09,8.7c-4.647,3.024-9.441,5.9-14.31,8.63a11.226,11.226,0,0,1-11.285-.074c-4.5-2.508-8.851-5.163-13.2-7.892C2.213,43.891.3,39.908.148,35.039c-.074-2.36,0-4.721,0-7.155Z"
          transform="translate(9.1 6.09)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="0.2"
        />
      </g>
    </svg>
  )
}
