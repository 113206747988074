import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store'

const UIState = (state: RootState) => state.ui

export const selectBrandingColors = createSelector(UIState, (state) => state.brandingColors)
export const selectIsSharedDevice = createSelector(UIState, (state) => state.isSharedDevice)
export const selectOrganizationMentions = createSelector(
  UIState,
  (state) => state.organizationMentions
)
export const selectMasterOrganizatonName = createSelector(
  UIState,
  (state) => state.masterOrganization.name
)
export const selectMasterOrganizatonLogo = createSelector(
  UIState,
  (state) => state.masterOrganization.logo
)

export const selectMasterSettingsLogo = createSelector(
  UIState,
  (state) => state.masterOrgSettings.logo
)

export const selectPlayingVideoId = createSelector(UIState, (state) => state.playingVideoId)
