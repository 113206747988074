import {
  NewGroupState,
  NewGroupStep,
  NewGroupManagerDetails,
  NewGroupCreateResponse,
} from './types'
import { createSlice, PayloadAction, isRejectedWithValue, isPending } from '@reduxjs/toolkit'
import { getNewGroupSettings, validateGroupExist, createNewGroup } from './thunk'
import { NewGroupRegistrationType } from '../new-group-registration/types'

const shouldOpenModal = (responseType: NewGroupRegistrationType | null) => {
  switch (responseType) {
    case NewGroupRegistrationType.EmailTaken:
    case NewGroupRegistrationType.NameIsDifferent:
    case NewGroupRegistrationType.PhoneNumberAndEmailTaken:
    case NewGroupRegistrationType.PhoneNumberTaken:
      return true
    default:
      return false
  }
}

const initialState: NewGroupState = {
  settings: {
    organizationLogo: '',
    groupType: 0,
    addManagerOfGroups: null,
    journeys: [],
    managerDetails: {} as NewGroupManagerDetails,
  },
  title: '',
  journeyId: '',
  addManagerGroupId: null,
  managerName: '',
  managerPhoneNumber: null,
  managerEmail: null,
  response: {} as NewGroupCreateResponse,
  error: null,
  isLoading: false,
  isChangeNameModalVisible: false,
  step: NewGroupStep.Settings,
  titleInFocus: false,
  confirmManagerNameChange: false,
  showNewGroupRegistrationModal: false,
}

const newGroupSlice = createSlice({
  name: 'newGroup',
  initialState,
  reducers: {
    setJourneyId: (state: NewGroupState, action: PayloadAction<string | null>) => {
      state.journeyId = action.payload
    },
    setAddManagerGroupId: (state: NewGroupState, action: PayloadAction<string | null>) => {
      state.addManagerGroupId = action.payload
    },
    setTitle: (state: NewGroupState, action: PayloadAction<string>) => {
      state.title = action.payload
    },
    setManagerName: (state: NewGroupState, action: PayloadAction<string>) => {
      state.managerName = action.payload
    },
    setManagerEmail: (state: NewGroupState, action: PayloadAction<string>) => {
      state.managerEmail = action.payload
      if (state.response.errorType === NewGroupRegistrationType.EmailTaken) {
        state.response.errorType = null
      }
      if (state.response.errorType === NewGroupRegistrationType.PhoneNumberAndEmailTaken) {
        state.response.errorType = NewGroupRegistrationType.PhoneNumberTaken
      }
    },
    setManagerPhoneNumber: (state: NewGroupState, action: PayloadAction<string>) => {
      state.managerPhoneNumber = action.payload
      if (state.response.errorType === NewGroupRegistrationType.PhoneNumberTaken) {
        state.response.errorType = null
      }
      if (state.response.errorType === NewGroupRegistrationType.PhoneNumberAndEmailTaken) {
        state.response.errorType = NewGroupRegistrationType.EmailTaken
      }
    },
    setTitleFocus: (state: NewGroupState, action: PayloadAction<boolean>) => {
      state.titleInFocus = action.payload
    },
    setChangeNameModalVisible: (state: NewGroupState, action: PayloadAction<boolean>) => {
      state.isChangeNameModalVisible = action.payload
    },
    changeTitleModalClick: (state: NewGroupState) => {
      state.isChangeNameModalVisible = false
      state.titleInFocus = true
    },
    setStep: (state: NewGroupState, action: PayloadAction<NewGroupStep>) => {
      state.step = action.payload
    },
    setConfirmManagerNameChange: (state: NewGroupState, action: PayloadAction<boolean>) => {
      state.confirmManagerNameChange = action.payload
    },
    setShowNewGroupRegistrationModal: (state: NewGroupState, action: PayloadAction<boolean>) => {
      state.showNewGroupRegistrationModal = action.payload
    },
    setErrorType: (
      state: NewGroupState,
      action: PayloadAction<NewGroupRegistrationType | null>
    ) => {
      state.response.errorType = action.payload
    },
    setManagerDetails: (
      state: NewGroupState,
      action: PayloadAction<{
        name: string
        email: string | null
        phoneNumber: string | null
      }>
    ) => {
      state.managerName = action.payload.name
      state.managerEmail = action.payload.email
      state.managerPhoneNumber = action.payload.phoneNumber
    },
    clearState: (state: NewGroupState) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNewGroupSettings.fulfilled, (state, action) => {
      state.isLoading = false
      state.settings = action.payload
    })
    builder.addCase(validateGroupExist.fulfilled, (state, action) => {
      if (action.payload) {
        state.isChangeNameModalVisible = true
      } else {
        state.step = NewGroupStep.Manager
      }
      state.isLoading = false
    })
    builder.addCase(createNewGroup.fulfilled, (state, action) => {
      state.isLoading = false
      state.response = action.payload
      if (state.response.errorType || state.response.errorType === 0) {
        state.showNewGroupRegistrationModal = shouldOpenModal(action.payload.errorType)
      } else {
        state.step = NewGroupStep.Link
      }
    })
    builder.addMatcher(
      isRejectedWithValue(getNewGroupSettings, validateGroupExist, createNewGroup),
      (state, action) => {
        state.error = {
          message: action.payload.message,
        }
        state.isLoading = false
      }
    )
    builder.addMatcher(
      isPending(getNewGroupSettings, validateGroupExist, createNewGroup),
      (state) => {
        state.isLoading = true
      }
    )
  },
})

export const newGroupReducer = newGroupSlice.reducer
export const {
  setJourneyId,
  setAddManagerGroupId,
  setTitle,
  setManagerName,
  setManagerEmail,
  setManagerPhoneNumber,
  clearState,
  setTitleFocus,
  setChangeNameModalVisible,
  changeTitleModalClick,
  setManagerDetails,
  setStep,
  setConfirmManagerNameChange,
  setErrorType,
  setShowNewGroupRegistrationModal,
} = newGroupSlice.actions
