import { InfoResultType } from 'store/types/info'
import { mapMedia } from './TimeLineMapper'
import { mapTimeLineQuestionType, mapAnswerKey } from './TimelineQuestionMapper'
import { AboutMeResultType } from 'store/types/about-me'

const QUESTION_RESULT = 0
const SERIES_RESULT = 1
const USER_RESULT = 2
const COURSE_RESULT = 3

const mapResultType = (result: AboutMeResultType) => {
  switch (result.resultType) {
    case SERIES_RESULT:
      return InfoResultType.Series
    case QUESTION_RESULT:
      return InfoResultType.Question
    case USER_RESULT:
      return InfoResultType.UserResult
    case COURSE_RESULT:
      return InfoResultType.CourseResult
    default:
      return QUESTION_RESULT
  }
}

const mapData = (result: AboutMeResultType) => {
  switch (result.resultType) {
    case QUESTION_RESULT:
      return {
        id: result.id,
        text: result.text,
        title: result.title,
        questionType: mapTimeLineQuestionType(result.type),
        date: result.date,
        min: result.min,
        max: result.max,
        media: mapMedia(result.attachment),
        isSeriesQuestion: result.isSeriesQuestion,
        variants: result.resultOptions.map((option) => ({
          id: '',
          key: mapAnswerKey(result.type, option.key.toString()),
          value: option.value,
          percent: option.percent,
        })),
        userName: null,
      }
    case SERIES_RESULT:
      return {
        media: mapMedia(result.attachment),
        header: result.text,
        title: result.title,
        date: result.date,
        id: result.id,
        isSeriesQuestion: result.isSeriesQuestion,
        resultOptions: result.resultOptions.map((option) => ({
          characteristic: option.value.toString(),
          percent: option.percent,
        })),
        userName: null,
      }
    case COURSE_RESULT:
      return {
        media: mapMedia(result.attachment),
        header: result.text,
        title: result.title,
        date: result.date,
        id: result.id,
        points: result.points,
        userSeriesVersionId: result.userSeriesVersionId,
        hasReport: result.hasReport,
        resultOptions: result.resultOptions.map((option) => ({
          characteristic: option.value.toString(),
          percent: option.percent,
        })),
        userName: null,
      }
    case USER_RESULT:
      if (result.type === 4) {
        return {
          id: result.id,
          questionId: result.id,
          text: result.text,
          questionType: mapTimeLineQuestionType(result.type),
          date: result.date,
          min: result.min,
          max: result.max,
          isSaved: true,
          answer: result.answer,
          media: mapMedia(result.attachment),
          concerning: result.concerning,
          isSeriesQuestion: result.isSeriesQuestion,
          value: 0,
          variants: result.resultOptions.map((option) => ({
            id: Math.random().toString(),
            key: option.key.toString(),
            value: option.value,
            percent: 0,
          })),
          userName: null,
          answered: true,
        }
      }
      return {
        id: result.id,
        questionId: result.id,
        text: result.text,
        questionType: mapTimeLineQuestionType(result.type),
        date: result.date,
        answer: {
          ...result.answer,
          key: mapAnswerKey(result.type, result.answer.key),
        },
        media: mapMedia(result.attachment),
        variants: result.resultOptions.map((option) => ({
          id: Math.random().toString(),
          key: mapAnswerKey(result.type, option.key.toString()),
          value: option.value,
        })),
        userName: null,
        value: null,
        isSaved: true,
        answered: true,
      }
    default:
      throw new Error('render about me items error')
  }
}

export const mapResults = (results: AboutMeResultType[]): AboutResultsDataType[] => {
  return results.map((result) => {
    return {
      date: result.date,
      resultType: mapResultType(result),
      data: mapData(result),
      id: result.id,
    }
  })
}

export interface AboutResultsDataType {
  date: string
  resultType: number
  data: ReturnType<typeof mapData>
  id: string
}
