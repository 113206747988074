export enum PostCategoryItemType {
  Link,
  Registration = 2,
  AnotherTime,
  NotInterested,
  ChooseOption,
  StillCanJoin,
  NoOptionWorks,
  FullyBooked,
  IAmParticipant,
  NotPossibleToParticipate,
}

export enum PostCategoryType {
  Invite,
  Reminder,
  Confirmation,
  ChangeOfPlanning,
  Cancellation,
  InviteReminder,
}
