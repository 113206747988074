import { authInstanceOrg } from 'api/axios.config'
import { activePageMapper, detailsMapper } from 'api/mappers/TimelineEpisodeMapper'
import {
  ActivePageResponse,
  CompleteActivePageResponse,
  CourseDetailsResponse,
  EpisodePrivacySettingsResponse,
  RestartEpisodeResponse,
} from './types/response'

export class EpisodeApi {
  static getEpisodeDetailsRequest = async (seriesVersionId: string) => {
    const { data } = await authInstanceOrg.get<CourseDetailsResponse>(
      `/userEpisode/courseDetails/${seriesVersionId}`
    )

    return {
      episodeDetails: data.episodes.map((episode) => detailsMapper(episode)),
      isCourseCompleted: data.isCourseCompleted,
    }
  }

  static startEpisodeRequest = async (id: string) => {
    authInstanceOrg.put<void>(`userEpisode/startEpisode/${id}`, { userEpisodeId: id })
  }

  static episodePrivacySettingsRequest = async (timelineItemId: string) => {
    return authInstanceOrg.get<EpisodePrivacySettingsResponse>(
      `userEpisode/episodePrivacySettings?timeLineItemId=${timelineItemId}`
    )
  }

  static episodePrivacySettingsMeOtherRequest = async (userSeriesVersionId: string) => {
    return authInstanceOrg.get<EpisodePrivacySettingsResponse>(
      `userEpisode/episodePrivacySettings?userSeriesVersionId=${userSeriesVersionId}`
    )
  }

  static restartEpisodeRequest = async (id: string) => {
    return authInstanceOrg.put<RestartEpisodeResponse>(`userEpisode/restartEpisode/${id}`, {
      userEpisodeId: id,
    })
  }

  static getActivePageRequest = async (id: string, currentPageId?: string) => {
    let pageData = {} as ActivePageResponse

    if (currentPageId) {
      const { data } = await authInstanceOrg.get<ActivePageResponse>(
        `userEpisode/getActivePage/${id}/${currentPageId}`
      )

      pageData = data
    }

    if (!currentPageId) {
      const { data } = await authInstanceOrg.get<ActivePageResponse>(
        `userEpisode/getActivePage/${id}`
      )

      pageData = data
    }

    return activePageMapper(pageData)
  }

  static completeActivePageRequest = async (id: string) => {
    const { data } = await authInstanceOrg.put<CompleteActivePageResponse>(
      `userEpisode/completeActivePage/${id}`,
      {
        userEpisodeId: id,
      }
    )

    return data
  }

  static saveProgressRequest = async (
    params: {
      questionId: string
      progress: number
    }[]
  ) => {
    await authInstanceOrg.post<void>(`timeLine/questionProgress`, params)
  }

  static getPreviousPageRequest = async (userEpisodeId: string, currentPageId: string) => {
    const { data } = await authInstanceOrg.get<ActivePageResponse>(
      `userEpisode/getPreviousPage/${userEpisodeId}/${currentPageId}`
    )

    return activePageMapper(data)
  }

  static getNextPageRequest = async (userEpisodeId: string, currentPageId: string) => {
    const { data } = await authInstanceOrg.get<ActivePageResponse>(
      `userEpisode/getNextPage/${userEpisodeId}/${currentPageId}`
    )

    return activePageMapper(data)
  }

  static answerEpisodeQuestionRequest = async (
    userEpisodeId: string,
    questionId: string,
    questionAnswerOptionId?: string,
    value?: number
  ) => {
    const { data } = await authInstanceOrg.post<{ isSuccess: boolean; isAnswerCorrect: boolean }>(
      'timeLine/sendEpisodeQuestionAnswer',
      {
        questionAnswerOptionId,
        userEpisodeId,
        questionId,
        value,
      }
    )

    return data
  }
}
