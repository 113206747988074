import React from 'react'

export const GroupOutlined = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="67%" height="45%" viewBox="0 0 32.173 22.348">
      <g id="Сгруппировать_2923" data-name="Сгруппировать 2923" transform="translate(0.555 0.4)">
        <circle
          id="Эллипс_46"
          data-name="Эллипс 46"
          cx="4.674"
          cy="4.674"
          r="4.674"
          transform="translate(2.324 6.613)"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="0.8"
        />
        <path
          id="Контур_546"
          data-name="Контур 546"
          d="M3,144.137A8.364,8.364,0,0,1,9.986,140.4h0a8.364,8.364,0,0,1,6.96,3.7"
          transform="translate(-3 -122.743)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="0.8"
        />
        <circle
          id="Эллипс_47"
          data-name="Эллипс 47"
          cx="4.674"
          cy="4.674"
          r="4.674"
          transform="translate(19.442 6.613)"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="0.8"
        />
        <path
          id="Контур_547"
          data-name="Контур 547"
          d="M136.3,144.137a8.364,8.364,0,0,1,6.986-3.737h0a8.364,8.364,0,0,1,6.96,3.7"
          transform="translate(-119.183 -122.743)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="0.8"
        />
        <circle
          id="Эллипс_48"
          data-name="Эллипс 48"
          cx="4.674"
          cy="4.674"
          r="4.674"
          transform="translate(10.581)"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="0.8"
        />
        <path
          id="Контур_548"
          data-name="Контур 548"
          d="M102.5,89.336a8.658,8.658,0,0,1,2.466-.475h0A8.461,8.461,0,0,1,108,89.3"
          transform="translate(-89.723 -77.817)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="0.8"
        />
      </g>
    </svg>
  )
}
