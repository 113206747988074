import React from 'react'

export const HexArrowButton = ({
  startColor,
  endColor,
  arrowColor,
  isReversed = false,
}: {
  startColor: string
  endColor: string
  arrowColor: string
  isReversed?: boolean
}) => {
  if (isReversed) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="57.607"
        height="62.16"
        viewBox="0 0 57.607 62.16"
      >
        <defs>
          <linearGradient
            id="linear-gradient-hex-arrow"
            x1="0.5"
            x2="0.5"
            y2="1"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor={startColor} />
            <stop offset="1" stopColor={endColor} />
          </linearGradient>
          <filter
            id="Контур_154"
            x="0"
            y="0"
            width="57.607"
            height="62.16"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="3" result="blur" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <g
          id="Сгруппировать_4573"
          data-name="Сгруппировать 4573"
          transform="translate(-101.39 -881.105)"
        >
          <g transform="matrix(1, 0, 0, 1, 101.39, 881.11)" filter="url(#Контур_154)">
            <path
              id="Контур_154-2"
              data-name="Контур 154"
              d="M36.354,12.917l9.9,5.923a10.272,10.272,0,0,1,4.951,8.841V39.528a10.164,10.164,0,0,1-4.951,8.841l-9.9,5.923a9.5,9.5,0,0,1-9.9,0l-9.9-5.923A10.272,10.272,0,0,1,11.6,39.528V27.682a10.164,10.164,0,0,1,4.951-8.841l9.9-5.923A9.5,9.5,0,0,1,36.354,12.917Z"
              transform="translate(-2.6 -5.53)"
              fill="url(#linear-gradient-hex-arrow)"
            />
          </g>
          <path
            id="Контур_252"
            data-name="Контур 252"
            d="M0,8,1.41,6.59,7,12.17V0H9V12.17l5.58-5.59L16,8,8,16Z"
            transform="translate(137.997 900.779) rotate(90)"
            fill={arrowColor}
          />
        </g>
      </svg>
    )
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="57.607"
      height="62.16"
      viewBox="0 0 57.607 62.16"
    >
      <defs>
        <linearGradient
          id="linear-gradient-hex-arrow"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor={startColor} />
          <stop offset="1" stopColor={endColor} />
        </linearGradient>
        <clipPath id="clip-path">
          <path
            id="Контур_153"
            data-name="Контур 153"
            d="M36.354,12.917l9.9,5.923a10.272,10.272,0,0,1,4.951,8.841V39.528a10.164,10.164,0,0,1-4.951,8.841l-9.9,5.923a9.5,9.5,0,0,1-9.9,0l-9.9-5.923A10.272,10.272,0,0,1,11.6,39.528V27.682a10.164,10.164,0,0,1,4.951-8.841l9.9-5.923A9.5,9.5,0,0,1,36.354,12.917Z"
            transform="translate(-11.6 -11.525)"
            fill="url(#linear-gradient-hex-arrow)"
          />
        </clipPath>
        <filter
          id="Контур_154"
          x="0"
          y="0"
          width="57.607"
          height="62.16"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Сгруппировать_4572"
        data-name="Сгруппировать 4572"
        transform="translate(-213.391 -881.105)"
      >
        <g
          id="Сгруппировать_274"
          data-name="Сгруппировать 274"
          transform="translate(222.391 887.105)"
        >
          <g
            id="Сгруппировать_48"
            data-name="Сгруппировать 48"
            transform="translate(0 0)"
            clipPath="url(#clip-path)"
          >
            <rect
              id="Прямоугольник_5"
              data-name="Прямоугольник 5"
              width="48.625"
              height="53.045"
              transform="translate(-4.509 -4.443)"
              fill="url(#linear-gradient-hex-arrow)"
            />
          </g>
          <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Контур_154)">
            <path
              id="Контур_154-2"
              data-name="Контур 154"
              d="M36.354,12.917l9.9,5.923a10.272,10.272,0,0,1,4.951,8.841V39.528a10.164,10.164,0,0,1-4.951,8.841l-9.9,5.923a9.5,9.5,0,0,1-9.9,0l-9.9-5.923A10.272,10.272,0,0,1,11.6,39.528V27.682a10.164,10.164,0,0,1,4.951-8.841l9.9-5.923A9.5,9.5,0,0,1,36.354,12.917Z"
              transform="translate(-2.6 -5.53)"
              fill="url(#linear-gradient-hex-arrow)"
            />
          </g>
        </g>
        <path
          id="Контур_7683"
          data-name="Контур 7683"
          d="M0,8,1.41,9.41,7,3.83V16H9V3.83l5.58,5.59L16,8,8,0Z"
          transform="translate(249.997 900.779) rotate(90)"
          fill={arrowColor}
        />
      </g>
    </svg>
  )
}
