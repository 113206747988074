import React, { CSSProperties } from 'react'

export const ArrowSvg = ({
  color,
  opacity = '1',
  style = {},
}: {
  color: string
  opacity?: string
  style?: CSSProperties
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="15"
    height="7.5"
    viewBox="0 0 15 7.5"
    style={{ ...style, transform: style.transform ?? 'scale(1.2)' }}
  >
    <path
      id="Контур_201"
      data-name="Контур 201"
      d="M7,10l7.5,7.5L22,10Z"
      transform="translate(-7 -10)"
      fill={color}
      opacity={opacity}
    />
  </svg>
)
