import { RootState } from 'store'
import { createSelector } from '@reduxjs/toolkit'

const newGroupRegistrationState = (state: RootState) => state.newGroupRegistration

export const selectSettings = createSelector(newGroupRegistrationState, (state) => state.settings)

export const selectName = createSelector(newGroupRegistrationState, (state) => state.name)
export const selectEmail = createSelector(newGroupRegistrationState, (state) => state.email)
export const selectPhoneNumber = createSelector(
  newGroupRegistrationState,
  (state) => state.phoneNumber
)
export const selectResponseType = createSelector(
  newGroupRegistrationState,
  (state) => state.responseType
)
export const selectShowModal = createSelector(newGroupRegistrationState, (state) => state.showModal)
export const selectConfirmNameChange = createSelector(
  newGroupRegistrationState,
  (state) => state.confirmNameChange
)
export const selectIsLoading = (state: RootState) => state.newGroupRegistration.isLoading
