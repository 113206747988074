/*eslint-disable no-case-declarations*/
import React from 'react'
import { ImageMedia, Media, VideoFromDeviceMedia, VideoMedia } from 'store/types'
import styled from 'styled-components/macro'
import Player from 'modules/common/components/Player'
import { MediaType } from 'store/types/timelineMedia'

interface IProps {
  media: Media | VideoMedia | VideoFromDeviceMedia | ImageMedia | null
  hasControls?: boolean
  hasVideoProgress?: boolean
  itemId: string
  toggleOverlay?: (isShown: boolean) => void
  isPage?: boolean
}

export const MediaContent: React.FC<IProps> = ({
  media,
  itemId,
  toggleOverlay,
  isPage = false,
  hasVideoProgress = false,
  hasControls = true,
}) => {
  if (!media) return null

  const getMediaContent = (type: number) => {
    switch (type) {
      case MediaType.YOUTUBE_VIDEO:
        const videoSource = media as VideoMedia
        return (
          <Player
            src={`https://www.youtube.com/watch?v=${videoSource.id}`}
            itemId={itemId}
            thumbnail={null}
            subtitles={null}
            hasControls={hasControls}
            toggleOverlay={toggleOverlay}
            isPage={isPage}
            hasVideoProgress={false}
          />
        )

      case MediaType.IMAGE:
        const imageSource = media as ImageMedia
        return <Image src={imageSource.source} />

      case MediaType.GIPHY:
        const giphySource = media as ImageMedia
        return <Image src={giphySource.source} />

      case MediaType.VIDEO_FROM_DEVICE:
        const videoFromDeviceSource = media as VideoFromDeviceMedia

        return (
          <Player
            src={videoFromDeviceSource.source}
            itemId={itemId}
            thumbnail={videoFromDeviceSource.thumbnail}
            subtitles={videoFromDeviceSource.subtitlesUrl}
            hasControls={hasControls}
            toggleOverlay={toggleOverlay}
            isPage={isPage}
            hasVideoProgress={hasVideoProgress}
          />
        )
      default:
        return null
    }
  }

  return <MediaWrapper>{getMediaContent(media.type)}</MediaWrapper>
}

const MediaWrapper = styled.div`
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 22px;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  height: 215px;
  border-radius: 15px;
  object-fit: cover;
`
