import React from 'react'

export const BackBtnHex = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="47.598"
      height="51"
      viewBox="0 0 47.598 51"
    >
      <defs>
        <filter id="Path_7690" x="0" y="0" width="47.598" height="51" filterUnits="userSpaceOnUse">
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Component_2_1" data-name="Component 2 – 1" transform="translate(9 6)">
        <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Path_7690)">
          <path
            id="Path_7690-2"
            data-name="Path 7690"
            d="M30.1,43.484l7.4-4.426a7.676,7.676,0,0,0,3.7-6.607V23.6a7.6,7.6,0,0,0-3.7-6.607l-7.4-4.426a7.1,7.1,0,0,0-7.4,0l-7.4,4.426A7.676,7.676,0,0,0,11.6,23.6v8.853a7.6,7.6,0,0,0,3.7,6.607l7.4,4.426A7.1,7.1,0,0,0,30.1,43.484Z"
            transform="translate(-2.6 -5.53)"
            fill="rgba(0,0,0,0.5)"
          />
        </g>
        <path
          id="Path_7691"
          data-name="Path 7691"
          d="M0,0H24V24H0Z"
          transform="translate(2.602 28.906) rotate(-90)"
          fill="none"
        />
        <path
          id="Path_7692"
          data-name="Path 7692"
          d="M0,8,1.41,9.41,7,3.83V16H9V3.83l5.58,5.59L16,8,8,0Z"
          transform="translate(6.602 24.906) rotate(-90)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}
