import React from 'react'
import { Lottie } from '@crello/react-lottie'
import { animationData } from 'modules/common/components/Loader/animationData'
import styled from 'styled-components/macro'
import { useBrandingColors } from 'hooks/useBrandingColors'

const MediaLoader = () => {
  const { backgroundStart, backgroundEnd } = useBrandingColors()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <MediaLoaderWrapper backgroundStart={backgroundStart} backgroundEnd={backgroundEnd}>
      <Lottie config={defaultOptions} height="60px" width="60px" style={{ margin: '0 auto' }} />
    </MediaLoaderWrapper>
  )
}

export default MediaLoader

const MediaLoaderWrapper = styled.div<{ backgroundStart: string; backgroundEnd: string }>`
  height: 215px;
  border-radius: 15px;
  border: 1px solid #fff;
  position: relative;
  background: ${({ backgroundStart, backgroundEnd }) =>
    `linear-gradient(to bottom, ${backgroundStart}, ${backgroundEnd})`};
  display: flex;
  justify-content: center;
  align-items: center;
`
