import React from 'react'

export const EpisodeTopMenu = (props: { logo: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 0 370 151.147"
    >
      <defs>
        <clipPath id="shape-menu-top">
          <path
            id="Контур_8788-2"
            data-name="Контур 8788"
            d="M-2022-4119.063v-109.671h370v109.671s-157.773,23.476-191.206,23.476S-2022-4119.063-2022-4119.063Z"
            transform="translate(2022 4226.73)"
            fill="none"
          />
        </clipPath>

        <filter id="filter-shadow" x="0" y="0" filterUnits="userSpaceOnUse">
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>

      <g filter="url(#filter-shadow)">
        <g clipPath="url(#shape-menu-top)">
          {props.logo ? (
            <image
              href={props.logo}
              id="logo-top"
              x="0"
              y="0"
              width="100%"
              height="100%"
              preserveAspectRatio="xMidYMid slice"
            />
          ) : (
            <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
          )}
        </g>
      </g>
      <use xlinkHref="#menu-shape" />
    </svg>
  )
}
