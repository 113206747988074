import React from 'react'

export const StarSvg = (props: { isActive?: boolean }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="39"
      height="39"
      viewBox="0 0 39 39"
    >
      <defs>
        <filter id="Эллипс_216" x="0" y="0" width="39" height="39" filterUnits="userSpaceOnUse">
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Сгруппировать_2947" data-name="Сгруппировать 2947" transform="translate(-168 -530)">
        <g id="Сгруппировать_2259" data-name="Сгруппировать 2259" transform="translate(-6225 2821)">
          <g
            id="Сгруппировать_2064"
            data-name="Сгруппировать 2064"
            transform="translate(-1317 455)"
          >
            <g transform="matrix(1, 0, 0, 1, 7710, -2746)" filter="url(#Эллипс_216)">
              <circle
                id="Эллипс_216-2"
                data-name="Эллипс 216"
                cx="10.5"
                cy="10.5"
                r="10.5"
                transform="translate(9 6)"
                fill={props.isActive ? '#f2d814' : '#fff'}
              />
            </g>
          </g>
        </g>
        <path
          id="Контур_7293"
          data-name="Контур 7293"
          d="M50.853,45.462l2.809.4a.972.972,0,0,1,.54,1.656l-2.03,1.983a.972.972,0,0,0-.278.86l.483,2.8a.971.971,0,0,1-1.408,1.025l-2.514-1.318a.972.972,0,0,0-.9,0L45.04,54.2a.971.971,0,0,1-1.41-1.023l.476-2.8a.971.971,0,0,0-.281-.859l-2.035-1.979a.971.971,0,0,1,.537-1.657l2.808-.411a.971.971,0,0,0,.731-.532L47.12,42.39a.971.971,0,0,1,1.742,0l1.259,2.544A.973.973,0,0,0,50.853,45.462Z"
          transform="translate(139.504 498.152)"
          fill={props.isActive ? '#fff' : '#707070'}
          opacity={props.isActive ? 1 : '0.204'}
        />
      </g>
    </svg>
  )
}
