// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import {
  getPosts,
  // getMorePosts,
} from './thunks'
import { GetPostsResponse } from 'api/posts/types/response'

interface PostsState {
  journeys: GetPostsResponse | null
  success: boolean
  error: SerializedError | null
  isLoading: boolean
  loadingJourneyId: string
  isMoreItemsLoading: boolean
}

const initialState: PostsState = {
  journeys: null,
  success: false,
  error: null,
  isLoading: false,
  loadingJourneyId: '',
  isMoreItemsLoading: false,
}

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    resetState: (state: PostsState) => {
      state.journeys = initialState.journeys
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPosts.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getPosts.rejected, (state, action) => {
      state.isLoading = false
      if (action.payload) {
        state.error = {
          message: action.payload.message,
        }
      }
    })
    builder.addCase(getPosts.fulfilled, (state, action) => {
      state.isLoading = false
      state.journeys =
        action.meta.arg.page === 1
          ? action.payload
          : {
              ...action.payload,
              data: [...(state.journeys?.data || []), ...action.payload.data],
            }
    })
  },
})

export const postsReducer = postsSlice.reducer
export const { resetState } = postsSlice.actions
