import React from 'react'
import styled from 'styled-components/macro'
import { useBrandingColors } from 'hooks/useBrandingColors'
import HexagonBackground from 'layouts/HexagonsBackground'
import { getScaledValue } from 'utils/scale'
import { TextCalibriBold } from 'modules/common/components/Typography'
import { ITheme } from 'styles/types'

const SentMessage = ({ text }) => {
  const { backgroundStart, backgroundEnd } = useBrandingColors()

  return (
    <SentWrapper startColor={backgroundStart} stopColor={backgroundEnd}>
      <HexagonBackground>
        <SentText>{text}</SentText>
      </HexagonBackground>
    </SentWrapper>
  )
}

export default SentMessage

const SentText = styled(TextCalibriBold)`
  font-size: ${getScaledValue(30, 'px')};
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  position: absolute;

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    font-size: 30px;
  }
`

const SentWrapper = styled.div<{
  startColor: string
  stopColor: string
}>`
  ${({ startColor, stopColor }) => {
    if (startColor && stopColor) {
      return `background: linear-gradient(to bottom, ${startColor}, ${stopColor})`
    }
  }};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
`
