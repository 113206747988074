import React, { useEffect, lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { AuthRoutes, AppRoutes } from './routesConfig'
import { WaitingComponent } from './waitingComponent'
import { PrivateRoute } from './privateRoute'
import { preloadUserData } from 'store/slices/UI/thunks'
import { useAppDispatch } from 'store'
import { getCurrentUser } from 'modules/common/helpers'
import Loader from 'modules/common/components/Loader'

const EmailContainer = lazy(() => import('modules/auth/containers/Email'))

const AccessCodeContainer = lazy(() => import('modules/auth/containers/AccessCode'))
const CreatePasswordContainer = lazy(() => import('modules/auth/containers/CreatePassword'))
const PrivacyPolicyContainer = lazy(() => import('modules/auth/containers/PrivacyPolicy'))
const CheckPasswordContainer = lazy(() => import('modules/auth/containers/CheckPassword'))
const PhoneContainer = lazy(() => import('modules/auth/containers/Phone'))
const LanguageContainer = lazy(() => import('modules/auth/containers/Language'))
const UserLogoContainer = lazy(() => import('modules/auth/containers/UserLogo'))
const OrganizationsContainer = lazy(() => import('modules/auth/containers/Organizations'))
const ForgotPasswordContainer = lazy(() => import('modules/auth/containers/ForgotPassword'))

const Timeline = lazy(() => import('modules/timeline/components/Timeline/Timeline'))
const SubEpisode = lazy(() => import('modules/episode/SubEpisode'))
const OpenEpisode = lazy(() => import('modules/episode/OpenEpisode'))
const EpisodePage = lazy(() => import('modules/episode/EpisodePage'))
const EpisodeResult = lazy(() => import('modules/episode/EpisodePage/EpisodeResults/Result'))
const EpisodePrivacy = lazy(() => import('modules/timeline/components/EpisodePrivacySettings'))
const QuestionPrivacy = lazy(() => import('modules/timeline/components/QuestionPrivacySettings'))
const ResultPrivacy = lazy(() => import('modules/timeline/components/ResultPrivacySettings'))
const AboutMe = lazy(() => import('modules/about-user/about-me/AboutMe'))
const AboutOthers = lazy(() => import('modules/about-user/about-others/AboutOthers'))
const Organization = lazy(() => import('modules/organization'))
const ChangePassword = lazy(() => import('modules/change-password'))
const ItemDetails = lazy(() => import('modules/item-details'))
const MentionChat = lazy(() => import('modules/mention-chat'))
const AddComment = lazy(() => import('modules/create-mention/AddComment'))
const ComplaintPrivacySettings = lazy(
  () => import('modules/create-mention/components/ComplaintPrivacySettings')
)
const MentionPrivacySettings = lazy(
  () => import('modules/create-mention/components/MentionPrivacySettings')
)
const ComplaintChatPrivacySettings = lazy(
  () => import('modules/mention-chat/components/ComplaintChatPrivacySettings')
)
const MentionChatPrivacySettings = lazy(
  () => import('modules/mention-chat/components/MentionChatPrivacySettings')
)
const ThankYouPage = lazy(() => import('modules/common/components/ThankYouPage'))

const AddSuggestion = lazy(() => import('modules/create-mention/AddSuggestion'))
const AddCompliment = lazy(() => import('modules/create-mention/AddCompliment'))
const AddComplaint = lazy(() => import('modules/create-mention/AddComplaint'))
const AppSearch = lazy(() => import('modules/search'))
const Calendar = lazy(() => import('modules/calendar'))
const Posts = lazy(() => import('modules/posts/AllPosts'))
const PostEdit = lazy(() => import('modules/posts/PostEdit'))
const MyCourses = lazy(() => import('modules/my-courses'))
const AboutOthersUserSearch = lazy(() => import('modules/about-user/about-others/UserSearch'))

const NewGroup = lazy(() => import('modules/new-group'))
const NewGroupRegistration = lazy(() => import('modules/new-group-registration'))
const CredentialChangePasswordCheck = lazy(
  () => import('modules/password-check/CredentialChangePasswordCheck')
)
const NewGroupRegistrationLink = lazy(() => import('modules/new-group-registration-link'))

export const AppRouter = () => {
  const dispatch = useAppDispatch()
  const user = getCurrentUser()

  useEffect(() => {
    if (!user) {
      dispatch(preloadUserData())
    }
  }, [])

  return (
    <Suspense fallback={<Loader isLoading />}>
      <Switch>
        <Route
          exact
          path={AppRoutes.NEW_GROUP_REGISTRATION_LINK}
          component={WaitingComponent(NewGroupRegistrationLink)}
        />
        <Route
          exact
          path={AppRoutes.CHECK_PASSWORD_CREDENTIAL_CHANGE}
          component={WaitingComponent(CredentialChangePasswordCheck)}
        />
        <Route exact path={AuthRoutes.EMAIL} component={WaitingComponent(EmailContainer)} />
        <Route exact path={AuthRoutes.PHONE} component={WaitingComponent(PhoneContainer)} />
        <Route
          exact
          path={AuthRoutes.CHECK_PASSWORD}
          component={WaitingComponent(CheckPasswordContainer)}
        />
        <Route exact path={AuthRoutes.LANGUAGE} component={WaitingComponent(LanguageContainer)} />
        <Route
          exact
          path={AuthRoutes.ACCESS_CODE}
          component={WaitingComponent(AccessCodeContainer)}
        />
        <Route
          exact
          path={AuthRoutes.PASSWORD}
          component={WaitingComponent(CreatePasswordContainer)}
        />
        <Route
          exact
          path={AuthRoutes.PRIVACY_POLICY}
          component={WaitingComponent(PrivacyPolicyContainer)}
        />
        <Route exact path={AuthRoutes.LOGO} component={WaitingComponent(UserLogoContainer)} />
        <Route
          exact
          path={AppRoutes.ORGANIZATIONS}
          component={WaitingComponent(OrganizationsContainer)}
        />

        <Route
          exact
          path={AppRoutes.FORGOT_PASSWORD}
          component={WaitingComponent(ForgotPasswordContainer)}
        />
        <Route
          exact
          path={AppRoutes.NEW_GROUP_REGISTRATION}
          component={WaitingComponent(NewGroupRegistration)}
        />
        <PrivateRoute exact path={AppRoutes.TIMELINE} component={WaitingComponent(Timeline)} />
        <PrivateRoute
          exact
          path={AppRoutes.ADD_SUGGESTION}
          component={WaitingComponent(AddSuggestion)}
        />
        <PrivateRoute
          exact
          path={AppRoutes.ADD_COMPLAINT}
          component={WaitingComponent(AddComplaint)}
        />
        <PrivateRoute
          exact
          path={AppRoutes.ADD_COMPLIMENT}
          component={WaitingComponent(AddCompliment)}
        />
        <PrivateRoute exact path={AppRoutes.SUB_EPISODE} component={WaitingComponent(SubEpisode)} />
        <PrivateRoute
          exact
          path={AppRoutes.OPEN_EPISODE}
          component={WaitingComponent(OpenEpisode)}
        />
        <PrivateRoute
          exact
          path={AppRoutes.EPISODE_PAGE}
          component={WaitingComponent(EpisodePage)}
        />
        <PrivateRoute
          exact
          path={AppRoutes.ITEM_DETAILS}
          component={WaitingComponent(ItemDetails)}
        />
        <PrivateRoute exact path={AppRoutes.ADD_COMMENT} component={WaitingComponent(AddComment)} />
        <PrivateRoute
          exact
          path={AppRoutes.EPISODE_RESULT}
          component={WaitingComponent(EpisodeResult)}
        />
        <PrivateRoute
          exact
          path={AppRoutes.EPISODE_PRIVACY}
          component={WaitingComponent(EpisodePrivacy)}
        />
        <PrivateRoute
          exact
          path={AppRoutes.QUESTION_PRIVACY}
          component={WaitingComponent(QuestionPrivacy)}
        />
        <PrivateRoute
          exact
          path={AppRoutes.RESULT_PRIVACY}
          component={WaitingComponent(ResultPrivacy)}
        />
        <PrivateRoute exact path={AppRoutes.ABOUT_ME} component={WaitingComponent(AboutMe)} />
        <PrivateRoute
          exact
          path={AppRoutes.ABOUT_OTHERS}
          component={WaitingComponent(AboutOthers)}
        />

        <PrivateRoute
          exact
          path={AppRoutes.ORGANIZATION}
          component={WaitingComponent(Organization)}
        />
        <PrivateRoute
          exact
          path={AppRoutes.CHANGE_PASSWORD}
          component={WaitingComponent(ChangePassword)}
        />
        <PrivateRoute
          exact
          path={AppRoutes.MENTION_CHAT}
          component={WaitingComponent(MentionChat)}
        />
        <PrivateRoute
          exact
          path={AppRoutes.COMPLAINT_PRIVACY}
          component={WaitingComponent(ComplaintPrivacySettings)}
        />
        <PrivateRoute
          exact
          path={AppRoutes.MENTION_PRIVACY}
          component={WaitingComponent(MentionPrivacySettings)}
        />
        <PrivateRoute
          exact
          path={AppRoutes.COMPLAINT_CHAT_PRIVACY}
          component={WaitingComponent(ComplaintChatPrivacySettings)}
        />
        <PrivateRoute
          exact
          path={AppRoutes.MENTION_CHAT_PRIVACY}
          component={WaitingComponent(MentionChatPrivacySettings)}
        />
        <PrivateRoute exact path={AppRoutes.SEARCH} component={WaitingComponent(AppSearch)} />
        <PrivateRoute exact path={AppRoutes.CALENDAR} component={WaitingComponent(Calendar)} />
        <PrivateRoute exact path={AppRoutes.POSTS} component={WaitingComponent(Posts)} />
        <PrivateRoute exact path={AppRoutes.POST_EDIT} component={WaitingComponent(PostEdit)} />
        <PrivateRoute exact path={AppRoutes.MY_COURSES} component={WaitingComponent(MyCourses)} />
        <PrivateRoute exact path={AppRoutes.THANK_YOU} component={WaitingComponent(ThankYouPage)} />
        <PrivateRoute
          exact
          path={AppRoutes.ABOUT_OTHERS_USER_SEARCH}
          component={WaitingComponent(AboutOthersUserSearch)}
        />
        <PrivateRoute exact path={AppRoutes.NEW_GROUPS} component={WaitingComponent(NewGroup)} />
        <Redirect path="*" to={AuthRoutes.EMAIL} />
      </Switch>
    </Suspense>
  )
}
