import React from 'react'

export const TrashBinOutlined = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="73.378"
      height="79.056"
      viewBox="0 0 73.378 79.056"
    >
      <defs>
        <filter
          id="Контур_4718"
          x="0"
          y="0"
          width="73.378"
          height="79.056"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Контур_189"
          x="18.332"
          y="18.432"
          width="36.762"
          height="42.123"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur-2" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-2" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Сгруппировать_1714"
        data-name="Сгруппировать 1714"
        transform="translate(-825.952 -631.99)"
      >
        <g transform="matrix(1, 0, 0, 1, 825.95, 631.99)" filter="url(#Контур_4718)">
          <path
            id="Контур_4718-2"
            data-name="Контур 4718"
            d="M0,29.388c.155-3.343.078-6.608.466-9.951A12.629,12.629,0,0,1,6.608,9.876c4.276-2.721,8.707-5.209,13.06-7.774,4.82-2.876,9.64-2.721,14.46.078C38.015,4.511,41.9,6.844,45.789,9.1c4.587,2.721,7.308,6.686,7.463,12.05.155,5.986.078,12.05-.233,18.036a11.667,11.667,0,0,1-5.364,9.173c-4.9,3.187-9.951,6.219-15.082,9.1a11.832,11.832,0,0,1-11.894-.078c-4.742-2.643-9.329-5.442-13.916-8.318-4.431-2.8-6.452-7-6.608-12.128-.078-2.488,0-4.975,0-7.541Z"
            transform="translate(10.05 7)"
            fill="none"
            stroke="#fff"
            strokeWidth="2"
          />
        </g>
        <g id="baseline-delete-24px" transform="translate(846.583 652.401)">
          <g transform="matrix(1, 0, 0, 1, -20.63, -20.41)" filter="url(#Контур_189)">
            <path
              id="Контур_189-2"
              data-name="Контур 189"
              d="M6.34,24.442a2.688,2.688,0,0,0,2.68,2.68H19.742a2.688,2.688,0,0,0,2.68-2.68V8.361H6.34ZM23.762,4.34H19.072L17.731,3h-6.7L9.691,4.34H5V7.02H23.762Z"
              transform="translate(22.33 21.43)"
              fill="#fff"
            />
          </g>
          <path id="Контур_190" data-name="Контур 190" d="M0,0H32.164V32.164H0Z" fill="none" />
        </g>
      </g>
    </svg>
  )
}
