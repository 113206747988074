import {
  createSlice,
  isFulfilled,
  isRejectedWithValue,
  isPending,
  PayloadAction,
} from '@reduxjs/toolkit'
import { getEpisodeDetails, getCourseDetails } from './thunks'
import { ItemDetailsStateType } from './types'

const initialState: ItemDetailsStateType = {
  expandedItemId: '',
  details: [],
  isCourseCompleted: false,
  dates: [],
  loadingItemId: null,
  error: null,
  success: false,
}

const itemDetailsSlice = createSlice({
  name: 'item-details',
  initialState,
  reducers: {
    setExpandedItemId: (state, action: PayloadAction<string>) => {
      state.expandedItemId = action.payload
    },

    collapseItem: (state) => {
      state.expandedItemId = initialState.expandedItemId
    },

    setLoadingItemId: (state, action: PayloadAction<string>) => {
      state.loadingItemId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEpisodeDetails.fulfilled, (state, action) => {
      state.details = action.payload.episodeDetails
      state.isCourseCompleted = action.payload.isCourseCompleted
    })

    builder.addCase(getCourseDetails.fulfilled, (state, action) => {
      state.details = action.payload.episodesDetails
      state.dates = action.payload.episodeDates
    })

    builder.addMatcher(isFulfilled(getEpisodeDetails, getCourseDetails), (state) => {
      state.success = true
      state.error = null
      state.loadingItemId = null
    })

    builder.addMatcher(isPending(getEpisodeDetails, getCourseDetails), (state) => {
      state.error = null
      state.success = false
    })
    builder.addMatcher(
      isRejectedWithValue(getEpisodeDetails, getCourseDetails),
      (state, action) => {
        state.error = {
          message: action.payload.message,
        }
        state.loadingItemId = null
        state.success = false
      }
    )
  },
})

export const itemDetailsReducer = itemDetailsSlice.reducer
export const { setExpandedItemId, collapseItem, setLoadingItemId } = itemDetailsSlice.actions
