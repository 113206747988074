import React from 'react'

const scaleFactor = 1.2

export const SubTabHex = ({ isActive }: { isActive: boolean }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="98.564"
      height="23.522"
      viewBox="0 0 98.564 23.522"
      style={{ transform: `scale(${scaleFactor})` }}
    >
      <path
        id="Контур_7383"
        data-name="Контур 7383"
        d="M23.793-230.665v-84.657a3.848,3.848,0,0,0-2.559-3.387l-6.554-2.908a6.423,6.423,0,0,0-5.1,0l-6.554,2.908A3.883,3.883,0,0,0,.47-315.322v84.641a3.848,3.848,0,0,0,2.559,3.387L9.6-224.386a6.424,6.424,0,0,0,5.1,0l6.574-2.908A3.832,3.832,0,0,0,23.793-230.665Z"
        transform="translate(322.283 23.893) rotate(-90)"
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.2"
        opacity={isActive ? '0.3' : '0.2'}
      />
    </svg>
  )
}
