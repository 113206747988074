import { RootState } from 'store'
import { createSelector } from '@reduxjs/toolkit'

const timelineState = (state: RootState) => state.timeline

export const selectTimelineItems = createSelector(timelineState, (state) => state.timelineItems)
export const selectExpandedItemId = createSelector(timelineState, (state) => state.expandedItemId)
export const selectExpandLoadingId = createSelector(timelineState, (state) => state.expandLoadingId)
export const selectAvailableAppSettings = createSelector(
  timelineState,
  (state) => state.availableAppSettings
)
export const selectIsLastPage = createSelector(timelineState, (state) => state.isLastPage)
export const selectQuestionPrivacySettings = createSelector(
  timelineState,
  (state) => state.currentQuestionPrivacySettings
)

export const selectResultPrivacySettings = createSelector(
  timelineState,
  (state) => state.currentResultPrivacySettings
)

export const selectIsLoading = (state: RootState) => state.timeline.isLoading

export const selectIsInitialLoadPending = (state: RootState) => state.timeline.isInitialLoadPending
