import React from 'react'
import { getScaledValue } from 'utils/scale'

export const ConnectLineSvg = (props: { color: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={getScaledValue(81.559)}
      height={getScaledValue(24.565)}
      viewBox="0 0 81.559 24.565"
      style={{ transform: 'scale(1.2)' }}
    >
      <path
        id="Контур_7432"
        data-name="Контур 7432"
        d="M9959.5-1982.5h44.9l36.525,24.107"
        transform="translate(-9959.501 1982.75)"
        fill="none"
        stroke={props.color}
        strokeLinejoin="round"
        strokeWidth="0.5"
      />
    </svg>
  )
}
