import { createGlobalStyle, keyframes } from 'styled-components'

import { ITheme } from 'styles/types/index'

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
    margin: 0;
    scrollbar-width: none;
  }

  body {
    background: ${({ theme }: { theme: ITheme }) => theme.backgroundGradient};
    font-family: 'Calibri', sans-serif;
    font-size: 22px;
    position: fixed;
  }

  textarea{
    resize: none;
    font-family: 'Calibri', sans-serif;
  }
  input{
    font-size: 22px; 
    font-weight: 700;
    color: ${({ theme }: { theme: ITheme }) => theme.text.main};
    line-height: normal;
    font-family: 'Calibri', sans-serif;
    outline: none;
    box-shadow: none;
    text-align: center;
    
    ::placeholder {
        font-family: 'AvenirNextCondensedRegular', sans-serif;
        font-size: 18px; 
        text-align: center;
        color: #fff;
    }
    :focus {
        transition: all 0.15s linear;
    }
    
  }

  ul,li{
    margin: 0;
    padding: 0;
    /* list-style: none; */
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0
  }

  p {
    margin-bottom: 0px;
  }

  button {
    border: none;
    outline: none;
  }

`

export const shake = keyframes`
  0% { transform: translate(1px, 1px) }
  10% { transform: translate(-1px, -2px)  }
  20% { transform: translate(-3px, 0px) }
  30% { transform: translate(3px, 2px) }
  40% { transform: translate(1px, -1px) }
  50% { transform: translate(-1px, 2px) }
  60% { transform: translate(-3px, 1px) }
  70% { transform: translate(3px, 1px) }
  80% { transform: translate(-1px, -1px) }
  90% { transform: translate(1px, 2px) }
  100% { transform: translate(1px, -2px) }
`
