import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const MoodGoodSmall = ({ isActive }: { isActive: boolean }) => {
  const { icon } = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="74.84"
      height="81.564"
      viewBox="0 0 74.84 81.564"
    >
      <defs>
        <filter
          id="Контур_1138"
          x="0"
          y="0"
          width="74.84"
          height="81.564"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Сгруппировать_4600"
        data-name="Сгруппировать 4600"
        transform="translate(-219.721 -738.318)"
      >
        <g transform="matrix(1, 0, 0, 1, 219.72, 738.32)" filter="url(#Контур_1138)">
          <path
            id="Контур_1138-2"
            data-name="Контур 1138"
            d="M46.5,13.488l13.96,8.351A14.481,14.481,0,0,1,67.44,34.3v16.7A14.33,14.33,0,0,1,60.46,63.47L46.5,71.821a13.39,13.39,0,0,1-13.96,0L18.58,63.47A14.481,14.481,0,0,1,11.6,51.006V34.3a14.33,14.33,0,0,1,6.98-12.464l13.96-8.351A13.39,13.39,0,0,1,46.5,13.488Z"
            transform="translate(-2.1 -4.89)"
            fill={isActive ? '#fff' : 'transparent'}
            stroke="#fff"
            opacity={isActive ? '1' : '0.5'}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
        <g id="Сгруппировать_987" data-name="Сгруппировать 987" transform="translate(237 756.082)">
          <path
            id="Контур_1127"
            data-name="Контур 1127"
            d="M132.957,12.376a20.579,20.579,0,0,0-10.8-10.8A20.714,20.714,0,0,0,114.29,0a21.167,21.167,0,0,0-7.913,1.572,19.841,19.841,0,0,0-6.442,4.362,21.273,21.273,0,0,0-4.362,6.441,20.693,20.693,0,0,0,0,15.824,20.579,20.579,0,0,0,10.8,10.8A20.7,20.7,0,0,0,122.2,39a19.841,19.841,0,0,0,6.442-4.362,21.272,21.272,0,0,0,4.362-6.441,21.161,21.161,0,0,0,1.572-7.912A20.26,20.26,0,0,0,132.957,12.376Zm-6.036,20.592a17.855,17.855,0,0,1-30.486-12.68,18.01,18.01,0,0,1,5.225-12.68,17.855,17.855,0,0,1,30.486,12.68A17.711,17.711,0,0,1,126.921,32.968Z"
            transform="translate(-94 0)"
            fill={isActive ? icon : '#fff'}
            opacity={isActive ? '1' : '0.5'}
          />
          <path
            id="Контур_1128"
            data-name="Контур 1128"
            d="M124.661,51.429a19.222,19.222,0,0,1-8-1.733,1.236,1.236,0,0,0-1.581.51h0a1.193,1.193,0,0,0,.561,1.631,21.475,21.475,0,0,0,18.049,0,1.221,1.221,0,0,0,.561-1.631h0a1.219,1.219,0,0,0-1.581-.51A19.639,19.639,0,0,1,124.661,51.429Z"
            transform="translate(-104.371 -24.452)"
            fill={isActive ? icon : '#fff'}
            opacity={isActive ? '1' : '0.5'}
          />
          <ellipse
            id="Эллипс_70"
            data-name="Эллипс 70"
            cx="3.008"
            cy="3.008"
            rx="3.008"
            ry="3.008"
            transform="translate(11.099 12.567)"
            fill={isActive ? icon : '#fff'}
            opacity={isActive ? '1' : '0.5'}
          />
          <ellipse
            id="Эллипс_71"
            data-name="Эллипс 71"
            cx="3.008"
            cy="3.008"
            rx="3.008"
            ry="3.008"
            transform="translate(23.465 12.567)"
            fill={isActive ? icon : '#fff'}
            opacity={isActive ? '1' : '0.5'}
          />
        </g>
      </g>
    </svg>
  )
}
