import React from 'react'

export const AnswerIndicatorIncorrect = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34.852"
      height="26.660"
      viewBox="0 0 26.852 18.273"
    >
      <path
        id="Path_10703"
        data-name="Path 10703"
        d="M304.47,228.415a1.978,1.978,0,0,0-.536-1.323l-5.2-5.335,4.673-4.791.169-.172q.17-.174.34-.349l.021-.022a1.965,1.965,0,0,0,.536-1.323,1.462,1.462,0,0,0-.157-.725,1.432,1.432,0,0,0-.381-.6,1.8,1.8,0,0,0-1.294-.547,1.882,1.882,0,0,0-1.29.549l-5.2,5.335-4.673-4.791-.169-.174c-.113-.116-.226-.232-.339-.347l-.021-.022a1.868,1.868,0,0,0-1.291-.549,1.368,1.368,0,0,0-.707.161,1.388,1.388,0,0,0-.59.39,1.9,1.9,0,0,0-.534,1.327,1.978,1.978,0,0,0,.536,1.323l5.2,5.335-4.673,4.791-.169.173q-.17.173-.339.348l-.021.022a1.964,1.964,0,0,0-.536,1.323,1.462,1.462,0,0,0,.157.725,1.43,1.43,0,0,0,.381.6,1.832,1.832,0,0,0,1.232.549h.062a1.881,1.881,0,0,0,1.29-.549l5.2-5.335,4.673,4.791.17.175q.169.174.338.346l.022.022a1.868,1.868,0,0,0,1.291.549h.057a1.364,1.364,0,0,0,.65-.162,1.388,1.388,0,0,0,.59-.39A1.9,1.9,0,0,0,304.47,228.415Z"
        transform="translate(-287.22 -212.621)"
        fill="#bd3c26"
        stroke="#bd3c26"
        strokeWidth="1.2"
      />
    </svg>
  )
}
