import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { EpisodeApi } from 'api/episode'
import { ActivePageTimeline, CompleteEpisodeType, EpisodeProgressType } from 'store/types'
import { AnswerEpisodeQuestionType, GetPageType } from './types'
import history from 'utils/history'
import { AppRoutes, createPath } from 'router/routesConfig'
import { setIsSaved, setQuestionHighlighted } from './slice'
import { EpisodePrivacySettingsResponse } from 'api/episode/types/response'
import { setMessage } from '../feedback/slice'
import { TimelineApi } from 'api/timeline'
import { RootState } from 'store'

export const startEpisode = createAsyncThunk<void, string, { rejectValue: { message: string } }>(
  'episode/startEpisode',
  async (id, { rejectWithValue }) => {
    try {
      await EpisodeApi.startEpisodeRequest(id)
      history.push(
        createPath({
          path: AppRoutes.EPISODE_PAGE,
          params: { episodeId: id, episodeType: EpisodeProgressType.Invite.toString() },
        })
      )
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getEpisodePrivacySettings = createAsyncThunk<
  EpisodePrivacySettingsResponse,
  string,
  { rejectValue: { message: string } }
>('episode/getEpisodePrivacySettings', async (timelineItemId, { rejectWithValue }) => {
  try {
    const { data } = await EpisodeApi.episodePrivacySettingsRequest(timelineItemId)

    return data
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getEpisodePrivacySettingsMeOther = createAsyncThunk<
  EpisodePrivacySettingsResponse,
  string,
  { rejectValue: { message: string } }
>('episode/getEpisodePrivacySettingsMeOther', async (userSeriesVersionId, { rejectWithValue }) => {
  try {
    const { data } = await EpisodeApi.episodePrivacySettingsMeOtherRequest(userSeriesVersionId)

    return data
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getGroupedEpisodePrivacySettings = createAsyncThunk<
  EpisodePrivacySettingsResponse,
  { userSeriesVersionId: string; type: number },
  { rejectValue: { message: string } }
>(
  'episode/getGroupedEpisodePrivacySettings',
  async ({ userSeriesVersionId, type }, { rejectWithValue }) => {
    try {
      const { data } = await TimelineApi.groupedItemPrivacySettingsRequest(
        userSeriesVersionId,
        type
      )

      return data
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const restartEpisode = createAsyncThunk<void, string, { rejectValue: { message: string } }>(
  'episode/restartEpisode',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await EpisodeApi.restartEpisodeRequest(id)
      history.push(
        createPath({
          path: AppRoutes.EPISODE_PAGE,
          params: { episodeId: data.id, episodeType: EpisodeProgressType.Invite.toString() },
        })
      )
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const saveVideoProgress = createAsyncThunk<void, void, { rejectValue: { message: string } }>(
  'episode/saveVideoProgress',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        episode: { activePage },
      } = getState() as RootState

      const pageProgress = {
        progress: activePage.questionProgress,
        questionId: activePage.questionId,
      }
      const pageItemProgresses = activePage.questions.map((question) => {
        return {
          progress: question.questionProgress,
          questionId: question.questionId,
        }
      })

      const progressArray = [pageProgress, ...pageItemProgresses]

      await EpisodeApi.saveProgressRequest(progressArray)
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const completeActivePage = createAsyncThunk<
  ActivePageTimeline | void,
  string,
  { rejectValue: { message: string } }
>('episode/completeActivePage', async (id, { rejectWithValue, dispatch }) => {
  try {
    const response = await EpisodeApi.completeActivePageRequest(id)

    if (response.completeType === CompleteEpisodeType.ActivePageCompleted) {
      return await EpisodeApi.getActivePageRequest(id)
    } else if (response.completeType === CompleteEpisodeType.EpisodeCompleted) {
      history.push({
        pathname: AppRoutes.EPISODE_RESULT,
        state: { results: response.episodeResult },
      })
    } else {
      history.push({
        pathname: AppRoutes.EPISODE_RESULT,
        state: { noResults: true, results: response.episodeResult },
      })
    }
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      dispatch(setMessage(error.response?.data.Message))
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getActivePage = createAsyncThunk<
  ActivePageTimeline,
  { id: string; currentPageId?: string },
  { rejectValue: { message: string } }
>('episode/getActivePage', async ({ id, currentPageId }, { rejectWithValue }) => {
  try {
    return await EpisodeApi.getActivePageRequest(id, currentPageId)
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getPreviousPage = createAsyncThunk<
  ActivePageTimeline,
  GetPageType,
  { rejectValue: { message: string } }
>('episode/getPreviousPage', async ({ episodeId, pageId }, { rejectWithValue }) => {
  try {
    const activePage = await EpisodeApi.getPreviousPageRequest(episodeId, pageId)

    return activePage
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getNextPage = createAsyncThunk<
  ActivePageTimeline,
  GetPageType,
  { rejectValue: { message: string } }
>('episode/getNextPage', async ({ episodeId, pageId }, { rejectWithValue }) => {
  try {
    const activePage = await EpisodeApi.getNextPageRequest(episodeId, pageId)

    return activePage
  } catch (err) {
    const error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const answerEpisodeQuestion = createAsyncThunk<
  void,
  AnswerEpisodeQuestionType,
  { rejectValue: { message: string } }
>(
  'episode/answerEpisodeQuestion',
  async ({ questionId, answer, value, userEpisodeId }, { rejectWithValue, dispatch }) => {
    try {
      const { isSuccess, isAnswerCorrect } = await EpisodeApi.answerEpisodeQuestionRequest(
        userEpisodeId,
        questionId,
        answer?.id,
        value
      )
      if (!isSuccess) return rejectWithValue({ message: '' })
      dispatch(setIsSaved({ questionId, isAnswerCorrect, isSuccess }))
    } catch (err) {
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const highlightQuestion = createAsyncThunk<
  void,
  { episodeId: string; questionId: string; isHighlighted: boolean },
  { rejectValue: { message: string } }
>(
  'episode/highlightQuestion',
  async ({ episodeId, questionId, isHighlighted }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setQuestionHighlighted({ questionId, isHighlighted }))
      await TimelineApi.highlightQuestionRequest(episodeId, questionId, isHighlighted)
    } catch (err) {
      dispatch(setQuestionHighlighted({ questionId, isHighlighted: !isHighlighted }))
      const error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)
