import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const ResultDislike = () => {
  const brandingColors = useBrandingColors()
  return (
    <svg width={42.933} height={50.011} viewBox="0 0 41.933 50.011">
      <defs>
        <linearGradient
          id="linear-gradient-dislike"
          x1="0"
          x2="0"
          y2="100%"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#c10d06" />
          <stop offset="1" stopColor="#e67707" />
        </linearGradient>
      </defs>
      <g transform="translate(-103.175 -1020.134)">
        <g transform="matrix(0.875, -0.485, 0.485, 0.875, 100.41, 1028.739)">
          <path
            d="M14.2,79.806S24.9,64.766,18.887,43.3"
            transform="translate(-4.565 -28.833)"
            fill="none"
            stroke={brandingColors.iconOnBackground}
            strokeWidth="2.2"
          />
          <path
            d="M41.1,23.989s7.1,3.252,10.037-.459S48.168,13.033,41.1,23.989Z"
            transform="translate(-27.388 -12.49)"
            stroke={brandingColors.iconOnBackground}
            strokeWidth="2.2"
            fill="url(#linear-gradient-dislike"
          />
          <path
            d="M22.558,13.5S15.277,10.705,15.843,6,25.456.774,22.558,13.5Z"
            transform="translate(-11.036 -2.742)"
            stroke={brandingColors.iconOnBackground}
            strokeWidth="2.2"
            fill="url(#linear-gradient-dislike"
          />
          <path
            d="M45.2,93.987s3.552-5.4,9.58-4.985S61.493,96.121,45.2,93.987Z"
            transform="translate(-30.038 -57.164)"
            fill="rgba(0,0,0,0)"
            stroke={brandingColors.iconOnBackground}
            strokeWidth="2.2"
          />
          <path
            d="M13.483,38.838s-7.351-2.615-9.967,1.308S7.439,50.395,13.483,38.838Z"
            transform="matrix(0.914, -0.407, 0.407, 0.914, -23.808, -9.706)"
            stroke={brandingColors.iconOnBackground}
            strokeWidth="2.2"
            fill="url(#linear-gradient-dislike"
          />
          <path
            d="M41,37.574s6.75-3.888,10.037-.459S49.023,47.859,41,37.574Z"
            transform="translate(-27.323 -23.92)"
            stroke={brandingColors.iconOnBackground}
            strokeWidth="2.2"
            fill="url(#linear-gradient-dislike"
          />
          <path
            d="M38.575,44.5s7.281,2.792,6.715,7.493S35.712,57.223,38.575,44.5Z"
            transform="translate(-25.393 -29.609)"
            stroke={brandingColors.iconOnBackground}
            strokeWidth="2.2"
            fill="url(#linear-gradient-dislike"
          />
          <ellipse
            cx="4.691"
            cy="4.691"
            rx="4.691"
            ry="4.691"
            transform="translate(7.643 7.938)"
            stroke={brandingColors.iconOnBackground}
            strokeWidth="2.2"
            fill="url(#linear-gradient-dislike)"
          />
        </g>
      </g>
    </svg>
  )
}
