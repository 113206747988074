import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import AppContentLayout from 'layouts/AppContentLayout'
import { useHistory, useParams } from 'react-router-dom'
import { getScaledValue } from 'utils/scale'
import { useAppDispatch } from 'store'
import { useSelector } from 'react-redux'
import { TextCalibriBold } from 'modules/common/components/Typography'
import { useBrandingColors } from 'hooks/useBrandingColors'
import { PrivacyHeader } from 'modules/timeline/components/ComponentsTimeline/PrivacyHeader'
import { selectMentionPrivacySettings } from 'store/slices/mentions/selectors'
import { getMentionPrivacySettings } from 'store/slices/mentions/thunks'
import PrivacyViewer from 'modules/common/components/PrivacyViewer'
import { MentionChatType } from 'store/types'
import { useTranslation } from 'react-i18next'
import { ITheme } from 'styles/types'

const MentionPrivacySettings = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const brandingColors = useBrandingColors()
  const dispatch = useAppDispatch()
  const { recipientId, mentionType } = useParams<{ recipientId: string; mentionType: string }>()

  const mentionPrivacySettings = useSelector(selectMentionPrivacySettings)

  useEffect(() => {
    dispatch(
      getMentionPrivacySettings({
        recipientId,
        isCompliment: Number(mentionType) === MentionChatType.Compliment,
      })
    )
  }, [])

  const VIEWER_EVERYONE = {
    id: 'EVERYONE',
    fullName: t('PRIVACY.EVERYONE'),
    phoneNumber: '',
    logo: null,
    position: null,
    type: NaN,
  }

  const handleBackBtnClick = () => {
    history.goBack()
  }

  const privacyViewers = mentionPrivacySettings.timelineViewers
    ? mentionPrivacySettings.timelineViewers
    : []

  const getTitleText = () => ({
    [MentionChatType.Compliment]: t('PRIVACY.ACCESS_COMPLIMENT_VIEWERS'),
    [MentionChatType.Suggestion]: t('PRIVACY.ACCESS_SUGGESTION_VIEWERS'),
    [MentionChatType.Comment]: t('PRIVACY.ACCESS_COMMENT_VIEWERS'),
  })

  return (
    <AppContentLayout screenTitle={t('SCREEN_TITLES.PRIVACY')} onBackBtnClick={handleBackBtnClick}>
      <PrivacyWrapper>
        <PrivacyHeader />
        <Title textColor={brandingColors.textOnBackground}>
          {getTitleText()[Number(mentionType)]}
        </Title>
        {Number(mentionType) === MentionChatType.Comment ? (
          <PrivacyViewer key={VIEWER_EVERYONE.id} viewer={VIEWER_EVERYONE} isEveryone />
        ) : (
          <ViewersWrapper>
            {privacyViewers.map((viewer) => {
              return <PrivacyViewer key={viewer.id} viewer={viewer} />
            })}
          </ViewersWrapper>
        )}
      </PrivacyWrapper>
    </AppContentLayout>
  )
}

export default MentionPrivacySettings

const Title = styled(TextCalibriBold)<{ textColor: string }>`
  font-size: ${getScaledValue(19, 'px')};
  color: ${({ textColor }) => textColor};
  display: block;
  margin: ${getScaledValue(12, 'px')} 0 ${getScaledValue(20, 'px')};
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    font-size: 19px;
    margin: 12px 0 20px;
  }
`

const PrivacyWrapper = styled.div`
  padding: 0 35px;
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    padding: 0 10px;
  }
`

const ViewersWrapper = styled.div`
  height: calc(100vh - 260px);
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  @supports (-webkit-touch-callout: none) {
    height: calc(100vh - 350px);
  }
`
