/*  eslint-disable @typescript-eslint/no-unused-vars*/
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrganizationMentions } from 'store/types'
import { BrandingColorsType, MasterOrganizationType, MasterSettingsType } from './types'

interface UIStateType {
  brandingColors: BrandingColorsType
  masterOrganization: MasterOrganizationType
  masterOrgSettings: MasterSettingsType
  organizationMentions: OrganizationMentions
  expandedItemId: string
  playingVideoId: string
  isSharedDevice: boolean
  hasBranding: boolean
}

const initialState: UIStateType = {
  masterOrganization: {} as MasterOrganizationType,
  masterOrgSettings: {} as MasterSettingsType,
  isSharedDevice: false,
  hasBranding: false,
  organizationMentions: [] as OrganizationMentions,
  expandedItemId: '',
  playingVideoId: '',
  brandingColors: {
    backgroundStart: '#0623C1',
    backgroundEnd: '#26AAE5',
    text: '#0623C1',
    textOnBackground: '#FFFFFF',
    frame: '#0623C1',
    frameOnBackground: '#FFFFFF',
    icon: '#0623C1',
    iconOnBackground: '#FFFFFF',
    mood1Start: '#C10D06',
    mood1End: '#E67707',
    mood2Start: '#BF8C01',
    mood2End: '#FD9C03',
    mood3Start: '#7E04BE',
    mood3End: '#F40CD9',
    mood4Start: '#0D43C9',
    mood4End: '#26E5E5',
    mood5Start: '#09AF43',
    mood5End: '#79D80C',
    complimentStart: '#000000',
    complimentEnd: '#FFFFFF',
    suggestionStart: '#000000',
    suggestionEnd: '#FFFFFF',
    complaintStart: '#000000',
    complaintEnd: '#FFFFFF',
    supportStart: '#FFFFFF',
    supportEnd: '#FFFFFF',
    commentStart: '#FFFFFF',
    commentEnd: '#FFFFFF',
    outLineColor: '#9CA8E6',
  },
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setBrandingColors: (state, action: PayloadAction<BrandingColorsType>) => {
      state.brandingColors = action.payload
    },
    setDefaultBrandingColors: (state) => {
      state.brandingColors = initialState.brandingColors
    },

    setMasterOrganization: (state, action: PayloadAction<MasterOrganizationType>) => {
      state.masterOrganization = action.payload
    },
    setMasterOrganizationSettings: (state, action: PayloadAction<MasterSettingsType>) => {
      state.masterOrgSettings = action.payload
    },

    setOrganizationMentions: (state, action: PayloadAction<OrganizationMentions>) => {
      state.organizationMentions = action.payload
    },

    setSharedDevice: (state, action: PayloadAction<boolean>) => {
      state.isSharedDevice = action.payload
    },

    setExpandedItemId: (state, action: PayloadAction<string>) => {
      state.expandedItemId = action.payload
    },

    setPlayingVideoId: (state, action: PayloadAction<string>) => {
      state.playingVideoId = action.payload
    },

    collapseItem: (state) => {
      state.expandedItemId = initialState.expandedItemId
    },
  },
})

export const uiReducer = uiSlice.reducer
export const {
  setBrandingColors,
  setDefaultBrandingColors,
  setMasterOrganization,
  setMasterOrganizationSettings,
  setOrganizationMentions,
  setSharedDevice,
  setExpandedItemId,
  setPlayingVideoId,
  collapseItem,
} = uiSlice.actions
