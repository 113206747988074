import styled from 'styled-components/macro'
import { ITheme } from 'styles/types'

export const TextAvenirNextRegular = styled.span`
  font-family: 'AvenirNextCondensedRegular';
  font-size: 18px;
  color: ${({ theme }: { theme: ITheme }) => theme.text.white};

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    font-size: 16px;
  } ;
`
export const TextAvenirNextBold = styled.span`
  font-family: 'AvenirNextCondensedBold';
  font-size: 18px;
  color: ${({ theme }: { theme: ITheme }) => theme.text.white};

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    font-size: 16px;
  } ;
`
