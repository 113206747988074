import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserApi } from 'api/user'
import { AxiosError } from 'axios'
import { AboutOthersInfo, TimelineMention } from 'store/types'
import { AboutResultsDataType } from 'api/mappers/AboutResultsMapper'
import { CourseTab } from 'store/types/info'
import { Error } from './types'

const courseQuery = (filterType: number, page: number) => ({
  pager: {
    current: page,
    pageSize: 10,
  },
  sorter: [
    {
      field: 'createdDate',
      order: 'desc',
    },
  ],
  filters:
    filterType === 0
      ? []
      : [
          {
            operator: 'eq',
            field: 'filterType',
            value: filterType.toString(),
            logic: 'and',
          },
        ],
})

const mentionQuery = (
  mentionType: number,
  received: boolean,
  text: string,
  page: number,
  handled?: boolean
) => {
  return {
    pager: {
      current: page,
      pageSize: 10,
    },
    sorter: [
      {
        field: 'lastUpdatedDate',
        order: 'desc',
      },
    ],
    filters: [
      {
        operator: 'eq',
        field: 'mentionType',
        value: mentionType.toString(),
        logic: 'and',
      },
      {
        operator: 'eq',
        field: 'direction',
        // eslint-disable-next-line no-nested-ternary
        value: handled ? '2' : received ? '1' : '0',
        logic: 'and',
      },
    ],
    search: {
      fields: ['text', 'searchName'],
      value: text,
    },
  }
}

const resultQuery = (page: number) => ({
  pager: {
    current: page,
    pageSize: 10,
  },
  sorter: [
    {
      field: 'date',
      order: 'desc',
    },
  ],
})

const resultSearchQuery = (text: string, page: number) => ({
  pager: {
    current: page,
    pageSize: 10,
  },
  sorter: [
    {
      field: 'date',
      order: 'desc',
    },
  ],
  search: {
    fields: ['text'],
    value: text,
  },
})

export const getAboutOthersUserInfo = createAsyncThunk<
  AboutOthersInfo,
  { id: string; aboutOthersType: number },
  { rejectValue: Error }
>('about-others/getAboutOthersUserInfo', async ({ id, aboutOthersType }, { rejectWithValue }) => {
  try {
    const { data: info } = await UserApi.getAboutOthersUserInfoRequest(id, aboutOthersType)

    return info
  } catch (err) {
    let error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getCourses = createAsyncThunk<
  { data: CourseTab[]; total: number },
  { filterType: number; id: string },
  { rejectValue: Error }
>('about-others/getCourses', async ({ filterType, id }, { rejectWithValue }) => {
  try {
    return await UserApi.getOthersCoursesRequest(courseQuery(filterType, 1), id)
  } catch (err) {
    let error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})
export const getMoreCourses = createAsyncThunk<
  { data: CourseTab[]; total: number },
  { filterType: number; page: number; id: string },
  { rejectValue: Error }
>('about-others/getMoreCourses', async ({ filterType, page, id }, { rejectWithValue }) => {
  try {
    return await UserApi.getOthersCoursesRequest(courseQuery(filterType, page), id)
  } catch (err) {
    let error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getMentions = createAsyncThunk<
  { data: TimelineMention[]; total: number },
  {
    mentionType: number
    received: boolean
    handled?: boolean
    id: string
    aboutOthersType: number
  },
  { rejectValue: Error }
>(
  'about-others/getMentions',
  async ({ mentionType, received, handled, id, aboutOthersType }, { rejectWithValue }) => {
    try {
      return await UserApi.getOthersMentionsRequest(
        mentionQuery(mentionType, received, '', 1, handled),
        id,
        aboutOthersType
      )
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const searchMentions = createAsyncThunk<
  { data: TimelineMention[]; total: number },
  {
    searchValue: string
    mentionType: number
    received: boolean
    handled?: boolean
    id: string
    aboutOthersType: number
  },
  { rejectValue: Error }
>(
  'about-others/searchMentions',
  async (
    { searchValue, mentionType, received, handled, id, aboutOthersType },
    { rejectWithValue }
  ) => {
    try {
      return await UserApi.getOthersMentionsRequest(
        mentionQuery(mentionType, received, searchValue, 1, handled),
        id,
        aboutOthersType
      )
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getMoreMentions = createAsyncThunk<
  { data: TimelineMention[]; total: number },
  {
    searchValue: string
    page: number
    mentionType: number
    received: boolean
    handled?: boolean
    id: string
    aboutOthersType: number
  },
  { rejectValue: Error }
>(
  'about-others/getMoreMentions',
  async (
    { searchValue, page, mentionType, received, handled, id, aboutOthersType },
    { rejectWithValue }
  ) => {
    try {
      return await UserApi.getOthersMentionsRequest(
        mentionQuery(mentionType, received, searchValue, page, handled),
        id,
        aboutOthersType
      )
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getResults = createAsyncThunk<
  { results: { data: AboutResultsDataType[]; total: number }; totalPoints: number },
  { id: string; aboutOthersType: number },
  { rejectValue: Error }
>('about-others/getResults', async ({ id, aboutOthersType }, { rejectWithValue }) => {
  try {
    const results = await UserApi.getOthersResultsRequest(resultQuery(1), id, aboutOthersType)
    const totalPoints = await UserApi.getTotalPointsRequest(id)

    return {
      results,
      totalPoints,
    }
  } catch (err) {
    let error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})
export const getMoreResults = createAsyncThunk<
  { results: { data: AboutResultsDataType[]; total: number }; totalPoints: number },
  { id: string; aboutOthersType: number; page: number },
  { rejectValue: Error }
>('about-others/getMoreResults', async ({ id, aboutOthersType, page }, { rejectWithValue }) => {
  try {
    const results = await UserApi.getOthersResultsRequest(resultQuery(page), id, aboutOthersType)
    const totalPoints = await UserApi.getTotalPointsRequest(id)

    return {
      results,
      totalPoints,
    }
  } catch (err) {
    let error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const searchResults = createAsyncThunk<
  { data: AboutResultsDataType[]; total: number },
  { searchValue: string; id: string; aboutOthersType: number },
  { rejectValue: Error }
>(
  'about-others/searchResults',
  async ({ searchValue, id, aboutOthersType }, { rejectWithValue }) => {
    try {
      return await UserApi.getOthersResultsRequest(
        resultSearchQuery(searchValue, 1),
        id,
        aboutOthersType
      )
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)
