import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store'

const episodeState = (state: RootState) => state.episode

export const selectIsLoading = (state: RootState) => state.episode.isLoading

export const selectEpisodeQuestion = (id: string) =>
  createSelector(episodeState, (episodeState) => {
    if (episodeState.activePage.questions) {
      return episodeState.activePage.questions.find((item) => item.id === id)
    }
  })

export const selectActivePage = createSelector(episodeState, (state) => state.activePage)
export const selectEpisodePrivacySettings = createSelector(
  episodeState,
  (state) => state.episodePrivacySettings
)

export const selectProgressArray = createSelector(episodeState, (state) => state.progressArray)

// export const selectVideoProgress = (id: string) => (state: RootState) => {
//   return state.episode.progressArray.find((x) => x.questionId === id)?.progress || 0
// }

export const selectVideoProgress = (id: string, isPage: boolean) => (state: RootState) => {
  if (!state.episode.activePage || !state.episode.activePage.questions) return 0
  if (isPage) {
    return state.episode.activePage.questionProgress || 0
  }

  return state.episode.activePage.questions.find((x) => x.questionId === id)?.questionProgress || 0
}
