/*eslint-disable react/display-name*/
import React, { forwardRef, useState } from 'react'
import { Slider, Popover } from 'antd'
import styled from 'styled-components/macro'
import { TextCalibriRegular } from '../Typography'
import {
  MdReplay,
  MdPlayArrow,
  MdPause,
  MdVolumeUp,
  MdVolumeOff,
  MdVolumeDown,
  MdClosedCaption,
  MdFullscreen,
} from 'react-icons/md'
import { ITheme } from 'styles/types'

interface IProps {
  playing: boolean
  played: number
  elapsedTime: string
  totalDuration: string
  muted: boolean
  playbackRate: number
  volume: number
  isSubtitlesShown: boolean
  isEnded: boolean
  onSeek: (newValue: number) => void
  onSeekMouseUp: (newValue: number) => void
  onTogglePlay: () => void
  onMute: () => void
  onReplay: () => void
  onVolumeSeekDown: (newValue: number) => void
  onPlaybackRateChange: (rate: number) => void
  onToggleFullScreen: () => void
  toggleSubtitles: () => void
  onVolumeChange: (newValue: number) => void
}

const CONTROL_ICONS_SIZE = '28px'

const BottomControls = forwardRef<HTMLDivElement, IProps>(
  (
    {
      playing,
      played,
      elapsedTime,
      totalDuration,
      volume,
      muted,
      isEnded,
      isSubtitlesShown,
      playbackRate,
      onVolumeSeekDown,
      onMute,
      onReplay,
      onTogglePlay,
      onSeek,
      onSeekMouseUp,
      onPlaybackRateChange,
      onToggleFullScreen,
      toggleSubtitles,
      onVolumeChange,
    },
    ref
  ) => {
    const [popoverVisible, setPopoverVisible] = useState(false)

    const togglePopover = () => {
      setPopoverVisible(!popoverVisible)
    }

    return (
      <BottomControlsWrapper ref={ref}>
        <Bottom>
          <PlayProgressWrapper>
            <Slider
              min={0}
              max={100}
              value={played * 100}
              onChange={onSeek}
              onAfterChange={onSeekMouseUp}
            />
          </PlayProgressWrapper>
          <BottomControlsInner>
            <BottomLeftContainer>
              {playing && !isEnded ? (
                <IconButton onClick={onTogglePlay}>
                  <MdPause style={{ fontSize: CONTROL_ICONS_SIZE }} />
                </IconButton>
              ) : !playing && !isEnded ? (
                <IconButton onClick={onTogglePlay}>
                  <MdPlayArrow style={{ fontSize: CONTROL_ICONS_SIZE }} />
                </IconButton>
              ) : isEnded ? (
                <IconButton onClick={onReplay}>
                  <MdReplay style={{ fontSize: CONTROL_ICONS_SIZE }} />
                </IconButton>
              ) : null}

              <IconButton onClick={onMute}>
                {muted ? (
                  <MdVolumeOff style={{ fontSize: CONTROL_ICONS_SIZE }} />
                ) : volume > 0.5 ? (
                  <MdVolumeUp style={{ fontSize: CONTROL_ICONS_SIZE }} />
                ) : (
                  <MdVolumeDown style={{ fontSize: CONTROL_ICONS_SIZE }} />
                )}
              </IconButton>

              <VolumeSliderWrapper>
                <div
                  onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()}
                >
                  <Slider
                    min={0}
                    max={100}
                    value={muted ? 0 : volume * 100}
                    onChange={onVolumeChange}
                    onAfterChange={onVolumeSeekDown}
                    style={{ width: '50px' }}
                  />
                </div>
              </VolumeSliderWrapper>
              <ControlsText>
                {elapsedTime}/{totalDuration}
              </ControlsText>
            </BottomLeftContainer>
            <BottomRightContainer>
              <Popover
                content={[0.5, 1, 1.5, 2].map((rate) => {
                  return (
                    <PlaybackRates key={rate} onClick={() => onPlaybackRateChange(rate)}>
                      <ControlsText style={{ color: '#000' }}>{rate}</ControlsText>
                    </PlaybackRates>
                  )
                })}
                title="Playback speed"
                trigger="click"
                visible={popoverVisible}
                onVisibleChange={togglePopover}
                getPopupContainer={(triggerNode) => {
                  return triggerNode
                }}
              >
                <IconButton style={{ fontSize: '16px' }}>{playbackRate}X</IconButton>
              </Popover>

              <IconButton onClick={toggleSubtitles} isSubtitlesShown={isSubtitlesShown}>
                <MdClosedCaption />
              </IconButton>
              <IconButton onClick={onToggleFullScreen}>
                <MdFullscreen style={{ fontSize: CONTROL_ICONS_SIZE }} />
              </IconButton>
            </BottomRightContainer>
          </BottomControlsInner>
        </Bottom>
      </BottomControlsWrapper>
    )
  }
)

export default BottomControls

const Bottom = styled.div``

const BottomControlsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 15px 5px 15px;
  background: linear-gradient(to bottom, transparent 0%, #000 100%);
  opacity: 0;
  transition: opacity linear 0.5s;
  border-radius: 15px;

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    padding: 0 10px 5px 10px;
  }
`

const VolumeSliderWrapper = styled.div`
  margin-right: 10px;
`

const BottomControlsInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const IconButton = styled.div<{ isSubtitlesShown?: boolean }>`
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: ${({ isSubtitlesShown }) => (isSubtitlesShown ? '#fff' : '#999')};
  transition: transform 0.2s ease-in;
  &:hover {
    color: #fff;
    /* transform: scale(1.2); */
  }

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    padding: 0 2px;
    transform: scale(0.8);
  }
`

const PlayProgressWrapper = styled.div`
  &&& {
    .ant-slider {
      margin: 0;
    }

    .ant-slider-track {
      transition: width 0.1s ease-in;
    }

    .ant-slider-handle {
      transition: left 0.1s ease-in;
    }
  }
`

const BottomLeftContainer = styled.div`
  display: flex;
  align-items: center;
`
const BottomRightContainer = styled.div`
  display: flex;
  align-items: center;
`

const ControlsText = styled(TextCalibriRegular)`
  font-size: 16px;
`

const PlaybackRates = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`
