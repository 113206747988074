import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

const scaleFactor = 1.2

export const MoodBestOutlined = (props: { isSelected: boolean; id: string }) => {
  const brandingColors = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="73"
      height="79.323"
      viewBox="0 0 73 79.323"
      style={{ transform: `scale(${scaleFactor})` }}
    >
      <defs>
        {props.isSelected && (
          <linearGradient
            id={`mood-best-gradient-${props.id}`}
            x1="0.5"
            x2="0.5"
            y2="1"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor={brandingColors.mood5Start} />
            <stop offset="1" stopColor={brandingColors.mood5End} />
          </linearGradient>
        )}
        <filter
          id="Контур_1186"
          x="0"
          y="0"
          width="73"
          height="79.323"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Сгруппировать_4570"
        data-name="Сгруппировать 4570"
        transform="translate(-288.389 -1314.326)"
      >
        <g transform="matrix(1, 0, 0, 1, 288.39, 1314.33)" filter="url(#Контур_1186)">
          <path
            id="Контур_1186-2"
            data-name="Контур 1186"
            d="M45.975,13.459l13.75,8.225A14.264,14.264,0,0,1,66.6,33.961V50.412a14.114,14.114,0,0,1-6.875,12.277l-13.75,8.225a13.189,13.189,0,0,1-13.75,0l-13.75-8.225A14.264,14.264,0,0,1,11.6,50.412V33.961a14.114,14.114,0,0,1,6.875-12.277l13.75-8.225A13.189,13.189,0,0,1,45.975,13.459Z"
            transform="translate(-2.6 -5.53)"
            stroke={!props.isSelected ? brandingColors.icon : 'transparent'}
            fill={`url(#mood-best-gradient-${props.id})`}
          />
        </g>
        <g
          id="Сгруппировать_1098"
          data-name="Сгруппировать 1098"
          transform="translate(1790.996 1585)"
        >
          <path
            id="Контур_1125"
            data-name="Контур 1125"
            d="M38.4,12.2a19.558,19.558,0,0,0-4.3-6.35,19.558,19.558,0,0,0-6.35-4.3A20.416,20.416,0,0,0,20,0a20.863,20.863,0,0,0-7.8,1.55,19.558,19.558,0,0,0-6.35,4.3,20.971,20.971,0,0,0-4.3,6.35,20.4,20.4,0,0,0,0,15.6,19.558,19.558,0,0,0,4.3,6.35,19.558,19.558,0,0,0,6.35,4.3,20.4,20.4,0,0,0,15.6,0,19.558,19.558,0,0,0,6.35-4.3,20.971,20.971,0,0,0,4.3-6.35A20.863,20.863,0,0,0,40,20,19.974,19.974,0,0,0,38.4,12.2ZM32.45,32.5A17.6,17.6,0,0,1,2.4,20,17.756,17.756,0,0,1,7.55,7.5,17.6,17.6,0,0,1,37.6,20,17.461,17.461,0,0,1,32.45,32.5Z"
            transform="translate(-1486 -254)"
            fill={props.isSelected ? '#fff' : brandingColors.icon}
          />
          <path
            id="Контур_1126"
            data-name="Контур 1126"
            d="M30.176,51.82a11.315,11.315,0,0,1-7.8-3.4,1.159,1.159,0,0,0-1.55-.15h0a1.169,1.169,0,0,0-.15,1.75,12.858,12.858,0,0,0,5.55,3.5,13.244,13.244,0,0,0,4,.7,12.807,12.807,0,0,0,7.4-2.4,11.415,11.415,0,0,0,2.1-1.8,1.176,1.176,0,0,0-.15-1.75h0a1.159,1.159,0,0,0-1.55.15A11.642,11.642,0,0,1,30.176,51.82Z"
            transform="translate(-1496.176 -278.02)"
            fill={props.isSelected ? '#fff' : brandingColors.icon}
          />
          <circle
            id="Эллипс_68"
            data-name="Эллипс 68"
            cx="2.95"
            cy="2.95"
            r="2.95"
            transform="translate(-1475.05 -241.6)"
            fill={props.isSelected ? '#fff' : brandingColors.icon}
          />
          <circle
            id="Эллипс_69"
            data-name="Эллипс 69"
            cx="2.95"
            cy="2.95"
            r="2.95"
            transform="translate(-1462.85 -241.6)"
            fill={props.isSelected ? '#fff' : brandingColors.icon}
          />
        </g>
      </g>
    </svg>
  )
}
