import React, { CSSProperties } from 'react'

export const PlusSvg = (props: { color: string; style?: CSSProperties }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="29.609"
      height="29.609"
      viewBox="0 0 29.609 29.609"
      style={props.style}
    >
      <defs>
        <clipPath id="clip-path-999">
          <path
            id="Контур_158"
            d="M11.689,9.92h3.536a.884.884,0,0,1,0,1.768H11.689v3.536a.884.884,0,0,1-1.768,0V11.689H6.384a.884.884,0,1,1,0-1.768H9.92V6.384a.884.884,0,1,1,1.768,0Z"
            transform="translate(-5.5 -5.5)"
            fill={props.color}
          />
        </clipPath>
        <filter
          id="Контур_159"
          x="0"
          y="0"
          width="29.609"
          height="29.609"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Сгруппировать_967" transform="translate(9.5 6.5)">
        <path
          id="Контур_157"
          d="M11.689,9.92h3.536a.884.884,0,0,1,0,1.768H11.689v3.536a.884.884,0,0,1-1.768,0V11.689H6.384a.884.884,0,1,1,0-1.768H9.92V6.384a.884.884,0,1,1,1.768,0Z"
          transform="translate(-5.5 -5.5)"
          fill={props.color}
        />
        <g transform="matrix(1, 0, 0, 1, -9.5, -6.5)" filter="url(#Контур_159)">
          <path
            id="Контур_159-2"
            data-name="Контур 159"
            d="M11.689,9.92h3.536a.884.884,0,0,1,0,1.768H11.689v3.536a.884.884,0,0,1-1.768,0V11.689H6.384a.884.884,0,1,1,0-1.768H9.92V6.384a.884.884,0,1,1,1.768,0Z"
            transform="translate(4 1)"
            fill="none"
            stroke={props.color}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  )
}
