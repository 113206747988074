import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { CalendarApi } from 'api/calendar'
import { GetJourneysResponse, GetJourneyDetailsResponse } from 'api/calendar/types/response'
import { TimelineApi } from '../../../api/timeline/index'
import { PostAnswerButton } from 'api/timeline/types/response'

export const getJourneys = createAsyncThunk<
  GetJourneysResponse,
  { isUpcoming: boolean },
  { rejectValue: { message: string } }
>('calendar/getJourneys', async ({ isUpcoming }, { rejectWithValue }) => {
  try {
    return await CalendarApi.getJourneysRequest({ currentPage: 1, isUpcoming })
  } catch (err) {
    let error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getMoreJourneys = createAsyncThunk<
  GetJourneysResponse,
  { currentPage: number; isUpcoming: boolean },
  { rejectValue: { message: string } }
>('calendar/getMoreJourneys', async ({ currentPage, isUpcoming }, { rejectWithValue }) => {
  try {
    return await CalendarApi.getJourneysRequest({ currentPage, isUpcoming })
  } catch (err) {
    let error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getJourneyDetails = createAsyncThunk<
  GetJourneyDetailsResponse & { journeyId: string },
  { journeyId: string; isUpcoming: boolean },
  { rejectValue: { message: string } }
>('calendar/getJourneyDetails', async ({ journeyId, isUpcoming }, { rejectWithValue }) => {
  try {
    const res = await CalendarApi.getJourneyDetailsRequest({
      journeyId,
      currentPage: 1,
      isUpcoming,
    })

    return {
      journeyId,
      ...res,
    }
  } catch (err) {
    let error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})

export const getMoreJourneyDetails = createAsyncThunk<
  GetJourneyDetailsResponse & { journeyId: string },
  { journeyId: string; currentPage: number; isUpcoming: boolean },
  { rejectValue: { message: string } }
>(
  'calendar/getMoreJourneyDetails',
  async ({ journeyId, currentPage, isUpcoming }, { rejectWithValue }) => {
    try {
      const res = await CalendarApi.getJourneyDetailsRequest({
        journeyId,
        currentPage,
        isUpcoming,
      })

      return {
        journeyId,
        ...res,
      }
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const answerPost = createAsyncThunk<
  { journeyId: string; itemId: string; button: PostAnswerButton },
  { id: string; postUserId: string; journeyId: string; itemId: string },
  { rejectValue: { message: string } }
>('calendar/answerPost', async ({ id, postUserId, journeyId, itemId }, { rejectWithValue }) => {
  try {
    const { button } = await TimelineApi.answerPostRequest(id, postUserId)
    return {
      button,
      journeyId,
      itemId,
    }
  } catch (err) {
    let error = err as AxiosError
    if (error) {
      return rejectWithValue({ message: error.response?.data.Message })
    }
    throw err
  }
})
