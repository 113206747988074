import { mapTimeLineQuestionType, mapAnswerKey } from './TimelineQuestionMapper'
import { mapMedia } from './TimeLineMapper'

import { TimelineQuestionResults } from 'store/types'
import { formatName } from 'utils/nameFormatter'
import { TimelineResultResponse } from 'api/timeline/types/response'

export const resultMapper = (
  result: TimelineResultResponse,
  date: string
): TimelineQuestionResults => ({
  id: result.id,
  media: mapMedia(result.attachment),
  text: result.text,
  title: result.title,
  variants: result.resultOptions.map((answer) => ({
    id: answer.key.toString(),
    value: answer.value !== null ? answer.value : '',
    key: mapAnswerKey(result.type, answer.key.toString()),
    percent: answer.percent,
    isAnswerCorrect: false,
    isAnswerSaved: false,
  })),
  questionType: mapTimeLineQuestionType(result.type),
  date,
  min: result.min,
  max: result.max,
  userName: result.userName ? formatName(result.userName) : null,
})
