import { useSelector } from 'react-redux'

import { DefaultTheme } from 'styled-components'
import { selectBrandingColors } from '../store/slices/UI/selectors'

function useTheme(): DefaultTheme {
  const {
    backgroundStart,
    backgroundEnd,
    // commentEnd,
    // commentStart,
    // complaintEnd,
    // complaintStart,
    // frame,
    // frameOnBackground,
    // icon,
    // iconOnBackground,
    // complimentEnd,
    // complimentStart,
    // mood1End,
    // mood1Start,
    // mood2End,
    // mood2Start,
    // mood3End,
    // mood3Start,
    // mood4End,
    // mood4Start,
    // mood5End,
    // mood5Start,
    // outLineColor,
    // suggestionEnd,
    // suggestionStart,
    // supportEnd,
    // supportStart,
    text,
    textOnBackground,
  } = useSelector(selectBrandingColors)

  return {
    backgroundGradient: `linear-gradient(to bottom, ${backgroundStart}, ${backgroundEnd})`,
    palette: {
      white: '#fff',
      error: '#fa0e15',
      pink: '#fe0582',
    },
    text: {
      main: `${text}`,
      white: `${textOnBackground}`,
      black: '#000',
    },
    breakpoints: {
      sm: `(max-width: 600px)`,
      md: `(max-width: 960px)`,
      lg: `(max-width: 1280px)`,
      xl: `(max-width: 1920px)`,
    },
    view: {
      maxWidth: `570px`,
      sidePadding: 35,
    },
  }
}

export default useTheme
