import React from 'react'

export const UserOutlined = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50%" height="50%" viewBox="0 0 20.572 21.581">
      <g id="Сгруппировать_2916" data-name="Сгруппировать 2916" transform="translate(0.555 0.4)">
        <ellipse
          id="Эллипс_46"
          data-name="Эллипс 46"
          cx="6.523"
          cy="6.523"
          rx="6.523"
          ry="6.523"
          transform="translate(3.244)"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="0.8"
        />
        <path
          id="Контур_546"
          data-name="Контур 546"
          d="M3,145.615a11.673,11.673,0,0,1,9.749-5.215h0a11.673,11.673,0,0,1,9.713,5.161"
          transform="translate(-3 -124.988)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="0.8"
        />
      </g>
    </svg>
  )
}
