import { AppRoutes, AuthRoutes } from 'router/routesConfig'

const stepURLs = {
  /*eslint-disable  no-useless-computed-key*/
  0: AuthRoutes.ACCESS_CODE,
  1: AuthRoutes.LANGUAGE,
  2: AuthRoutes.PRIVACY_POLICY,
  3: AuthRoutes.PASSWORD,
  4: AuthRoutes.LOGO,
  5: AuthRoutes.CHECK_PASSWORD,
}

type URLType =
  | AuthRoutes.ACCESS_CODE
  | AuthRoutes.LANGUAGE
  | AuthRoutes.PRIVACY_POLICY
  | AuthRoutes.PASSWORD
  | AuthRoutes.LOGO
  | AppRoutes.TIMELINE

export const getUrlByStep = (step: number): URLType => {
  return stepURLs[step]
}

export enum LoginMethod {
  PhoneNumber,
  Email,
}

export enum RegistrationStep {
  Code = 0,
  Language = 1,
  PrivacyPolicy = 2,
  Password = 3,
  Logo = 4,
  Completed = 5,
}
