import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { AuthApi } from 'api/auth'
import {
  setBrandingColors,
  setDefaultBrandingColors,
  setMasterOrganization,
  setMasterOrganizationSettings,
} from './slice'
import { Error } from './types'
import { getFromLocalStorage } from 'utils/storage'
import { LOCAL_STORAGE_KEYS } from 'modules/common/constants/storageKeys'
import i18n from 'i18next'
import { getLanguageCodeById } from 'utils/languageCodes'

export const preloadMaster = createAsyncThunk<void, void, { rejectValue: Error }>(
  'ui/preloadMaster',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data: masterOrganization } = await AuthApi.masterOrganizationRequest()
      const { data: masterSettings } = await AuthApi.masterOrganizationSettingsRequest()
      const { data: masterColors } = await AuthApi.masterOrganizationColorsRequest()

      if (masterColors && !masterColors.StatusCode && masterSettings) {
        dispatch(setBrandingColors(masterColors))
        dispatch(setMasterOrganizationSettings(masterSettings))
      }

      if (masterColors && masterColors.StatusCode === 500) {
        dispatch(setDefaultBrandingColors())
      }

      if (masterOrganization && !masterOrganization.StatusCode) {
        dispatch(setMasterOrganization(masterOrganization))
        const langCode = getLanguageCodeById(masterOrganization.languageId)
        i18n.changeLanguage(langCode)
      }
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const preloadOrganization = createAsyncThunk<void, string, { rejectValue: Error }>(
  'ui/preloadOrganization',
  async (organizationId, { rejectWithValue, dispatch }) => {
    try {
      const { data: organizationColors } = await AuthApi.organizationColorsRequest(organizationId)

      if (organizationColors) {
        dispatch(setBrandingColors(organizationColors))
      }
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const preloadUserData = createAsyncThunk<void, void, { rejectValue: Error }>(
  'ui/preloadUserData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const user = getFromLocalStorage(LOCAL_STORAGE_KEYS.USER)

      if (user && user.organizationId) {
        dispatch(preloadOrganization(user.organizationId))
      } else {
        dispatch(preloadMaster())
      }
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)

export const getMasterOrg = createAsyncThunk<void, void, { rejectValue: Error }>(
  'ui/getMasterOrg',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data: masterOrganization } = await AuthApi.masterOrganizationRequest()
      dispatch(setMasterOrganization(masterOrganization))
    } catch (err) {
      let error = err as AxiosError
      if (error) {
        return rejectWithValue({ message: error.response?.data.Message })
      }
      throw err
    }
  }
)
