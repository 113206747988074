import { authInstanceOrg } from 'api/axios.config'
import type {
  CategoriesResponse,
  CategoryItem,
  CategoryItemsResponse,
  CategoryItemsResult,
  GetPostsResponse,
  ParticipantPostUsersGridResponse,
  PostMaintainsResponse,
} from './types/response'
import { categoryItemMapper } from './helpers'

const generateQueries = (activeTab: boolean, page: number, text?: string) => ({
  sorter: [
    {
      field: 'onTimelineDateTime',
      order: 'desc',
    },
    {
      field: 'title',
      order: 'asc',
    },
  ],
  filters: [
    {
      operator: 'eq',
      field: 'isActive',
      value: activeTab,
      logic: 'and',
    },
  ],
  pager: {
    current: page,
    pageSize: 20,
  },
  search: {
    fields: ['title'],
    value: text ?? '',
  },
})

export class PostsApi {
  static getPostsRequest = async ({
    currentPage,
    isActive,
    text = '',
  }: {
    currentPage: number
    isActive: boolean
    text?: string
  }) => {
    const { data } = await authInstanceOrg.post<GetPostsResponse>(
      `post/getAll`,
      generateQueries(isActive, currentPage, text)
    )
    return data
  }

  static getPostById = async (id: string) => {
    const { data } = await authInstanceOrg.get<PostMaintainsResponse>(`post/get/${id}`)

    return data
  }

  static fetchCategoryList = async () => {
    const { data } = await authInstanceOrg.get<CategoriesResponse[]>('categories/getAllActive')

    return data
  }

  static getCategoryItems = async (
    categoryId: string,
    postId: string
  ): Promise<CategoryItemsResult> => {
    const { data } = await authInstanceOrg.get<CategoryItemsResponse>(
      `categoryItems/get/${categoryId}`
    )

    return {
      categoryId: data.categoryId,
      onTimeline: data.onTimeline,
      categoryItems: data.categoryItems?.map((categoryItem: CategoryItem) =>
        categoryItemMapper(categoryItem, postId)
      ),
    }
  }

  static createPost = async (id: string) => {
    const { data } = await authInstanceOrg.post<any>(`post/createPostByCategory/${id}`)

    return data
  }

  static updatePost = async (post: any) => {
    const { data } = await authInstanceOrg.put<any>(`post/update`, post)

    return data
  }

  static updateAndPublish = async (post: any) => {
    const { data } = await authInstanceOrg.put<any>(`post/updateAndPublish`, post)

    return data
  }

  static getParticipants = async (options: any) => {
    const { data } = await authInstanceOrg.post<any>(`participant/users/false`, options)

    return data
  }

  static getPostUsers = async (options: any) => {
    const { data } = await authInstanceOrg.post<ParticipantPostUsersGridResponse>(
      `participant/postUsers`,
      options
    )

    return data
  }

  static getParticipantGroups = async (options: any, groupType: number, onlyMyGroups: boolean) => {
    const { data } = await authInstanceOrg.post<any>(
      `participant/groups/${groupType}?onlyMyGroups=${onlyMyGroups}`,
      options
    )

    return data
  }

  static removePost = async (id: string) => {
    const { data } = await authInstanceOrg.post<any>(`post/delete/${id}`)

    return data
  }
}
