import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const LikeSvg = (props: { isSelected: boolean }) => {
  const brandingColors = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="98"
      height="107.58"
      viewBox="0 0 98 107.58"
    >
      <defs>
        <linearGradient
          id="like-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#09af43" />
          <stop offset="1" stopColor="#79d80c" />
        </linearGradient>
        <filter id="Контур_481" x="0" y="0" width="98" height="107.58" filterUnits="userSpaceOnUse">
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Сгруппировать_4569"
        data-name="Сгруппировать 4569"
        transform="translate(-213.393 -969.326)"
      >
        <g transform="matrix(1, 0, 0, 1, 213.39, 969.33)" filter="url(#Контур_481)">
          <path
            id="Контур_481-2"
            data-name="Контур 481"
            d="M61.6,14.35l20,12.016A20.856,20.856,0,0,1,91.6,44.3V68.331a20.637,20.637,0,0,1-10,17.934l-20,12.016a19.113,19.113,0,0,1-20,0l-20-12.016a20.856,20.856,0,0,1-10-17.934V44.3a20.637,20.637,0,0,1,10-17.934l20-12.016A19.114,19.114,0,0,1,61.6,14.35Z"
            transform="translate(-2.6 -5.53)"
            stroke={props.isSelected ? 'transparent' : brandingColors.icon}
            fill={props.isSelected ? 'url(#like-gradient)' : 'transparent'}
          />
        </g>
        <g
          id="Сгруппировать_305"
          data-name="Сгруппировать 305"
          transform="translate(244.307 986.026)"
        >
          <path
            id="Контур_285"
            data-name="Контур 285"
            d="M14.2,92.184S28.533,72.045,20.476,43.3"
            transform="translate(-1.298 -23.927)"
            stroke={props.isSelected ? '#fff' : brandingColors.icon}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2.2"
            fill={props.isSelected ? 'url(#like-gradient)' : 'transparent'}
          />
          <path
            id="Контур_286"
            data-name="Контур 286"
            d="M41.1,26.011s9.513,4.354,13.441-.615S50.565,11.34,41.1,26.011Z"
            transform="translate(-22.738 -10.614)"
            stroke={props.isSelected ? '#fff' : brandingColors.icon}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2.2"
            fill={props.isSelected ? 'url(#like-gradient)' : 'transparent'}
          />
          <path
            id="Контур_287"
            data-name="Контур 287"
            d="M39.027,17.1s8.992-5.348,7.146-11.406S32.26.962,39.027,17.1Z"
            transform="translate(-20.571 -2.366)"
            stroke={props.isSelected ? '#fff' : brandingColors.icon}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2.2"
            fill={props.isSelected ? 'url(#like-gradient)' : 'transparent'}
          />
          <path
            id="Контур_288"
            data-name="Контур 288"
            d="M17.066,28.432s-9.039,5.206-13.441.615S6.323,14.66,17.066,28.432Z"
            transform="translate(-2.301 -12.373)"
            stroke={props.isSelected ? '#fff' : brandingColors.icon}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2.2"
            fill={props.isSelected ? 'url(#like-gradient)' : 'transparent'}
          />
          <path
            id="Контур_289"
            data-name="Контур 289"
            d="M24.845,17.075S15.1,13.336,15.853,7.042,28.726.038,24.845,17.075Z"
            transform="translate(-9.417 -2.672)"
            stroke={props.isSelected ? '#fff' : brandingColors.icon}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2.2"
            fill={props.isSelected ? 'url(#like-gradient)' : 'transparent'}
          />
          <path
            id="Контур_290"
            data-name="Контур 290"
            d="M45.2,99.174S50.359,88.18,59.114,89.026,68.863,103.518,45.2,99.174Z"
            transform="translate(-24.898 -50.157)"
            stroke={props.isSelected ? '#fff' : brandingColors.icon}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2.2"
            fill={props.isSelected ? 'url(#like-gradient)' : 'transparent'}
          />
          <path
            id="Контур_291"
            data-name="Контур 291"
            d="M17.063,39.127s-9.844-3.5-13.346,1.751S8.97,54.6,17.063,39.127Z"
            transform="translate(-2.629 -21.127)"
            stroke={props.isSelected ? '#fff' : brandingColors.icon}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2.2"
            fill={props.isSelected ? 'url(#like-gradient)' : 'transparent'}
          />
          <path
            id="Контур_292"
            data-name="Контур 292"
            d="M26.777,43.8s-8.85,5.584-6.815,11.595S33.923,59.749,26.777,43.8Z"
            transform="translate(-11.444 -24.191)"
            stroke={props.isSelected ? '#fff' : brandingColors.icon}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2.2"
            fill={props.isSelected ? 'url(#like-gradient)' : 'transparent'}
          />
          <path
            id="Контур_293"
            data-name="Контур 293"
            d="M41,38.209S50.039,33,54.441,37.594,51.743,51.981,41,38.209Z"
            transform="translate(-22.685 -19.925)"
            stroke={props.isSelected ? '#fff' : brandingColors.icon}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2.2"
            fill={props.isSelected ? 'url(#like-gradient)' : 'transparent'}
          />
          <path
            id="Контур_294"
            data-name="Контур 294"
            d="M38.765,44.5s9.749,3.739,8.992,10.033S34.932,61.537,38.765,44.5Z"
            transform="translate(-21.113 -24.559)"
            stroke={props.isSelected ? '#fff' : brandingColors.icon}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2.2"
            fill={props.isSelected ? 'url(#like-gradient)' : 'transparent'}
          />
          <ellipse
            id="Эллипс_30"
            data-name="Эллипс 30"
            cx="6.282"
            cy="6.282"
            rx="6.282"
            ry="6.282"
            transform="translate(10.235 10.63)"
            stroke={props.isSelected ? '#fff' : brandingColors.icon}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2.2"
            fill={props.isSelected ? 'url(#like-gradient)' : 'transparent'}
          />
        </g>
      </g>
    </svg>
  )
}
