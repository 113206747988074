export enum MediaType {
  YOUTUBE_VIDEO,
  IMAGE,
  GIPHY,
  VIDEO_FROM_DEVICE,
}

export interface Media {
  type: MediaType
}

export interface VideoMedia extends Media {
  id: string
  thumbnail: string | null
}

export interface ImageMedia extends Media {
  source: string
}

export interface VideoFromDeviceMedia extends Media {
  source: string
  thumbnail?: string | null
  subtitlesUrl?: string | null
}
