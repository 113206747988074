import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

const scaleFactor = 1.2

export const MoodWorse = () => {
  const brandingColors = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      style={{ transform: `scale(${scaleFactor})` }}
    >
      <g id="Сгруппировать_1119" data-name="Сгруппировать 1119" transform="translate(1080.6 254)">
        <path
          id="Контур_1129"
          data-name="Контур 1129"
          d="M443.8,12.2a19.558,19.558,0,0,0-4.3-6.35,19.558,19.558,0,0,0-6.35-4.3A20.416,20.416,0,0,0,425.4,0a20.863,20.863,0,0,0-7.8,1.55,19.558,19.558,0,0,0-6.35,4.3,20.971,20.971,0,0,0-4.3,6.35,20.4,20.4,0,0,0,0,15.6A20.286,20.286,0,0,0,417.6,38.45a20.4,20.4,0,0,0,15.6,0,19.558,19.558,0,0,0,6.35-4.3,20.97,20.97,0,0,0,4.3-6.35A20.863,20.863,0,0,0,445.4,20,19.974,19.974,0,0,0,443.8,12.2Zm-5.95,20.3A17.6,17.6,0,0,1,407.8,20a17.756,17.756,0,0,1,5.15-12.5A17.6,17.6,0,0,1,443,20,17.461,17.461,0,0,1,437.85,32.5Z"
          transform="translate(-1486 -254)"
          fill={brandingColors.iconOnBackground}
        />
        <path
          id="Контур_1130"
          data-name="Контур 1130"
          d="M439.576,48.5a13.244,13.244,0,0,0-4-.7,12.807,12.807,0,0,0-7.4,2.4,11.415,11.415,0,0,0-2.1,1.8,1.176,1.176,0,0,0,.15,1.75h0a1.159,1.159,0,0,0,1.55-.15,10.863,10.863,0,0,1,7.8-3.45,11.315,11.315,0,0,1,7.8,3.4,1.159,1.159,0,0,0,1.55.15h0a1.169,1.169,0,0,0,.15-1.75A13.068,13.068,0,0,0,439.576,48.5Z"
          transform="translate(-1496.176 -277.9)"
          fill={brandingColors.iconOnBackground}
        />
        <circle
          id="Эллипс_72"
          data-name="Эллипс 72"
          cx="2.95"
          cy="2.95"
          r="2.95"
          transform="translate(-1069.65 -241.6)"
          fill={brandingColors.iconOnBackground}
        />
        <circle
          id="Эллипс_73"
          data-name="Эллипс 73"
          cx="2.95"
          cy="2.95"
          r="2.95"
          transform="translate(-1057.45 -241.6)"
          fill={brandingColors.iconOnBackground}
        />
      </g>
    </svg>
  )
}
