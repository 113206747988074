import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

const scaleFactor = 1.2

export const SavedIcon = () => {
  const brandingColors = useBrandingColors()
  return (
    <svg width={20} height={20} style={{ transform: `scale(${scaleFactor})` }}>
      <circle cx={10} cy={10} r={9} stroke={brandingColors.icon} strokeWidth={1.5} fill="none" />
      <polyline
        points={'6.5, 10, 9, 12.5, 14, 7.5'}
        stroke={brandingColors.icon}
        strokeWidth={1.5}
        fill="none"
      />
    </svg>
  )
}
