import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const HexanogYesNo = (props: {
  isSelected: boolean
  id: string
  startColor: string
  endColor: string
}) => {
  const brandingColors = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="98"
      height="108.1"
      viewBox="0 0 98 100.1"
    >
      <defs>
        <linearGradient id={props.id} x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor={props.startColor} />
          <stop offset="1" stopColor={props.endColor} />
        </linearGradient>
        <filter id="Контур_1263" x="0" y="0" width="98" height="108.1" filterUnits="userSpaceOnUse">
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Контур_1263)">
        <path
          id="Контур_1263-2"
          data-name="Контур 1263"
          d="M61.6,14.366l20,12.085a21,21,0,0,1,10,18.038V68.66A20.783,20.783,0,0,1,81.6,86.7l-20,12.085a19.019,19.019,0,0,1-20,0L21.6,86.7a21,21,0,0,1-10-18.038V44.49a20.783,20.783,0,0,1,10-18.038l20-12.085A19.02,19.02,0,0,1,61.6,14.366Z"
          transform="translate(-2.6 -5.53)"
          fill={props.isSelected ? `url(#${props.id})` : '#fff'}
          stroke={props.isSelected ? 'transparent' : brandingColors.icon}
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </svg>
  )
}
