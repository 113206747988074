import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'

interface ErrorStateType {
  message: string | null
}

const initialState: ErrorStateType = {
  message: null,
}

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload
    },
    clearMessage: (state) => {
      state.message = null
    },
  },
})

export const feedbackReducer = feedbackSlice.reducer
export const { setMessage, clearMessage } = feedbackSlice.actions
export const selectMessage = (state: RootState) => state.feedback.message
