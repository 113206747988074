/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, ReactNode, useState } from 'react'
import styled from 'styled-components/macro'
import { ITheme } from 'styles/types'

import { ReactComponent as ArrowBackBtn } from 'assets/svg/ArrowBack.svg'
import BurgerButton from 'assets/svg/BurgerButton'
import { SlidingMenu } from 'modules/common/components/SlidingMenu'
import { getScaledValue } from 'utils/scale'
import { TextCalibriBold } from 'modules/common/components/Typography'
import { useBrandingColors } from 'hooks/useBrandingColors'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/RemoveCircle'

interface IProps {
  children: React.ReactNode
  screenTitle: string
  onBackBtnClick: () => void
  menuHidden?: boolean
  isColorsReversed?: boolean
  noPadding?: boolean
  onAddBtnClick?: () => void
  onRemoveBtnClick?: () => void
  hidden?: boolean
  additionalHeaderItems?: ReactNode[]
  backArrowHidden?: boolean
  titleStyle?: CSSProperties | undefined
  containerClassName?: string
}

const AppContentLayout: React.FC<IProps> = ({
  children,
  screenTitle,
  onBackBtnClick,
  menuHidden,
  isColorsReversed = false,
  noPadding = false,
  onAddBtnClick,
  onRemoveBtnClick,
  hidden,
  additionalHeaderItems,
  backArrowHidden = false,
  titleStyle,
  containerClassName,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { textOnBackground, text, icon } = useBrandingColors()
  const [isMenuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen)
  }

  return (
    <Screen hidden={hidden ?? false}>
      {!menuHidden && (
        <>
          <SlidingMenuWrapper $isOpen={isMenuOpen}>
            <SlidingMenu />
          </SlidingMenuWrapper>

          <BurgerButtonWrapper onClick={toggleMenu}>
            <BurgerButton />
          </BurgerButtonWrapper>
        </>
      )}
      <Container bgColor={isColorsReversed ? '#fff' : 'transparent'} className={containerClassName}>
        <ScreenHeader>
          {!backArrowHidden && (
            <ArrowWrapper onClick={onBackBtnClick} color={isColorsReversed ? text : '#fff'}>
              <ArrowBackBtn />
            </ArrowWrapper>
          )}

          <Title textColor={isColorsReversed ? text : textOnBackground} style={titleStyle}>
            {screenTitle}
          </Title>

          {onAddBtnClick && (
            <PlusIcon onClick={onAddBtnClick}>
              <AddIcon
                fontSize="large"
                style={{ color: isColorsReversed ? text ?? 'fff' : '#fff' }}
              />
            </PlusIcon>
          )}

          {onRemoveBtnClick && (
            <PlusIcon onClick={onRemoveBtnClick}>
              <RemoveIcon
                fontSize="large"
                style={{ color: isColorsReversed ? text ?? 'fff' : '#fff' }}
              />
            </PlusIcon>
          )}
          {!!additionalHeaderItems && additionalHeaderItems.map((x) => x)}
        </ScreenHeader>
        <ScreenContent noPadding={noPadding}>{children}</ScreenContent>
      </Container>
    </Screen>
  )
}

export default AppContentLayout

const Screen = styled.div`
  position: relative;
  max-height: 100vh;
`

const Container = styled.div<{ bgColor: string }>`
  max-width: 570px;
  margin: 0 auto;
  position: relative;
  background-color: ${({ bgColor }) => bgColor};
  height: 100vh;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`

const ScreenHeader = styled(TextCalibriBold)`
  text-align: center;
  position: relative;
  text-transform: uppercase;
  font-size: ${getScaledValue(11, 'px')};
  color: ${({ theme }: { theme: ITheme }) => theme.text.white};
  display: block;
  padding: 35px 0;
  color: #000;

  @media (max-width: 450px) {
    font-size: 11px;
  }
`

const Title = styled.div<{ textColor: string }>`
  font-size: ${getScaledValue(16, 'px')};
  justify-self: center;
  color: ${({ textColor }) => textColor};
`

const ArrowWrapper = styled.div<{ color: string }>`
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }

  svg {
    stroke: ${({ color }) => color};

    path {
      fill: ${({ color }) => color};
    }
  }

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    left: 0;
  }
`

const ScreenContent = styled.div<{ noPadding: boolean }>`
  padding: ${({ noPadding }) => (noPadding ? 0 : `0 ${getScaledValue(35, 'px')}`)};
  @media (max-width: 450px) {
    ${({ noPadding }) => (noPadding ? 0 : `0 10px`)}
  }
`

const BurgerButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 100;
  cursor: pointer;
  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    display: none;
  }
`

const SlidingMenuWrapper = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  width: 500px;
  right: 0;
  top: ${({ $isOpen }) => ($isOpen ? 0 : '-800px')};
  transition: all 0.4s ease-in-out;
`
const PlusIcon = styled.div`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }

  svg {
    stroke: ${({ color }) => color};

    path {
      fill: ${({ color }) => color};
    }
  }

  @media ${({ theme }: { theme: ITheme }) => theme.breakpoints.sm} {
    right: 0;
  }
`
