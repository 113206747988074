import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const DislikeSvg = (props: { isSelected: boolean }) => {
  const brandingColors = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="98"
      height="107.355"
      viewBox="0 0 98 107.355"
    >
      <defs>
        <linearGradient
          id="dislike-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#c10d06" />
          <stop offset="1" stopColor="#e67707" />
        </linearGradient>
        <filter
          id="Контур_480"
          x="0"
          y="0"
          width="98"
          height="107.355"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Сгруппировать_4570"
        data-name="Сгруппировать 4570"
        transform="translate(-83.393 -2589.326)"
      >
        <g transform="matrix(1, 0, 0, 1, 83.39, 2589.33)" filter="url(#Контур_480)">
          <path
            id="Контур_480-2"
            data-name="Контур 480"
            d="M61.6,14.343l20,11.986a20.792,20.792,0,0,1,10,17.889V68.188a20.574,20.574,0,0,1-10,17.889l-20,11.986a19.154,19.154,0,0,1-20,0l-20-11.986a20.792,20.792,0,0,1-10-17.889V44.217a20.574,20.574,0,0,1,10-17.889l20-11.986A19.155,19.155,0,0,1,61.6,14.343Z"
            transform="translate(-2.6 -5.52)"
            fill={props.isSelected ? 'url(#dislike-gradient)' : 'transparent'}
            stroke={props.isSelected ? 'transparent' : brandingColors.icon}
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
        <g
          id="Сгруппировать_306"
          data-name="Сгруппировать 306"
          transform="matrix(0.875, -0.485, 0.485, 0.875, 96.59, 2625.178)"
        >
          <path
            id="Контур_285"
            data-name="Контур 285"
            d="M-.04,48.754S14.208,28.656,6.231-.005"
            transform="translate(20.421 19.047)"
            fill="none"
            stroke={props.isSelected ? '#fff' : brandingColors.icon}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2.2"
          />
          <path
            id="Контур_286"
            data-name="Контур 286"
            d="M-.007,7.957s9.48,4.33,13.4-.624S9.442-6.667-.007,7.957Z"
            transform="translate(25.869 7.116)"
            fill={props.isSelected ? '#C10D06' : '#fff'}
            stroke={props.isSelected ? '#fff' : brandingColors.icon}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2.2"
          />
          <path
            id="Контур_289"
            data-name="Контур 289"
            d="M8.989,14.077S-.721,10.358.038,4.083,12.869-2.911,8.989,14.077Z"
            transform="translate(13.958 0.011)"
            fill={props.isSelected ? '#C10D06' : '#fff'}
            stroke={props.isSelected ? '#fff' : brandingColors.icon}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2.2"
          />
          <path
            id="Контур_290"
            data-name="Контур 290"
            d="M-.006,6.676S4.743-.527,12.792.02,21.747,9.5-.006,6.676Z"
            transform="translate(27.772 42.202)"
            fill={props.isSelected ? '#C10D06' : '#fff'}
            stroke={props.isSelected ? '#fff' : brandingColors.icon}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2.2"
          />
          <path
            id="Контур_291"
            data-name="Контур 291"
            d="M14.1,1.132S4.285-2.351.79,2.883,6.022,16.549,14.1,1.132Z"
            transform="matrix(0.914, -0.407, 0.407, 0.914, -0.026, 31.527)"
            fill={props.isSelected ? '#C10D06' : '#fff'}
            stroke={props.isSelected ? '#fff' : brandingColors.icon}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2.2"
          />
          <path
            id="Контур_293"
            data-name="Контур 293"
            d="M0,2.5S9.014-2.7,13.4,1.874,10.7,16.209,0,2.5Z"
            transform="translate(25.815 15.458)"
            fill={props.isSelected ? '#C10D06' : '#fff'}
            stroke={props.isSelected ? '#fff' : brandingColors.icon}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2.2"
          />
          <path
            id="Контур_294"
            data-name="Контур 294"
            d="M.747,0s9.71,3.719,8.95,9.995S-3.086,16.987.747,0Z"
            transform="translate(24.407 19.61)"
            fill={props.isSelected ? '#C10D06' : '#fff'}
            stroke={props.isSelected ? '#fff' : brandingColors.icon}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2.2"
          />
          <path
            id="Контур_8799"
            data-name="Контур 8799"
            d="M6.261-.005A6.261,6.261,0,1,1-.005,6.261,6.248,6.248,0,0,1,6.261-.005Z"
            transform="translate(17.782 10.333)"
            fill={props.isSelected ? '#C10D06' : '#fff'}
            stroke={props.isSelected ? '#fff' : brandingColors.icon}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2.2"
          />
        </g>
      </g>
    </svg>
  )
}
