import React from 'react'
import styled from 'styled-components/macro'
import { getScaledValue } from 'utils/scale'

interface IProps {
  percentPlayed: number
}

const VideoProgressBar: React.FC<IProps> = ({ percentPlayed }) => {
  return (
    <ProgressWrapper>
      <ProgressBar>
        <ProgressBg />
        <ProgressLine fillRatio={percentPlayed} />
      </ProgressBar>
    </ProgressWrapper>
  )
}

export default VideoProgressBar

const ProgressWrapper = styled.div`
  margin-top: ${getScaledValue(10, 'px')};
`

const ProgressBar = styled.div`
  height: 8px;
  width: 100%;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
`
const ProgressBg = styled.div`
  background-color: #e2e2e2;
  height: 100%;
  width: 100%;
`
const ProgressLine = styled.div<{ fillRatio: number }>`
  background-color: #7f7f7f;
  height: 100%;
  width: ${({ fillRatio }) => `${fillRatio}%`};
  position: absolute;
  top: 0;

  transition: width 0.2s ease;
`
