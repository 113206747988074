import { RootState } from 'store'
import { createSelector } from '@reduxjs/toolkit'

const aboutMeState = (state: RootState) => state.aboutMe

export const selectUserInfo = createSelector(aboutMeState, (state) => state.info)

export const selectUserId = createSelector(aboutMeState, (state) => state.info.id)

export const selectUserLogo = createSelector(aboutMeState, (state) => state.info.logo)

export const selectUserName = createSelector(aboutMeState, (state) => state.info.fullName)

export const selectTotalPoints = createSelector(aboutMeState, (state) => state.totalPoints)

export const selectOrgLanguages = createSelector(
  aboutMeState,
  (state) => state.organizationLanguages
)

export const selectCourses = createSelector(aboutMeState, (state) => state.courses)

export const selectCoursesTotal = createSelector(aboutMeState, (state) => state.coursesTotal)

export const selectCourseCount = createSelector(aboutMeState, (state) => state.info.courseCount)

export const selectComplimentsCount = createSelector(
  aboutMeState,
  (state) => state.info.complimentsCount
)

export const selectComplaintsCount = createSelector(
  aboutMeState,
  (state) => state.info.complaintsCount
)

export const selectCommentsCount = createSelector(aboutMeState, (state) => state.info.commentsCount)

export const selectSuggestionsCount = createSelector(aboutMeState, (state) => state.info.tipsCount)

export const selectResultsCount = createSelector(aboutMeState, (state) => state.info.resultsCount)

export const selectCourseDetails = createSelector(aboutMeState, (state) => state.courseDetails)

export const selectCourseDates = createSelector(aboutMeState, (state) => state.courseDates)

export const selectIsExpanded = (courseId: string) =>
  createSelector(aboutMeState, (state) => state.expandedItemId === courseId)

export const selectCourseItem = (id: string) =>
  createSelector(aboutMeState, (state) => state.courseDetails?.find((item: any) => item.id === id))

export const selectMentions = createSelector(aboutMeState, (state) => state.mentions)

export const selectMentionsCount = createSelector(aboutMeState, (state) => state.totalMentionsCount)

export const selectResults = createSelector(aboutMeState, (state) => state.results)

export const selectIsLoading = (state: RootState) => state.aboutMe.isLoading
export const selectIsTabContentLoading = (state: RootState) => state.aboutMe.isTabContentLoading
export const selectMyAccount = createSelector(aboutMeState, (state) => state.myAccount)
