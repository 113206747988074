import React from 'react'

export const HexanogYesNoFrame = (props: { isAnswerCorrect: boolean }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="147"
      height="134.21"
      viewBox="0 0 108 102.21"
    >
      <defs>
        <filter
          id="Path_10697"
          x="0"
          y="0"
          width="147"
          height="134.21"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_10697)">
        <path
          id="Path_10697-2"
          data-name="Path 10697"
          d="M54.1,13.916l17,10.17a17.635,17.635,0,0,1,8.5,15.179V59.6a17.451,17.451,0,0,1-8.5,15.179l-17,10.17a16.306,16.306,0,0,1-17,0l-17-10.17A17.635,17.635,0,0,1,11.6,59.6V39.264a17.451,17.451,0,0,1,8.5-15.179l17-10.17A16.306,16.306,0,0,1,54.1,13.916Z"
          transform="translate(-1.1 -3.85)"
          fill="none"
          stroke={props.isAnswerCorrect ? '#28b573' : '#bd3c26'}
          strokeMiterlimit="10"
          strokeWidth="3"
        />
      </g>
    </svg>
  )
}
