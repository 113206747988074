import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const NotesIcon = ({
  isActive,
  noBackground = false,
}: {
  isActive: boolean
  noBackground?: boolean
}) => {
  const { icon } = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52.747"
      height="58.811"
      viewBox="0 0 52.747 58.811"
    >
      <g id="Сгруппировать_4602" data-name="Сгруппировать 4602" transform="translate(-52.393)">
        <g id="Сгруппировать_4601" data-name="Сгруппировать 4601" transform="translate(-24 -38)">
          <g
            id="Сгруппировать_2709"
            data-name="Сгруппировать 2709"
            transform="translate(76.393 38)"
            opacity={isActive ? '1' : '0.504'}
          >
            <path
              id="Контур_161"
              data-name="Контур 161"
              d="M44.567,13.379l13.187,7.889a13.679,13.679,0,0,1,6.593,11.774V48.819a13.536,13.536,0,0,1-6.593,11.774L44.567,68.481a12.648,12.648,0,0,1-13.187,0L18.193,60.593A13.679,13.679,0,0,1,11.6,48.819V33.042a13.536,13.536,0,0,1,6.593-11.774L31.38,13.379A12.649,12.649,0,0,1,44.567,13.379Z"
              transform="translate(-11.6 -11.525)"
              fill={noBackground ? 'none' : '#fff'}
            />
          </g>
          <g
            id="Сгруппировать_3121"
            data-name="Сгруппировать 3121"
            transform="translate(87.856 52.65)"
          >
            <path
              id="Контур_7892"
              data-name="Контур 7892"
              d="M-1194.082,665.769c-.009-1.432-.76-2-2.321-2q-12.681-.006-25.361,0c-1.814,0-2.49.669-2.492,2.453q0,12.681,0,25.362c0,1.806.662,2.458,2.488,2.46q7.337.006,14.676,0h3.012c0-2.024.039-3.933-.007-5.838-.106-4.371.221-4.388,4.243-4.31,1.871.036,3.744.007,5.624.007a2.329,2.329,0,0,0,.148-.527c.007-5.87.031-11.741-.012-17.611m-10.325,11.754h-14.343a1.2,1.2,0,0,1-1.175-1.176,1.186,1.186,0,0,1,1.175-1.175h14.343a1.2,1.2,0,0,1,1.175,1.175,1.189,1.189,0,0,1-1.175,1.176m4.569-5.926h-18.912a1.194,1.194,0,0,1-1.175-1.176,1.187,1.187,0,0,1,1.175-1.175h18.912a1.2,1.2,0,0,1,1.176,1.175,1.189,1.189,0,0,1-1.176,1.176"
              transform="translate(1224.257 -663.77)"
              fill={isActive ? icon : '#fff'}
            />
            <path
              id="Контур_7893"
              data-name="Контур 7893"
              d="M-1207.087,688.126l7.156-7.181h-7.156Z"
              transform="translate(1229.954 -658.072)"
              fill={isActive ? icon : '#fff'}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
