import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const IconQuestion = () => {
  const { icon, frame } = useBrandingColors()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="68.818"
      height="74.23"
      viewBox="0 0 68.818 74.23"
    >
      <defs>
        <filter
          id="Контур_1279"
          x="0"
          y="0"
          width="68.818"
          height="74.23"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Сгруппировать_4592"
        data-name="Сгруппировать 4592"
        transform="translate(-460.895 -1669.9)"
      >
        <g transform="matrix(1, 0, 0, 1, 460.9, 1669.9)" filter="url(#Контур_1279)">
          <path
            id="Контур_1279-2"
            data-name="Контур 1279"
            d="M0,27.891c.148-3.173.074-6.271.443-9.444A11.986,11.986,0,0,1,6.271,9.373C10.329,6.791,14.534,4.43,18.666,2c4.574-2.73,9.149-2.582,13.723.074C36.078,4.282,39.767,6.5,43.456,8.635c4.353,2.582,6.935,6.345,7.083,11.436.148,5.681.074,11.436-.221,17.117a11.073,11.073,0,0,1-5.091,8.706c-4.648,3.025-9.444,5.9-14.313,8.632a11.229,11.229,0,0,1-11.288-.074c-4.5-2.508-8.854-5.165-13.206-7.894C2.213,43.9.3,39.918.148,35.048c-.074-2.361,0-4.722,0-7.157Z"
            transform="translate(9.1 6.09)"
            fill="#fff"
            stroke={frame}
            strokeWidth="0.2"
          />
        </g>
        <g
          id="Сгруппировать_1191"
          data-name="Сгруппировать 1191"
          transform="translate(488 1690.539)"
        >
          <g id="Сгруппировать_215" data-name="Сгруппировать 215" transform="translate(0.5 0.461)">
            <path
              id="Контур_333"
              data-name="Контур 333"
              d="M14.632,8.093a8.889,8.889,0,0,1-.47,3.02A6.793,6.793,0,0,1,12.8,13.439a6.629,6.629,0,0,1-2.079,1.584c-1.188.569-2.549.173-2.549,2.648,0,1.856,0,3.069-2.129,3.069-1.955,0-2.129-1.312-2.129-4.381,0-4.554,5.173-2.624,6.262-6.188a5.963,5.963,0,0,0,.248-1.658,6.011,6.011,0,0,0-.272-1.807A3.7,3.7,0,0,0,9.311,5.3,3.769,3.769,0,0,0,7.9,4.381a5.5,5.5,0,0,0-2-.322,7.979,7.979,0,0,0-1.832.2c-.52.149-.99.3-1.361.446s-.718.347-.965.47a1.5,1.5,0,0,1-.594.2.412.412,0,0,1-.272-.1.587.587,0,0,1-.2-.347A3.239,3.239,0,0,1,.55,4.257C.525,3.96.5,3.564.5,3.119a1.164,1.164,0,0,1,.05-.47.823.823,0,0,1,.272-.52,2.18,2.18,0,0,1,.792-.5A13.414,13.414,0,0,1,8.222.5,6.433,6.433,0,0,1,12.5,2.426,7.176,7.176,0,0,1,14.113,5,9.123,9.123,0,0,1,14.632,8.093Z"
              transform="translate(-0.5 -0.461)"
              fill={icon}
            />
          </g>
          <circle
            id="Эллипс_32"
            data-name="Эллипс 32"
            cx="2.054"
            cy="2.054"
            r="2.054"
            transform="translate(4.089 22.498)"
            fill={icon}
          />
        </g>
      </g>
    </svg>
  )
}
