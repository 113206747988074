import { CourseResultResponse, SeriesResultResponse } from 'api/timeline/types/response'
import { CourseResult, SeriesResultType } from 'store/types'
import { formatName } from 'utils/nameFormatter'
import { mapMedia } from './TimeLineMapper'

export const seriesResultsMapper = (
  response: SeriesResultResponse,
  wasRead: boolean
): SeriesResultType => ({
  id: response.id,
  media: mapMedia(response.attachment),
  header: response.header,
  title: response.title,
  resultOptions: response.resultOptions,
  userName: response.userName ? formatName(response.userName) : null,
  userSeriesVersionId: response.userSeriesVersionId,
  points: response.points,
  hasReport: response.hasReport,
  wasRead,
})

export const courseResultsMapper = (
  response: CourseResultResponse,
  wasRead: boolean,
  groupParentId?: string
): CourseResult => ({
  id: response.id,
  media: mapMedia(response.attachment),
  header: response.header,
  title: response.title,
  points: response.points,
  resultOptions: response.resultOptions,
  hasReport: response.hasReport,
  userName: response.userName ? formatName(response.userName) : null,
  userSeriesVersionId: response.userSeriesVersionId,
  groupParentId: groupParentId || null,
  wasRead,
})
