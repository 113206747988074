import { ActivePageResponse, EpisodeDetailsItem, EpisodeResponse } from 'api/episode/types/response'
import { EpisodeDetailsTimeline, TimelineEpisode, ActivePageTimeline } from 'store/types'
import { EpisodePropertiesType } from 'store/types/episode'
import { episodeItemMapper } from './EpisodeMapper'
import { mapMedia } from './TimeLineMapper'

export const episodeMapper = (
  episode: EpisodeResponse,
  groupParentId?: string
): TimelineEpisode => {
  const { episodeLogo, episodeName, index, textTypes } = JSON.parse(
    episode.properties
  ) as EpisodePropertiesType

  return {
    id: episode.id,
    seriesName: episode.seriesName,
    type: episode.type,
    episodeLogo,
    episodeName,
    index,
    textTypes,
    media: mapMedia(episode.attachment),
    progress: episode.progress,
    unreadMessageCount: episode.unreadMessageCount,
    userSeriesVersionId: episode.userSeriesVersionId,
    isCommentsFollowed: episode.isCommentsFollowed,
    groupParentId: groupParentId || null,
  }
}

export const activePageMapper = (activePage: ActivePageResponse): ActivePageTimeline => {
  return {
    attachment: mapMedia(activePage.attachment),
    seriesVersionAttachment:
      activePage.seriesVersionAttachment && activePage.seriesVersionAttachment.content,
    isLastPage: activePage.isLastPage,
    isActivePage: activePage.isActivePage,
    previosPage: activePage.previosPage,
    properties: JSON.parse(activePage.properties),
    questionId: activePage.questionId,
    questionProgress: activePage.questionProgress,
    questions: activePage.questions.map((item) => episodeItemMapper(item)),
  }
}

export const detailsMapper = (episode: EpisodeDetailsItem): EpisodeDetailsTimeline => {
  const { episodeName, textTypes } = JSON.parse(episode.properties)

  return {
    id: episode.id,
    attachment: mapMedia(episode.attachment),
    progress: episode.progress,
    episodeName,
    textTypes,
    properties: episode.properties,
    score: episode.score,
    subEpisodes: episode.subEpisodes.map((item) => {
      const { episodeName, textTypes } = JSON.parse(item.properties)
      return {
        ...item,
        episodeName,
        textTypes,
      }
    }),
    times: episode.times,
    type: episode.type,
    episodeLogo: episode.episodeLogo,
    unreadMessageCount: episode.unreadMessageCount,
    isCommentsFollowed: episode.isCommentsFollowed,
    hasQuestions: episode.hasQuestions,
  }
}
