import { authInstanceOrg } from 'api/axios.config'
import { NewGroupSettingsResponse } from './types/response'
import { NewGroupCreateRequest } from './types/request'
import { DropDownMapper } from './../mappers/DropDownMapper'

export class NewGroupApi {
  static getNewGroupSettings = async (id: string) => {
    const { data } = await authInstanceOrg.get<NewGroupSettingsResponse>(
      `/group/getNewGroupSettings/${id}`
    )

    return {
      ...data,
      journeys: DropDownMapper(data.journeys),
      addManagerOfGroups: DropDownMapper(data.addManagerOfGroups),
    }
  }

  static isGroupExist = async (title: string) => {
    return await authInstanceOrg.get<boolean>(`/group/isGroupExist/${title}`)
  }

  static createGroup = async (model: NewGroupCreateRequest) => {
    return await authInstanceOrg.post<{
      errorType: number | null
      joinUrl: string | null
    }>(`/group/newGroupCreate`, model)
  }
}
