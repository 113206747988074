export enum HTTP_STATUS_CODES {
  ERROR = 409,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  INTERNAL_SERVER_ERROR = 500,
}

export enum REFRESH_TOKEN_RESPONSE_CODES {
  SUCCESS = 0,
  FAILED = 1,
  USER_DOES_NOT_EXIST = 2,
  USER_DEACTIVATED = 3,
}

export enum DELETE_USER_RESPONSE_CODES {
  SUCCESS,
  FAILED,
  ONLY_ONE_IN_SERIES,
}
