import { TimelineEpisodeResponse } from 'api/timeline/types/response'
import {
  EpisodeItem,
  EpisodePropertiesType,
  EpisodeQuestionType,
  EpisodeResults,
} from 'store/types/episode'
import { mapMedia } from './TimeLineMapper'
import { mapAnswerKey, mapEpisodeItemType } from './TimelineQuestionMapper'

export const episodeMapper = (response: TimelineEpisodeResponse, date: string): EpisodeResults => {
  const { episodeLogo, episodeName, index, textTypes } = JSON.parse(
    response.properties
  ) as EpisodePropertiesType

  return {
    id: response.id,
    media: mapMedia(response.attachment),
    seriesName: response.seriesName,
    progress: response.progress,
    type: response.type,
    unreadMessageCount: response.unreadMessageCount,
    userSeriesVersionId: response.userSeriesVersionId,
    episodeLogo,
    episodeName,
    index,
    date,
    textTypes,
    itemType: mapEpisodeItemType(response.type),
    isCommentsFollowed: response.isCommentsFollowed,
    groupParentId: null,
  }
}

export const episodeItemMapper = (data: EpisodeQuestionType): EpisodeItem => {
  return {
    id: data.id,
    questionId: data.questionId,
    date: data.dateActiveFrom,
    media: mapMedia(data.attachment),
    text: data.text || '',
    variants: data.answerOptions.map((answer) => {
      return {
        id: answer.id,
        value: answer.value !== null ? answer.value : '',
        key: mapAnswerKey(data.type, answer.key),
        isAnswerCorrect: false,
        isAnswerSaved: false,
      }
    }),
    answer: data.answer
      ? {
          id: data.answer.id,
          value: data.answer.value,
          key: mapAnswerKey(data.type, data.answer.key),
          isAnswerCorrect: data.answer.isAnswerCorrect,
          isAnswerSaved: data.answer.isAnswerSaved,
        }
      : null,
    itemType: mapEpisodeItemType(data.type),
    isSaved: data.answer !== null,
    isSaveInProgress: false,
    value: data.value,
    min: data.min,
    max: data.max,
    answered: !!data.answer,
    unreadCommentsCount: data.unreadCommentsCount,
    hasNote: data.hasNote,
    hasWrongAnswer: data.hasWrongAnswer,
    properties: JSON.parse(data.properties),
    isCommentsFollowed: data.isCommentsFollowed,
    questionProgress: data.questionProgress,
    isHighlighted: data.isHighlighted,
    isSeriesQuestion: data.isSeriesQuestion,
  }
}
