import {
  createSlice,
  isPending,
  isRejectedWithValue,
  isFulfilled,
  PayloadAction,
} from '@reduxjs/toolkit'
import {
  getActivePage,
  getNextPage,
  getPreviousPage,
  completeActivePage,
  getEpisodePrivacySettings,
  getEpisodePrivacySettingsMeOther,
  getGroupedEpisodePrivacySettings,
} from './thunks'
import { EpisodeStateType } from './types'
import { ActivePageTimeline, TimelineAnswer } from 'store/types'
import { EpisodePrivacySettingsResponse } from 'api/episode/types/response'

const initialState: EpisodeStateType = {
  episodePrivacySettings: {} as EpisodePrivacySettingsResponse,
  activePage: {} as ActivePageTimeline,
  progressArray: [],
  isLoading: false,
  error: null,
  success: false,
}

const episodeSlice = createSlice({
  name: 'episode',
  initialState,
  reducers: {
    clearActivePage: (state) => {
      state.activePage = initialState.activePage
    },

    setIsSaved: (
      state,
      action: PayloadAction<{ questionId: string; isAnswerCorrect: boolean; isSuccess: boolean }>
    ) => {
      state.activePage.questions = state.activePage.questions.map((item) => {
        if (item.id === action.payload.questionId) {
          if (item?.answer) {
            item.answer.isAnswerCorrect = action.payload.isAnswerCorrect
            item.answer.isAnswerSaved = action.payload.isSuccess
          }
          return {
            ...item,
            isSaveInProgress: false,
            isSaved: true,
          }
        }
        return item
      })
    },

    setSaveInProgress: (state, action: PayloadAction<{ questionId: string }>) => {
      state.activePage.questions = state.activePage.questions.map((item) => {
        if (item.id === action.payload.questionId) {
          return {
            ...item,
            isSaveInProgress: true,
          }
        }
        return item
      })
    },

    setIsAnswered: (state, action: PayloadAction<string>) => {
      state.activePage.questions = state.activePage.questions.map((item) => {
        if (item.id === action.payload) {
          return {
            ...item,
            answered: true,
          }
        }
        return item
      })
    },

    setEpisodeQuestionAnswer: (
      state,
      action: PayloadAction<{ answer: TimelineAnswer | null; questionId: string }>
    ) => {
      state.activePage.questions = state.activePage.questions.map((item) => {
        if (item.id === action.payload.questionId) {
          return {
            ...item,
            answer: action.payload.answer,
          }
        }
        return item
      })
    },

    setQuestionHighlighted: (
      state,
      action: PayloadAction<{ questionId: string; isHighlighted: boolean }>
    ) => {
      state.activePage.questions = state.activePage.questions.map((item) => {
        if (item.id === action.payload.questionId) {
          return {
            ...item,
            isHighlighted: action.payload.isHighlighted,
          }
        }
        return item
      })
    },

    updateVideoProgress: (
      state,
      action: PayloadAction<{ id: string; progressUpdate: number; isPage: boolean }>
    ) => {
      if (!state.activePage.questions || !state.activePage.questions.length) return

      if (action.payload.isPage) {
        state.activePage.questionProgress =
          state.activePage.questionProgress + action.payload.progressUpdate
      }

      if (!action.payload.isPage) {
        state.activePage.questions = state.activePage.questions.map((question) => {
          if (question.id === action.payload.id) {
            return {
              ...question,
              questionProgress: question.questionProgress + action.payload.progressUpdate,
            }
          }
          return question
        })
      }
    },

    clearEpisodePrivacySettings: (state) => {
      state.episodePrivacySettings = initialState.episodePrivacySettings
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEpisodePrivacySettings.fulfilled, (state, action) => {
      state.episodePrivacySettings = action.payload
    })
    builder.addCase(getGroupedEpisodePrivacySettings.fulfilled, (state, action) => {
      state.episodePrivacySettings = action.payload
    })

    builder.addCase(getEpisodePrivacySettingsMeOther.fulfilled, (state, action) => {
      state.episodePrivacySettings = action.payload
    })

    builder.addMatcher(
      isFulfilled(getActivePage, getNextPage, getPreviousPage, completeActivePage),
      (state, action) => {
        if (action.payload) {
          state.activePage = action.payload

          const pageProgress = {
            progress: action.payload.questionProgress,
            questionId: action.payload.questionId,
          }
          const pageItemProgresses = action.payload.questions.map((question) => {
            return {
              progress: question.questionProgress,
              questionId: question.questionId,
            }
          })

          state.progressArray = [pageProgress, ...pageItemProgresses]
        }
      }
    )

    builder.addMatcher(
      isFulfilled(
        getActivePage,
        getNextPage,
        getPreviousPage,
        completeActivePage,
        getEpisodePrivacySettings,
        getGroupedEpisodePrivacySettings
      ),
      (state) => {
        state.isLoading = false
        state.error = null
      }
    )

    builder.addMatcher(
      isPending(
        getActivePage,
        getNextPage,
        getPreviousPage,
        getEpisodePrivacySettings,
        completeActivePage,
        getGroupedEpisodePrivacySettings
      ),
      (state) => {
        state.isLoading = true
        state.error = null
        state.success = false
      }
    )
    builder.addMatcher(
      isRejectedWithValue(
        getActivePage,
        getNextPage,
        getPreviousPage,
        getEpisodePrivacySettings,
        completeActivePage,
        getGroupedEpisodePrivacySettings
      ),
      (state, action) => {
        state.error = {
          message: action.payload.message,
        }
        state.isLoading = false
        state.success = false
      }
    )
  },
})

export const episodeReducer = episodeSlice.reducer
export const {
  clearActivePage,
  setIsSaved,
  setIsAnswered,
  setEpisodeQuestionAnswer,
  clearEpisodePrivacySettings,
  setQuestionHighlighted,
  updateVideoProgress,
  setSaveInProgress,
} = episodeSlice.actions
