import React from 'react'
import { useBrandingColors } from 'hooks/useBrandingColors'

export const PersonIconTransparent = ({ isActive }: { isActive: boolean }) => {
  const { icon } = useBrandingColors()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="53.64"
      height="59.961"
      viewBox="0 0 53.64 59.961"
    >
      <defs>
        <clipPath id="clip-path-person-tp">
          <path
            id="Контур_160"
            data-name="Контур 160"
            d="M44.5,13.375l13.16,7.87a13.646,13.646,0,0,1,6.58,11.746v15.74a13.5,13.5,0,0,1-6.58,11.746L44.5,68.348a12.626,12.626,0,0,1-13.16,0l-13.16-7.87A13.646,13.646,0,0,1,11.6,48.732V32.992a13.5,13.5,0,0,1,6.58-11.746l13.16-7.87A12.626,12.626,0,0,1,44.5,13.375Z"
            transform="translate(-11.6 -11.525)"
            fill={isActive ? icon : '#fff'}
          />
        </clipPath>
      </defs>
      <g
        id="Сгруппировать_4589"
        data-name="Сгруппировать 4589"
        transform="translate(-81.893 -147.371)"
      >
        <g
          id="Сгруппировать_2766"
          data-name="Сгруппировать 2766"
          transform="translate(82.393 148)"
          opacity={isActive ? '1' : '0.5'}
        >
          <g
            id="Сгруппировать_53"
            data-name="Сгруппировать 53"
            transform="translate(0 0)"
            clipPath="url(#clip-path-person-tp)"
          >
            <rect
              id="Прямоугольник_7"
              data-name="Прямоугольник 7"
              width="64.625"
              height="70.478"
              transform="translate(-5.992 -5.903)"
              fill={isActive ? icon : '#fff'}
            />
          </g>
          <path
            id="Контур_161"
            data-name="Контур 161"
            d="M44.5,13.375l13.16,7.87a13.646,13.646,0,0,1,6.58,11.746v15.74a13.5,13.5,0,0,1-6.58,11.746L44.5,68.348a12.626,12.626,0,0,1-13.16,0l-13.16-7.87A13.646,13.646,0,0,1,11.6,48.732V32.992a13.5,13.5,0,0,1,6.58-11.746l13.16-7.87A12.626,12.626,0,0,1,44.5,13.375Z"
            transform="translate(-11.6 -11.525)"
            fill="#fff"
          />
        </g>
        <g id="Group_3172" data-name="Group 3172" transform="translate(-642.398 -227.582)">
          <path
            id="Path_7934"
            data-name="Path 7934"
            d="M757.418,394.19a7.608,7.608,0,1,0-7.609,7.608,7.608,7.608,0,0,0,7.609-7.608"
            transform="translate(0.929 0)"
            fill={isActive ? icon : '#fff'}
          />
          <path
            id="Path_7935"
            data-name="Path 7935"
            d="M760.468,404.681a9.153,9.153,0,0,0-1.613-1.455,15.027,15.027,0,0,0-16.263,0,9.209,9.209,0,0,0-1.615,1.455,6.026,6.026,0,0,0-1.579,3.952v9.62h22.64v-9.62a6.025,6.025,0,0,0-1.577-3.952"
            transform="translate(0 4.72)"
            fill={isActive ? icon : '#fff'}
          />
        </g>
      </g>
    </svg>
  )
}
