import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TokenResponse } from 'api/auth/types/response'
import { checkUserIndividual, getUsersList } from './thunks'
import { UserState } from './types'

const initialState: UserState = {
  currentUser: {} as TokenResponse,
  phoneNumber: '',
  email: '',
  users: [],
  searchPage: 1,
  hasOneOrganization: true,
  isUserIndividual: false,
  total: 0,
  userListLoading: false,
  isLoading: false,
  error: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setOrgInfo: (state, action: PayloadAction<boolean>) => {
      state.hasOneOrganization = action.payload
    },

    clearUsersSearchList: (state) => {
      state.users = initialState.users
      state.searchPage = initialState.searchPage
      state.total = initialState.total
    },
    setTotalUsers: (state, action: PayloadAction<number>) => {
      state.total = action.payload
    },

    setCurrentUser: (state, action: PayloadAction<TokenResponse>) => {
      state.currentUser = action.payload
    },

    setUserLanguage: (
      state,
      action: PayloadAction<{ languageId: number; languageCode: string }>
    ) => {
      state.currentUser.credentialsInfo.userLanguageId = action.payload.languageId
      state.currentUser.credentialsInfo.languageId = action.payload.languageId
      state.currentUser.credentialsInfo.languageCode = action.payload.languageCode
    },

    updateCurrentUserLogo: (state, action: PayloadAction<any>) => {
      state.currentUser.credentialsInfo.userLogo = action.payload
    },
    clearUserInfo: (state) => {
      state.currentUser = {} as TokenResponse
      state.phoneNumber = ''
      state.email = ''
      state.users = []
      state.searchPage = 1
      state.hasOneOrganization = true
      state.total = 0
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsersList.fulfilled, (state, action) => {
      if (action.payload.isSearch) {
        state.users = action.payload.users
      }

      if (!action.payload.isSearch) {
        state.users = [...state.users, ...action.payload.users]
      }

      state.total = action.payload.total
      state.searchPage = action.payload.searchPage
      state.userListLoading = false
    })
    builder.addCase(getUsersList.pending, (state) => {
      if (state.searchPage === 1) {
        state.userListLoading = true
      }
    })
    builder.addCase(getUsersList.rejected, (state, action) => {
      state.userListLoading = false
      if (action.payload) {
        state.error = {
          message: action.payload.message,
        }
      }
    })

    builder.addCase(checkUserIndividual.fulfilled, (state, action) => {
      state.isUserIndividual = action.payload
    })
  },
})

export const userReducer = userSlice.reducer
export const {
  setOrgInfo,
  setCurrentUser,
  setUserLanguage,
  setTotalUsers,
  clearUsersSearchList,
  clearUserInfo,
  updateCurrentUserLogo,
} = userSlice.actions
